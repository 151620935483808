import React from 'react';

import UserWithHealthProfile from '../../containers/userWithHealthProfile';

const ClinicalTrialCounselingDetail = () => {
  return (
    <UserWithHealthProfile type="clinicalTrialCounseling" tabLink="tabData" />
  );
};

export default ClinicalTrialCounselingDetail;
