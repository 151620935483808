// set file input event (image upload)
import { useState, useEffect, useCallback } from 'react';
import qs from 'query-string';
import {
  getSignedUrl,
  changeAccessRangeToPublic,
  getMultiSignedUrl,
  deleteImageRequest,
  deleteImagesRequest,
} from '../../services/fileUploadService';
import { useFetch } from '../../hooks/useRequest';

export function useDistinctFileUpload(fileInputEvent) {
  const [done, setDone] = useState(false);
  const [publicUrl, setPublicUrl] = useState(null);
  const [filePath, setFilePath] = useState(null);
  const {
    done: changeDone,
    call: changeUrlToPublic,
    initialize: initializeDone,
  } = useFetch(null, changeAccessRangeToPublic, { filePath });

  const {
    data: signedUrlData,
    call: fetchSignedUrl,
    initializeData: initializeSignedUrl,
  } = useFetch(null, getSignedUrl, {
    params: {
      functionType: 'PUT',
      fileName: fileInputEvent?.target?.files[0].name,
      contentType: fileInputEvent?.target?.files[0].type,
    },
  });

  if (fileInputEvent) fileInputEvent.persist();

  const onUploadFinish = (newSignedUrlUploadData) => {
    setFilePath(newSignedUrlUploadData?.filePath);
  };

  useEffect(() => {
    if (filePath) {
      changeUrlToPublic();
      setFilePath(null);
    }
  }, [filePath, changeDone, changeUrlToPublic]);

  useEffect(() => {
    if (changeDone) {
      setDone(true);
      initializeSignedUrl();
      initializeDone();
    }
  }, [changeDone, initializeDone, initializeSignedUrl]);

  const onUploadError = () => {
    alert('이미지 업로드에 실패했습니다!');
  };

  const imageUpload = () => {
    fetchSignedUrl();
  };
  useEffect(() => {
    if (signedUrlData) {
      const data = signedUrlData;
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', data.signedUrl);
      xhr.onload = () => {
        onUploadFinish(data);
      };
      xhr.onerror = onUploadError;
      xhr.setRequestHeader(
        'Content-Type',
        fileInputEvent.target?.files[0].type,
      );
      xhr.send(fileInputEvent.target?.files[0]);
      setPublicUrl(data.publicUrl);
    }
  }, [fileInputEvent, signedUrlData]);

  const initialize = () => {
    setPublicUrl(null);
    setDone(false);
  };
  return { publicUrl, done, imageUpload, initialize };
}

export function useDistinctFileDelete(distinctImageToDelete) {
  const [done, setDone] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileNameList, setFileNameList] = useState(null);
  const [deleteDone, setDeleteDone] = useState(false);

  const isArrayData = typeof distinctImageToDelete === 'object';

  const isSingleImageDelete =
    !isArrayData || distinctImageToDelete?.length === 1;

  const {
    data: signedUrlData,
    call: fetchSignedUrl,
    initialize: initializeSignedUrlDone,
  } = useFetch(null, getSignedUrl, {
    params: {
      functionType: 'DELETE',
      fileName,
      contentType: '',
    },
  });

  const {
    data: multiSignedUrlData,
    call: fetchMultiSignedUrl,
    initialize: initializeMultiSignedUrlDone,
  } = useFetch(null, getMultiSignedUrl, {
    functionType: 'DELETE',
    fileNameList,
    contentType: '',
  });

  useEffect(() => {
    if (fileName) {
      fetchSignedUrl();
      setFileName(null);
    }
  }, [fileName, fetchSignedUrl]);

  const deleteImage = useCallback(async () => {
    await deleteImageRequest(signedUrlData);
    setDone(true);
    initializeSignedUrlDone();
  }, [signedUrlData, initializeSignedUrlDone]);

  useEffect(() => {
    if (signedUrlData && !deleteDone) {
      setDeleteDone(true);
      deleteImage();
    }
  }, [signedUrlData, deleteImage, deleteDone]);

  useEffect(() => {
    if (fileNameList) {
      fetchMultiSignedUrl();
      setFileNameList(null);
    }
  }, [fileNameList, fetchMultiSignedUrl]);

  const deleteImages = useCallback(async () => {
    await deleteImagesRequest(multiSignedUrlData);
    setDone(true);
    initializeMultiSignedUrlDone();
  }, [multiSignedUrlData, initializeMultiSignedUrlDone]);

  useEffect(() => {
    if (multiSignedUrlData && !deleteDone) {
      setDeleteDone(true);
      deleteImages();
    }
  }, [multiSignedUrlData, deleteImages, deleteDone]);

  const imageDelete = async () => {
    const newFileName = isArrayData
      ? distinctImageToDelete[0]
      : distinctImageToDelete;
    if (isSingleImageDelete) {
      setFileName(newFileName);
    } else {
      setFileNameList(distinctImageToDelete);
    }
  };

  const initialize = () => {
    setDone(false);
  };
  return { done, imageDelete, initialize };
}
