import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 연구 신청 데이터를 가져옵니다.
 */
export async function fetchAllGeneResearch() {
  const res = await axios.get(`/v4/admin/userParticipationHistories`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 연구 신청 내역을 가져옵니다.
 * @param {String} userId
 */
export async function fetchGeneResearchContent(userId) {
  const res = await axios.get(
    `/v4/admin/users/${userId}/userParticipationHistories`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 연구 신청 내역을 수정합니다.
 * @param {String} geneResearchId
 * @param {String} memo
 */
export async function patchGeneResearchContent(geneResearchId, { memo }) {
  const res = await axios.patch(
    `/v4/admin/userParticipationHistories/${geneResearchId}`,
    { memo },
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 연구 신청 내역을 삭제합니다.
 * @param {String} geneResearchId
 */
export async function deleteGeneResearchContent(geneResearchId) {
  const res = await axios.delete(
    `/v4/admin/userParticipationHistories/${geneResearchId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 연구 리스트 데이터를 가져옵니다.
 */
export async function fetchAllResearch() {
  const res = await axios.get(`/v4/admin/researches`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 연구 정보를 가져옵니다.
 * @param {string} researchId
 *
 */
export async function fetchResearch(researchId) {
  const res = await axios.get(`/v4/admin/researches/${researchId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 연구를 생성합니다.
 * @param {object} body
 */
export async function postResearch(body) {
  const res = await axios.post(`/v4/admin/researches`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 연구를 수정합니다.
 * @param {String} researchId
 * @param {object} body
 */
export async function patchResearch(researchId, body) {
  const res = await axios.patch(`/v4/admin/researches/${researchId}`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 연구를 삭제합니다.
 * @param {String} researchId
 */
export async function deleteResearch(researchId) {
  const res = await axios.delete(`/v4/admin/researches/${researchId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 연구 배포 여부를 변경합니다.
 * @param {object} body
 */
export async function patchDeployResearch(body) {
  const res = await axios.patch('/v4/admin/researches', body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
