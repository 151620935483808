import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Layout, Tabs } from 'antd';
import 'antd/dist/antd.css';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { treatmentNewsCreators } from '../../../store/reducers/treatmentNews.reducer';
import ContentsInfo from './contentsInfo';
import { tagCreators } from '../../../store/reducers/tag.reducer';
import TreatmentNews from './treatmentNews';

const Contents = () => {
  const dispatch = useDispatch();
  const { treatmentNews, treatmentNewsOld } = useSelector((state) => {
    return {
      treatmentNews: state.treatmentNewsReducer.treatmentNews.data?.filter(
        (info) => info.category === 'content' && info.styleVersion === 2,
      ),
      treatmentNewsOld: state.treatmentNewsReducer.treatmentNews.data?.filter(
        (info) => info.category === 'content' && info.styleVersion === 1,
      ),
    };
  }, shallowEqual);

  const getData = useCallback(() => {
    dispatch(treatmentNewsCreators.fetchAllTreatmentNews.request({}));
    dispatch(tagCreators.fetchTopicTags.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const { TabPane } = Tabs;
  const { Content } = Layout;

  const [key, setKey] = useState('content');
  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="contents" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="콘텐츠"
            subTitle="레어노트 콘텐츠"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Tabs defaultActiveKey={key} onChange={setKey}>
              <TabPane tab="레어노트 콘텐츠" key="content">
                <ContentsInfo data={treatmentNews} getData={getData} />
              </TabPane>
              <TabPane tab="구_소식" key="treatmentNews">
                <TreatmentNews
                  treatmentNewsOld={treatmentNewsOld}
                  getData={getData}
                />
              </TabPane>
            </Tabs>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Contents;
