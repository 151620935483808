import { all, takeLatest, call } from 'redux-saga/effects';
import {
  diseaseTypeCreators,
  diseaseTypeActions,
} from '../reducers/diseaseType.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  fetchAllDiseaseType,
  fetchDiseaseType,
  fetchDiseaseGroupDiseaseType,
  postDiseaseType,
  patchDiseaseType,
  patchDistributeDiseaseType,
  deleteDiseaseType,
} from '../../services/diseaseTypeService';

const handleFetchAllDiseaseTypes = createAsyncSaga(
  diseaseTypeCreators.fetchAllDiseaseTypes,
  fetchAllDiseaseType,
);

const handleFetchDiseaseType = createAsyncSaga(
  diseaseTypeCreators.fetchDiseaseType,
  fetchDiseaseType,
);

const handleFetchDiseaseGroupDiseaseType = createAsyncSaga(
  diseaseTypeCreators.fetchDiseaseGroupDiseaseType,
  fetchDiseaseGroupDiseaseType,
);

const handlePostDiseaseType = createAsyncSaga(
  diseaseTypeCreators.postDiseaseType,
  postDiseaseType,
);

const handlePatchDiseaseType = createAsyncSaga(
  diseaseTypeCreators.patchDiseaseType,
  patchDiseaseType,
);

const handleDeleteDiseaseType = createAsyncSaga(
  diseaseTypeCreators.deleteDiseaseType,
  deleteDiseaseType,
);

function* handlePatchDistrubuteDiseaseType({ payload }) {
  try {
    yield call(patchDistributeDiseaseType, payload);
  } catch (err) {
    console.log(err);
  }
}

export function* diseaseTypeSaga() {
  yield all([
    takeLatest(
      diseaseTypeActions.FETCH_ALL_DISEASE_TYPES,
      handleFetchAllDiseaseTypes,
    ),
    takeLatest(diseaseTypeActions.FETCH_DISEASE_TYPE, handleFetchDiseaseType),
    takeLatest(
      diseaseTypeActions.FETCH_DISEASE_GROUP_DISEASE_TYPE,
      handleFetchDiseaseGroupDiseaseType,
    ),
    takeLatest(diseaseTypeActions.POST_DISEASE_TYPE, handlePostDiseaseType),
    takeLatest(diseaseTypeActions.PATCH_DISEASE_TYPE, handlePatchDiseaseType),
    takeLatest(
      diseaseTypeActions.PATCH_DISTRIBUTE_DISEASE_TYPE,
      handlePatchDistrubuteDiseaseType,
    ),
    takeLatest(diseaseTypeActions.DELETE_DISEASE_TYPE, handleDeleteDiseaseType),
  ]);
}
