export const SUCCESS = 0;
export const INVALID_PARAMETER = 1;
export const UNAUTHORIZED = 2;
export const CONFLICT = 3;
export const NO_MATCHING_DATA_FOUND = 4;
export const TOKEN_HAS_BEEN_EXPIRED = 5;
export const TRANSACTION_FAILED = 6;
export const INTERNAL_SERVER_ERROR = 7;
export const EXTERNAL_SERVER_ERROR = 8;
export const FORBIDDEN = 10;

export const HTTP_SUCCESS = 200;
export const HTTP_CREATED = 201;
export const HTTP_NO_CONTENT = 204;
export const HTTP_BAD_REQUEST = 400;
export const HTTP_UNAUTHORIZED = 401;
export const HTTP_NOT_FOUND = 404;
export const HTTP_CONFLICT = 409;
export const HTTP_INTERNAL_SERVER_ERROR = 500;
