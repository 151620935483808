export default function imagesOnPage(content) {
  const imageList = content.getElementsByTagName('img');
  const existImage = [];
  for (let i = 0; i < imageList.length; i += 1) {
    const indexSrc = imageList[i].outerHTML.indexOf('src');
    const indexStart = imageList[i].outerHTML.substring(indexSrc).indexOf('"');
    const indexEnd = imageList[i].outerHTML
      .substring(indexSrc + indexStart + 1)
      .indexOf('"');
    const imageUrl = imageList[i].outerHTML.substring(
      indexSrc + indexStart + 1,
      indexSrc + indexStart + indexEnd + 1,
    );
    existImage.push(imageUrl);
  }
  return existImage;
}
