import React from 'react';
import { Button } from 'antd';
import 'antd/dist/antd.css';

const DeployButton = ({
  onClickDeploy,
  onClickCancelDeploy,
  disabled = false,
}) => {
  return (
    <div style={{ height: 42, flexWrap: 'row' }}>
      <div style={{ float: 'right' }}>
        <Button
          type="primary"
          style={{ width: 100 }}
          className="distributeButton"
          onClick={onClickDeploy}
          disabled={disabled}
        >
          배포
        </Button>
        <Button
          className="distributeButton"
          onClick={onClickCancelDeploy}
          disabled={disabled}
        >
          되돌리기
        </Button>
      </div>
    </div>
  );
};

export default DeployButton;
