import { handleActions } from 'redux-actions';
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  ipAllow: getAsyncState.initial(null),
};

// action types
export const ipAllowActions = {
  FETCH_ALL_IPALLOWS: '@ipAllow/FETCH_ALL_IPALLOWS',
};

// actions
export const ipAllowCreators = {
  fetchAllIpAllows: createAsyncActions(ipAllowActions, 'FETCH_ALL_IPALLOWS'),
};

const ipALlowReducer = handleActions(
  {
    ...handleAsyncActions(ipAllowActions.FETCH_ALL_IPALLOWS, 'ipAllow'),
  },
  initialState,
);

export default ipALlowReducer;
