import React from 'react';
import { useLocation } from 'react-router-dom';
import UserWithHealthProfile from '../../containers/userWithHealthProfile';

const MutationDnaReportRegisterRequestDetail = () => {
  const { state } = useLocation();

  return <UserWithHealthProfile type={state?.type} tabLink="tabData" />;
};

export default MutationDnaReportRegisterRequestDetail;
