import { createAction, handleActions } from 'redux-actions';

const initialState = {
  activeWindows: [],
};

export const windowActions = {
  ADD_WINDOW: '@window/ADD_WINDOW',
  REMOVE_WINDOW_BY_ID: '@window/REMOVE_WINDOW_BY_ID',
  PURGE: '@window/PURGE',
};

export const windowActionCreators = {
  addWindow: createAction(windowActions.ADD_WINDOW, (payload) =>
    Object.freeze(payload),
  ),
  removeWindowById: createAction(windowActions.REMOVE_WINDOW_BY_ID),
  purge: createAction(windowActions.PURGE),
};

const windowReducer = handleActions(
  {
    [windowActions.ADD_WINDOW]: (state, { payload }) => ({
      ...state,
      activeWindows: state.activeWindows.concat(payload),
    }),
    [windowActions.REMOVE_WINDOW_BY_ID]: (state, { payload }) => ({
      ...state,
      activeWindows: state.activeWindows.filter(
        (activeWindow) => activeWindow.id !== payload,
      ),
    }),
    [windowActions.PURGE]: () => [],
  },
  initialState,
);

export default windowReducer;
