import { handleActions } from 'redux-actions';
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  event: getAsyncState.initial(null),
};

// action types
export const eventActions = {
  FETCH_ALL_EVENTS: '@event/FETCH_ALL_EVENTS',
};

// actions
export const eventCreators = {
  fetchAllEvents: createAsyncActions(eventActions, 'FETCH_ALL_EVENTS'),
};

const eventReducer = handleActions(
  {
    ...handleAsyncActions(eventActions.FETCH_ALL_EVENTS, 'event'),
  },
  initialState,
);

export default eventReducer;
