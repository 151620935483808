import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Layout, Table, Input, Button } from 'antd';
import 'antd/dist/antd.css';
import { useHistory } from 'react-router-dom';
import { SettingOutlined } from '@ant-design/icons';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { clinicalTrialCounselingCreators } from '../../../store/reducers/clinicalTrialCounseling.reducer';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import * as listMap from '../../../util/listMap';

const ClinicalTrialCounseling = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    clinicalTrialCounseling,
    clinicalTrialCounselingPagination,
  } = useSelector((state) => {
    return {
      clinicalTrialCounseling:
        state.clinicalTrialCounselingReducer?.clinicalTrialCounseling?.data,
      clinicalTrialCounselingPagination:
        state?.paginationReducer?.clinicalTrialCounselingPagination,
    };
  }, shallowEqual);

  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const confirmRef = useRef(() => {});

  const getData = useCallback(() => {
    dispatch(
      clinicalTrialCounselingCreators.fetchAllClinicalTrialCounselingRequests.request(),
    );
  }, [dispatch]);
  useEffect(getData, [getData]);

  useEffect(() => {
    if (clinicalTrialCounseling) {
      if (clinicalTrialCounselingPagination.total === null)
        setTotal(clinicalTrialCounseling?.length);
      else setTotal(clinicalTrialCounselingPagination.total);
      setPageUpdate(clinicalTrialCounselingPagination.page);
      confirmRef.current(clinicalTrialCounselingPagination.text);
    }
  }, [clinicalTrialCounseling]);

  const { Content } = Layout;
  const { Search } = Input;

  const handleMoveDetailPage = useCallback(
    ({ userId }) => () =>
      history.push('/clinicalTrialCounselingDetail', { userId }),
    [history],
  );

  const columns = [
    {
      title: '계정',
      dataIndex: 'email',
      width: 170,
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: clinicalTrialCounselingPagination.filter?.email || null,
      onFilter: (
        search,
        {
          email,
          name,
          phone,
          diseaseTypeKrName,
          relationship,
          registeredAt,
          counselingStatus,
          memo,
        },
      ) =>
        [
          email,
          name,
          phone,
          diseaseTypeKrName,
          relationship,
          registeredAt,
          counselingStatus,
          memo,
        ].some((value) => value?.includes(search)),
      render: (text, record) =>
        text ? <a onClick={handleMoveDetailPage(record)}>{text}</a> : '-',
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      width: 65,
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      width: 110,
      sorter: {
        compare: (a, b) => a?.phone?.localeCompare(b?.phone),
      },
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypeKrName',
      key: 'diseaseTypeKrName',
      width: 110,
      sorter: {
        compare: (a, b) =>
          a?.diseaseTypeKrName?.localeCompare(b?.diseaseTypeKrName),
      },
    },
    {
      title: '계정 유형',
      dataIndex: 'relationship',
      key: 'relationship',
      width: 105,
      sorter: {
        compare: (a, b) => a?.relationship?.localeCompare(b?.relationship),
      },
      filters: listMap.relationshipFilter,
      filteredValue:
        clinicalTrialCounselingPagination.filter?.relationship || null,
      onFilter: (value, record) => record.relationship.includes(value),
    },
    {
      title: '신청일',
      dataIndex: 'registeredAt',
      key: 'registeredAt',
      width: 80,
      sorter: {
        compare: (a, b) => a?.registeredAt?.localeCompare(b?.registeredAt),
      },
    },
    {
      title: '상담 상태',
      dataIndex: 'counselingStatus',
      key: 'counselingStatus',
      width: 105,
      sorter: {
        compare: (a, b) =>
          a?.counselingStatus?.localeCompare(b?.counselingStatus),
      },
      filters: [
        {
          text: '신청',
          value: '신청',
        },
        {
          text: '재신청',
          value: '재신청',
        },
        {
          text: '상담중',
          value: '상담중',
        },
        {
          text: '상담완료',
          value: '상담완료',
        },
        {
          text: '상담불가',
          value: '상담불가',
        },
        {
          text: '상담취소',
          value: '상담취소',
        },
      ],
      filteredValue:
        clinicalTrialCounselingPagination.filter?.counselingStatus || null,
      onFilter: (value, record) => record.counselingStatus === value,
    },
    {
      title: '테스트ID',
      dataIndex: 'isTest',
      key: 'isTest',
      width: 90,
      render: (isTest) => <>{isTest ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      filteredValue: clinicalTrialCounselingPagination.filter?.isTest || null,
      onFilter: (value, record) => record.isTest === value,
    },
    {
      title: '상담내용',
      dataIndex: 'memo',
      key: 'memo',
      width: 40,
      ellipsis: true,
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabData" link="clinicalTrialCounseling" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="데이터"
            subTitle="상담"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button
              type="default"
              style={{ width: 100 }}
              icon={
                <SettingOutlined
                  style={{ fontSize: '18px', verticalAlign: 'baseline' }}
                />
              }
              onClick={() => {
                history.push('/clinicalTrialCounselingSetting');
              }}
            >
              설정
            </Button>

            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (clinicalTrialCounselingPagination.text = value),
                  ),
                );
              }}
              defaultValue={clinicalTrialCounselingPagination.text}
            />
            <div className="searchResult">
              {clinicalTrialCounseling && `검색결과 ${total}개`}
            </div>

            <Table
              rowKey={(record) => record.id}
              columns={columns}
              loading={!clinicalTrialCounseling}
              dataSource={clinicalTrialCounseling}
              pagination={{
                onChange: (page) => {
                  setPageUpdate(page);
                },
                current: pageUpdate,
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(pagination, filters, sorter, extra) => {
                dispatch(
                  paginationCreators.setValue(
                    (clinicalTrialCounselingPagination.page =
                      pagination?.current),
                    (clinicalTrialCounselingPagination.filter = filters),
                    (clinicalTrialCounselingPagination.total =
                      extra?.currentDataSource?.length),
                  ),
                );
                setTotal(extra?.currentDataSource?.length);
              }}
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ClinicalTrialCounseling;
