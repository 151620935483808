import { all, takeLatest } from 'redux-saga/effects';
import {
  remoteConfigCreators,
  remoteConfigActions,
} from '../reducers/remoteConfig.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchAllRemoteConfig } from '../../services/remoteConfigService';

const handleFetchAllRemoteConfigs = createAsyncSaga(
  remoteConfigCreators.fetchAllRemoteConfigs,
  fetchAllRemoteConfig,
);

export function* remoteConfigSaga() {
  yield all([
    takeLatest(
      remoteConfigActions.FETCH_ALL_REMOTE_CONFIGS,
      handleFetchAllRemoteConfigs,
    ),
  ]);
}
