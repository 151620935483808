import React from 'react';
import { Row, Form, Table, Button } from 'react-bootstrap';

const CrudTableView = ({
  headerText,
  tableViewCondition,
  listData,
  setListData,
  listDataView,
  postElementCondition,
  postElementFeatures,
  postElement,
}) => {
  return (
    <Form.Group>
      <Form.Group className="formContent" as={Row}>
        <h5 className="title">{headerText}</h5>
      </Form.Group>
      <div className="tableFixHead">
        <Table id="table" hover size="sm">
          <thead>
            <tr>
              {listDataView.map((dataView) => (
                <th style={{ width: `${dataView.width}` }}>
                  {dataView.headerText}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {listData &&
              tableViewCondition &&
              listData.map((dataElement) => (
                <tr key={dataElement.id}>
                  {listDataView.map((dataView) =>
                    dataView.type === 'textInput' ? (
                      <td>
                        <Form.Control
                          className="text-center"
                          size="sm"
                          type="text"
                          value={dataElement[dataView.attribute]}
                          style={{ fontWeight: 'bold' }}
                          readOnly={dataView.readOnly ?? false}
                          onChange={(e) => {
                            const newListData = listData.map((element) =>
                              element.id === dataElement.id
                                ? {
                                    ...element,
                                    [dataView.attribute]: e.target.value,
                                  }
                                : element,
                            );
                            setListData(newListData);
                          }}
                        />
                      </td>
                    ) : dataView.type === 'patchButton' ? (
                      <td>
                        <Button
                          size="md"
                          className="exposureButton"
                          variant="dark"
                          onClick={() => dataView.patchElement(dataElement)}
                        >
                          {dataView.buttonText}
                        </Button>
                      </td>
                    ) : dataView.type === 'deleteButton' ? (
                      <td>
                        <Button
                          size="md"
                          className="exposureButton"
                          variant="danger"
                          onClick={() => dataView.deleteElement(dataElement)}
                        >
                          {dataView.buttonText}
                        </Button>
                      </td>
                    ) : dataView.type === 'functionButton' ? (
                      <td>
                        <Button
                          size="md"
                          className="exposureButton"
                          variant={dataView.variant(dataElement)}
                          onClick={() => dataView.function(dataElement)}
                        >
                          {dataView.buttonText(dataElement)}
                        </Button>
                      </td>
                    ) : null,
                  )}
                </tr>
              ))}
            {postElementCondition && (
              <tr>
                {postElementFeatures.map((feature) => (
                  <td>
                    <Form.Control
                      className="text-center"
                      placeholder={feature.placeholder}
                      size="sm"
                      type="text"
                      value={feature.value}
                      style={{
                        fontWeight: feature.value !== '' ? 'bold' : 'normal',
                      }}
                      onChange={(e) => feature.setValue(e.target.value)}
                    />
                  </td>
                ))}
                <td
                  colSpan={
                    listDataView.filter(
                      (dataView) => dataView.type !== 'textInput',
                    ).length
                  }
                >
                  <Button
                    size="md"
                    className="exposureButton"
                    variant="primary"
                    onClick={() => postElement()}
                  >
                    {`+ ${headerText} 추가`}
                  </Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Form.Group>
  );
};

export default CrudTableView;
