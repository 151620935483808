import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, Layout, Table, Typography } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { tagCreators } from '../../../store/reducers/tag.reducer';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../../window/util/utils';
import { useMutate } from '../../../hooks/useRequest';
import { deleteTopicTag } from '../../../services/tagService';

const Tag = () => {
  const dispatch = useDispatch();
  const { topicTags } = useSelector(
    (state) => ({ topicTags: state.tagReducer?.topicTags?.data }),
    shallowEqual,
  );

  const getData = useCallback(() => {
    dispatch(tagCreators.fetchTopicTags.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setDataSource(topicTags);
    setFilteredData(topicTags?.length);
  }, [topicTags]);

  const { Content } = Layout;
  const [dataSource, setDataSource] = useState('');
  const { Search } = Input;
  const [filteredData, setFilteredData] = useState(null);
  const [mutateTagId, setMutateTagId] = useState('');
  const {
    mutate: deleteMutation,
    done: mutationDone,
    initialize: resetMutation,
  } = useMutate(deleteTopicTag, mutateTagId);

  useEffect(() => {
    if (mutationDone) {
      getData();
      resetMutation();
    }
  }, [getData, mutationDone, resetMutation]);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (record) => {
    if (findWindowById(record.id)) {
      alert('이미 편집중인 주제입니다.');
      return;
    }

    createWindow({
      id: record.id,
      dataType: 'tag',
      subId: JSON.stringify(record),
    });
  };

  const handleCreateNewWindow = () => {
    createWindow({ id: `${NEW}${Date.now()}`, dataType: 'tag' });
  };

  const onSearch = (e) => {
    if (e === '') {
      setDataSource(topicTags);
      setFilteredData(topicTags?.length);
    } else {
      const filteredDatas = dataSource?.filter((entry) =>
        Object?.values(entry).toString().includes(e),
      );
      setDataSource(filteredDatas);
      setFilteredData(filteredDatas.length);
    }
  };

  const deleteTag = (tagId) => {
    setMutateTagId(tagId);
  };

  useEffect(() => {
    if (mutateTagId === '') return;
    const deletionConfirm = window.confirm('정말로 삭제하시겠습니까?');
    if (deletionConfirm) {
      deleteMutation();
    }
    setMutateTagId('');
  }, [deleteMutation, mutateTagId]);

  const columns = [
    {
      title: '주제별 태그',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
      width: '174px',
      render: (text, record) => (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography.Text
            underline
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleCreateEditWindow(record);
            }}
          >
            {text}
          </Typography.Text>
          <Typography.Text
            underline
            style={{ cursor: 'pointer' }}
            onClick={() => {
              deleteTag(record.id);
            }}
          >
            삭제
          </Typography.Text>
        </div>
      ),
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="tag" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="콘텐츠 보조 요소"
            subTitle="주제별 태그"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button
              type="primary"
              style={{ width: 100 }}
              onClick={handleCreateNewWindow}
            >
              추가
            </Button>

            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={onSearch}
            />
            <div className="searchResult">검색결과 {filteredData}개</div>

            <Table
              columns={columns}
              dataSource={dataSource}
              rowKey="id"
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(_, __, ___, { currentDataSource }) =>
                setFilteredData(currentDataSource.length)
              }
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Tag;
