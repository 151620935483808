import dayjs from 'dayjs';

// timeStamp로 2020-04-03T05:45:01.429Z 와 같은 input이 들어오면 2020/04/03와 같은 형태로 변형
export function changeTimeFormat(timeStamp) {
  const time = dayjs(timeStamp).format('YYYY/MM/DD');
  return time;
}
// 만 나이를 계산
export function getAgeFromBirthDate(birthDate) {
  if (!birthDate) return '';
  return dayjs().diff(dayjs(birthDate).format('YYYYMMDD'), 'years');
}
