import { all, takeLatest } from 'redux-saga/effects';
import {
  dictionaryCreators,
  dictionaryActions,
} from '../reducers/dictionary.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchAllDictionary } from '../../services/dictionaryService';

const handleFetchAllDictionary = createAsyncSaga(
  dictionaryCreators.fetchAllDictionary,
  fetchAllDictionary,
);

export function* dictionarySaga() {
  yield all([
    takeLatest(
      dictionaryActions.FETCH_ALL_DICTIONARY,
      handleFetchAllDictionary,
    ),
  ]);
}
