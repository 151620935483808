import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 푸쉬내역 리스트를 가져옵니다.
 */
export async function fetchPushMessageList() {
  const res = await axios.get(`/v4/admin/pushMessages`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 모든 알림/푸쉬 기록을 가져옵니다.
 */
export async function fetchAllNotificationHistory() {
  const res = await axios.get(
    `/v4/admin/FCMHistoriesAndScheduledNotifications`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 모든 메세지 알림/푸쉬 기록을 가져옵니다.
 */
export async function fetchAllNotificationIndividual() {
  const res = await axios.get(
    `/v3/admin/notifications/history?type=INDIVIDUAL`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 알림/푸쉬 기록을 생성합니다.
 * @param {String} type
 * @param {uuid[]} userIds
 * @param {uuid[]} contentsId
 * @param {uuid[]} diseaseTypeIds
 * @param {String} title
 * @param {String} body
 * @param {String} memo
 * @param {Boolean} completed
 */
export async function postNotification({
  type,
  userIds,
  diseaseTypeIds,
  contentsIds,
  body,
  title,
  memo,
  completed,
}) {
  const res = await axios.post('/v4/admin/notifications', {
    type,
    userIds,
    diseaseTypeIds,
    contentsIds,
    body,
    title,
    memo,
    completed,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 예약 알림/푸쉬 기록을 생성합니다.
 * @param {String} type
 * @param {uuid[]} userIds
 * @param {string} contentsId
 * @param {uuid[]} diseaseTypeIds
 * @param {String} title
 * @param {String} body
 * @param {String} memo
 * @param {Date} scheduledAt
 */
export async function postScheduleNotification({
  type,
  userIds,
  diseaseTypeIds,
  contentsId,
  body,
  title,
  memo,
  scheduledAt,
}) {
  const res = await axios.post('/v4/admin/scheduledNotifications', {
    type,
    userIds,
    diseaseTypeIds,
    contentsId,
    body,
    title,
    memo,
    scheduledAt,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 예약 알림/푸쉬를 삭제합니다.
 * @param {String} scheduledNotificationId
 */
export async function deleteScheduleNotification(scheduledNotificationId) {
  const res = await axios.delete(
    `/v4/admin/scheduledNotifications/${scheduledNotificationId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 알림/푸쉬를 받을 유저/디바이스를 가져옵니다.
 * @param {String} contentsId
 * @param {String} type
 */
export async function fetchNotificationUser({
  contentsId,
  type,
  completed,
  diseaseTypeIds,
}) {
  const res = await axios.get(
    `/v3/admin/contents/${contentsId}/notifications?type=${type}${
      ['true', 'false'].includes(completed) ? `&completed=${completed}` : ''
    }&${diseaseTypeIds.map((id) => `diseaseTypeIds=${id}`).join('&')}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 알림/푸쉬를 보낼 컨텐츠 리스트를 가져옵니다.
 */
export async function fetchAllNotificationContents() {
  const res = await axios.get(`/v3/admin/notifications/contents`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 토큰이 등록된 모든 기기에 FCM dryrun을 실행합니다.
 */
export async function executeFcmDryrun() {
  const res = await axios.post(`/v3/admin/notifications/fcm/dryrun`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 의학소식 피드 목록을 가져옵니다.(의학소식 피드 리스트, 검색, 홈에서 같이 사용)
 * @param {string} tags__name 태그 이름
 * @param {string} search_keyword 질환 이름 or 입력한 검색어
 * @param {string} created_at_gt 크롤링 시간 기준
 * @param {string} offset
 * @param {string} limit
 *
 */
export async function fetchNewsFeedList(
  created_at__gt,
  search_keyword,
  limit,
  offset,
  tags__name,
) {
  const res = await axios.get(
    process.env.REACT_APP_DEPLOY === 'production'
      ? `https://news-api.humanscape.io/news/news`
      : process.env.REACT_APP_DEPLOY === 'staging'
      ? `https://news-api-stage.humanscape.io/news/news`
      : `https://news-api-dev.humanscape.io/news/news`,
    {
      created_at__gt,
      search_keyword,
      limit,
      offset,
      tags__name,
    },
  );
  if (res.status !== HTTP_SUCCESS) throw res;
  return res.data;
}
