import { handleActions } from 'redux-actions';
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  rehabilitation: getAsyncState.initial(null),
  rehabilitationDetail: getAsyncState.initial(null),
};

// action types
export const rehabilitationActions = {
  FETCH_ALL_REHABILITATIONS: '@rehabilitation/FETCH_ALL_REHABILITATIONS',
  FETCH_ALL_REHABILITATION_DETAILS:
    '@rehabilitation/FETCH_ALL_REHABILITATION_DETAILS',
};

// actions
export const rehabilitationCreators = {
  fetchAllRehabilitations: createAsyncActions(
    rehabilitationActions,
    'FETCH_ALL_REHABILITATIONS',
  ),
  fetchAllRehabilitationDetails: createAsyncActions(
    rehabilitationActions,
    'FETCH_ALL_REHABILITATION_DETAILS',
  ),
};

const rehabilitationReducer = handleActions(
  {
    ...handleAsyncActions(
      rehabilitationActions.FETCH_ALL_REHABILITATIONS,
      'rehabilitation',
    ),
    ...handleAsyncActions(
      rehabilitationActions.FETCH_ALL_REHABILITATION_DETAILS,
      'rehabilitationDetail',
    ),
  },
  initialState,
);

export default rehabilitationReducer;
