import React, { useEffect, useCallback, useReducer, useState } from 'react';
import moment from 'moment';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  Layout,
  Row,
  Col,
  Spin,
  Button,
  Typography,
  DatePicker,
  Radio,
  Table,
  Form,
  Checkbox,
  Select,
  notification,
} from 'antd';
import 'antd/dist/antd.css';

import { Column } from '@ant-design/charts';
import { useSelector, useDispatch } from 'react-redux';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { dashboardCreators } from '../../../store/reducers/dashboard.reducer';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import 'react-datepicker/dist/react-datepicker.css';

const Dashboard = () => {
  const dispatch = useDispatch();

  const {
    dashboardKeys,
    dashboardUsers,
    dashboardDevices,
    diseaseTypeInfo,
    dashboardUserCountByDiseases,
  } = useSelector((state) => {
    return {
      dashboardKeys: state.dashboardReducer.dashboardKeys,
      dashboardUsers: state.dashboardReducer.dashboardUsers,
      dashboardDevices: state.dashboardReducer.dashboardDevices,
      dashboardUserCountByDiseases:
        state.dashboardReducer.dashboardUserCountByDiseases,
      diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data
        ? state.diseaseTypeReducer.diseaseTypes.data.map(({ id, krName }) => ({
            id,
            krName,
          }))
        : null,
    };
  });

  const appLoading = dashboardKeys?.loading;
  const deviceLoading = dashboardDevices?.loading;
  const userLoading = dashboardUsers?.loading;
  const userCountLoading = dashboardUserCountByDiseases?.loading;

  const { Content } = Layout;
  const { Title } = Typography;
  const { RangePicker } = DatePicker;
  const [appTypeValue, setAppTypeValue] = useState('default');
  const [deviceTypeValue, setDeviceTypeValue] = useState('default');
  const [userTypeValue, setUserTypeValue] = useState('default');
  const [deviceValue, setDeviceValue] = useState([]);
  const [userCountTypeValue, setUserCountTypeValue] = useState('default');
  const dateFormat = 'YYYY-MM-DD';
  const appData = dashboardKeys?.data;
  const usersData = dashboardUsers?.data;
  const devicesData = dashboardDevices?.data;
  const userCountData = dashboardUserCountByDiseases?.data;
  const initialState = {
    appStartAt: moment().format(dateFormat),
    appEndAt: moment().format(dateFormat),
    userStartAt: moment().format(dateFormat),
    userEndAt: moment().format(dateFormat),
    deviceStartAt: moment().format(dateFormat),
    deviceEndAt: moment().format(dateFormat),
    userCountStartAt: moment().format(dateFormat),
    userCountEndAt: moment().format(dateFormat),
    diseaseType: [],
    userTypeCheck: [
      '입력 없음',
      '환자',
      '보호자',
      '의사',
      '약사',
      '제약사',
      '연구원',
      '기타',
    ],
    userFunnelCheck: [],
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);

  const radioOptions = [
    { label: '현황', value: 'default' },
    { label: '기간 검색', value: 'searchDate' },
  ];

  const userTypeOptions = [
    { label: '입력 없음', value: '입력 없음' },
    { label: '환자', value: '환자' },
    { label: '보호자', value: '보호자' },
    { label: '의사', value: '의사' },
    { label: '약사', value: '약사' },
    { label: '제약사', value: '제약사' },
    { label: '연구원', value: '연구원' },
    { label: '기타', value: '기타' },
  ];

  const userFunnelOptions = [
    { label: '입력 없음', value: '입력 없음' },
    { label: '병원 의료진', value: '병원 의료진' },
    { label: '앱스토어 검색', value: '앱스토어 검색' },
    { label: '환우회', value: '환우회' },
    { label: '오프라인 광고', value: '오프라인 광고' },
    { label: '인터넷 검색', value: '인터넷 검색' },
    { label: '카페/블로그', value: '카페/블로그' },
    { label: '온라인 광고', value: '온라인 광고' },
    { label: '블록체인 커뮤니티', value: '블록체인 커뮤니티' },
    { label: '지인', value: '지인' },
    { label: '기타', value: '기타' },
  ];

  const onDiseaseChange = (diseases) => {
    setState({
      diseaseType: diseases,
    });
  };

  const onAppTypeChange = (e) => {
    setAppTypeValue(e.target.value);
    if (e.target.value === 'default') {
      getAppData(moment().format(dateFormat), moment().format(dateFormat));
    }
    if (e.target.value === 'searchDate') {
      getAppData(state.appStartAt, state.appEndAt);
    }
  };

  const onDeviceTypeChange = (e) => {
    setDeviceTypeValue(e.target.value);
    if (e.target.value === 'default') {
      getDeviceData(
        moment().add(-6, 'days').format(dateFormat),
        moment().format(dateFormat),
      );
    }
    if (e.target.value === 'searchDate') {
      if (state.deviceStartAt === state.deviceEndAt) {
        getDeviceData(
          state.deviceStartAt,
          moment(state.deviceEndAt).add(1, 'days').format(dateFormat),
        );
      } else {
        getDeviceData(state.deviceStartAt, state.deviceEndAt);
      }
    }
  };

  const onUserCountTypeChange = (e) => {
    setUserCountTypeValue(e.target.value);
    if (e.target.value === 'default') {
      getUserCountByDisease(
        moment('2019-01-01').format(dateFormat),
        moment().format(dateFormat),
      );
    }
    if (e.target.value === 'searchDate') {
      getUserCountByDisease(state.userCountStartAt, state.userCountEndAt);
    }
  };

  const onUserTypeChange = (e) => {
    setUserTypeValue(e.target.value);
  };

  const onAppDateChange = (e) => {
    if (e !== null) {
      setState({
        appStartAt: e?.[0].format(dateFormat),
        appEndAt: e?.[1].format(dateFormat),
      });
    }
  };

  const onDeviceDateChange = (e) => {
    if (e !== null) {
      setState({
        deviceStartAt: e?.[0].format(dateFormat),
        deviceEndAt: e?.[1].format(dateFormat),
      });
    }
  };

  const onUserDateChange = (e) => {
    if (e !== null) {
      setState({
        userStartAt: e?.[0].format(dateFormat),
        userEndAt: e?.[1].format(dateFormat),
      });
    }
  };

  const onUserCountDateChange = (e) => {
    if (e !== null) {
      setState({
        userCountStartAt: e?.[0].format(dateFormat),
        userCountEndAt: e?.[1].format(dateFormat),
      });
    }
  };

  const onTypeChange = (list) => {
    setState({
      userTypeCheck: list,
    });
  };

  const onFunnelChange = (list) => {
    setState({
      userFunnelCheck: list,
    });
  };

  const paramsData = () => {
    const params = new URLSearchParams();
    // add date params
    if (userTypeValue === 'default') {
      params.append('from', moment().format(dateFormat));
      params.append('to', moment().add(1, 'days').format(dateFormat));
    } else {
      params.append('from', state.userStartAt);
      params.append(
        'to',
        moment(state.userEndAt).add(1, 'days').format(dateFormat),
      );
    }
    // add date params
    state?.diseaseType?.map((disease) => {
      return params.append(
        'd',
        diseaseTypeInfo.find((diseaseInfo) => diseaseInfo.krName === disease)
          ?.id,
      );
    });
    // add userType params
    state?.userTypeCheck?.map((userType) => {
      return params.append('r', userType);
    });
    // add userFunnel params
    state?.userFunnelCheck?.map((userFunnel) => {
      return params.append('f', userFunnel);
    });

    return params.toString();
  };

  const onFinish = () => {
    const params = paramsData();
    getUserData(params);
  };

  // dispatch to fetch data
  const getAppData = useCallback(
    (start, end) => {
      dispatch(
        dashboardCreators.fetchDashboardKeys.request({
          startAt: start,
          endAt: moment(end).add(1, 'days').format(dateFormat),
        }),
      );
    },
    [dispatch],
  );

  // dispatch to fetch data
  const getDeviceData = useCallback(
    (start, end) => {
      dispatch(
        dashboardCreators.fetchDashboardDevices.request({
          startAt: start,
          endAt: end,
        }),
      );
    },
    [dispatch],
  );

  // dispatch to fetch data
  const getUserData = useCallback(
    (userParams) => {
      dispatch(
        dashboardCreators.fetchDashboardUsers.request({
          params: userParams,
        }),
      );
    },
    [dispatch],
  );

  // dispatch to fetch data
  const getUserCountByDisease = useCallback(
    (start, end) => {
      dispatch(
        dashboardCreators.fetchDashboardUserCountByDiseases.request({
          startAt: start,
          endAt: moment(end).add(1, 'days').format(dateFormat),
        }),
      );
    },
    [dispatch],
  );

  // re-fetching after distribute information patched
  useEffect(() => {
    const params = paramsData();
    getAppData(moment().format(dateFormat), moment().format(dateFormat));
    getDeviceData(
      moment().add(-6, 'days').format(dateFormat),
      moment().format(dateFormat),
    );
    getUserCountByDisease(
      moment('2019-01-01').format(dateFormat),
      moment().format(dateFormat),
    );
    getUserData(params);
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [getAppData, getDeviceData, getUserCountByDisease, getUserData]);

  // appdata columns
  const columns = [
    {
      title: '항목',
      dataIndex: 'label',
    },
    {
      title: '값',
      dataIndex: 'value',
    },
  ];

  // appdata bar chart
  const data = [
    { label: '앱 첫 실행', text: 'firstOpen', value: appData?.firstOpen },
    {
      label: '전체 유저 기기 알림',
      text: 'isSystemNotiOn',
      value: appData?.isSystemNotiOn,
    },
    { label: '회원가입', text: 'join', value: appData?.join },
    { label: '유입경로 입력 수', text: 'funnel', value: appData?.funnel },
    {
      label: '질환인증 유저수',
      text: 'diseaseTypeCertification',
      value: appData?.diseaseTypeCertification,
    },
    { label: '앱 삭제', text: 'deletedDevice', value: appData?.deletedDevice },
    { label: '탈퇴', text: 'withdrawal', value: appData?.withdrawal },
  ];

  // appdata columns
  const userColumns = [
    {
      title: '질환명',
      dataIndex: 'krName',
    },
    {
      title: '회원가입자',
      dataIndex: 'join',
    },
    {
      title: '질환 인증',
      dataIndex: 'diseaseTypeCertification',
    },
    {
      title: '유전자 검사',
      dataIndex: 'dnaTestReport',
    },
    {
      title: '건강설문',
      dataIndex: 'healthProfile',
    },
    {
      title: '병원검사',
      dataIndex: 'hospitalExaminationRecord',
    },
    {
      title: '기기 알림(예정)',
      dataIndex: 'isSystemNotiOn',
    },
    {
      title: '마케팅 수신',
      dataIndex: 'agreementMarketingAt',
    },
    {
      title: '임상시험 알림',
      dataIndex: 'clinicalTrialsNotiOn',
    },
    {
      title: '임상시험 제안',
      dataIndex: 'clinicalTrialsNewsNotiOn',
    },
  ];

  // devicedata columns
  const deviceColumns = [
    {
      title: '날짜',
      dataIndex: 'date',
    },
    {
      title: '앱 첫 실행',
      dataIndex: 'newDevice',
    },
    {
      title: '회원가입',
      dataIndex: 'newUser',
    },
    {
      title: '질환인증',
      dataIndex: 'newDiseaseTypeCertification',
    },
    {
      title: '첫 질환인증',
      dataIndex: 'firstDiseaseTypeCertification',
    },
    {
      title: '탈퇴',
      dataIndex: 'deletedUser',
    },
    {
      title: '전체 유저 기기 알림',
      dataIndex: 'isSystemSettingOn',
    },
    {
      title: '유입경로 입력',
      dataIndex: 'isFunnelAnswered',
    },
    {
      title: '앱 삭제',
      dataIndex: 'deletedDevice',
    },
  ];

  // userCountData columns
  const userCountColumns = [
    {
      title: '질환',
      dataIndex: 'diseaseType',
    },
    {
      title: '환자 수',
      dataIndex: 'userCount',
    },
  ];

  // userCountData data
  const userCountTempData = [];
  if (userCountData) {
    Object?.values(userCountData)?.forEach((user, index) => {
      userCountTempData.push({
        key: index,
        diseaseType: user[0],
        userCount: user[1],
      });
    });
  }

  // userData data
  const userData = [];
  if (usersData) {
    Object?.values(usersData)?.forEach((user, index) => {
      userData.push({
        key: index,
        krName: user.krName === 'all' ? '전체' : user.krName,
        join: user.join?.length,
        diseaseTypeCertification: user.diseaseTypeCertification?.length,
        dnaTestReport: user.dnaTestReport?.length,
        healthProfile: user.healthProfile?.length,
        hospitalExaminationRecord: user.hospitalExaminationRecord?.length,
        isSystemNotiOn: user.isSystemNotiOn?.length,
        agreementMarketingAt: user.agreementMarketingAt?.length,
        clinicalTrialsNotiOn: user.clinicalTrialsNotiOn?.length,
        clinicalTrialsNewsNotiOn: user.clinicalTrialsNewsNotiOn?.length,
      });
    });
  }

  if (userData.length > 1) {
    userData.push({
      key: 'all',
      krName: '합계',
      join: userData.reduce(
        (accumulator, currentValue) => accumulator + currentValue.join,
        0,
      ),
      diseaseTypeCertification: userData.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.diseaseTypeCertification,
        0,
      ),
      dnaTestReport: userData.reduce(
        (accumulator, currentValue) => accumulator + currentValue.dnaTestReport,
        0,
      ),
      healthProfile: userData.reduce(
        (accumulator, currentValue) => accumulator + currentValue.healthProfile,
        0,
      ),
      hospitalExaminationRecord: userData.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.hospitalExaminationRecord,
        0,
      ),
      isSystemNotiOn: userData.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.isSystemNotiOn,
        0,
      ),
      agreementMarketingAt: userData.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.agreementMarketingAt,
        0,
      ),
      clinicalTrialsNotiOn: userData.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.clinicalTrialsNotiOn,
        0,
      ),
      clinicalTrialsNewsNotiOn: userData.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue.clinicalTrialsNewsNotiOn,
        0,
      ),
    });
  }

  // appdata bar chart config
  const appConfig = {
    data,
    height: 400,
    xField: 'label',
    yField: 'value',
    label: {
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: false,
        autoRotate: true,
      },
    },
  };

  useEffect(() => {
    const deviceData = [];
    if (deviceTypeValue === 'default') {
      for (
        let i = moment().add(-6, 'days').format(dateFormat);
        i <= moment().format(dateFormat);
        i = moment(i).add(1, 'days').format(dateFormat)
      ) {
        deviceData.push({
          date: i,
          ...devicesData?.[i],
        });
      }
    }

    if (
      deviceTypeValue === 'searchDate' &&
      state.deviceStartAt === state.deviceEndAt
    ) {
      deviceData.push({
        date: state.deviceStartAt,
        ...devicesData?.[state.deviceStartAt],
      });
    }

    if (
      deviceTypeValue === 'searchDate' &&
      state.deviceStartAt !== state.deviceEndAt
    ) {
      for (
        let i = state?.deviceStartAt;
        i <= state?.deviceEndAt;
        i = moment(i).add(1, 'days').format(dateFormat)
      ) {
        deviceData.push({
          date: i,
          ...devicesData?.[i],
        });
      }
    }

    setDeviceValue(deviceData);
  }, [devicesData]);

  const excelDown = (appData2) => {
    const xlsx = require('xlsx');
    const book = xlsx.utils.book_new();
    const rows = [];
    // excelHeader
    rows.push([...deviceColumns?.map((column) => column?.title)]);
    // excelBody
    appData2?.forEach((answer) => {
      const temp = [];
      deviceColumns?.forEach((column) => {
        temp.push(answer[column.dataIndex]);
      });
      rows.push(temp);
    });
    const excelData = xlsx.utils.aoa_to_sheet(rows);
    xlsx.utils.book_append_sheet(book, excelData, '앱데이터2');
    xlsx.writeFile(book, `${dayjs().format(`[앱데이터2]YYYY_MM_DD`)}.xlsx`);

    notification.success({
      message: '앱데이터2 데이터 다운로드가 완료 되었습니다.',
      description: '앱데이터2 데이터 다운로드가 완료 되었습니다. ',
    });
  };
  const userCountExcelDown = (userCountExcelData) => {
    const xlsx = require('xlsx');
    const book = xlsx.utils.book_new();
    const rows = [];
    // excelHeader
    rows.push(['질환', '환자 수'], ...userCountExcelData);
    // excelBody
    const excelData = xlsx.utils.aoa_to_sheet(rows);
    xlsx.utils.book_append_sheet(book, excelData, '질환별환자수');
    xlsx.writeFile(book, `${dayjs().format(`[질환별환자수]YYYY_MM_DD`)}.xlsx`);

    notification.success({
      message: '질환별 환자 수 데이터 다운로드가 완료 되었습니다.',
      description: '질환별 환자 수 데이터 다운로드가 완료 되었습니다. ',
    });
  };

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabDashboard" link="dashboard" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="대시보드"
            subTitle="전체"
            className="white-bg"
          />
          <Content className="site-layout-background dashboardStyle">
            {appLoading ? (
              <div className="loading">
                <Spin tip="Loading..." />
              </div>
            ) : (
              <div>
                <Row>
                  <Col span={6}>
                    <Title level={3}>앱 데이터</Title>
                  </Col>
                  <Col span={18}>
                    <Radio.Group
                      onChange={onAppTypeChange}
                      value={appTypeValue}
                      optionType="button"
                      buttonStyle="solid"
                      options={radioOptions}
                      style={{ float: 'right' }}
                    />
                    {appTypeValue === 'searchDate' && (
                      <div style={{ float: 'right' }}>
                        <RangePicker
                          defaultValue={[
                            moment(state.appStartAt, dateFormat),
                            moment(state.appEndAt, dateFormat),
                          ]}
                          onChange={onAppDateChange}
                          format={dateFormat}
                        />
                        <Button
                          icon={
                            <SearchOutlined
                              style={{ verticalAlign: 'baseline' }}
                            />
                          }
                          style={{ marginRight: 16 }}
                          onClick={() =>
                            getAppData(state.appStartAt, state.appEndAt)
                          }
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <div className="chart-title-border" />

                <Row>
                  <Col span={12} className="appBarWrapper">
                    <Column {...appConfig} />
                  </Col>
                  <Col span={12} className="appTextWrapper">
                    <Table
                      columns={columns}
                      dataSource={data}
                      size="middle"
                      bordered
                      pagination={false}
                      title={() => '앱 데이터'}
                      rowKey={(record) => record.text}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Content>
          <Content className="site-layout-background dashboardStyle">
            {deviceLoading ? (
              <div className="loading">
                <Spin tip="Loading..." />
              </div>
            ) : (
              <div>
                <Row>
                  <Col span={6}>
                    <Title level={3}>앱 데이터2</Title>
                  </Col>
                  <Col span={18}>
                    <Radio.Group
                      onChange={onDeviceTypeChange}
                      value={deviceTypeValue}
                      optionType="button"
                      buttonStyle="solid"
                      options={radioOptions}
                      style={{ float: 'right' }}
                    />
                    <Button
                      type="primary"
                      icon={
                        <DownloadOutlined
                          style={{ verticalAlign: 'baseline' }}
                        />
                      }
                      style={{ float: 'right', marginRight: 8 }}
                      onClick={() => excelDown(deviceValue)}
                    >
                      다운로드
                    </Button>
                    {deviceTypeValue === 'searchDate' && (
                      <div style={{ float: 'right' }}>
                        <RangePicker
                          defaultValue={[
                            moment(state.deviceStartAt, dateFormat),
                            moment(state.deviceEndAt, dateFormat),
                          ]}
                          onChange={onDeviceDateChange}
                          format={dateFormat}
                        />
                        <Button
                          icon={
                            <SearchOutlined
                              style={{ verticalAlign: 'baseline' }}
                            />
                          }
                          style={{ marginRight: 16 }}
                          onClick={() =>
                            state.deviceStartAt === state.deviceEndAt
                              ? getDeviceData(
                                  state.deviceStartAt,
                                  moment(state.deviceEndAt)
                                    .add(1, 'days')
                                    .format(dateFormat),
                                )
                              : getDeviceData(
                                  state.deviceStartAt,
                                  state.deviceEndAt,
                                )
                          }
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <div className="chart-title-border" />

                <Row>
                  <Col span={24}>
                    <Table
                      columns={deviceColumns}
                      dataSource={deviceValue}
                      size="middle"
                      bordered
                      pagination={false}
                      title={() => '앱 데이터2'}
                      rowKey={(record) => record.date}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Content>
          <Content className="site-layout-background dashboardStyle">
            {userLoading ? (
              <div className="loading">
                <Spin tip="Loading..." />
              </div>
            ) : (
              <div>
                <Row>
                  <Col span={6}>
                    <Title level={3}>회원가입 유저</Title>
                  </Col>
                  <Col span={18}>
                    <Radio.Group
                      onChange={onUserTypeChange}
                      value={userTypeValue}
                      optionType="button"
                      buttonStyle="solid"
                      options={radioOptions}
                      style={{ float: 'right' }}
                    />
                    {userTypeValue === 'searchDate' && (
                      <div style={{ float: 'right', marginRight: 16 }}>
                        <RangePicker
                          defaultValue={[
                            moment(state.userStartAt, dateFormat),
                            moment(state.userEndAt, dateFormat),
                          ]}
                          onChange={onUserDateChange}
                          format={dateFormat}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <div className="chart-title-border" />
                <Row>
                  <Col span={24}>
                    <Form
                      name="basic"
                      labelCol={{ span: 4 }}
                      wrapperCol={{ span: 20 }}
                      onFinish={onFinish}
                      initialValues={{
                        remember: true,
                        diseaseType: state.diseaseType,
                      }}
                    >
                      <Form.Item name="diseaseType" label="질환" hasFeedback>
                        <Select
                          showSearch
                          allowClear
                          mode="multiple"
                          style={{ width: '100%' }}
                          placeholder="질환을 선택해주세요"
                          name="diseaseType"
                          onChange={onDiseaseChange}
                        >
                          {diseaseTypeInfo?.map((diseaseType) => (
                            <Select.Option
                              key={diseaseType?.krName}
                              id={diseaseType?.krName}
                              value={diseaseType?.krName}
                            >
                              {diseaseType?.krName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item label="사용자 유형">
                        <Checkbox.Group
                          options={userTypeOptions}
                          defaultValue={state?.userTypeCheck}
                          onChange={onTypeChange}
                        />
                      </Form.Item>
                      <Form.Item label="유입경로">
                        <Checkbox.Group
                          options={userFunnelOptions}
                          defaultValue={state?.userFunnelCheck}
                          onChange={onFunnelChange}
                        />
                      </Form.Item>
                      <Form.Item
                        wrapperCol={{
                          offset: 4,
                          span: 20,
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ width: 200 }}
                        >
                          검색
                        </Button>
                      </Form.Item>
                    </Form>
                  </Col>
                  <Col span={24}>
                    <Table
                      columns={userColumns}
                      dataSource={userData}
                      size="middle"
                      bordered
                      pagination={false}
                      title={() => '회원가입 유저 데이터'}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Content>
          <Content className="site-layout-background dashboardStyle">
            {userCountLoading ? (
              <div className="loading">
                <Spin tip="Loading..." />
              </div>
            ) : (
              <div>
                <Row>
                  <Col span={6}>
                    <Title level={3}>질환별 환자 수</Title>
                  </Col>
                  <Col span={18}>
                    <Radio.Group
                      onChange={onUserCountTypeChange}
                      value={userCountTypeValue}
                      optionType="button"
                      buttonStyle="solid"
                      options={radioOptions}
                      style={{ float: 'right' }}
                    />
                    <Button
                      type="primary"
                      icon={
                        <DownloadOutlined
                          style={{ verticalAlign: 'baseline' }}
                        />
                      }
                      style={{ float: 'right', marginRight: 8 }}
                      onClick={() => userCountExcelDown(userCountData)}
                    >
                      다운로드
                    </Button>
                    {userCountTypeValue === 'searchDate' && (
                      <div style={{ float: 'right' }}>
                        <RangePicker
                          defaultValue={[
                            moment(state.userCountStartAt, dateFormat),
                            moment(state.userCountEndAt, dateFormat),
                          ]}
                          onChange={onUserCountDateChange}
                          format={dateFormat}
                        />
                        <Button
                          icon={
                            <SearchOutlined
                              style={{ verticalAlign: 'baseline' }}
                            />
                          }
                          style={{ marginRight: 16 }}
                          onClick={() =>
                            getUserCountByDisease(
                              state.userCountStartAt,
                              state.userCountEndAt,
                            )
                          }
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <div className="chart-title-border" />

                <Row>
                  <Col span={24}>
                    <Table
                      columns={userCountColumns}
                      dataSource={userCountTempData}
                      size="middle"
                      bordered
                      pagination={{
                        defaultPageSize: 50,
                        pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
                      }}
                      title={() => '질환별 환자 수'}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
