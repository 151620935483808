import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react';

const SingleSelectDropdown = ({
  text,
  subText,
  dropdownOptions,
  attributeName = null,
  selectedItem,
  setItem,
  selected,
  disabled = false,
  dependentDropdownOptions,
  dependentSelectedItem,
  setDependentItem,
  dependentAttributeName,
  isEssential = false,
  name,
  dependentname,
}) => {
  return (
    <Form.Group className="formContent" as={Row}>
      <Form.Label column sm="3" md="2" xl="1">
        {text}
        {isEssential && <span className="required-tag">*필수</span>}
      </Form.Label>
      {subText && (
        <Form.Label column sm="3" md="2" xl="2">
          {subText}
        </Form.Label>
      )}
      <Col sm="7" md="8" xl="9">
        <Form.Group className="formContent" as={Row}>
          <Dropdown
            name={name}
            className="dropdownButton"
            button
            labeled
            options={dropdownOptions}
            search
            placeholder={`${subText ?? text}을(를) 선택하세요`}
            text={
              attributeName
                ? selectedItem?.[attributeName]
                : selectedItem?.krName
            }
            disabled={disabled}
            onChange={(_, data) => {
              if (data.value !== null) {
                data.value = JSON.parse(data.value);
                setItem({ target: data });
                if (selected) selected.current = true;
              }
            }}
          />
          <div style={{ paddingRight: '5px' }} />
          {dependentDropdownOptions && (
            <Dropdown
              name={dependentname}
              className="dropdownButton"
              button
              labeled
              disabled={
                (attributeName
                  ? selectedItem?.[attributeName]
                  : selectedItem?.krName) !== '개발 중'
              }
              options={dependentDropdownOptions}
              search
              text={
                (attributeName
                  ? selectedItem?.[attributeName]
                  : selectedItem?.krName) !== '개발 중'
                  ? '선택 없음'
                  : (dependentAttributeName
                      ? dependentSelectedItem?.[dependentAttributeName]
                      : dependentSelectedItem?.krName) === null
                  ? '선택하세요'
                  : dependentAttributeName
                  ? dependentSelectedItem?.[dependentAttributeName]
                  : dependentSelectedItem?.krName
              }
              placeholder="선택하세요"
              onChange={(_, data) => {
                if (data.value !== null) {
                  data.value = JSON.parse(data.value);
                  setDependentItem({ target: data });
                }
              }}
            />
          )}
        </Form.Group>
      </Col>
    </Form.Group>
  );
};

export default SingleSelectDropdown;
