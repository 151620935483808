import React, { Component } from 'react';
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import { Header, Footer } from '../../../component';

export default class PageDoesntExist extends Component {
  render() {
    const { Content } = Layout;
    return (
      <Layout>
        <Header />
        <Layout>
          <Layout style={{ padding: '12px' }}>
            <Content
              className="site-layout-background"
              style={{
                margin: 0,
                padding: 12,
                minHeight: '100vh',
                backgroundColor: 'white',
              }}
            >
              <h1
                style={{
                  margin: 'auto',
                  textAlign: 'center',
                  paddingTop: '15px',
                }}
                className="title"
              >
                존재하지 않는 페이지입니다.
              </h1>
            </Content>
          </Layout>
        </Layout>
        <Footer />
      </Layout>
    );
  }
}
