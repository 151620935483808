import React from 'react';
import 'antd/dist/antd.css';
import WindowHeader from '../../components/windowHeader';

const RssFeedWindow = () => {
  return (
    <>
      <WindowHeader title="글로벌 뉴스" />
    </>
  );
};

export default RssFeedWindow;
