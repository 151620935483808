import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authCreators } from '../store/reducers/auth.reducer';

export function useSession() {
  const dispatch = useDispatch();
  const { accessToken, refreshToken, expiresAt } = useSelector(
    (state) => state.authReducer.auth?.data,
  );

  const calculateRemaining = useCallback(
    () => (expiresAt ? (expiresAt - new Date()) / 60_000 : null),
    [expiresAt],
  );

  const [remaining, setRemaining] = useState(0);

  useEffect(() => {
    setRemaining(calculateRemaining());
  }, [calculateRemaining]);

  useEffect(() => {
    const timerId = setInterval(() => {
      const newRemaining = calculateRemaining();
      if (newRemaining < 0) return dispatch(authCreators.logout());
      setRemaining(newRemaining);
    }, 5_000);
    return () => clearInterval(timerId);
  }, [calculateRemaining, dispatch]);

  return { accessToken, refreshToken, remaining };
}
