import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 대시보드 유저를 가져옵니다.
 * @param {String} params
 */
export async function fetchDashboardUser({ params }) {
  const res = await axios.get(`/v4/admin/dashboard/users?${params}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 대시보드 데이터를 가져옵니다.
 * @param {String} startAt
 * @param {String} endAt
 */
export async function fetchDashboardKey({ startAt, endAt }) {
  const res = await axios.get(
    `/v1/admin/dashboard/indicators?from=${startAt}&to=${endAt}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 대시보드 device 통계(질환, 관계별) 데이터를 가져옵니다.
 */
export async function fetchDashboardDevice({ startAt, endAt }) {
  const res = await axios.get(
    `/v4/admin/dashboard/analyticsData/?from=${startAt}&to=${endAt}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 콘텐츠 대시보드 데이터를 가져옵니다.
 */
export async function fetchDashboardContent() {
  const res = await axios.get(`/v3/admin/dashboard/contents`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 대시보드 유입경로 데이터를 가져옵니다.
 * @param {String} startAt
 * @param {String} endAt
 */
export async function fetchDashboardFunnel({ startAt, endAt }) {
  const res = await axios.get(
    `/v3/admin/dashboard/funnels?from=${startAt}&to=${endAt}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 대시보드 유저 데이터 내역을 가져옵니다.
 */
export async function fetchDashboardUserData() {
  const res = await axios.get('/v4/admin/users/data');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 대시보드 질환별 환자 수 통계을 가져옵니다.
 */
export async function fetchDashboardUserCountByDisease({ startAt, endAt }) {
  const res = await axios.get(
    `/v4/admin/dashboard/userCountByDisease?from=${startAt}&to=${endAt}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
