import { all, takeLatest } from 'redux-saga/effects';
import {
  permissionActions,
  permissionCreators,
} from '../reducers/permission.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchAllPermissions } from '../../services/permissionService';

const handleFetchPermission = createAsyncSaga(
  permissionCreators.fetchAllPermissions,
  fetchAllPermissions,
);

export function* permissionSaga() {
  yield all([
    takeLatest(permissionActions.FETCH_ALL_PERMISSION, handleFetchPermission),
  ]);
}
