import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

const initialState = {
  tags: getAsyncState.initial(null),
  topicTags: getAsyncState.initial(null),
  legacyTags: getAsyncState.initial(null),
};

export const tagActions = {
  FETCH_ALL_TAGS: '@tag/FETCH_ALL_TAGS',
  FETCH_TOPIC_TAGS: '@tag/FETCH_TOPIC_TAGS',
  FETCH_LEGACY_TAGS: '@tag/FETCH_LEGACY_TAGS',
};

export const tagCreators = {
  fetchAllTags: createAsyncActions(tagActions, 'FETCH_ALL_TAGS'),
  fetchTopicTags: createAsyncActions(tagActions, 'FETCH_TOPIC_TAGS'),
  fetchLegacyTags: createAsyncActions(tagActions, 'FETCH_LEGACY_TAGS'),
};

const tagReducer = handleActions(
  {
    ...handleAsyncActions(tagActions.FETCH_ALL_TAGS, 'tags'),
    ...handleAsyncActions(tagActions.FETCH_TOPIC_TAGS, 'topicTags'),
    ...handleAsyncActions(tagActions.FETCH_LEGACY_TAGS, 'legacyTags'),
  },
  initialState,
);

export default tagReducer;
