import React, { useEffect, useCallback, useRef } from 'react';
import { Table, Form, Input } from 'antd';
import { FilterFilled } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { useHistory, useLocation } from 'react-router';
import dayjs from 'dayjs';
import useWindow from '../../../hooks/useWindow';
import { usePagination } from '../../../hooks/usePagination';
import { fetchAllClinicalTrials } from '../../../services/clinicalTrialService';
import DiseaseTypeCell from '../diseaseType/link';
import { makeLink } from '../diseaseType/diseaseType';

const formatDateTime = (text) => dayjs(text).format('YYYY-MM-DD HH:mm');

const ClinicalTrialInfo = () => {
  const history = useHistory();
  const { findWindowById, createWindow, destroyWindowById } = useWindow();
  const { state } = useLocation();
  const searchInputRef = useRef(null);
  const {
    apiParams: [{ by, searchText }],
    setApiParams,
    initialize,
    data,
    loading,
    pageState,
    pagination,
  } = usePagination({
    call: fetchAllClinicalTrials,
    initialApiParams: [
      { by: state?.by ?? 'title', searchText: state?.searchText ?? '' },
    ],
    initialPageState: state?.pageState ?? {
      current: 1,
      pageSize: 50,
      showSizeChanger: true,
      total: 0,
    },
  });
  const filtered = searchText?.trim().length > 0;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initialize, []);

  const onSearch = useCallback(
    (newSearchText) => setApiParams([{ by, searchText: newSearchText.trim() }]),
    [setApiParams, by],
  );

  const handleCreateEditWindow = (record) => {
    if (findWindowById(record.ctkId.toString())) {
      alert('이미 편집중인 글입니다.');
      return;
    }

    createWindow({
      id: record.ctkId,
      dataType: 'clinicalTrial',
    });
  };

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      setApiParams([{ by, searchText }], pageState);
    },
    [destroyWindowById, setApiParams, by, searchText, pageState],
  );

  const toClinicalTrialPage = useCallback(
    ({ krName: name, id: diseaseTypeId }) => {
      history.replace('/clinicalTrial', {
        ...state,
        pageState,
        by,
        searchText,
      });
      history.push('/diseaseTypeClinicalTrial', { name, diseaseTypeId });
    },
    [history, pageState, state, by, searchText],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const columns = [
    {
      title: '업데이트 시각',
      dataIndex: 'updatedAt',
      key: 'date',
      width: '10%',
      render: formatDateTime,
      showSorterTooltip: false,
      sorter: true,
      sortOrder: by === 'date' && 'descend',
      sortDirections: ['descend'],
    },
    {
      title: '한임정 ID',
      dataIndex: 'ctkId',
      width: '6%',
      render: makeLink(handleCreateEditWindow),
    },
    {
      title: 'NCT ID',
      dataIndex: 'nctId',
      width: '9%',
      render: (nctId) =>
        nctId && (
          <a
            style={{ color: '#40a9ff' }}
            href={`https://clinicaltrials.gov/ct2/show/${nctId}`}
            target="_blank"
            rel="noreferrer"
          >
            {nctId}
          </a>
        ),
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      width: '50%',
      filterDropdownVisible: false,
      filtered,
      filterIcon: (
        <FilterFilled onClick={() => searchInputRef.current.focus()} />
      ),
      filteredValue: filtered ? [] : null,
      filters: [],
      showSorterTooltip: false,
      sorter: true,
      sortOrder: by === 'title' && 'ascend',
      sortDirections: ['ascend'],
    },
    {
      title: '관련 질환',
      dataIndex: 'diseaseTypes',
      width: '25%',
      render: (diseaseTypes) => (
        <DiseaseTypeCell
          diseaseTypes={diseaseTypes}
          toClinicalTrialPage={toClinicalTrialPage}
        />
      ),
    },
  ];

  return (
    <div>
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={() => {}}
        initialValues={{
          remember: true,
        }}
      >
        <Input.Search
          ref={searchInputRef}
          defaultValue={searchText}
          className="searchStyle"
          loading={loading}
          onSearch={onSearch}
          onPressEnter={(e) => {
            e.preventDefault(); // no form submit
            onSearch(e.target.value);
          }}
        />
        <div className="searchResult">검색결과 {pagination.total ?? 0}개</div>
      </Form>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        size="small"
        onChange={(_, __, { columnKey }, { action }) => {
          if (action !== 'sort') return;
          if (columnKey !== by) setApiParams([{ by: columnKey, searchText }]);
        }}
        bordered
      />
    </div>
  );
};

export default ClinicalTrialInfo;
