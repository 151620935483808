import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import ListView from '../../components/listView';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../../window/util/utils';
import { useMutate } from '../../../hooks/useRequest';
import { eventCreators } from '../../../store/reducers/event.reducer';
import { patchDistributeEvent } from '../../../services/eventService';
import SearchButton from '../../components/searchButton';
import DeployButton from '../../components/deployButton';

const Event = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { event } = useSelector(
    (state) => ({
      event: state.eventReducer?.event?.data?.events,
    }),
    shallowEqual,
  );

  const loading = !event;
  const getData = useCallback(() => {
    dispatch(eventCreators.fetchAllEvents.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);
  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const searchFilterContent = [
    '프로젝트',
    'HP',
    '시작일',
    '종료일',
    '정원',
    '질환',
    '병원검사',
    '건강설문',
    '유전자검사',
    '상태',
    '참여인원',
  ];

  const { Content } = Layout;
  const [searchFilter, setSearchFilter] = useState(searchFilterContent[0]);
  const [searchWord, setSearchWord] = useState('');
  const [deployChangeInfo, setDeployChangeInfo] = useState([]);
  const [moveParticipantesReady, setMoveParticipantesReady] = useState(false);
  const eventData = useRef(null);

  const {
    done: patchDeployDone,
    mutate: patchDeployMutate,
    initialize: patchDeployInitialize,
  } = useMutate(patchDistributeEvent, {
    ids: deployChangeInfo.map((deployInfo) => deployInfo.id),
    changes: deployChangeInfo.map((deployInfo) => {
      return {
        isApp: deployInfo.isApp,
      };
    }),
  });

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (event) => {
    if (findWindowById(event.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({ id: event.id, dataType: 'event' });
  };

  const handleCreateNewWindow = () => {
    createWindow({ id: `${NEW}${Date.now()}`, dataType: 'event' });
  };

  const handleParticipantPage = (event) => {
    eventData.current = event;
    setMoveParticipantesReady(true);
  };

  const deploy = () => {
    if (window.confirm('앱에 변경된 사항을 반영하여 배포하시겠습니까?')) {
      patchDeployInitialize();
      patchDeploy();
    }
  };

  const patchDeploy = () => {
    patchDeployMutate();
    setDeployChangeInfo([]);
  };

  const cancelDeploy = () => {
    setDeployChangeInfo([]);
  };

  const handleChange = (e) => {
    setSearchFilter(e.target.value);
  };

  const onTableSearch = (e) => {
    setSearchWord(e.target.value.toLowerCase());
  };

  const isDeployReady = deployChangeInfo?.length > 0;

  const onClickDeploy = () => {
    if (!isDeployReady) {
      alert('배포할 변경사항이 없습니다.');
      return;
    }
    deploy();
  };

  const onClickCancelDeploy = () => {
    if (!isDeployReady) {
      alert('변경사항이 없습니다.');
      return;
    }
    cancelDeploy();
  };

  if (moveParticipantesReady) {
    history.push('/eventParticipants', {
      eventData: eventData.current,
    });
  }

  const statusMap = { true: '마감', false: '진행 중' };
  const eventTypeMap = { true: 'O', false: '-' };

  const listName = [
    { name: '앱 노출', width: '7%' },
    { name: '프로젝트', width: '20%' },
    { name: 'HP', width: '10%' },
    { name: '시작일', width: '10%' },
    { name: '종료일', width: '10%' },
    { name: '정원', width: '10%' },
    { name: '질환', width: '5%' },
    { name: '병원검사', width: '5%' },
    { name: '건강설문', width: '5%' },
    { name: '유전자검사', width: '10%' },
    { name: '상태', width: '10%' },
    { name: '참여인원', width: '10%' },
  ];

  const listItemView = [
    { view: (item) => item.isApp, type: 'normal', deployOption: 'isApp' },
    { view: (item) => item.title, type: 'link' },
    { view: (item) => item.point, type: 'normal' },
    { view: (item) => item.startDate, type: 'normal' },
    { view: (item) => item.endDate, type: 'normal' },
    { view: (item) => item.capacity, type: 'normal' },
    {
      view: (item) =>
        item.eventConditions?.map(
          (eventCondition) =>
            eventCondition?.type === 'diseaseTypeCertification' &&
            `${eventCondition?.order}순위`,
        ),
      type: 'normal',
    },
    {
      view: (item) =>
        item.eventConditions?.map(
          (eventCondition) =>
            eventCondition?.type === 'hospitalRecord' &&
            `${eventCondition?.order}순위`,
        ),
      type: 'normal',
    },
    {
      view: (item) =>
        item.eventConditions?.map(
          (eventCondition) =>
            eventCondition?.type === 'healthProfile' &&
            `${eventCondition?.order}순위`,
        ),
      type: 'normal',
    },
    {
      view: (item) =>
        item.eventConditions?.map(
          (eventCondition) =>
            eventCondition?.type === 'dnaAnalysis' &&
            `${eventCondition?.order}순위`,
        ),
      type: 'normal',
    },
    { view: (item) => statusMap[item.status], type: 'normal' },
    { view: (item) => item.userCount, type: 'conversion' },
  ];

  useEffect(() => {
    getData();
  }, [patchDeployDone, getData]);

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabOperation" link="event" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="운영"
            subTitle="프로젝트"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <DeployButton
              onClickDeploy={onClickDeploy}
              onClickCancelDeploy={onClickCancelDeploy}
            />
            <div className="divider" />
            <Form className="form-inline justify-content-between">
              <Form.Group>
                <Button className="buttons" onClick={handleCreateNewWindow}>
                  프로젝트 추가
                </Button>
              </Form.Group>
              <SearchButton
                searchFilterContent={searchFilterContent}
                searchFilter={searchFilter}
                handleChange={handleChange}
                onTableSearch={onTableSearch}
              />
            </Form>
            <ListView
              listData={event}
              loading={loading}
              searchFilter={searchFilter}
              searchWord={searchWord}
              editWindow={handleCreateEditWindow}
              conversionWindow={handleParticipantPage}
              listName={listName}
              listItemView={listItemView}
              cancelDeploy={cancelDeploy}
              deployChangeInfo={deployChangeInfo}
              setDeployChangeInfo={setDeployChangeInfo}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Event;
