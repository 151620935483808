import { all, takeLatest } from 'redux-saga/effects';
import {
  supportVersionCreators,
  supportVersionActions,
} from '../reducers/supportVersion.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchAllSupportVersion } from '../../services/supportVersionService';

const handleFetchAllSupportVersions = createAsyncSaga(
  supportVersionCreators.fetchAllSupportVersions,
  fetchAllSupportVersion,
);

export function* supportVersionSaga() {
  yield all([
    takeLatest(
      supportVersionActions.FETCH_ALL_SUPPORT_VERSIONS,
      handleFetchAllSupportVersions,
    ),
  ]);
}
