import { all, takeLatest } from 'redux-saga/effects';
import { dnaCreators, dnaActions } from '../reducers/dna.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchAllDna } from '../../services/dnaService';

const handleFetchAllDnas = createAsyncSaga(
  dnaCreators.fetchAllDnas,
  fetchAllDna,
);

export function* dnaSaga() {
  yield all([takeLatest(dnaActions.FETCH_ALL_DNAS, handleFetchAllDnas)]);
}
