import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  dna: getAsyncState.initial(null),
};

// action types
export const dnaActions = {
  FETCH_ALL_DNAS: '@dna/FETCH_ALL_DNAS',
};

// actions
export const dnaCreators = {
  fetchAllDnas: createAsyncActions(dnaActions, 'FETCH_ALL_DNAS'),
};

const dnaReducer = handleActions(
  {
    ...handleAsyncActions(dnaActions.FETCH_ALL_DNAS, 'dna'),
  },
  initialState,
);

export default dnaReducer;
