export const antTinymceInit = {
  selector: 'textarea',
  extended_valid_elements:
    'lottie-player[src|style|autoplay|loop|background|speed]',
  max_height: 520,
  min_height: 520,
  inline_styles: false,
  keep_styles: false,
  valid_styles: {
    '*': '',
    'lottie-player': 'width,height,aspect-ratio',
  },
  image_title: true,
  image_caption: true,
  image_class_list: [
    { title: '이미지', value: 'v3-editor-content-image-paragraph' },
    { title: '줌이미지', value: 'v3-editor-content-image-paragraph-zoomable' },
    { title: '첫이미지', value: 'v3-editor-content-image-title' },
    { title: 'gif이미지', value: 'v3-editor-content-image-gif' },
    { title: '이미지 라인', value: 'v3-editor-content-image-paragraph-border' },
    {
      title: '줌이미지 라인',
      value: 'v3-editor-content-image-paragraph-border-zoomable',
    },
    { title: '첫이미지 라인', value: 'v3-editor-content-image-title-border' },
    { title: 'gif이미지 라인', value: 'v3-editor-content-image-gif-border' },
  ],
  valid_classes: {
    '*':
      'v3-editor-content-image-title v3-editor-content-image-paragraph-zoomable v3-editor-content-image-paragraph v3-editor-content-image-gif v3-editor-content-image-paragraph-border v3-editor-content-image-paragraph-border-zoomable v3-editor-content-image-title-border v3-editor-content-image-gif-border v3-editor-heading-1 v3-editor-heading-2 v3-editor-heading-3 v3-editor-heading-4 v3-editor-heading-5 v3-editor-heading-6 v3-editor-content-paragraph v3-editor-content-paragraph-helpline v3-editor-content-line v3-editor-content-blank-space v3-editor-content-reference v3-editor-content-reference-helpline v3-editor-content-tag v3-editor-content-point v3-editor-content-point-book v3-editor-content-point-check-black v3-editor-content-point-check-blue v3-editor-content-point-check-gray v3-editor-content-point-check-red v3-editor-content-point-check-green v3-editor-content-point-check-yellow v3-editor-content-point-search v3-editor-content-point-light-bulb v3-editor-content-dictionary v3-editor-content-link v3-editor-content-blockquote v3-editor-content-image-paragraph-wrap v3-editor-content-image-paragraph-helpline v3-editor-content-image-paragraph-helpline-zoomable v3-editor-content-cta v3-editor-content-cta-dtr v3-editor-content-cta-he v3-editor-content-cta-dc v3-editor-content-cta-ctd v3-editor-content-cta-mnd v3-editor-content-list v3-editor-content-cta-notice v3-editor-content-cta-consult v3-editor-content-cta-alarm v3-editor-content-cta-join v3-editor-content-cta-mail v3-editor-content-cta-search editor-hidden v3-editor-video-container',
  },
  entity_encoding: 'raw',
  menubar: false,
  paste_data_images: true,
  init_instance_callback(editor) {
    // get the head element
    const head = editor.dom.select('head')[0];

    // just add in whatever plugins needed
    addScript(
      'https://unpkg.com/@lottiefiles/lottie-player@1.3.0/dist/lottie-player.js',
    );

    // helper function
    function addScript(scriptUrl) {
      editor.dom.add(head, 'script', {
        src: scriptUrl,
        type: 'text/javascript',
      });
    }
  },
  content_css: '',
  content_style:
    process.env.REACT_APP_DEPLOY === 'production'
      ? "@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css'); @import url('https://rarenote.io/css/root.css'); @import url('https://rarenote.io/css/editorStyles.css'); body { margin: 0;}"
      : process.env.REACT_APP_DEPLOY === 'staging'
      ? "@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css'); @import url('https://rarenote.io/css/root.css'); @import url('https://rarenote.io/css/editorStyles.css'); body { margin: 0;}"
      : "@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css'); @import url('https://rarenote.io/css/root.css'); @import url('https://dev.rarenote.io/css/editorStyles.css'); body { margin: 0;}",

  formats: {
    underline: {
      inline: 'u',
      exact: true,
    },
    heading1: {
      block: 'h1',
      classes: 'v3-editor-heading-1',
      attributes: { class: 'v3-editor-heading-1' },
    },
    heading2: {
      block: 'h2',
      classes: 'v3-editor-heading-2',
      attributes: { class: 'v3-editor-heading-2' },
    },
    heading3: {
      block: 'h3',
      classes: 'v3-editor-heading-3',
      attributes: { class: 'v3-editor-heading-3' },
    },
    heading4: {
      block: 'h4',
      classes: 'v3-editor-heading-4',
      attributes: { class: 'v3-editor-heading-4' },
    },
    heading5: {
      block: 'h5',
      classes: 'v3-editor-heading-5',
      attributes: { class: 'v3-editor-heading-5' },
    },
    rareparagraph: {
      block: 'p',
      classes: 'v3-editor-content-paragraph',
      attributes: { class: 'v3-editor-content-paragraph' },
    },
    contentBlankSpace: {
      block: 'hr',
      classes: 'v3-editor-content-blank-space',
      attributes: { class: 'v3-editor-content-blank-space' },
    },
    contentLine: {
      block: 'hr',
      classes: 'v3-editor-content-line',
      attributes: { class: 'v3-editor-content-line' },
    },
    contentLink: {
      block: 'ul',
      classes: 'v3-editor-content-link',
      attributes: { class: 'v3-editor-content-link' },
    },
    contentLinkNum: {
      block: 'ol',
      classes: 'v3-editor-content-link',
      attributes: { class: 'v3-editor-content-link' },
    },
    contentLinkLink: {
      block: 'li',
    },
    contentReference: {
      block: 'ul',
      classes: 'v3-editor-content-reference',
      attributes: { class: 'v3-editor-content-reference' },
    },
    contentReferenceLink: {
      block: 'li',
    },
    rareparagrapDiv: {
      block: 'div',
      classes: 'v3-editor-content-paragraph-helpline',
      attributes: { class: 'v3-editor-content-paragraph-helpline' },
    },
    contentReferenceDiv: {
      block: 'div',
      classes: 'v3-editor-content-reference-helpline',
      attributes: { class: 'v3-editor-content-reference-helpline' },
    },
    contentPoint: {
      block: 'dl',
      classes: 'v3-editor-content-point',
      attributes: { class: 'v3-editor-content-point' },
    },
    contentPoint_book: {
      block: 'dl',
      classes: 'v3-editor-content-point-book',
      attributes: { class: 'v3-editor-content-point-book' },
    },
    contentPoint_check_black: {
      block: 'dl',
      classes: 'v3-editor-content-point-check-black',
      attributes: { class: 'v3-editor-content-point-check-black' },
    },
    contentPoint_check_blue: {
      block: 'dl',
      classes: 'v3-editor-content-point-check-blue',
      attributes: { class: 'v3-editor-content-point-check-blue' },
    },
    contentPoint_check_red: {
      block: 'dl',
      classes: 'v3-editor-content-point-check-red',
      attributes: { class: 'v3-editor-content-point-check-red' },
    },
    contentPoint_check_green: {
      block: 'dl',
      classes: 'v3-editor-content-point-check-green',
      attributes: { class: 'v3-editor-content-point-check-green' },
    },
    contentPoint_search: {
      block: 'dl',
      classes: 'v3-editor-content-point-search',
      attributes: { class: 'v3-editor-content-point-search' },
    },
    contentPoint_light_bulb: {
      block: 'dl',
      classes: 'v3-editor-content-point-light-bulb',
      attributes: { class: 'v3-editor-content-point-light-bulb' },
    },
    contentDictionary: {
      block: 'dl',
      classes: 'v3-editor-content-dictionary',
      attributes: { class: 'v3-editor-content-dictionary' },
    },
    contentBlockquote: {
      block: 'blockquote',
      classes: 'v3-editor-content-blockquote',
      attributes: { class: 'v3-editor-content-blockquote' },
    },
    contentCardTitle: {
      block: 'dt',
    },
    contentCardText: {
      block: 'dd',
    },
  },
  style_formats: [
    { title: '제목2', format: 'heading2' },
    { title: '제목3', format: 'heading3' },
    { title: '제목4', format: 'heading4' },
    { title: '제목5', format: 'heading5' },
    { title: '본문', format: 'rareparagraph' },
    { title: '인용구', format: 'contentBlockquote' },
    { title: '용어 설명', format: 'contentDictionary' },
    {
      title: '강조',
      items: [
        { title: '기본', format: 'contentPoint' },
        {
          title: '책',
          format: 'contentPoint_book',
        },
        {
          title: '돋보기',
          format: 'contentPoint_search',
        },
        {
          title: '전구',
          format: 'contentPoint_light_bulb',
        },
        {
          title: '체크_블랙',
          format: 'contentPoint_check_black',
        },
        {
          title: '체크_블루',
          format: 'contentPoint_check_blue',
        },
        {
          title: '체크_레드',
          format: 'contentPoint_check_red',
        },
        {
          title: '체크_그린',
          format: 'contentPoint_check_green',
        },
        {
          title: '체크_옐로우',
          format: 'contentPoint_check_green',
        },
        { title: '강조 제목', format: 'contentCardTitle' },
        { title: '강조 내용', format: 'contentCardText' },
      ],
    },
    { title: '간격', format: 'contentBlankSpace' },
    { title: '선', format: 'contentLine' },
    {
      title: '링크 버튼',
      items: [
        { title: '기본', format: 'contentLink' },
        { title: '숫자', format: 'contentLinkNum' },
        { title: '버튼', format: 'contentLinkLink' },
      ],
    },
    {
      title: '헬프라인',
      items: [
        { title: '헬프라인 본문', format: 'rareparagrapDiv' },
        { title: '헬프라인 출처', format: 'contentReferenceDiv' },
      ],
    },
  ],
  plugins: ['link searchreplace code image paste fullscreen autoresize'],
  toolbar:
    'mybutton undo redo | styleselect | link bold italic underline strikethrough subscript superscript | image code | fullscreen | searchreplace | alignleft aligncenter alignright alignjustify | fontselect fontsizeselect | powerpaste',
};
