import { all, takeLatest } from 'redux-saga/effects';
import { tagCreators, tagActions } from '../reducers/tag.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchAllTags, fetchLegacyTags } from '../../services/tagService';

const handleFetchAllTags = createAsyncSaga(
  tagCreators.fetchAllTags,
  fetchAllTags,
);

// NOTE(reo): 레어노트 콘텐츠의 질환태그를 위한 사가
const handleFetchLegacyTags = createAsyncSaga(
  tagCreators.fetchLegacyTags,
  fetchLegacyTags,
);

export function* tagSaga() {
  yield all([
    takeLatest(tagActions.FETCH_ALL_TAGS, handleFetchAllTags),
    takeLatest(tagActions.FETCH_LEGACY_TAGS, handleFetchLegacyTags),
  ]);
}
