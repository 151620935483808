import { handleActions } from 'redux-actions';
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  rssFeed: getAsyncState.initial(null),
};

// action types
export const rssFeedActions = {
  FETCH_ALL_RSS_FEEDS: '@rssFeed/FETCH_ALL_RSS_FEEDS',
};

// actions
export const rssFeedCreators = {
  fetchAllRssFeeds: createAsyncActions(rssFeedActions, 'FETCH_ALL_RSS_FEEDS'),
};

const rssFeedReducer = handleActions(
  {
    ...handleAsyncActions(rssFeedActions.FETCH_ALL_RSS_FEEDS, 'rssFeed'),
  },
  initialState,
);

export default rssFeedReducer;
