import React, { useCallback } from 'react';
import { Form, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import { CONFIRM_MESSAGES } from '../../assets/alert';

const MutateButton = ({
  content,
  elementId,
  create,
  update,
  remove,
  initialPhotos,
  addedPhotos,
  imageType,
  setIsCanceled,
  initialPhoto: initialBannerImage,
  photo: bannerImage,
  setEditorRemoveArray,
  setDistinctRemoveImage,
  isDeleteImageExist,
}) => {
  // find images on window page
  const imagesOnPage = useCallback(() => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(content, 'text/html');
    const imageList = htmlDoc.getElementsByTagName('img');
    const existImage = [];
    for (let i = 0; i < imageList.length; i += 1) {
      const indexSrc = imageList[i].outerHTML.indexOf('src');
      const indexStart = imageList[i].outerHTML
        .substring(indexSrc)
        .indexOf('"');
      const indexEnd = imageList[i].outerHTML
        .substring(indexSrc + indexStart + 1)
        .indexOf('"');
      const imageUrl = imageList[i].outerHTML.substring(
        indexSrc + indexStart + 1,
        indexSrc + indexStart + indexEnd + 1,
      );
      existImage.push(imageUrl);
    }
    return existImage;
  }, [content]);

  // create element (e.g. notice, dnaAnalysisStats, ...)
  const createElement = useCallback(() => {
    const imageToDelete = [];
    const existImage = imagesOnPage();
    const maxPhotos = initialPhotos ? initialPhotos.concat(addedPhotos) : [];
    if (imageType !== 'figure' && existImage.length !== maxPhotos.length) {
      maxPhotos.forEach((photo) => {
        if (!existImage.includes(photo)) {
          const splittedUrl = photo.split('/');
          imageToDelete.push(
            `${splittedUrl[splittedUrl.length - 2]}/${
              splittedUrl[splittedUrl.length - 1]
            }`,
          );
        }
      });
    }
    create();
    if (isDeleteImageExist) {
      if (imageToDelete.length === 0) isDeleteImageExist.current = false;
      else isDeleteImageExist.current = true;
    }
    if (setEditorRemoveArray) setEditorRemoveArray(imageToDelete);
  }, [
    addedPhotos,
    create,
    imageType,
    imagesOnPage,
    initialPhotos,
    isDeleteImageExist,
    setEditorRemoveArray,
  ]);

  // update element (e.g. notice, dnaAnalysisStats, ...)
  const updateElement = useCallback(() => {
    const imageToDelete = [];
    const existImage = imagesOnPage();
    const maxPhotos = initialPhotos ? initialPhotos.concat(addedPhotos) : [];
    if (imageType !== 'figure' && existImage.length !== maxPhotos.length) {
      maxPhotos.forEach((photo) => {
        if (!existImage.includes(photo)) {
          const splittedUrl = photo.split('/');
          imageToDelete.push(
            `${splittedUrl[splittedUrl.length - 2]}/${
              splittedUrl[splittedUrl.length - 1]
            }`,
          );
        }
      });
    }
    update();
    if (isDeleteImageExist) {
      if (imageToDelete.length === 0) isDeleteImageExist.current = false;
      else isDeleteImageExist.current = true;
    }
    if (setEditorRemoveArray) setEditorRemoveArray(imageToDelete);
  }, [
    addedPhotos,
    imageType,
    imagesOnPage,
    initialPhotos,
    isDeleteImageExist,
    setEditorRemoveArray,
    update,
  ]);

  // delete element (e.g. notice, dnaAnalysisStats, ...)
  const removeElement = useCallback(() => {
    const imageToDelete = [];
    let distinctImageToDelete = null;
    if (initialPhotos) {
      const maxPhotos = initialPhotos.concat(addedPhotos);
      maxPhotos.forEach((imageInPage) => {
        const splittedUrl = imageInPage.split('/');
        imageToDelete.push(
          `${splittedUrl[splittedUrl.length - 2]}/${
            splittedUrl[splittedUrl.length - 1]
          }`,
        );
      });
      if (setEditorRemoveArray) setEditorRemoveArray(imageToDelete);
    }
    if (bannerImage && typeof bannerImage === 'object') {
      distinctImageToDelete = bannerImage.map((image) => {
        const splittedUrl = image.split('/');
        return `${splittedUrl[splittedUrl.length - 2]}/${
          splittedUrl[splittedUrl.length - 1]
        }`;
      });
      setDistinctRemoveImage(distinctImageToDelete);
    } else if (bannerImage) {
      const splittedUrl = bannerImage.split('/');
      distinctImageToDelete = `${splittedUrl[splittedUrl.length - 2]}/${
        splittedUrl[splittedUrl.length - 1]
      }`;
      setDistinctRemoveImage(distinctImageToDelete);
    }
    if (isDeleteImageExist) {
      if (
        imageToDelete.length === 0 &&
        (!distinctImageToDelete || !distinctImageToDelete.length)
      )
        isDeleteImageExist.current = false;
      else isDeleteImageExist.current = true;
    }
    remove();
  }, [
    addedPhotos,
    bannerImage,
    initialPhotos,
    isDeleteImageExist,
    remove,
    setDistinctRemoveImage,
    setEditorRemoveArray,
  ]);

  // cancel working on element (e.g. notice, dnaAnalysisStats, ...)
  const cancelElement = () => {
    setIsCanceled(true);
    let imageToDelete = [];
    let distinctImageToDelete = null;
    if (addedPhotos) {
      imageToDelete = addedPhotos.map((photo) => {
        const splittedUrl = photo.split('/');
        return `${splittedUrl[splittedUrl.length - 2]}/${
          splittedUrl[splittedUrl.length - 1]
        }`;
      });
      if (setEditorRemoveArray) setEditorRemoveArray(imageToDelete);
    }
    if (bannerImage && typeof bannerImage === 'object') {
      distinctImageToDelete = bannerImage.reduce((accu, curr, idx) => {
        if (!initialBannerImage[idx] && curr) {
          const splittedUrl = curr.split('/');
          accu.push(
            `${splittedUrl[splittedUrl.length - 2]}/${
              splittedUrl[splittedUrl.length - 1]
            }`,
          );
        }
        return accu;
      }, []);
      setDistinctRemoveImage(distinctImageToDelete);
    } else if (!initialBannerImage && bannerImage) {
      const splittedUrl = bannerImage.split('/');
      distinctImageToDelete = `${splittedUrl[splittedUrl.length - 2]}/${
        splittedUrl[splittedUrl.length - 1]
      }`;
      setDistinctRemoveImage(distinctImageToDelete);
    }
    if (isDeleteImageExist) {
      if (
        imageToDelete.length === 0 &&
        (!distinctImageToDelete || !distinctImageToDelete?.length)
      )
        isDeleteImageExist.current = false;
      else isDeleteImageExist.current = true;
    }
  };

  const removeItem = useCallback(() => {
    if (window.confirm(CONFIRM_MESSAGES.DELETE)) {
      removeElement();
    }
  }, [removeElement]);

  const updateItem = useCallback(() => {
    if (window.confirm(CONFIRM_MESSAGES.UPDATE)) {
      updateElement();
    }
  }, [updateElement]);

  const createItem = useCallback(() => {
    if (window.confirm(CONFIRM_MESSAGES.CREATE)) {
      createElement();
    }
  }, [createElement]);

  return (
    <Form.Group className="formContent buttons">
      <ButtonToolbar className="justify-content-between">
        {elementId && remove ? (
          <Button onClick={removeItem} variant="danger">
            삭제
          </Button>
        ) : (
          <ButtonGroup />
        )}
        <ButtonGroup>
          <Button onClick={cancelElement} variant="outline-primary">
            취소
          </Button>
          <Button
            onClick={elementId ? updateItem : createItem}
            disabled={false}
          >
            저장
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    </Form.Group>
  );
};

export default MutateButton;
