import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button, Layout } from 'antd';
import 'antd/dist/antd.css';
import { Content } from 'antd/lib/layout/layout';
import AntList from '../../components/antList';
import DeployButton from '../../components/deployButton';
import { NEW } from '../../../window/util/utils';
import { useMutate } from '../../../hooks/useRequest';
import { patchDeployTreatmentNews } from '../../../services/treatmentNewsService';
import { treatmentNewsCreators } from '../../../store/reducers/treatmentNews.reducer';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import useWindow from '../../../hooks/useWindow';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';

const SOURCE_NAME = {
  helpline: '헬프라인',
  default: '레어노트',
  StatPearls: 'StatPearls',
  MedlinePlus: 'MedlinePlus',
};

const DiseaseInfo = () => {
  const dispatch = useDispatch();
  const { diseaseInfos, diseaseInfoPagination } = useSelector((state) => {
    return {
      diseaseInfos: state.treatmentNewsReducer.treatmentNews.data?.filter(
        (info) => info.category === 'diseaseInfo',
      ),
      diseaseInfoPagination: state?.paginationReducer?.diseaseInfoPagination,
    };
  });

  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const confirmRef = useRef(() => {});

  const getData = useCallback(() => {
    dispatch(treatmentNewsCreators.fetchAllTreatmentNews.request({}));
  }, [dispatch]);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();
  const { Search } = Input;
  const [deployChangeInfo, setDeployChangeInfo] = useState([]);

  useEffect(() => {
    if (diseaseInfos) {
      if (diseaseInfoPagination.total === null) setTotal(diseaseInfos?.length);
      else setTotal(diseaseInfoPagination.total);
      setPageUpdate(diseaseInfoPagination.page);
      confirmRef.current(diseaseInfoPagination.text);
    }
  }, [diseaseInfos]);

  const {
    done: patchDeployDone,
    mutate: patchDeployMutate,
    initialize: patchDeployInitialize,
  } = useMutate(patchDeployTreatmentNews, {
    ids: deployChangeInfo.map((deployInfo) => deployInfo.id),
    changes: deployChangeInfo.map((deployInfo) => {
      return {
        isApp:
          deployInfo.isApp === undefined
            ? diseaseInfos.find(({ id }) => id === deployInfo.id).isApp
            : deployInfo.isApp,
      };
    }),
  });

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (treatmentNewsInfo) => {
    if (findWindowById(treatmentNewsInfo.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({ id: treatmentNewsInfo.id, dataType: 'diseaseInfo' });
  };

  const handleCreateNewWindow = () => {
    createWindow({ id: `${NEW}${Date.now()}`, dataType: 'diseaseInfo' });
  };

  const deploy = () => {
    if (window.confirm('앱에 변경된 사항을 반영하여 배포하시겠습니까?')) {
      patchDeployInitialize();
      patchDeploy();
    }
  };

  const patchDeploy = () => {
    patchDeployMutate();
    setDeployChangeInfo([]);
  };

  const cancelDeploy = () => {
    setDeployChangeInfo([]);
  };

  const isDeployReady = deployChangeInfo?.length > 0;

  const onClickDeploy = () => {
    if (!isDeployReady) {
      alert('배포할 변경사항이 없습니다.');
      return;
    }
    deploy();
  };

  const onClickCancelDeploy = () => {
    if (!isDeployReady) {
      alert('변경사항이 없습니다.');
      return;
    }
    cancelDeploy();
  };

  useEffect(getData, [getData]);

  useEffect(() => {
    if (patchDeployDone) {
      getData();
    }
  }, [patchDeployDone]);

  const tagWrapper = (tags) => {
    const tag = tags?.split(',');

    return tag?.map((diseaseType) => (
      <div
        className="tagWrapper"
        key={diseaseType}
        style={{
          color: '#979797',
        }}
      >
        {diseaseType}
      </div>
    ));
  };

  const onChange = (pagination, filters, sorter, extra) => {
    dispatch(
      paginationCreators.setValue(
        (diseaseInfoPagination.page = pagination?.current),
        (diseaseInfoPagination.filter = filters),
        (diseaseInfoPagination.total = extra?.currentDataSource?.length),
      ),
    );
    setTotal(extra?.currentDataSource?.length);
  };

  const columns = [
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '120px',
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: diseaseInfoPagination.filter?.title || null,
      onFilter: (search, { title, createdAt, diseaseTypes }) =>
        [title, createdAt, diseaseTypes].some((value) =>
          value?.includes(search),
        ),
      render: (text, record) =>
        text ? (
          <Button
            type="link"
            size="small"
            style={{ textDecoration: 'underline' }}
            onClick={() => handleCreateEditWindow(record)}
          >
            {text}
          </Button>
        ) : (
          '-'
        ),
    },
    {
      title: '앱 노출',
      dataIndex: 'isApp',
      key: 'isApp',
      width: '100px',
      filters: [
        {
          text: 'On',
          value: true,
        },
        {
          text: 'Off',
          value: false,
        },
      ],
      filteredValue: diseaseInfoPagination.filter?.isApp || null,
      onFilter: (value, record) => record.isApp === value,
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypes',
      key: 'diseaseTypes',
      width: '250px',
      render: (text) => tagWrapper(text),
    },
    {
      title: '출처',
      dataIndex: 'source',
      key: 'source',
      render: (source) => (
        <>{tagWrapper(SOURCE_NAME[source] || SOURCE_NAME.default)}</>
      ),
      filters: [
        {
          text: '헬프라인',
          value: 'helpline',
        },
        {
          text: '레어노트',
          value: null,
        },
      ],
      filteredValue: diseaseInfoPagination.filter?.source || null,
      onFilter: (value, record) => record.source === value,
    },
    {
      title: '조회수',
      dataIndex: 'readCount',
      key: 'readCount',
      width: '90px',
      sorter: (a, b) => a.readCount - b.readCount,
    },
    {
      title: '공유하기',
      dataIndex: 'shareCount',
      key: 'shareCount',
      width: '90px',
      sorter: (a, b) => a.shareCount - b.shareCount,
    },
    {
      title: '북마크',
      dataIndex: 'bookmarkCount',
      key: 'bookmarkCount',
      width: '90px',
      sorter: (a, b) => a.bookmarkCount - b.bookmarkCount,
    },
    {
      title: '유익해요',
      dataIndex: 'isHelpfulCount',
      key: 'isHelpfulCount',
      width: '90px',
      sorter: (a, b) => a.isHelpfulCount - b.isHelpfulCount,
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="diseaseInfo" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="콘텐츠"
            subTitle="질환 정보"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <DeployButton
              onClickDeploy={onClickDeploy}
              onClickCancelDeploy={onClickCancelDeploy}
              disabled={!isDeployReady}
            />
            <div className="divider" />
            <Button
              type="primary"
              style={{ width: 100 }}
              onClick={handleCreateNewWindow}
            >
              추가
            </Button>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (diseaseInfoPagination.text = value),
                  ),
                );
              }}
              defaultValue={diseaseInfoPagination.text}
            />
            <div className="searchResult">
              {diseaseInfos && `검색결과 ${total}개`}
            </div>

            <AntList
              deployChangeInfo={deployChangeInfo}
              setDeployChangeInfo={setDeployChangeInfo}
              pageUpdate={pageUpdate}
              setPageUpdate={setPageUpdate}
              columns={columns}
              dataSource={diseaseInfos}
              onChange={onChange}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DiseaseInfo;
