import React from 'react';
import { Form, Input } from 'antd';
import 'antd/dist/antd.css';
import * as listMap from '../../util/listMap';

const DisabledInput = ({ label, type, value, size = false }) => {
  return (
    <Form.Item
      label={label}
      name={type}
      labelCol={size ? listMap.largeLayout.labelCol : listMap.layout.labelCol}
      wrapperCol={
        size ? listMap.largeLayout.wrapperCol : listMap.layout.wrapperCol
      }
    >
      <Input value={value} disabled />
    </Form.Item>
  );
};

export default DisabledInput;
