import { createAction, handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  diseaseTypes: getAsyncState.initial(null),
};

// action types
export const diseaseTypeActions = {
  SET_DISEASE_TYPES: '@diseaseType/SET_DISEASE_TYPES',
  FETCH_ALL_DISEASE_TYPES: '@diseaseType/FETCH_ALL_DISEASE_TYPES',
  FETCH_DISEASE_TYPE: '@diseaseType/FETCH_DISEASE_TYPE',
  FETCH_DISEASE_GROUP_DISEASE_TYPE:
    '@diseaseType/FETCH_DISEASE_GROUP_DISEASE_TYPE',
  POST_DISEASE_TYPE: '@diseaseType/POST_DISEASE_TYPE',
  PATCH_DISEASE_TYPE: '@diseaseType/PATCH_DISEASE_TYPE',
  DELETE_DISEASE_TYPE: '@diseaseType/DELETE_DISEASE_TYPE',
  PATCH_DISTRIBUTE_DISEASE_TYPE: '@diseaseType/PATCH_DISTRIBUTE_DISEASE_TYPE',
};

// actions
export const diseaseTypeCreators = {
  setDiseaseTypes: createAction(diseaseTypeActions.SET_DISEASE_TYPES),
  fetchAllDiseaseTypes: createAsyncActions(
    diseaseTypeActions,
    'FETCH_ALL_DISEASE_TYPES',
  ),
  fetchDiseaseType: createAsyncActions(
    diseaseTypeActions,
    'FETCH_DISEASE_TYPE',
  ),
  fetchDiseaseGroupDiseaseType: createAsyncActions(
    diseaseTypeActions,
    'FETCH_DISEASE_GROUP_DISEASE_TYPE',
  ),
  postDiseaseType: createAsyncActions(diseaseTypeActions, 'POST_DISEASE_TYPE'),
  patchDiseaseType: createAsyncActions(
    diseaseTypeActions,
    'PATCH_DISEASE_TYPE',
  ),
  deleteDiseaseType: createAsyncActions(
    diseaseTypeActions,
    'DELETE_DISEASE_TYPE',
  ),
  patchDistributeDiseaseType: createAsyncActions(
    diseaseTypeActions,
    'PATCH_DISTRIBUTE_DISEASE_TYPE',
  ),
};

const setDiseaseTypeReducer = (state, { payload }) => ({
  diseaseTypes: { data: payload, error: null, loading: false },
});

const diseaseTypeReducer = handleActions(
  {
    [diseaseTypeActions.SET_DISEASE_TYPES]: setDiseaseTypeReducer,
    ...handleAsyncActions(
      diseaseTypeActions.FETCH_ALL_DISEASE_TYPES,
      'diseaseTypes',
    ),
    ...handleAsyncActions(
      diseaseTypeActions.FETCH_DISEASE_GROUP_DISEASE_TYPE,
      'diseaseTypes',
    ),
    ...handleAsyncActions(
      diseaseTypeActions.FETCH_DISEASE_TYPE,
      'diseaseTypes',
    ),
    ...handleAsyncActions(diseaseTypeActions.POST_DISEASE_TYPE, 'diseaseTypes'),
    ...handleAsyncActions(
      diseaseTypeActions.PATCH_DISEASE_TYPE,
      'diseaseTypes',
    ),
    ...handleAsyncActions(
      diseaseTypeActions.DELETE_DISEASE_TYPE,
      'diseaseTypes',
    ),
    ...handleAsyncActions(
      diseaseTypeActions.PATCH_DISTRIBUTE_DISEASE_TYPE,
      'diseaseTypes',
    ),
  },
  initialState,
);

export default diseaseTypeReducer;
