import axios from 'axios';
import { convertToQuery } from '../hooks/usePagination';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 변이 유전자 리포트 등록 신청 현황을 가져옵니다.
 */
export async function fetchAllMutationDnaReportRegisterRequest(
  pagination,
  { type },
) {
  const res = await axios.get(
    `/v3/admin/userRequests?type=${type}&${convertToQuery(pagination)}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 모든 변이 유전자 리포트 등록 신청 현황을 가져옵니다.
 * @param {String} userId
 */
export async function fetchMutationDnaReportRegisterRequestContent(userId) {
  const res = await axios.get(`/v3/admin/users/${userId}/userRequests`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 변이 유전자 리포트 등록 신청 현황을 수정합니다.
 * @param {String} userId
 * @param {String} dnaTestReportId
 * @param {object} body
 */
export async function patchMutationDnaReportRegisterRequest(userId, body) {
  const res = await axios.patch(`/v3/admin/users/${userId}/userRequests`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 변이 유전자 리포트 등록 신청 현황을 삭제합니다.
 * @param {String} userId
 * @param {String} dnaTestReportId
 */
export async function deleteMutationDnaReportRegisterRequest(
  userId,
  dnaTestReportId,
) {
  const res = await axios.delete(
    `/v3/admin/users/${userId}/userRequests/${dnaTestReportId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
