import { handleActions } from 'redux-actions';
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  clinicalTrialCounseling: getAsyncState.initial(null),
  clinicalTrialCounselingSetting: getAsyncState.initial(null),
};

// action types
export const clinicalTrialCounselingActions = {
  FETCH_ALL_CLINICALTRIAL_COUNSELING:
    '@clinicalTrialCounseling/FETCH_ALL_CLINICALTRIAL_COUNSELING',
  FETCH_ALL_CLINICALTRIAL_COUNSELING_SETTING:
    '@clinicalTrialCounseling/FETCH_ALL_CLINICALTRIAL_COUNSELING_SETTING',
};

// actions
export const clinicalTrialCounselingCreators = {
  fetchAllClinicalTrialCounselingRequests: createAsyncActions(
    clinicalTrialCounselingActions,
    'FETCH_ALL_CLINICALTRIAL_COUNSELING',
  ),
  fetchAllClinicalTrialCounselingSetting: createAsyncActions(
    clinicalTrialCounselingActions,
    'FETCH_ALL_CLINICALTRIAL_COUNSELING_SETTING',
  ),
};

const clinicalTrialCounselingReducer = handleActions(
  {
    ...handleAsyncActions(
      clinicalTrialCounselingActions.FETCH_ALL_CLINICALTRIAL_COUNSELING,
      'clinicalTrialCounseling',
    ),
    ...handleAsyncActions(
      clinicalTrialCounselingActions.FETCH_ALL_CLINICALTRIAL_COUNSELING_SETTING,
      'clinicalTrialCounselingSetting',
    ),
  },
  initialState,
);

export default clinicalTrialCounselingReducer;
