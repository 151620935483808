import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 팝업데이터를 가져옵니다.
 */
export async function fetchAllPopup() {
  const res = await axios.get(`/v3/admin/popups`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 팝업을 생성합니다.
 * @param {object} body
 */
export async function postPopup(body) {
  const res = await axios.post('/v3/admin/popups', body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 팝업을 수정합니다.
 * @param {String} popupId
 * @param {object} body
 */
export async function patchPopup(popupId, body) {
  const res = await axios.put(`/v3/admin/popups/${popupId}`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 팝업을 삭제합니다.
 * @param {String} popupId
 */
export async function deletePopup(popupId) {
  const res = await axios.delete(`/v3/admin/popups/${popupId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
