import React from 'react';
import { Row, Col, Button, Radio, Space, Empty } from 'antd';
import 'antd/dist/antd.css';

const RehabilitationStep = ({
  data,
  state,
  setState,
  handleCreateNewWindow,
  handleCreateEditWindow,
  getDetailData,
}) => {
  const handleStep = (e) => {
    setState({
      stepValue: e.target.value,
      selectContents: '',
    });
    getDetailData(e.target.value);
  };
  return (
    <Row span={24} className="rehabilitationList">
      <Col span={24}>
        <div className="rehabilitationTitle">재활 단계</div>
        <div className="addContentBtn">
          <Button
            type="primary"
            size="md"
            className="add-button"
            onClick={() => handleCreateNewWindow('rehabilitation')}
          >
            추가 하기
          </Button>
        </div>
        {data?.length > 0 ? (
          <>
            <Radio.Group onChange={handleStep} value={state.stepValue}>
              <Space direction="vertical">
                {data?.map((step) => (
                  <div>
                    <Radio value={step.id} key={step.id}>
                      {step.title}
                    </Radio>
                    <Button
                      style={{ float: 'right' }}
                      onClick={() =>
                        handleCreateEditWindow(step, 'rehabilitation')
                      }
                    >
                      수정
                    </Button>
                  </div>
                ))}
              </Space>
            </Radio.Group>
          </>
        ) : (
          <Empty description={false} />
        )}
      </Col>
    </Row>
  );
};

export default RehabilitationStep;
