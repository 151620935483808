import { all, takeLatest } from 'redux-saga/effects';
import {
  dnaCounselingRequestCreators,
  dnaCounselingRequestActions,
} from '../reducers/dnaCounselingRequest.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  fetchAllDnaCounselingRequest,
  fetchAllDnaCounselingSetting,
} from '../../services/dnaCounselingRequestService';

const handleFetchAllDnaCounselingRequest = createAsyncSaga(
  dnaCounselingRequestCreators.fetchAllDnaCounselingRequest,
  fetchAllDnaCounselingRequest,
);

const handleFetchAllDnaCounselingSetting = createAsyncSaga(
  dnaCounselingRequestCreators.fetchAllDnaCounselingSetting,
  fetchAllDnaCounselingSetting,
);

export function* dnaCounselingSaga() {
  yield all([
    takeLatest(
      dnaCounselingRequestActions.FETCH_ALL_DNA_COUNSELING_REQUEST,
      handleFetchAllDnaCounselingRequest,
    ),
    takeLatest(
      dnaCounselingRequestActions.FETCH_ALL_DNA_COUNSELING_SETTING,
      handleFetchAllDnaCounselingSetting,
    ),
  ]);
}
