import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { authCreators } from './store/reducers/auth.reducer';
import { userCreators } from './store/reducers/user.reducer';

const ProtectedRoute = ({ path, component: Component, permission }) => {
  const dispatch = useDispatch();
  const { accessToken, isLoggedIn, permissions, isPermitted } = useSelector(
    (state) => {
      const authData = state.authReducer.auth?.data;
      const token = authData?.accessToken;
      const permissionData = state.userReducer.permission?.data;
      return {
        accessToken: token,
        isLoggedIn: authData?.email,
        permissions: permissionData,
        isPermitted: permissionData?.includes(permission),
      };
    },
    shallowEqual,
  );

  useEffect(() => {
    if (accessToken) {
      switch (true) {
        case !isLoggedIn:
          dispatch(authCreators.autoLogin.request(accessToken));
          break;
        case !permissions:
          dispatch(userCreators.fetchPermission.request());
          break;
        case !isPermitted:
          alert('페이지 접근 권한이 없습니다.');
          break;
        default: // pass (avoid ESLint warning)
      }
    }
  }, [dispatch, accessToken, isLoggedIn, permissions, isPermitted]);

  if (!accessToken) return <Redirect to="/login" />;
  if (!isLoggedIn || !permissions) return <Spinner />;
  if (!isPermitted) {
    return <Redirect to={path === '/main' ? '/login' : '/main'} />;
  }
  if (path === '/')
    return (
      <Route
        render={(props) => (
          <Redirect
            to={{
              pathname: '/main',
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  return <Route render={(props) => <Component {...props} />} />;
};

export default ProtectedRoute;
