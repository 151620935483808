import React, { useEffect } from 'react';
import { Button } from 'antd';
import 'antd/dist/antd.css';
import { Redirect } from 'react-router';
import { useDispatch } from 'react-redux';

import { authCreators } from '../store/reducers/auth.reducer';
import { useSession } from '../hooks/useSession';

const clientId = process.env.REACT_APP_CLIENT_ID || '';
const clientSecret = process.env.REACT_APP_CLIENT_SECRET || '';

const parseTime = (remaining) => {
  const hours = Math.floor(remaining / 60);
  const minutes = Math.floor(remaining % 60);
  return `🕑 : ${hours ? `${hours}시간` : ''} ${minutes}분`;
};

const SessionTimer = ({ isWindow }) => {
  const dispatch = useDispatch();
  const { accessToken, refreshToken, remaining } = useSession();

  useEffect(() => {
    const storageToken = localStorage.getItem('accessToken');
    if (accessToken !== storageToken) {
      if (storageToken)
        dispatch(
          authCreators.renewWindowSession({
            accessToken: storageToken,
            refreshToken: localStorage.getItem('refreshToken'),
            expiresAt: new Date(localStorage.getItem('expiresAt')),
          }),
        );
      else if (isWindow) {
        alert('세션이 만료되었습니다.');
        window.close();
      }
    }
  }, [dispatch, isWindow, accessToken, remaining]);

  if (remaining < 0) {
    alert('세션이 만료되었습니다.');
    return <Redirect to="/login" />;
  }

  return (
    remaining && (
      <div style={{ float: 'right' }}>
        <b style={{ color: isWindow ? '#fff' : '#212529', marginRight: 8 }}>
          {parseTime(remaining)}
        </b>
        {!isWindow && (
          <Button
            onClick={() => {
              dispatch(
                authCreators.renewSession.request({
                  refreshToken,
                  clientId,
                  clientSecret,
                }),
              );
            }}
            style={{ marginRight: 8 }}
          >
            세션 연장
          </Button>
        )}
      </div>
    )
  );
};

export default SessionTimer;
