import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Layout, Button, Input, Table, Checkbox } from 'antd';
import 'antd/dist/antd.css';
import { Form } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../../window/util/utils';
import {
  fetchAllDiseaseTypeList,
  patchDistributeDiseaseType,
} from '../../../services/diseaseTypeService';
import DeployButton from '../../components/deployButton';
import { usePagination } from '../../../hooks/usePagination';
import { handleError } from '../../../hooks/useRequest';

const today = new Date();
const DAY = 86400 * 1000;
const hasRecentClinicalTrialUpdate = (
  interval,
  { recentClinicalTrialUpdate },
) =>
  recentClinicalTrialUpdate &&
  today - new Date(recentClinicalTrialUpdate) <= interval;

const CharIcon = ({ char }) => (
  <div
    style={{
      width: '15px',
      height: '15px',
      margin: '0 4px',
      backgroundColor: '#DC2366',
      borderRadius: '50%',
    }}
  >
    <span
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '8px',
        fontWeight: 'bold',
        color: 'white',
      }}
    >
      {char}
    </span>
  </div>
);

const StageText = ({ isApp, isRegular }) => {
  const defaultStyle = { margin: 0 };
  switch (true) {
    case !isApp:
      return (
        <p style={Object.assign(defaultStyle, { color: 'grey' })}>미지원</p>
      );
    case isRegular:
      return (
        <p style={Object.assign(defaultStyle, { fontWeight: 'bold' })}>정식</p>
      );
    default:
      return <p style={defaultStyle}>베타</p>;
  }
};

export const makeLink = (handleClick) => (text, record) => (
  <span
    style={{ color: '#40a9ff', cursor: 'pointer' }}
    key={text}
    onClick={() => handleClick(record)}
  >
    {text}
  </span>
);

const DiseaseType = () => {
  const { Content } = Layout;
  const history = useHistory();
  const { state } = useLocation();

  const {
    apiParams: [searchText],
    setApiParams,
    initialize,
    data,
    setData,
    loading,
    pageState,
    pagination,
  } = usePagination({
    call: fetchAllDiseaseTypeList,
    initialPageState: state?.pageState,
    initialApiParams: [state?.searchText ?? ''],
  });

  const onSearch = useCallback(
    (value) => {
      value = value.trim();
      if (value !== searchText) setApiParams([value]);
    },
    [searchText, setApiParams],
  );

  const toClinicalTrialPage = useCallback(
    (record) => {
      history.replace('/diseaseType', {
        ...state,
        pageState,
        searchText,
      });
      history.push('/diseaseTypeClinicalTrial', {
        name: record.krName,
        diseaseTypeId: record.id,
      });
    },
    [history, state, pageState, searchText],
  );

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const [isAppState, setIsAppState] = useState({
    ids: [],
    changedIds: [],
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initialize, []);
  useEffect(() => window.history.replaceState('/diseaseType', undefined), []);

  const resetAppIds = useCallback(
    () =>
      setIsAppState({
        ids: data.reduce((accu, { isApp, id }) => {
          if (isApp) accu.push(id);
          return accu;
        }, []),
        changedIds: [],
      }),
    [data],
  );

  useEffect(resetAppIds, [resetAppIds]);

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
        setApiParams([searchText]);
      }
    },
    [searchText, setApiParams, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = useCallback(
    (diseaseType) => {
      if (findWindowById(diseaseType.id)) {
        alert('이미 편집중인 글입니다.');
        return;
      }
      createWindow({ id: diseaseType.id, dataType: 'diseaseType' });
    },
    [findWindowById, createWindow],
  );

  const isAppColumn = useMemo(
    () => ({
      title: (
        <div>
          앱 노출&ensp;
          <Checkbox
            checked={isAppState.ids.length === data.length}
            indeterminate={
              isAppState.ids.length && data.length > isAppState.ids.length
            }
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              const ids = [];
              const changedIds = [];
              const newIsApp = e.target.checked;
              data.forEach(
                newIsApp
                  ? ({ id, isApp }) => {
                      ids.push(id);
                      if (isApp !== newIsApp) changedIds.push(id);
                    }
                  : ({ id, isApp }) =>
                      isApp === newIsApp || changedIds.push(id),
              );
              setIsAppState({ ids, changedIds });
            }}
          />
        </div>
      ),
      dataIndex: 'isApp',
      showSorterTooltip: false,
      sorter: (r1, r2) =>
        isAppState.ids.includes(r2.id) - isAppState.ids.includes(r1.id),
      width: '7%',
      render: (_, { isApp, id }) => {
        const { ids, changedIds } = isAppState;
        const checked = ids.includes(id);
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={checked}
              onChange={({ target }) => {
                const others = (prevId) => id !== prevId;
                const filteredIds = ids.filter(others);
                const filteredChangedIds = changedIds.filter(others);
                if (target.checked) filteredIds.push(id);
                if (isApp !== target.checked) filteredChangedIds.push(id);
                setIsAppState({
                  ids: filteredIds,
                  changedIds: filteredChangedIds,
                });
              }}
            />
            {isApp !== checked && <CharIcon char="C" />}
          </div>
        );
      },
    }),
    [isAppState, data],
  );

  const columns = [
    isAppColumn,
    {
      title: '질환명 (국문)',
      dataIndex: 'krName',
      width: '20%',
      sorter: {
        compare: (a, b) => a?.krName?.localeCompare(b?.krName),
      },
      render: makeLink(handleCreateEditWindow),
    },
    {
      title: '질환명 (영문)',
      dataIndex: 'enName',
      width: '20%',
      sorter: {
        compare: (a, b) => a?.enName?.localeCompare(b?.enName),
      },
    },
    {
      title: '동의어 (국문)',
      dataIndex: 'krSynonyms',
      render: (value) => value?.join(', '),
      ellipsis: true,
      width: '15%',
    },
    {
      title: '동의어 (영문)',
      dataIndex: 'enSynonyms',
      render: (value) => value?.join(', '),
      ellipsis: true,
      width: '15%',
    },
    {
      title: '산정특례코드',
      dataIndex: 'exemptionCode',
      width: '8%',
    },
    {
      title: '단계',
      dataIndex: 'isRegular',
      render: (_, record) => StageText(record),
      width: '5%',
    },
    {
      title: '희귀',
      dataIndex: 'isDomestic',
      render: (text) =>
        text && <p style={{ textAlign: 'center', margin: 0 }}>O</p>,
      width: '3%',
    },
    {
      title: '관련 임상시험',
      dataIndex: 'clinicalTrialCount',
      sorter: (a, b) => a.clinicalTrialCount - b.clinicalTrialCount,
      render: (text, record) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {makeLink(toClinicalTrialPage)(text, record) /* todo */}
          {hasRecentClinicalTrialUpdate(7 * DAY, record) && (
            <CharIcon char="N" />
          )}
        </div>
      ),
      width: '7%',
    },
  ];

  const handleCreateNewWindow = () => {
    createWindow({ id: `${NEW}${Date.now()}`, dataType: 'diseaseType' });
  };

  const deploy = () => {
    if (window.confirm('앱에 변경된 사항을 반영하여 배포하시겠습니까?')) {
      const { changedIds } = isAppState;
      patchDistributeDiseaseType(changedIds)
        .then(() => {
          setData(
            data.map((diseaseType) =>
              changedIds.includes(diseaseType.id)
                ? {
                    ...diseaseType,
                    isApp: !diseaseType.isApp,
                  }
                : diseaseType,
            ),
          );
        })
        .catch(handleError);
    }
  };

  const onClickDeploy = () => {
    if (!isAppState.changedIds.length) {
      alert('배포할 변경사항이 없습니다.');
      return;
    }
    deploy();
  };

  const onClickCancelDeploy = () => {
    if (!isAppState.changedIds.length) {
      alert('변경사항이 없습니다.');
      return;
    }
    resetAppIds();
  };

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="diseaseType" />
        <Layout className="right-layout">
          <TitleBreadcrumb title="정보" subTitle="질환" className="white-bg" />
          <Content className="site-layout-background contentStyle">
            <DeployButton
              onClickDeploy={onClickDeploy}
              onClickCancelDeploy={onClickCancelDeploy}
              disabled={!isAppState.changedIds.length}
            />
            <div className="divider" />
            <Form className="form-inline justify-content-between">
              <Form.Group>
                <Button type="primary" onClick={handleCreateNewWindow}>
                  질환 추가
                </Button>
              </Form.Group>
              <Form.Group>
                <div className="searchResult">
                  검색결과 {pagination.total ?? 0}개
                </div>

                <Input.Search
                  placeholder="검색어를 입력해주세요."
                  allowClear
                  defaultValue={searchText}
                  className="searchStyle"
                  loading={loading}
                  onSearch={onSearch}
                  onPressEnter={(e) => {
                    e.preventDefault(); // no form submit
                    onSearch(e.target.value);
                  }}
                />
              </Form.Group>
            </Form>
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DiseaseType;
