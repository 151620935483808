import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 환우회 리스트 데이터를 가져옵니다.
 */
export async function fetchAllPatientGroup() {
  const res = await axios.get(`/v4/admin/patientGroups`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 환우회 유저 리스트를 가져옵니다.
 * @param {string} patientGroupId
 *
 */
export async function fetchAllPatientGroupUser(patientGroupId) {
  const res = await axios.get(
    `/v4/admin/patientGroups/${patientGroupId}/users`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 환우회 정보를 가져옵니다.
 * @param {string} patientGroupId
 *
 */
export async function fetchPatientGroup(patientGroupId) {
  const res = await axios.get(`/v4/admin/patientGroups/${patientGroupId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 환우회를 생성합니다.
 * @param {object} body
 */
export async function postPatientGroup(body) {
  const res = await axios.post(`/v4/admin/patientGroups`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 환우회를 수정합니다.
 * @param {String} patientGroupId
 * @param {object} body
 */
export async function patchPatientGroup(patientGroupId, body) {
  const res = await axios.patch(
    `/v4/admin/patientGroups/${patientGroupId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 환우회를 삭제합니다.
 * @param {String} patientGroupId
 */
export async function deletePatientGroup(patientGroupId) {
  const res = await axios.delete(`/v4/admin/patientGroups/${patientGroupId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 환우회 유저 리스트 정보를 가져옵니다.
 * @param {String} projectName
 */
export async function fetchPatientGroupUserInfo(projectName) {
  const res = await axios.get(
    `/v4/admin/patientGroups/patientGroup/reports?patientGroupId=${projectName}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
