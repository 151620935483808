import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Image, Empty } from 'antd';
import 'antd/dist/antd.css';

const RehabilitationList = ({
  data,
  detailData,
  state,
  setState,
  handleCreateNewWindow,
  handleCreateEditWindow,
}) => {
  const [listData, setListData] = useState([]);

  const handleViewContents = (e) => {
    setState({
      selectContents: e,
    });
  };

  useEffect(() => {
    setListData(data?.find((element) => element.id === state?.stepValue));
  }, [state?.stepValue, data]);

  return (
    <Row span={24} className="rehabilitationList">
      <Col span={24}>
        <div className="rehabilitationTitle">재활 관리</div>
        <div className="addContentBtn">
          <Button
            type="primary"
            size="md"
            className="add-button"
            disabled={state?.stepValue === ''}
            onClick={() => handleCreateNewWindow('rehabilitationDetail')}
          >
            추가 하기
          </Button>
        </div>
        <div className="rehabilitationSubTitle">
          {listData?.title} <span>{listData?.subTitle}</span>
        </div>
        <div className="rehabilitationDescription">{listData?.description}</div>

        {detailData?.length > 0 && state?.stepValue !== '' ? (
          <>
            {detailData?.map((list) => (
              <div className="listBorder">
                <Image width={70} height={70} src={list?.thumbnail} />
                <div className="listTitle">{list?.title}</div>
                <Button
                  onClick={() =>
                    handleCreateEditWindow(list, 'rehabilitationDetail')
                  }
                >
                  수정
                </Button>
                <Button
                  type={
                    list?.contents === state.selectContents
                      ? 'primary'
                      : 'Default'
                  }
                  onClick={() => handleViewContents(list?.contents)}
                >
                  보기
                </Button>
              </div>
            ))}
          </>
        ) : (
          <Empty description={false} />
        )}
      </Col>
    </Row>
  );
};

export default RehabilitationList;
