import { handleActions } from 'redux-actions';
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  remoteConfig: getAsyncState.initial(null),
};

// action types
export const remoteConfigActions = {
  FETCH_ALL_REMOTE_CONFIGS: '@remoteConfig/FETCH_ALL_REMOTE_CONFIGS',
};

// actions
export const remoteConfigCreators = {
  fetchAllRemoteConfigs: createAsyncActions(
    remoteConfigActions,
    'FETCH_ALL_REMOTE_CONFIGS',
  ),
};

const remoteConfigReducer = handleActions(
  {
    ...handleAsyncActions(
      remoteConfigActions.FETCH_ALL_REMOTE_CONFIGS,
      'remoteConfig',
    ),
  },
  initialState,
);

export default remoteConfigReducer;
