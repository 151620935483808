import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Layout, Table, Input, notification } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { descending } from '../../../util/sort';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import { healthProfileCreators } from '../../../store/reducers/healthProfile.reducer';
import { useFetch } from '../../../hooks/useRequest';
import { fetchUnmaskedInfo as fetchUnmaskedInfoService } from '../../../services/userService';

const HealthProfileUsers = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { healthProfileUserInfo, healthProfileUserPagination } = useSelector(
    (state) => {
      return {
        healthProfileUserInfo: state?.healthProfileReducer?.healthProfileUser?.data?.sort(
          descending('updatedAt'),
        ),
        healthProfileUserPagination:
          state?.paginationReducer?.healthProfileUserPagination,
      };
    },
    shallowEqual,
  );

  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const confirmRef = useRef(() => {});

  const getData = useCallback(() => {
    dispatch(
      healthProfileCreators.fetchHealthProfileUsers.request(
        state?.healthProfileId,
      ),
    );
  }, [dispatch]);
  useEffect(getData, [getData]);

  useEffect(() => {
    if (healthProfileUserInfo) {
      if (healthProfileUserPagination.total === null)
        setTotal(healthProfileUserInfo?.length);
      else setTotal(healthProfileUserPagination.total);
      setPageUpdate(healthProfileUserPagination.page);
      confirmRef.current(healthProfileUserPagination.text);
    }
  }, [healthProfileUserInfo]);

  const { Search } = Input;
  const { Content } = Layout;

  const [unmaskedType, setUnmaskedType] = useState('');
  const [unmaskedId, setUnmaskedId] = useState('');
  const [getMasked, setGetMasked] = useState('');

  const {
    done: fetchUnmaskedInfoDone,
    data: fetchUnmaskedInfoData,
    call: fetchUnmaskedInfo,
    initialize: initializeDone,
  } = useFetch(null, fetchUnmaskedInfoService, {
    userId: unmaskedId,
    type: unmaskedType,
  });

  const onChangeMasked = (record, type) => {
    if (record === '-') return;
    setUnmaskedType(type);
    setUnmaskedId(record.userId);
    setGetMasked(true);
  };

  useEffect(() => {
    if (getMasked) {
      fetchUnmaskedInfo();
      setGetMasked(false);
    }
  }, [getMasked]);

  useEffect(() => {
    if (fetchUnmaskedInfoDone) {
      initializeDone();
      notification.success({
        message: '마스킹을 해제하였습니다.',
        description: '마스킹을 해제하였습니다.',
      });
    }
  }, [fetchUnmaskedInfoDone]);

  const MaskedData = useCallback(
    ({ record, type }) => {
      return (
        <>
          <a onClick={() => onChangeMasked(record, type)}>
            {record?.userId === unmaskedId && type === unmaskedType
              ? fetchUnmaskedInfoData?.[type] ?? '-'
              : type === 'birthDate'
              ? '****-**-**'
              : record?.[type] ?? '-'}
          </a>
        </>
      );
    },
    [fetchUnmaskedInfoDone],
  );

  const handleMoveDetailPage = useCallback(
    ({ userId }) => () => history.push('/userDetail', { userId }),
    [history],
  );

  const CompletedTag = (completed) => {
    return (
      <div
        className="tagWrapper"
        style={{
          color: completed ? '#1890ff' : '#979797',
        }}
      >
        {completed ? '완료' : '진행중'}
      </div>
    );
  };

  const columns = [
    {
      title: '상태',
      dataIndex: 'completed',
      key: 'completed',
      render: CompletedTag,
      filters: [
        {
          text: '완료',
          value: true,
        },
        {
          text: '진행중',
          value: false,
        },
      ],
      filteredValue: healthProfileUserPagination.filter?.completed || null,
      onFilter: (value, record) => record.completed === value,
    },
    {
      title: '업데이트일',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text, record) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
      sorter: {
        compare: (a, b) => a?.updatedAt?.localeCompare(b?.updatedAt),
      },
    },
    {
      title: '계정',
      dataIndex: 'email',
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: healthProfileUserPagination.filter?.email || null,
      onFilter: (
        search,
        { email, updatedAt, status, name, phone, funnel, dnaTest },
      ) =>
        [email, updatedAt, status, name, phone, funnel, dnaTest].some((value) =>
          value?.includes(search),
        ),
      render: (text, record) =>
        text ? (
          <>
            <MaskedData record={record} type="email" />
            <Button
              type="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={handleMoveDetailPage(record)}
            >
              이동
            </Button>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
      render: (text, record) => <MaskedData record={record} type="name" />,
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        compare: (a, b) => a?.phone?.localeCompare(b?.phone),
      },
      render: (text, record) => <MaskedData record={record} type="phone" />,
    },
    {
      title: '성별',
      dataIndex: 'gender',
      key: 'gender',
      sorter: {
        compare: (a, b) => a?.gender?.localeCompare(b?.gender),
      },
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypeKrName',
      key: 'diseaseTypeKrName',
      sorter: {
        compare: (a, b) =>
          a?.diseaseTypeKrName?.localeCompare(b?.diseaseTypeKrName),
      },
    },
    {
      title: '계정 유형',
      dataIndex: 'relationship',
      key: 'relationship',
      sorter: {
        compare: (a, b) => a?.phone?.localeCompare(b?.phone),
      },
    },
    {
      title: '14세 미만',
      dataIndex: 'isUnder14',
      key: 'isUnder14',
      render: (isUnder14) => <>{isUnder14 ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      filteredValue: healthProfileUserPagination.filter?.isUnder14 || null,
      onFilter: (value, record) => record.isUnder14 === value,
    },
    {
      title: '테스트ID',
      dataIndex: 'isTest',
      key: 'isTest',
      render: (isTest) => <>{isTest ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      filteredValue: healthProfileUserPagination.filter?.isTest || null,
      onFilter: (value, record) => record.isTest === value,
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="healthProfile" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="정보"
            subTitle="설문 완료/진행중인 유저"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button
              type="primary"
              onClick={() => history.push('/healthProfile')}
            >
              설문 돌아가기
            </Button>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (healthProfileUserPagination.text = value),
                  ),
                );
              }}
              defaultValue={healthProfileUserPagination.text}
            />
            <div className="searchResult">
              {healthProfileUserInfo && `검색결과 ${total}개`}
            </div>
            <Table
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={healthProfileUserInfo}
              pagination={{
                onChange: (page) => {
                  setPageUpdate(page);
                },
                current: pageUpdate,
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(pagination, filters, sorter, extra) => {
                dispatch(
                  paginationCreators.setValue(
                    (healthProfileUserPagination.page = pagination?.current),
                    (healthProfileUserPagination.filter = filters),
                    (healthProfileUserPagination.total =
                      extra?.currentDataSource?.length),
                  ),
                );
                setTotal(extra?.currentDataSource?.length);
              }}
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default HealthProfileUsers;
