import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  hospitalExamination: getAsyncState.initial(null),
  userHospitalExamination: getAsyncState.initial(null),
  userDiseaseTypeCertification: getAsyncState.initial(null),
};

// action types
export const hospitalExaminationActions = {
  FETCH_ALL_HOSPITAL_EXAMINATIONS:
    '@hospitalExamination/FETCH_ALL_HOSPITAL_EXAMINATIONS',
  FETCH_ALL_USER_HOSPITAL_EXAMINATIONS:
    '@userHospitalExamination/FETCH_ALL_USER_HOSPITAL_EXAMINATIONS',
  FETCH_ALL_USER_DISEASE_TYPE_CERTIFICATIONS:
    '@userDiseaseTypeCertification/FETCH_ALL_USER_DISEASE_TYPE_CERTIFICATIONS',
};

// actions
export const hospitalExaminationCreators = {
  fetchAllHospitalExaminations: createAsyncActions(
    hospitalExaminationActions,
    'FETCH_ALL_HOSPITAL_EXAMINATIONS',
  ),
  fetchAllUserHospitalExaminations: createAsyncActions(
    hospitalExaminationActions,
    'FETCH_ALL_USER_HOSPITAL_EXAMINATIONS',
  ),
  fetchAllUserDiseaseTypeCertifications: createAsyncActions(
    hospitalExaminationActions,
    'FETCH_ALL_USER_DISEASE_TYPE_CERTIFICATIONS',
  ),
};

const hospitalExaminationReducer = handleActions(
  {
    ...handleAsyncActions(
      hospitalExaminationActions.FETCH_ALL_HOSPITAL_EXAMINATIONS,
      'hospitalExamination',
    ),
    ...handleAsyncActions(
      hospitalExaminationActions.FETCH_ALL_USER_HOSPITAL_EXAMINATIONS,
      'userHospitalExamination',
    ),
    ...handleAsyncActions(
      hospitalExaminationActions.FETCH_ALL_USER_DISEASE_TYPE_CERTIFICATIONS,
      'userDiseaseTypeCertification',
    ),
  },
  initialState,
);

export default hospitalExaminationReducer;
