import React, { Component } from 'react';
import { Layout } from 'antd';
import SessionTimer from '../../component/sessionTimer';

const { Header } = Layout;

const headerOption =
  process.env.REACT_APP_DEPLOY === 'production'
    ? '#343a40'
    : process.env.REACT_APP_DEPLOY === 'staging'
    ? '#009b5a'
    : '#22549e';

export default class windowHeader extends Component {
  render() {
    return (
      <Header style={{ backgroundColor: headerOption, height: 48 }}>
        <div
          style={{
            position: 'absolute',
            left: 18,
            color: '#fff',
            fontSize: 20,
            lineHeight: '48px',
          }}
        >
          {this.props.title}
        </div>
        <div
          style={{
            position: 'absolute',
            right: 18,
            lineHeight: '48px',
          }}
        >
          <SessionTimer isWindow={true} />
        </div>
      </Header>
    );
  }
}
