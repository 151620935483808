import { all, takeLatest } from 'redux-saga/effects';
import { hpCreators, hpActions } from '../reducers/hp.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  fetchPointHistories,
  fetchPointConversions,
} from '../../services/hpService';

const handleFetchPointHistory = createAsyncSaga(
  hpCreators.fetchPointHistories,
  fetchPointHistories,
);

const handleFetchPointConversion = createAsyncSaga(
  hpCreators.fetchPointConversions,
  fetchPointConversions,
);

export function* hpSaga() {
  yield all([
    takeLatest(hpActions.FETCH_POINT_HISTORY, handleFetchPointHistory),
    takeLatest(hpActions.FETCH_POINT_CONVERSION, handleFetchPointConversion),
  ]);
}
