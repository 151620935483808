import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 설문 항목을 가져옵니다.
 */
export async function fetchAllHealthProfile() {
  const res = await axios.get('/v4/admin/healthProfiles');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 항목의 모든 설문 질문을 가져옵니다.
 * @param {uuid} healthProfileId
 */
export async function fetchAllHealthProfileQuestion(healthProfileId) {
  const res = await axios.get(
    `/v4/admin/healthProfiles/${healthProfileId}/healthProfileQuestions`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 질문의 모든 설문 선택지를 가져옵니다.
 * @param {uuid} healthProfileId
 * @param {uuid} healthProfileQuestionId
 */
export async function fetchAllHealthProfileOption({
  healthProfileId,
  healthProfileQuestionId,
}) {
  const res = await axios.get(
    `/v4/admin/healthProfiles/${healthProfileId}/healthProfileQuestions/${healthProfileQuestionId}/healthProfileQuestionOptions`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 항목을 가져옵니다.
 * @param {string} healthProfileId
 *
 */
export async function fetchHealthProfile(healthProfileId) {
  const res = await axios.get(`/v4/admin/healthProfiles/${healthProfileId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 항목을 생성합니다.
 * @param {Object} posthData
 */
export async function postHealthProfile(postData) {
  const res = await axios.post(`/v4/admin/healthProfiles`, postData);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 항목을 수정합니다.
 * @param {uuid} healthProfileId
 * @param {Object} patchData
 */
export async function patchHealthProfile(healthProfileId, patchData) {
  const res = await axios.patch(
    `/v4/admin/healthProfiles/${healthProfileId}`,
    patchData,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 설문의 앱 노출을 수정합니다.
 * @param {Object} patchData
 */
export async function patchHealthProfileBulk(patchData) {
  const res = await axios.patch(`/v4/admin/healthProfiles`, {
    healthProfiles: patchData,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 항목을 삭제합니다.
 * @param {uuid} healthProfileId
 */
export async function deleteHealthProfile(healthProfileId) {
  const res = await axios.delete(`/v4/admin/healthProfiles/${healthProfileId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 완료/진행중인 유저 리스트를 가져옵니다.
 * @param {string} healthProfileId
 *
 */
export async function fetchHealthProfileUser(healthProfileId) {
  const res = await axios.get(
    `/v4/admin/healthProfiles/${healthProfileId}/users`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 질문을 가져옵니다.
 * @param {string} healthProfileId
 * @param {string} healthProfileQuestionId
 */
export async function fetchHealthProfileQuestion(
  healthProfileId,
  healthProfileQuestionId,
) {
  const res = await axios.get(
    `/v4/admin/healthProfiles/${healthProfileId}/healthProfileQuestions/${healthProfileQuestionId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 질문을 생성합니다.
 * @param {uuid} healthProfileId
 * @param {string} mainQuestion,
 * @param {enum} mainQuestionType
 * @param {String} answerForSubQuestion
 * @param {array} mainQuestionOption
 * @param {String} subQuestion
 * @param {enum} subQuestionType
 * @param {array} subQuestionOption
 * @param {number} order
 * @param {string} photo
 * @param {string} info
 * @param {boolean} isChangeable
 */
export async function postHealthProfileQuestion({
  healthProfileId,
  mainQuestion,
  mainQuestionType,
  answerForSubQuestion,
  mainQuestionOption,
  subQuestion,
  subQuestionType,
  subQuestionOption,
  order,
  photo,
  info,
  isChangeable,
}) {
  const res = await axios.post(`/v4/admin/healthProfileQuestions`, {
    healthProfileId,
    mainQuestion,
    mainQuestionType,
    answerForSubQuestion,
    mainQuestionOption,
    subQuestion,
    subQuestionType,
    subQuestionOption,
    order,
    photo,
    info,
    isChangeable,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 질문을 수정합니다.
 * @param {uuid} healthProfileQuestionId
 * @param {array} patchData
 */
export async function patchHealthProfileQuestion(
  healthProfileQuestionId,
  patchData,
) {
  const res = await axios.patch(
    `/v4/admin/healthProfileQuestions/${healthProfileQuestionId}`,
    patchData,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 질문을 삭제합니다.
 * @param {uuid} healthProfileQuestionId
 */
export async function deleteHealthProfileQuestion(healthProfileQuestionId) {
  const res = await axios.delete(
    `/v4/admin/healthProfileQuestions/${healthProfileQuestionId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 선택지를 가져옵니다.
 * @param {string} healthProfileId
 * @param {string} healthProfileQuestionId
 * @param {string} healthProfileQuestionOptionId
 */
export async function fetchHealthProfileOption(
  healthProfileId,
  healthProfileQuestionId,
  healthProfileQuestionOptionId,
) {
  const res = await axios.get(
    `/v4/healthProfiles/${healthProfileId}/healthProfileQuestions/${healthProfileQuestionId}/healthProfileQuestionOptions/${healthProfileQuestionOptionId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 선택지를 생성합니다.
 * @param {uuid} healthProfileQuestionId
 * @param {uuid} nextQuestionId
 * @param {string} label
 * @param {string} info
 * @param {string} photo
 * @param {string} activePhoto
 * @param {integer} order
 * @param {string} placeholder
 * @param {boolean} isFinish
 * @param {enum} keyboardType
 * @param {boolean} isChangeableFinish
 * @param {boolean} checkNokAuth
 */
export async function postHealthProfileOption(
  healthProfileQuestionId,
  {
    nextQuestionId,
    label,
    info,
    photo,
    activePhoto,
    order,
    placeholder,
    isFinish,
    isChangeableFinish,
    checkNokAuth,
    keyboardType,
    unit,
    isAllowDecimal,
  },
) {
  const res = await axios.post(`/v4/admin/healthProfileQuestionOptions`, {
    healthProfileQuestionId,
    nextQuestionId,
    label,
    info,
    photo,
    activePhoto,
    order,
    placeholder,
    isFinish,
    isChangeableFinish,
    checkNokAuth,
    keyboardType,
    unit,
    isAllowDecimal,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 선택지를 수정합니다.
 * @param {string} healthProfileId
 * @param {string} healthProfileQuestionId
 * @param {string} healthProfileOptionId
 * @param {{nextQuestionId: string, label: string, info: string, photo:string, activePhoto:string, order:number, placeholder:string, isFinish:boolean, isChangeableFinish:boolean, keyboardType:string}} patchData
 */
export async function patchHealthProfileOption(
  healthProfileOptionId,
  patchData,
) {
  const res = await axios.patch(
    `/v4/admin/healthProfileQuestionOptions/${healthProfileOptionId}`,
    patchData,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 선택지를 삭제합니다.
 * @param {uuid} healthProfileOptionId
 */
export async function deleteHealthProfileOption(healthProfileOptionId) {
  const res = await axios.delete(
    `/v4/admin/healthProfileQuestionOptions/${healthProfileOptionId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 설문 질문과 답변 정보를 가져옵니다.
 * @param {uuid} healthProfileOptionId
 */
export async function fetchHealthProfileQuestionAnswer({
  userId,
  healthProfileId,
}) {
  const res = await axios.get(
    `/v4/admin/users/${userId}/healthProfiles/healthProfilesQNAs?healthProfileId=${healthProfileId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 설문 답변을 수정합니다.
 * @param {String} healthProfileOptionId
 * @param {Object} body
 */
export async function patchHealthProfileQuestionAnswer(
  userId,
  healthProfileAnswerId,
  body,
) {
  const res = await axios.patch(
    `/v3/admin/users/${userId}/healthProfileAnswers/${healthProfileAnswerId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 기본 설문 질문 답변 정보를 가져옵니다.
 * @param {String} userId
 * @param {String} type
 */
export async function fetchHealthProfileBasicQuestionAnswer({ userId, type }) {
  const res = await axios.get(
    `/v3/admin/healthProfileQuestions?userId=${userId}&type=${type}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 기본 설문 질문 답변 정보를 가져옵니다.
 * @param {String} userId
 * @param {String} diseaseGroupId
 * @param {Object} body
 */
export async function postHealthProfileAnswer(userId, diseaseGroupId, body) {
  const res = await axios.post(
    `/v3/admin/diseaseGroups/${diseaseGroupId}/users/${userId}/healthProfileAnswers`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 카이안과 설문 질문과 답변 정보를 가져옵니다.
 * @param {uuid} healthProfileOptionId
 */
export async function fetchHealthProfileQuestionsHealthProfileAnswers(
  projectName,
) {
  const res = await axios.get(
    `/v4/admin/userParticipationHistories/${projectName}/reports`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문을 복사합니다.
 * @param {string} healthProfileId
 */
export async function postHealthProfileCopy(healthProfileId) {
  const res = await axios.post(
    `/v4/admin/healthProfiles/${healthProfileId}/copy`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 액셀 다운로드 요청 / 다운로드 합니다.
 * @param {uuid} healthProfileId
 */
export async function fetchHealthProfileExcel(healthProfileId) {
  const res = await axios.get(
    `/v4/admin/healthProfiles/healthProfile/excel?contentsId=${healthProfileId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 액셀 다운로드 요청 리스트를 가져옵니다.
 */
export async function fetchHealthProfileWaitingList() {
  const res = await axios.get(
    `/v4/admin/securityApprovalHistories/waitingList`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 질환별 설문 질문과 답변 정보를 가져옵니다.
 * @param {uuid} diseaseId
 */
export async function fetchDiseaseHealthProfileAnswers(diseaseId) {
  const res = await axios.get(
    `/v4/admin/healthProfiles/diseaseHealthProfile/excel/?contentsId=${diseaseId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
