import { handleActions } from 'redux-actions';
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  patientGroup: getAsyncState.initial(null),
  patientGroupUser: getAsyncState.initial(null),
};

// action types
export const patientGroupActions = {
  FETCH_ALL_PATIENT_GROUPS: '@patientGroup/FETCH_ALL_PATIENT_GROUPS',
  FETCH_ALL_PATIENT_GROUP_USERS: '@healthProfile/FETCH_ALL_PATIENT_GROUP_USERS',
};

// actions
export const patientGroupCreators = {
  fetchAllPatientGroups: createAsyncActions(
    patientGroupActions,
    'FETCH_ALL_PATIENT_GROUPS',
  ),
  fetchAllPatientGroupUsers: createAsyncActions(
    patientGroupActions,
    'FETCH_ALL_PATIENT_GROUP_USERS',
  ),
};

const patientGroupReducer = handleActions(
  {
    ...handleAsyncActions(
      patientGroupActions.FETCH_ALL_PATIENT_GROUPS,
      'patientGroup',
    ),
    ...handleAsyncActions(
      patientGroupActions.FETCH_ALL_PATIENT_GROUP_USERS,
      'patientGroupUser',
    ),
  },
  initialState,
);

export default patientGroupReducer;
