import React, { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Layout, Table, Input, Button } from 'antd';
import 'antd/dist/antd.css';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import useWindow from '../../../hooks/useWindow';
import { mutationDnaCreators } from '../../../store/reducers/mutationDna.reducer';
import { NEW } from '../../../window/util/utils';

const actionCreatorByCommand = {
  create: mutationDnaCreators.createMutationDna,
  update: mutationDnaCreators.updateMutationDna,
  delete: mutationDnaCreators.deleteMutationDna,
};

const MutationDna = () => {
  const dispatch = useDispatch();
  const { mutationDnas } = useSelector(
    (state) => ({
      mutationDnas: state.mutationDnaReducer.mutationDna.data,
    }),
    shallowEqual,
  );

  const getData = useCallback(() => {
    dispatch(mutationDnaCreators.fetchAllMutationDna.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setDataSource(mutationDnas);
    setFilteredData(mutationDnas?.length);
  }, [mutationDnas]);

  const { Content } = Layout;
  const [dataSource, setDataSource] = useState('');
  const { Search } = Input;
  const [filteredData, setFilteredData] = useState(null);
  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const formatTimestamp = (text) =>
    text && dayjs(text).format('YYYY-MM-DD HH:mm');

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const {
        groups: { command, data },
      } = /^(?<command>(close|create|update|delete)) (?<data>.*)$/.exec(
        event.data,
      ) ?? { groups: {} };
      if (!command) return;
      if (command === 'close') {
        destroyWindowById(data);
        return;
      }
      const actionCreator = actionCreatorByCommand[command];
      if (!actionCreator) return;
      dispatch(actionCreator(JSON.parse(data)));
    },
    [destroyWindowById, dispatch],
  );
  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (mutationDna) => {
    if (findWindowById(mutationDna.id)) {
      alert('이미 편집중인 글입니다.');
    } else {
      createWindow({ id: mutationDna.id, dataType: 'mutationDna' });
    }
  };

  const handleCreateNewWindow = () => {
    createWindow({ id: `${NEW}${Date.now()}`, dataType: 'mutationDna' });
  };

  const onSearch = (e) => {
    if (e === '') {
      setDataSource(mutationDnas);
      setFilteredData(mutationDnas?.length);
    } else {
      const filteredDatas = dataSource?.filter((entry) =>
        Object?.values(entry).toString().includes(e),
      );
      setDataSource(filteredDatas);
      setFilteredData(filteredDatas.length);
    }
  };

  const columns = [
    {
      title: '등록일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
      render: formatTimestamp,
    },
    {
      title: '유전자',
      dataIndex: 'geneName',
      key: 'geneName',
      width: 200,
      sorter: {
        compare: (a, b) => a?.geneName?.localeCompare(b?.geneName),
      },
      render: (text, record) => (
        <a
          style={{ color: '#40a9ff' }}
          onClick={() => handleCreateEditWindow(record)}
        >
          {text}
        </a>
      ),
    },
    {
      title: 'nucleotide',
      dataIndex: 'nucleotide',
      key: 'nucleotide',
      sorter: {
        compare: (a, b) => a?.nucleotide?.localeCompare(b?.nucleotide),
      },
    },
    {
      title: 'aminoAcid',
      dataIndex: 'aminoAcid',
      key: 'aminoAcid',
      sorter: {
        compare: (a, b) => a?.aminoAcid?.localeCompare(b?.aminoAcid),
      },
    },
    {
      title: '유전형태',
      dataIndex: 'inheritance',
      key: 'inheritance',
      width: 100,
      sorter: {
        compare: (a, b) => a?.inheritance?.localeCompare(b?.inheritance),
      },
    },
    {
      title: '유전자형',
      dataIndex: 'zygosity',
      key: 'zygosity',
      sorter: {
        compare: (a, b) => a?.zygosity?.localeCompare(b?.zygosity),
      },
    },
    {
      title: 'annoVar',
      dataIndex: 'variantTypeAnnoVar',
      key: 'variantTypeAnnoVar',
      sorter: {
        compare: (a, b) =>
          a?.variantTypeAnnoVar?.localeCompare(b?.variantTypeAnnoVar),
      },
    },
    {
      title: 'VEP',
      dataIndex: 'variantTypeVep',
      key: 'variantTypeVep',
      sorter: {
        compare: (a, b) => a?.variantTypeVep?.localeCompare(b?.variantTypeVep),
      },
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabData" link="mutationDna" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="데이터"
            subTitle="변이 유전자"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button
              type="primary"
              style={{ width: 100 }}
              onClick={handleCreateNewWindow}
            >
              추가
            </Button>

            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={onSearch}
            />
            <div className="searchResult">검색결과 {filteredData}개</div>

            <Table
              columns={columns}
              loading={!dataSource}
              dataSource={dataSource}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(_, __, ___, { currentDataSource }) =>
                setFilteredData(currentDataSource.length)
              }
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MutationDna;
