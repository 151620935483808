import { handleActions } from 'redux-actions';
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  popup: getAsyncState.initial(null),
};

// action types
export const popupActions = {
  FETCH_ALL_POPUPS: '@popup/FETCH_ALL_POPUPS',
};

// actions
export const popupCreators = {
  fetchAllPopups: createAsyncActions(popupActions, 'FETCH_ALL_POPUPS'),
};

const popupReducer = handleActions(
  {
    ...handleAsyncActions(popupActions.FETCH_ALL_POPUPS, 'popup'),
  },
  initialState,
);

export default popupReducer;
