import React, { useState, useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { Layout, Button } from 'antd';
import 'antd/dist/antd.css';
import { useLocation, useHistory } from 'react-router-dom';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import ListView from '../../components/listView';
import * as listMap from '../../../util/listMap';
import SearchButton from '../../components/searchButton';
import { useFetch } from '../../../hooks/useRequest';
import { fetchEventParticipants } from '../../../services/eventService';

const EventParticipants = () => {
  const history = useHistory();
  const { state } = useLocation();
  const eventId = state?.eventData?.id;
  const {
    data: fetchEventParticipantsData,
    call: callEventParticipants,
  } = useFetch(null, fetchEventParticipants, eventId);

  useEffect(() => {
    if (eventId) callEventParticipants();
  }, [eventId, callEventParticipants]);

  const searchFilterContent = [
    '이름',
    '이메일',
    '질환',
    '이벤트 신청일시',
    '테스트ID',
  ];

  const listName = [
    { name: '이름', width: '10%' },
    { name: '이메일', width: '20%' },
    { name: '질환', width: '10%' },
    { name: '이벤트 신청일시', width: '10%' },
    { name: '테스트ID', width: '10%' },
  ];

  const listItemView = [
    { view: (item) => item.name, type: 'normal' },
    { view: (item) => item.email, type: 'link' },
    { view: (item) => item.diseaseTypeName, type: 'normal' },
    { view: (item) => item.createdAt, type: 'normal' },
    { view: (item) => listMap.agreeMap[item.isTest], type: 'normal' },
  ];

  const { Content } = Layout;
  const [searchFilter, setSearchFilter] = useState(searchFilterContent[0]);
  const [searchWord, setSearchWord] = useState('');
  const [moveReady, setMoveReady] = useState(false);
  const [moveEventReady, setMoveEventReady] = useState(false);
  const userId = useRef(null);

  const handleChange = (e) => {
    setSearchFilter(e.target.value);
  };

  const onTableSearch = (e) => {
    setSearchWord(e.target.value.toLowerCase());
  };

  const handleMoveDetailPage = (user) => {
    userId.current = user.id;
    setMoveReady(true);
  };

  if (moveReady) {
    history.push('/userDetail', {
      userId: userId.current,
    });
  }

  const handleMovePage = () => {
    setMoveEventReady(true);
  };

  if (moveEventReady) {
    history.push('/event');
  }
  const loading = !fetchEventParticipantsData;

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabOperation" link="event" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="운영"
            subTitle="프로젝트 참여인원"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button type="primary" onClick={() => handleMovePage()}>
              목록 돌아가기
            </Button>
            <Form className="form-inline justify-content-between">
              <Form.Group />
              <SearchButton
                searchFilterContent={searchFilterContent}
                searchFilter={searchFilter}
                handleChange={handleChange}
                onTableSearch={onTableSearch}
              />
            </Form>
            <ListView
              listData={fetchEventParticipantsData}
              loading={loading}
              searchFilter={searchFilter}
              searchWord={searchWord}
              editWindow={handleMoveDetailPage}
              listName={listName}
              listItemView={listItemView}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default EventParticipants;
