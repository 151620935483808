import { all, takeLatest } from 'redux-saga/effects';
import {
  notificationCreators,
  notificationActions,
} from '../reducers/notification.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  fetchAllNotificationHistory,
  fetchAllNotificationIndividual,
  postNotification,
  fetchAllNotificationContents,
  fetchNewsFeedList,
  fetchPushMessageList,
} from '../../services/notificationService';

const handlePushMessageList = createAsyncSaga(
  notificationCreators.fetchPushMessageList,
  fetchPushMessageList,
);

const handleFetchAllNotificationHistory = createAsyncSaga(
  notificationCreators.fetchAllNotificationHistory,
  fetchAllNotificationHistory,
);

const handleFetchAllNotificationIndividual = createAsyncSaga(
  notificationCreators.fetchAllNotificationIndividual,
  fetchAllNotificationIndividual,
);

const handlePostNotification = createAsyncSaga(
  notificationCreators.postNotification,
  postNotification,
);

const handleFetchAllNotificationContents = createAsyncSaga(
  notificationCreators.fetchAllNotificationContents,
  fetchAllNotificationContents,
);

const handleFetchNewsFeedLists = createAsyncSaga(
  notificationCreators.fetchNewsFeedList,
  fetchNewsFeedList,
);

export function* notificationSaga() {
  yield all([
    takeLatest(
      notificationActions.FETCH_PUSH_MESSAGE_LIST,
      handlePushMessageList,
    ),
    takeLatest(
      notificationActions.FETCH_ALL_NOTIFICATION_HISTORY,
      handleFetchAllNotificationHistory,
    ),
    takeLatest(
      notificationActions.FETCH_ALL_NOTIFICATION_INDIVIDUAL,
      handleFetchAllNotificationIndividual,
    ),
    takeLatest(notificationActions.POST_NOTIFICATION, handlePostNotification),
    takeLatest(
      notificationActions.FETCH_ALL_NOTIFICATION_CONTENTS,
      handleFetchAllNotificationContents,
    ),
    takeLatest(
      notificationActions.FETCH_NEWS_FEED_LIST,
      handleFetchNewsFeedLists,
    ),
  ]);
}
