import axios from 'axios';
import { convertToQuery, userConvertToQuery } from '../hooks/usePagination';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 알림/푸쉬 기록을 가져옵니다.
 * @param {Boolean} isIdentified
 */
export async function fetchAllUser(pagination, paramFilters, paramSorters) {
  const res = await axios.get(
    `/v4/admin/users?${convertToQuery(pagination)}${userConvertToQuery({
      filters: paramFilters,
      sorters: paramSorters,
    })}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 접속한 clientUser의 권한을 가져옵니다.
 */
export async function fetchPermission() {
  const res = await axios.get(`/v3/admin/userPermissions`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 유전자 상담 신청 정보를 가져옵니다.
 * @param {String} userId
 */
export async function fetchUser(userId) {
  const res = await axios.get(`/v4/admin/users/${userId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 질환 정보를 수정합니다.
 * @param {String} userId
 * @param {array} diseaseTypeIds
 */
export async function patchUserDiseaseType({ userId, diseaseTypeIds }) {
  const res = await axios.post(`/v3/admin/userDiseaseType`, {
    userId,
    diseaseTypeIds,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 어드민 권한이 존재하는 클라이언트-유저 매핑 테이블 항목을 모두 가져옵니다.
 * @param {String} clientId
 * @param {boolean} isAdmin
 */
export async function fetchAdminUser({ clientId, isAdmin }) {
  const res = await axios.get(
    `/v3/admin/clients/${clientId}/users?isAdmin=${isAdmin}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 어드민 클라이언트 유저를 수정합니다.
 * @param {String} clientId
 * @param {String} userId
 * @param {boolean} isAdmin
 * @param {object} body
 */
export async function patchAdminUser(clientId, userId, isAdmin, body) {
  const res = await axios.patch(
    `/v3/admin/clients/${clientId}/users/${userId}/permissions?isAdmin=${isAdmin}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 어드민 마스킹 데이터를 가져옵니다.
 * @param {String} userId
 * @param {String} type
 */
export async function fetchUnmaskedInfo({ userId, type }) {
  const res = await axios.get(
    `/v4/admin/users/${userId}/unmaskedInfo?type=${type}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 테스트ID 정보를 수정합니다.
 * @param {String} userId
 * @param {Object} body
 */
export async function patchTestUser(userId, body) {
  const res = await axios.patch(
    `/v3/admin/users/${userId}/filter/testUser`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 레어메이트 정보를 수정합니다.
 * @param {String} userId
 * @param {Object} body
 */
export async function patchUserProfiles(userId, body) {
  const res = await axios.patch(`/v4/admin/users/${userId}/userProfiles`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 모든 계정 탈퇴 기록을 가져옵니다.
 */
export async function fetchAllUserWithdrawal() {
  const res = await axios.get('/v4/admin/userWithdrawalRequests');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 게시글 목록을 가져옵니다.
 * @param {string} userId
 */
export async function fetchUserWriterList(userId) {
  const res = await axios.get(
    process.env.REACT_APP_DEPLOY === 'production'
      ? `https://healthcaretogether-server.humanscape.io/v2/feeds/writers/${userId}/`
      : process.env.REACT_APP_DEPLOY === 'staging'
      ? `https://healthcaretogether-server-staging.humanscape.io/v2/feeds/writers/${userId}/`
      : `https://healthcaretogether-server-dev.humanscape.io/v2/feeds/writers/${userId}/`,
  );
  if (res.status !== HTTP_SUCCESS) throw res;
  return res.data;
}

/**
 * 유저의 댓글 목록을 가져옵니다.
 * @param {string} userId
 */
export async function fetchUserCommentList(userId) {
  const res = await axios.get(
    process.env.REACT_APP_DEPLOY === 'production'
      ? `https://healthcaretogether-server.humanscape.io/v2/feeds/comments/writers/${userId}/`
      : process.env.REACT_APP_DEPLOY === 'staging'
      ? `https://healthcaretogether-server-staging.humanscape.io/v2/feeds/comments/writers/${userId}/`
      : `https://healthcaretogether-server-dev.humanscape.io/v2/feeds/comments/writers/${userId}/`,
  );
  if (res.status !== HTTP_SUCCESS) throw res;
  return res.data;
}
