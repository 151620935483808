import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Row, Col, Table, Button } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { hospitalExaminationCreators } from '../../../store/reducers/hospitalExamination.reducer';
import useWindow from '../../../hooks/useWindow';

const UserHospitalExamination = (userId) => {
  const dispatch = useDispatch();
  const { hospitalExaminations } = useSelector(
    (state) => ({
      hospitalExaminations:
        state.hospitalExaminationReducer?.userHospitalExamination?.data,
    }),
    shallowEqual,
  );

  const loading = !hospitalExaminations;
  const getData = useCallback(() => {
    dispatch(
      hospitalExaminationCreators.fetchAllUserHospitalExaminations.request(
        userId.userId,
      ),
    );
  }, [dispatch]);
  useEffect(getData, [getData]);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (hospitalExamination) => {
    if (findWindowById(hospitalExamination.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({
      id: `${userId.userId}/${hospitalExamination.id}`,
      dataType: 'userHospitalExamination',
    });
  };

  const handleCreateNewWindow = () => {
    createWindow({
      id: userId.userId,
      dataType: 'userHospitalExamination',
    });
  };

  const columns = [
    {
      title: '입력일시',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
      render: (text) => (text ? moment(text).format('YYYY-MM-DD') : '-'),
    },
    {
      title: '검사일',
      dataIndex: 'reportedAt',
      key: 'reportedAt',
      sorter: {
        compare: (a, b) => a?.reportedAt?.localeCompare(b?.reportedAt),
      },
    },
    {
      title: '병원명',
      dataIndex: 'institution',
      key: 'reportedAt',
      render: (text, record) => (
        <a onClick={() => handleCreateEditWindow(record)}>{text || '-'}</a>
      ),
    },
  ];

  return (
    <Row span={24}>
      <Col span={24}>
        <Button
          type="primary"
          style={{ marginBottom: 16 }}
          onClick={handleCreateNewWindow}
        >
          병원 검사 추가
        </Button>
        <Table
          rowKey={(item) => {
            return item.id;
          }}
          columns={columns}
          loading={loading}
          dataSource={hospitalExaminations}
          pagination={false}
          size="small"
          bordered
        />
      </Col>
    </Row>
  );
};

export default UserHospitalExamination;
