import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  Form,
  Input,
  Radio,
  Button,
  Row,
  Col,
  notification,
  Divider,
  Skeleton,
  Tabs,
  Typography,
} from 'antd';
import 'antd/dist/antd.css';
import { FileTwoTone } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  fetchUser as fetchUserService,
  patchTestUser as patchTestUserService,
  patchUserProfiles as patchUserProfilesService,
  fetchUnmaskedInfo as fetchUnmaskedInfoService,
} from '../../../services/userService';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { useFetch } from '../../../hooks/useRequest';
import * as listMap from '../../../util/listMap';
import system from '../../../img/system.png';
import humanscapeIcn from '../../../img/icn/humanscape.png';
import naverIcn from '../../../img/icn/naver.png';
import googleIcn from '../../../img/icn/google.png';
import kakaoIcn from '../../../img/icn/kakao.png';
import appleIcn from '../../../img/icn/apple.png';
import { getAgeFromBirthDate } from '../../../util/time';

const USER_INFO_COLUMN_DATA = [
  {
    title: (user) =>
      user.isUnder14 ? '14세 미만 유저 정보 (직접 입력)' : '본인 인증 정보',
    key: 'default',
    fields: [
      { type: 'name', label: '이름' },
      { type: 'phone', label: '연락처' },
      { type: 'optionalPhone', label: '추가 연락처' },
      {
        type: 'birthDate',
        label: '생년월일',
        value: (user) => {
          const date = user.birthDate;
          return date ? dayjs(date).format('YYYY-MM-DD') : '';
        },
      },
      { type: 'age', label: '만 나이' },
      { type: 'gender', label: '성별' },
    ],
  },
  {
    title: '법정 대리인 정보',
    key: 'nokInfo',
    fields: [
      {
        type: ['nokInfo', 'name'],
        label: '이름',
        value: (user) => user.nokInfo?.name,
      },
      {
        type: ['nokInfo', 'phoneNumber'],
        label: '연락처',
        value: (user) => user.nokInfo?.phoneNumber,
      },
      {
        type: ['nokInfo', 'birthday'],
        label: '생년월일',
        value: (user) => {
          const date = user.nokInfo?.birthday;
          return date ? dayjs(date.toString()).format('YYYY-MM-DD') : '';
        },
      },
      {
        type: ['nokInfo', 'age'],
        label: '만 나이',
        value: (user) => `${user.nokInfo?.age}세`,
      },
      {
        type: ['nokInfo', 'gender'],
        label: '성별',
        value: (user) => listMap.genderMap[user.nokInfo?.gender],
      },
    ],
  },
  {
    title: 'SNS 제공 정보',
    key: 'socialAccounts',
    fields: [
      {
        type: 'socialAccounts.name',
        label: '이름',
        value: (user) =>
          user.socialAccounts?.find((social) => social.name)?.name,
      },
      {
        type: 'socialAccounts.phone',
        label: '연락처',
        value: (user) =>
          user.socialAccounts?.find((social) => social.phone)?.phone,
      },
      {
        type: 'socialAccounts.birthDate',
        label: '생년월일',
        value: (user) => {
          const date = user.socialAccounts?.find((social) => social.birthDate)
            ?.birthDate;
          return date ? dayjs(date).format('YYYY-MM-DD') : '';
        },
      },
      {
        type: 'socialAccounts.age',
        label: '만 나이',
        value: (user) =>
          getAgeFromBirthDate(
            user.socialAccounts?.find((social) => social.birthDate)?.birthDate,
          ),
      },
      {
        type: 'socialAccounts.gender',
        label: '성별',
        value: (user) =>
          listMap.genderMap[
            user.socialAccounts?.find((social) => social.gender)?.gender
          ],
      },
    ],
  },
];

const UserInfo = (userId) => {
  const [key, setKey] = useState('default');
  const { TabPane } = Tabs;
  const { Title } = Typography;
  const { TextArea } = Input;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [unmaskedType, setUnmaskedType] = useState('');
  const [getMasked, setGetMasked] = useState('');
  const [userIsTest, setUserIsTest] = useState(false);
  const [userIsInfluencer, setUserIsInfluencer] = useState(false);
  const userDataFetchDone = useRef(false);
  const { done: fetchUserDone, data: userData, call: fetchUser } = useFetch(
    null,
    fetchUserService,
    userId?.userId,
  );
  const {
    done: fetchUnmaskedInfoDone,
    data: fetchUnmaskedInfoData,
    call: fetchUnmaskedInfo,
    initialize: initializeDone,
  } = useFetch(null, fetchUnmaskedInfoService, {
    userId: userId?.userId,
    type: unmaskedType,
  });

  const {
    done: patchTestUserDone,
    call: patchTestUser,
    initialize: initializePatchTestUser,
  } = useFetch(null, patchTestUserService, userId?.userId, {
    isTest: userIsTest,
  });

  const {
    done: patchUserProfilesDone,
    call: patchUserProfiles,
    initialize: initializePatchUserProfiles,
  } = useFetch(null, patchUserProfilesService, userId?.userId, {
    isInfluencer: userIsInfluencer,
  });

  useEffect(() => {
    if (!fetchUserDone && userId && !userDataFetchDone.current) {
      dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
      fetchUser();
      userDataFetchDone.current = true;
    }
  }, [fetchUser, fetchUserDone, userId]);

  useEffect(() => {
    if (getMasked) {
      fetchUnmaskedInfo();
      setGetMasked(false);
    }
  }, [getMasked]);

  useEffect(() => {
    if (fetchUnmaskedInfoDone) {
      initializeDone();
      notification.success({
        message: '마스킹을 해제하였습니다.',
        description: '마스킹을 해제하였습니다.',
      });
    }
  }, [fetchUnmaskedInfoDone]);

  useEffect(() => {
    if (fetchUserDone) {
      form.setFieldsValue({
        email: userData?.email,
        name: userData?.name,
        nickname: userData?.userProfile?.nickname,
        phone: userData?.phone,
        optionalPhone: userData?.optionalPhone,
        age: `${userData?.age}세`,
        gender: listMap.genderMap[userData?.gender],
        isIdentified: listMap.agreeMap[userData?.isIdentified],
        isCertified: listMap.agreeMap[userData?.isCertified],
        agreementMarketing: listMap.agreeMap[userData?.agreementMarketing],
        clinicalTrialsNotiOn: listMap.agreeMap[userData?.clinicalTrialsNotiOn],
        isUnder14: listMap.agreeMap[userData?.isUnder14],
        funnelMainAnswer: userData?.funnelMainAnswer,
        funnelSubAnswer: userData?.funnelSubAnswer,
        diseaseType: userData?.diseaseType,
        relationship: userData?.relationship,
        isTest: userData?.isTest,
      });
      setUserIsTest(userData?.isTest);
      setUserIsInfluencer(userData?.userProfile?.isInfluencer);
    }
  }, [fetchUserDone]);

  const loading = !userData;

  const onTestChange = (e) => {
    setUserIsTest(e.target.value);
  };

  const onInfluencerChange = (e) => {
    setUserIsInfluencer(e.target.value);
  };

  useEffect(() => {
    if (patchTestUserDone) {
      notification.success({
        message: '테스트 계정 업데이트에 성공하였습니다.',
        description: '테스트 계정을 확인해 주세요.',
      });
      initializePatchTestUser();
    }
  }, [patchTestUserDone]);

  useEffect(() => {
    if (patchUserProfilesDone) {
      notification.success({
        message: '레어메이트 업데이트에 성공하였습니다.',
        description: '레어메이트 정보를 확인해 주세요.',
      });
      initializePatchUserProfiles();
    }
  }, [patchUserProfilesDone]);

  const BasicData = useCallback(({ label, type, value }) => {
    return (
      <>
        <Form.Item label={label} name={type} initialValue={value}>
          <Input disabled />
        </Form.Item>
      </>
    );
  }, []);

  const socialRender = (values) => {
    return values.map((item) => {
      let source;
      let altText;

      switch (item?.social) {
        case 'HUMANSCAPE':
          source = humanscapeIcn;
          altText = 'humanscape';
          break;
        case 'KAKAO':
          source = kakaoIcn;
          altText = 'kakao';
          break;
        case 'NAVER':
          source = naverIcn;
          altText = 'naver';
          break;
        case 'APPLE':
          source = appleIcn;
          altText = 'apple';
          break;
        case 'GOOGLE':
          source = googleIcn;
          altText = 'google';
          break;
        default:
          source = '';
          altText = '';
      }

      return (
        <div key={item.id}>
          <img
            src={source}
            style={source ? { height: 20, marginRight: 4 } : {}}
            alt={altText}
          />
          <span>{item?.name ? `${item?.name}` : '-'}</span>
          <span>
            {item?.gender ? ` / ${listMap.genderMap[item?.gender]}` : ' / -'}
          </span>
          <span>{item?.phone ? ` / ${item?.phone}` : ' / -'}</span>
        </div>
      );
    });
  };

  const columnKeyList = useMemo(() => {
    const list = [];
    if (
      userData?.isIdentified ||
      !userData?.socialAccounts
        .length /** 이메일 가압했으나 본인인증 false인 경우 정보 표출 */
    ) {
      list.push('default');
    }
    if (userData?.nokInfo) {
      list.push('nokInfo');
    }
    if (userData?.socialAccounts?.length > 0) {
      list.push('socialAccounts');
    }
    return list;
  }, [userData]);

  return (
    <Row span={24} className="user_userInfo">
      <Col span={24}>
        <h5>
          <FileTwoTone className="userTitleIcon" />
          개인 정보
        </h5>
        <Divider plain />
        <Tabs
          defaultActiveKey={key}
          onChange={setKey}
          className="userDetailTab"
        >
          <TabPane tab="개인 정보" key="default">
            {loading ? (
              <Skeleton active />
            ) : (
              <Form name="basic" form={form} {...listMap.layout}>
                <Row span={24} style={{ marginBottom: 12 }}>
                  <Col span={12}>
                    <Form.Item label="계정유형">
                      {userData?.socialAccounts?.length > 0 ? (
                        socialRender(userData?.socialAccounts)
                      ) : (
                        <img
                          src={humanscapeIcn}
                          style={{ height: 20, marginRight: 4 }}
                          alt="humanscape"
                        />
                      )}
                    </Form.Item>
                    <BasicData label="계정" type="email" />
                  </Col>
                  <Col span={12}>
                    <Form.Item label="테스트ID">
                      <Radio.Group onChange={onTestChange} value={userIsTest}>
                        <Radio value={true}>테스트ID</Radio>
                        <Radio value={false}>실제ID</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Row span={24}>
                  <Col span={12}>
                    {columnKeyList.map((columnKey) => {
                      const columnData = USER_INFO_COLUMN_DATA.find(
                        (data) => data.key === columnKey,
                      );
                      return (
                        <div style={{ marginBottom: 12 }} key={columnKey}>
                          <Row>
                            <Col span={1} />
                            <Title level={5}>
                              {typeof columnData.title === 'function'
                                ? columnData.title(userData)
                                : columnData.title}
                            </Title>
                          </Row>

                          {columnData.fields.map((field) => {
                            return (
                              <BasicData
                                label={field.label}
                                type={field.type}
                                key={field.label}
                                {...(field.value && {
                                  value: field.value(userData),
                                })}
                              />
                            );
                          })}
                        </div>
                      );
                    })}
                  </Col>
                  <Col span={12}>
                    <Row>
                      <Col span={2} />
                      <Title level={5}>기타 정보</Title>
                    </Row>
                    <BasicData
                      label="질환"
                      type="diseaseType"
                      value={userData?.diseaseType}
                    />
                    <BasicData
                      label="관계"
                      type="relationship"
                      value={userData?.relationship}
                    />
                    <BasicData
                      label="14세 미만"
                      type="isUnder14"
                      value={listMap.agreeMap[userData?.isUnder14]}
                    />
                    <BasicData
                      label="본인 인증"
                      type="isIdentified"
                      value={listMap.agreeMap[userData?.isIdentified]}
                    />
                    <BasicData
                      label="질환 인증"
                      type="isCertified"
                      value={listMap.agreeMap[userData?.isCertified]}
                    />
                    <BasicData
                      label="마케팅 수신"
                      type="agreementMarketing"
                      value={listMap.agreeMap[userData?.agreementMarketing]}
                    />
                  </Col>
                </Row>
                <Row span={24}>
                  <Col span={24} style={{ height: 32 }}>
                    <Button
                      type="primary"
                      className="userInfoBtn"
                      onClick={patchTestUser}
                      disabled={loading}
                    >
                      저장
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </TabPane>
          <TabPane tab="커뮤니티 정보" key="community">
            {loading ? (
              <Skeleton active />
            ) : (
              <Form name="basic" form={form} {...listMap.layout}>
                <Row span={24}>
                  <Col span={12}>
                    <BasicData
                      label="닉네임"
                      type="nickname"
                      value={userData?.userProfile?.nickname}
                    />
                    <BasicData
                      label="게시글 수"
                      type="feedCount"
                      value={`${userData?.feedCount ?? 0}개`}
                    />
                    <BasicData
                      label="댓글 수"
                      type="commentCount"
                      value={`${userData?.commentCount ?? 0}개`}
                    />
                    <Form.Item label="신고 당한 수">
                      <div style={{ lineHeight: '32px' }}>
                        {userData?.abuseReportingUsers
                          ? userData?.abuseReportingUsers?.length
                          : 0}
                        건
                      </div>
                      {userData?.abuseReportingUsers?.length > 0 && (
                        <TextArea
                          autoSize={{ minRows: 1, maxRows: 3 }}
                          value={userData?.abuseReportingUsers
                            ?.map((user) => user?.email)
                            ?.join('\n')}
                          disabled
                        />
                      )}
                    </Form.Item>
                    <Form.Item label="차단한 사람">
                      <div style={{ lineHeight: '32px' }}>
                        {userData?.blockUsers
                          ? userData?.blockUsers?.length
                          : 0}
                        건
                      </div>
                      {userData?.blockUsers?.length > 0 && (
                        <TextArea
                          autoSize={{ minRows: 1, maxRows: 3 }}
                          value={userData?.blockUsers
                            ?.map((user) => user?.email)
                            ?.join('\n')}
                          disabled
                        />
                      )}
                    </Form.Item>
                    <Form.Item label="레어메이트">
                      <Radio.Group
                        onChange={onInfluencerChange}
                        value={userIsInfluencer}
                      >
                        <Radio value={false}>해당 없음</Radio>
                        <Radio value={true}>
                          <img
                            src={system}
                            style={{ height: 20 }}
                            alt="system"
                          />
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12} />
                </Row>
                <Row span={24}>
                  <Col span={24} style={{ height: 32 }}>
                    <Button
                      type="primary"
                      className="userInfoBtn"
                      onClick={patchUserProfiles}
                      disabled={loading}
                    >
                      저장
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default UserInfo;
