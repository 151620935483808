import { all, takeLatest } from 'redux-saga/effects';
import { eventCreators, eventActions } from '../reducers/event.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchAllEvent } from '../../services/eventService';

const handleFetchAllEvents = createAsyncSaga(
  eventCreators.fetchAllEvents,
  fetchAllEvent,
);

export function* eventSaga() {
  yield all([takeLatest(eventActions.FETCH_ALL_EVENTS, handleFetchAllEvents)]);
}
