import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  papProject: getAsyncState.initial(null),
};

// action types
export const papProjectActions = {
  FETCH_ALL_PAP_PROJECTS: '@papProject/FETCH_ALL_PAP_PROJECTS',
};

// actions
export const papProjectCreators = {
  fetchAllPapProjects: createAsyncActions(
    papProjectActions,
    'FETCH_ALL_PAP_PROJECTS',
  ),
};

const papProjectReducer = handleActions(
  {
    ...handleAsyncActions(
      papProjectActions.FETCH_ALL_PAP_PROJECTS,
      'papProject',
    ),
  },
  initialState,
);

export default papProjectReducer;
