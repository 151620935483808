import { all, takeLatest } from 'redux-saga/effects';
import {
  entryModalCreators,
  entryModalActions,
} from '../reducers/entryModal.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchEntryModals } from '../../services/entryModalService';

const handleFetchAllEntryModals = createAsyncSaga(
  entryModalCreators.fetchEntryModals,
  fetchEntryModals,
);

export function* entryModalSaga() {
  yield all([
    takeLatest(entryModalActions.FETCH_ENTRY_MODALS, handleFetchAllEntryModals),
  ]);
}
