import React from 'react';
import { Row, Col, Empty } from 'antd';
import 'antd/dist/antd.css';
import { Editor } from '@tinymce/tinymce-react';

const RehabilitationContents = ({ state }) => {
  const tinymceApiKey = process.env.REACT_APP_TINYMCE_API_KEY || '';
  return (
    <Row span={24} className="rehabilitationList">
      <Col span={24}>
        <div className="rehabilitationTitle">재활 콘텐츠 뷰어</div>
        {state?.selectContents ? (
          <Editor
            value={state?.selectContents}
            apiKey={tinymceApiKey}
            init={{
              width: '360',
              selector: 'textarea',
              plugins: 'autoresize',
              menubar: false,
              toolbar: 'preview',
              setup(editor) {
                editor.mode.set('readonly');
              },
              content_css: '',
              content_style:
                process.env.REACT_APP_DEPLOY === 'production'
                  ? "@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css'); @import url('https://rarenote.io/css/root.css'); @import url('https://rarenote.io/css/editorStyles.css'); body { margin: 0;}"
                  : process.env.REACT_APP_DEPLOY === 'staging'
                  ? "@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css'); @import url('https://rarenote.io/css/root.css'); @import url('https://rarenote.io/css/editorStyles.css'); body { margin: 0;}"
                  : "@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/variable/pretendardvariable.css'); @import url('https://rarenote.io/css/root.css'); @import url('https://dev.rarenote.io/css/editorStyles.css'); body { margin: 0;}",
            }}
          />
        ) : (
          <Empty description={false} />
        )}
      </Col>
    </Row>
  );
};

export default RehabilitationContents;
