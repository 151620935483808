import React, { useEffect, useState, useRef, useReducer } from 'react';
import {
  Spin,
  Form,
  Input,
  Select,
  Checkbox,
  DatePicker,
  Button,
  Radio,
  Alert,
  Row,
  Col,
} from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import qs from 'query-string';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { Option } from 'antd/lib/mentions';
import ElementLoading from '../../components/elementLoading';
import WindowHeader from '../../components/windowHeader';
import TinymceAntInput from '../../components/tinymceAntInput';
import AntTinymceInput from '../../components/antTinymceInput';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { useFetch, useMutate } from '../../../hooks/useRequest';

import {
  fetchNotice as fetchNoticeService,
  postNotice as postNoticeService,
  patchNotice as patchNoticeService,
  deleteNotice as deleteNoticeService,
} from '../../../services/noticeService';
import { ALERT_MESSAGES } from '../../../assets/alert';
import { TOPIC_TAG_TYPE } from '../../../services/tagService';
import { tagCreators } from '../../../store/reducers/tag.reducer';

const NoticeWindow = (props) => {
  const dispatch = useDispatch();
  const { diseaseTypeInfo, tags } = useSelector((state) => {
    return {
      diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data
        ? state.diseaseTypeReducer.diseaseTypes.data.map(({ id, krName }) => ({
            id,
            krName,
          }))
        : null,
      tags: state.tagReducer.tags.data
        ?.filter((tag) => tag.type === TOPIC_TAG_TYPE)
        ?.map((check) => ({
          ...check,
          label: check?.name,
          value: check?.id,
        })),
    };
  }, shallowEqual);
  const { search } = props.location;

  const styleOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
  ];

  const initialState = {
    title: '',
    content: '',
    styleVersion: 2,
    hasDisease: false,
    selectedDisease: [],
    wroteAt: moment(),
    tagIds: [],
    isApp: false,
    isInfo: false,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);
  const [windowId, setWindowId] = useState('');
  const [noticeId, setNoticeId] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const didCancel = useRef(false);
  const [checkAllTag, setCheckAllTag] = React.useState(false);

  const { data: fetchNoticeData, call: fetchNotice } = useFetch(
    null,
    fetchNoticeService,
    noticeId,
  );

  const { mutate: postNotice, done: isPosted } = useMutate(postNoticeService, {
    diseaseTypeIds: state.hasDisease
      ? state.selectedDisease?.map((disease) => {
          return diseaseTypeInfo?.find((role) => role.krName === disease)?.id;
        })
      : [],
    title: state.title,
    content: state.content,
    styleVersion: state.styleVersion,
    wroteAt: moment(state.wroteAt),
    tagIds: state.tagIds
      ? state.tagIds.map((tag) => tags.find((t) => t.name === tag)?.id)
      : [],
    type: 'NOTICE',
    isApp: state.isApp ?? false,
    isInfo: state.isInfo ?? false,
  });

  const { mutate: patchNotice, done: isPatched } = useMutate(
    patchNoticeService,
    {
      noticeId,
      diseaseTypeIds: state.hasDisease
        ? state.selectedDisease?.map((disease) => {
            return diseaseTypeInfo?.find((role) => role.krName === disease)?.id;
          })
        : [],
      isApp: state.isApp,
      isInfo: state.isInfo,
      title: state.title,
      content: state.content,
      styleVersion: state.styleVersion,
      wroteAt: moment(state.wroteAt),
      tagIds: state.tagIds
        ? state.tagIds.map((tag) => tags.find((t) => t.name === tag)?.id)
        : [],
      type: 'NOTICE',
    },
  );

  const { mutate: deleteNotice, done: isDeleted } = useMutate(
    deleteNoticeService,
    noticeId,
  );

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    dispatch(tagCreators.fetchAllTags.request());
    if (params.id) {
      setNoticeId(params.id);
    } else {
      setNoticeId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (noticeId) {
      fetchNotice();
    }
  }, [noticeId, fetchNotice]);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  useEffect(() => {
    if (fetchNoticeData && diseaseTypeInfo && !didCancel.current) {
      setState({
        title: fetchNoticeData.title,
        content: fetchNoticeData.content,
        styleVersion: fetchNoticeData.styleVersion,
        hasDisease: fetchNoticeData.diseaseTypeIds?.length > 0,
        selectedDisease: fetchNoticeData.diseaseTypeIds?.map((disease) => {
          return diseaseTypeInfo?.find((role) => role.id === disease).krName;
        }),
        wroteAt: moment(
          fetchNoticeData.wroteAt === null
            ? new Date()
            : fetchNoticeData.wroteAt,
        ),
        tagIds: fetchNoticeData.topicTags
          ? fetchNoticeData.topicTags.map(
              (tag) => tags.find((t) => t.id === tag.id)?.name,
            )
          : [],
        isApp: fetchNoticeData.isApp ?? false,
        isInfo: fetchNoticeData.isInfo ?? false,
      });
      didCancel.current = true;
      setIsLoading(false);
    } else if (noticeId === '' && diseaseTypeInfo && !didCancel.current) {
      setIsLoading(false);
      didCancel.current = true;
    }
  }, [fetchNoticeData, diseaseTypeInfo, noticeId]);

  const onDateChange = (date, dateString) => {
    setState({
      wroteAt: dateString,
    });
  };

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      title: values.title,
    });
    if (noticeId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      deleteNotice();
    }
  };

  useEffect(() => {
    if (isPatch) {
      patchNotice();
      setIsPatch(false);
    }
    if (isPost) {
      postNotice();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  const plainOptions = tags?.map((t) => t.name) ?? [];

  const onTagChange = (list) => {
    setState({
      tagIds: list,
    });
    setCheckAllTag(list.length === plainOptions.length);
  };

  const onCheckAllTagChange = (e) => {
    setState({
      tagIds: e.target.checked ? plainOptions : [],
    });
    setCheckAllTag(e.target.checked);
  };

  if (isLoading) return <ElementLoading type="공지" />;
  return (
    <>
      <WindowHeader title="공지" />

      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              title: state.title,
              selectedDisease: state.selectedDisease,
              styleVersion: state.styleVersion,
              content: state.title,
              wroteAt: state.wroteAt,
              tagIds: state.tagIds,
              isApp: state.isApp,
              isInfo: state.isInfo,
            }}
          >
            <Form.Item label="질환 선택" name="hasDisease">
              <Checkbox
                onChange={(e) => {
                  setState({ hasDisease: e.target.checked });
                }}
                checked={state?.hasDisease}
              >
                직접 선택하기 (질환 선택을 체크하지 않으면 질환이 전체
                선택됩니다.)
              </Checkbox>
            </Form.Item>
            {state?.hasDisease && (
              <Form.Item label="질환 태그" name="selectedDisease" hasFeedback>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="질환 태그를 선택해주세요"
                  onChange={(e) => {
                    setState({ selectedDisease: e });
                  }}
                >
                  {diseaseTypeInfo?.map((diseaseType) => (
                    <Option
                      key={diseaseType?.krName}
                      id={diseaseType?.id}
                      value={diseaseType?.krName}
                    >
                      {diseaseType.krName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              label="제목"
              name="title"
              rules={[
                {
                  required: true,
                  message: '제목을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="등록일"
              name="wroteAt"
              rules={[
                {
                  required: true,
                  message: '등록일을 확인해주세요.',
                },
              ]}
            >
              <DatePicker onChange={onDateChange} />
            </Form.Item>
            <Form.Item label="스타일 버전" name="styleVersion">
              <Radio.Group
                onChange={(e) => {
                  setState({ styleVersion: e.target.value });
                }}
                value={state.styleVersion}
                optionType="button"
                buttonStyle="solid"
                options={styleOptions}
              />
            </Form.Item>
            {state.styleVersion === 1 && (
              <TinymceAntInput
                content={state.content}
                setContent={(e) => {
                  setState({ [e.target.name]: e.target.value });
                }}
                setImageLoading={setImageLoading}
                name="content"
              />
            )}
            {state.styleVersion === 2 && (
              <Form.Item
                label="내용"
                name="content"
                className="treatmentContent"
              >
                <LoadingOverlay
                  active={imageLoading}
                  spinner={<Spin />}
                  text={<p>이미지를 업로드 하는 중</p>}
                >
                  <AntTinymceInput
                    content={state.content}
                    setContent={(e) => {
                      setState({ content: e });
                    }}
                    setImageLoading={setImageLoading}
                    onBlur={(e) => {}}
                    contentLinkData={{ setState }}
                  />
                </LoadingOverlay>
              </Form.Item>
            )}
            <Form.Item label="주제별 태그" name="tagIds">
              <Checkbox onChange={onCheckAllTagChange} checked={checkAllTag}>
                전체
              </Checkbox>
              <Checkbox.Group
                options={plainOptions}
                value={state?.tagIds}
                onChange={onTagChange}
              />
            </Form.Item>
            <Form.Item label="홈 노출">
              <Radio.Group
                onChange={(e) => {
                  setState({ isInfo: e.target.value });
                }}
                value={state?.isInfo}
              >
                <Radio value={false}>OFF</Radio>
                <Radio value={true}>ON</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {noticeId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default NoticeWindow;
