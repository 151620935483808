import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Button } from 'antd';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';
import AntList from '../../components/antList';
import DeployButton from '../../components/deployButton';
import { NEW } from '../../../window/util/utils';
import { useMutate } from '../../../hooks/useRequest';
import { patchDistributeNotice } from '../../../services/noticeService';
import { noticeCreators } from '../../../store/reducers/notice.reducer';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import useWindow from '../../../hooks/useWindow';
import { tagCreators } from '../../../store/reducers/tag.reducer';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';

const NoticeInfo = (data) => {
  const dispatch = useDispatch();
  const { noticesInfoPagination, topicTags, diseaseTypesInfo } = useSelector(
    (state) => {
      return {
        noticesInfoPagination: state?.paginationReducer?.noticesPagination,
        topicTags: state.tagReducer?.topicTags?.data,
        diseaseTypesInfo: state.diseaseTypeReducer.diseaseTypes?.data
          ? state.diseaseTypeReducer.diseaseTypes.data.map(
              ({ id, krName }) => ({
                id,
                krName,
              }),
            )
          : null,
      };
    },
  );

  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const confirmRef = useRef(() => {});

  const getData = useCallback(() => {
    dispatch(noticeCreators.fetchNotices.request());
    dispatch(tagCreators.fetchTopicTags.request());
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);

  const { Search } = Input;
  const [deployChangeInfo, setDeployChangeInfo] = useState([]);
  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  useEffect(() => {
    if (data?.data) {
      if (noticesInfoPagination.total === null) setTotal(data?.data?.length);
      else setTotal(noticesInfoPagination.total);
      setPageUpdate(noticesInfoPagination.page);
      confirmRef.current(noticesInfoPagination.text);
    }
  }, [data]);

  const {
    done: patchDistributeDone,
    mutate: patchDistributeMutate,
    initialize: patchDistributeInitialize,
  } = useMutate(patchDistributeNotice, {
    ids: deployChangeInfo.map((deployInfo) => deployInfo.id),
    changes: deployChangeInfo.map((deployInfo) => {
      return {
        isApp:
          deployInfo.isApp === undefined
            ? data.data.find(({ id }) => id === deployInfo.id).isApp
            : deployInfo.isApp,
        isInfo:
          deployInfo.isInfo === undefined
            ? data.data.find(({ id }) => id === deployInfo.id).isInfo
            : deployInfo.isInfo,
      };
    }),
  });

  // receive messages from NoticeWindow
  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  // add event listner(to receive message) & fetch data at mount
  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  // handling the case of click link to edit notice
  const handleCreateEditWindow = (notice) => {
    if (findWindowById(notice.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({ id: notice.id, dataType: 'notice' });
  };

  // handling case of click button to create notice
  const handleCreateNewWindow = () => {
    createWindow({ id: `${NEW}${Date.now()}`, dataType: 'notice' });
  };

  const deploy = () => {
    if (window.confirm('앱에 변경된 사항을 반영하여 배포하시겠습니까?')) {
      patchDistributeInitialize();
      patchDeploy();
    }
  };

  const patchDeploy = () => {
    patchDistributeMutate();
    setDeployChangeInfo([]);
  };

  const cancelDeploy = () => {
    setDeployChangeInfo([]);
  };

  const isDeployReady = deployChangeInfo?.length > 0;

  const onClickDeploy = () => {
    if (!isDeployReady) {
      alert('배포할 변경사항이 없습니다.');
      return;
    }
    deploy();
  };

  const onClickCancelDeploy = () => {
    if (!isDeployReady) {
      alert('변경사항이 없습니다.');
      return;
    }
    cancelDeploy();
  };

  useEffect(() => {
    if (patchDistributeDone) {
      getData();
    }
  }, [patchDistributeDone]);

  const onChange = (pagination, filters, sorter, extra) => {
    dispatch(
      paginationCreators.setValue(
        (noticesInfoPagination.page = pagination?.current),
        (noticesInfoPagination.filter = filters),
        (noticesInfoPagination.total = extra?.currentDataSource?.length),
      ),
    );
    setTotal(extra?.currentDataSource?.length);
  };

  const tagWrapper = (tags) => {
    const tag = typeof tags === 'string' ? tags?.split(',') : tags;

    return tag?.map((t) => (
      <div className="tagWrapper" key={t.id}>
        {t?.name}
      </div>
    ));
  };

  const topicTag =
    topicTags?.map((t) => ({ text: t.name, value: t.name })) ?? [];

  const columns = [
    {
      title: '날짜',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '90px',
      render: (text, record) => dayjs(text).format('YY-MM-DD'),
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: noticesInfoPagination.filter?.title || null,
      onFilter: (search, { title, diseaseTypeIds, createdAt }) =>
        [title, diseaseTypeIds, createdAt].some((value) =>
          value?.includes(search),
        ),
      render: (text, record) =>
        text ? (
          <a onClick={() => handleCreateEditWindow(record)}>{text}</a>
        ) : (
          '-'
        ),
    },
    {
      title: '앱 노출',
      dataIndex: 'isApp',
      key: 'isApp',
      width: '100px',
      filters: [
        {
          text: 'On',
          value: true,
        },
        {
          text: 'Off',
          value: false,
        },
      ],
      filteredValue: noticesInfoPagination.filter?.isApp || null,
      onFilter: (value, record) => record.isApp === value,
    },
    {
      title: '홈 노출',
      dataIndex: 'isInfo',
      key: 'isInfo',
      width: '100px',
      filters: [
        {
          text: 'On',
          value: true,
        },
        {
          text: 'Off',
          value: false,
        },
      ],
      filteredValue: noticesInfoPagination.filter?.isInfo || null,
      onFilter: (value, record) => record.isInfo === value,
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypeIds',
      key: 'diseaseTypeIds',
      width: '200px',
      // NOTE(reo): 성능 이슈로 인해 필터 주석처리
      // filters: diseaseTypesInfo?.map((d) => ({
      //   text: d.krName,
      //   value: d.id,
      // })),
      // filteredValue: noticesInfoPagination.filter?.diseaseTypeIds || null,
      // onFilter: (value, record) => record.diseaseTypeIds?.includes(value),
      render: (value) =>
        value?.length === 0 ? (
          <div
            className="tagWrapper"
            style={{
              color: '#1890ff',
            }}
          >
            전체 질환
          </div>
        ) : (
          value?.map((disease) => {
            const tag = diseaseTypesInfo?.find((d) => d.id === disease)?.krName;
            return (
              <div
                className="tagWrapper"
                style={{
                  color: '#979797',
                }}
                key={disease}
              >
                {tag}
              </div>
            );
          })
        ),
    },
    {
      title: '주제별 태그',
      dataIndex: 'topicTags',
      key: 'topicTags',
      width: '200px',
      sorter: {
        compare: (a, b) => a?.topicTags?.localeCompare(b?.topicTags),
      },
      filters: topicTag,
      filteredValue: noticesInfoPagination.filter?.topicTags || null,
      onFilter: (value, record) =>
        record.topicTags.find((t) => t.name === value),
      render: (text) => (text ? tagWrapper(text) : '-'),
    },
    {
      title: '조회수',
      dataIndex: 'readCount',
      key: 'readCount',
      width: '90px',
      sorter: (a, b) => a.readCount - b.readCount,
    },
    {
      title: '공유하기',
      dataIndex: 'shareCount',
      key: 'shareCount',
      width: '90px',
      sorter: (a, b) => a.shareCount - b.shareCount,
    },
    {
      title: '북마크',
      dataIndex: 'bookmarkCount',
      key: 'bookmarkCount',
      width: '90px',
      sorter: (a, b) => a.bookmarkCount - b.bookmarkCount,
    },
    {
      title: '유익해요',
      dataIndex: 'isHelpfulCount',
      key: 'isHelpfulCount',
      width: '90px',
      sorter: (a, b) => a.isHelpfulCount - b.isHelpfulCount,
    },
  ];

  return (
    <>
      <DeployButton
        onClickDeploy={onClickDeploy}
        onClickCancelDeploy={onClickCancelDeploy}
        disabled={!isDeployReady}
      />
      <div className="divider" />
      <Button
        type="primary"
        style={{ width: 100 }}
        onClick={handleCreateNewWindow}
      >
        추가
      </Button>
      <Search
        placeholder="검색어를 입력해주세요."
        allowClear
        className="searchStyle"
        onSearch={(value) => {
          confirmRef.current(value);
          dispatch(
            paginationCreators.setValue((noticesInfoPagination.text = value)),
          );
        }}
        defaultValue={noticesInfoPagination.text}
      />
      <div className="searchResult">{data && `검색결과 ${total}개`}</div>

      <AntList
        deployChangeInfo={deployChangeInfo}
        setDeployChangeInfo={setDeployChangeInfo}
        pageUpdate={pageUpdate}
        setPageUpdate={setPageUpdate}
        columns={columns}
        dataSource={data?.data}
        onChange={onChange}
      />
    </>
  );
};

export default NoticeInfo;
