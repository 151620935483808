import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  dictionary: getAsyncState.initial(null),
};

// action types
export const dictionaryActions = {
  FETCH_ALL_DICTIONARY: '@dictionary/FETCH_ALL_DICTIONARY',
};

// actions
export const dictionaryCreators = {
  fetchAllDictionary: createAsyncActions(
    dictionaryActions,
    'FETCH_ALL_DICTIONARY',
  ),
};

const dictionaryReducer = handleActions(
  {
    ...handleAsyncActions(dictionaryActions.FETCH_ALL_DICTIONARY, 'dictionary'),
  },
  initialState,
);

export default dictionaryReducer;
