import React, { useEffect, useState, useReducer, useCallback } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';
import WindowHeader from '../../components/windowHeader';
import TextInput from '../../components/textInput';
import MutateButton from '../../components/mutateButton';
import {
  postSupportVersion,
  patchSupportVersion,
  deleteSupportVersion,
} from '../../../services/supportVersionService';
import { useMutate } from '../../../hooks/useRequest';
import ElementLoading from '../../components/elementLoading';
import { ALERT_MESSAGES } from '../../../assets/alert';

const SupportVersionWindow = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [supportVersionId, setSupportVersionId] = useState(null);

  const initialState = {
    minimum: '',
    memo: '',
  };
  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(stateReducer, initialState);
  const [isCanceled, setIsCanceled] = useState(false);

  const { mutate: putSupportVersion, done: isPosted } = useMutate(
    postSupportVersion,
    state,
  );
  const { mutate: changeSupportVersion, done: isPatched } = useMutate(
    patchSupportVersion,
    supportVersionId,
    state,
  );
  const { mutate: eraseSupportVersion, done: isDeleted } = useMutate(
    deleteSupportVersion,
    supportVersionId,
  );

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id || params.new);
    if (params.id) {
      setSupportVersionId(params.id);
      const { minimum, memo } = JSON.parse(params.subId)
      setState({
        minimum: minimum,
        memo: memo,
      });
      setIsLoading(false);
    } else {
      setSupportVersionId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (supportVersionId) {
      setIsLoading(false);
    }
  }, [supportVersionId]);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
    if (isCanceled) {
      window.close();
    }
  }, [isDeleted, isPosted, isPatched, isCanceled]);

  const changeState = useCallback((e) => {
    setState({ [e.target.name]: e.target.value });
  }, []);

  if (isLoading) return <ElementLoading type="강제 업데이트" />;
  return (
    <>
      <WindowHeader title="강제 업데이트" />
      <Form className="windowForm">
        <TextInput
          text="버젼"
          value={state.minimum}
          onChange={changeState}
          isEssential={true}
          name="minimum"
        />
        <TextInput
          text="메모"
          value={state.memo}
          onChange={changeState}
          isEssential={false}
          as="textarea"
          rows="20"
          name="memo"
        />
        <MutateButton
          elementId={supportVersionId}
          create={putSupportVersion}
          update={changeSupportVersion}
          remove={eraseSupportVersion}
          setIsCanceled={setIsCanceled}
        />
      </Form>
    </>
  );
};

export default SupportVersionWindow;
