import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

const initialState = {
  permission: getAsyncState.initial([]),
};

export const permissionActions = {
  FETCH_ALL_PERMISSION: '@permission/FETCH_ALL_PERMISSION',
};

export const permissionCreators = {
  fetchAllPermissions: createAsyncActions(
    permissionActions,
    'FETCH_ALL_PERMISSION',
  ),
};

const permissionReducer = handleActions(
  {
    ...handleAsyncActions(permissionActions.FETCH_ALL_PERMISSION, 'permission'),
  },
  initialState,
);

export default permissionReducer;
