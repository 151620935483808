import { createAction } from 'redux-actions';
import { call, put } from 'redux-saga/effects';

export const setValueReducer = (state, { payload }) => ({
  ...state,
  ...payload,
});

// action type 을 받아 request action type, success action type, failure action type 을 만듬
export const createAsyncActions = (actionTypes, action) => ({
  request: createAction(actionTypes[action]),
  success: createAction(`${actionTypes[action]}_SUCCESS`),
  failure: createAction(`${actionTypes[action]}_FAILURE`),
});

// async action type과 async function(fetch, get 등 axios 요청)을 받아 async action create function 을 반환
export const createAsyncSaga = (
  asyncAction,
  func,
  { onSuccess, onFail, formatData } = {},
) =>
  function* handlePayload({ payload }) {
    try {
      const data = yield payload ? call(func, payload) : call(func); // payload 존재여부에 따라 call하는 함수 형태가 다르고 이 반환 결과를 data에 저장
      yield put(asyncAction.success(formatData ? formatData(data) : data)); // success action 실행을 통해 data를 상태(store)에 저장하려고 함
      if (onSuccess) yield call(onSuccess, data);
    } catch (err) {
      if (onFail) yield call(onFail, err);
      yield put(asyncAction.failure(err));
    }
  };

export const getAsyncState = {
  initial: (initialData) => ({
    loading: false,
    error: null,
    data: initialData,
  }),
  request: () => ({
    loading: true, // 요청의 경우 store의 loading에 true를 담음
  }),
  success: (data) => ({
    success: true,
    loading: false,
    data,
    error: null,
  }),
  failure: (error) => ({
    loading: false,
    error, // 실패 시 store의 error에 해당 error을 담음
  }),
};

export const handleAsyncActions = (action, key, reducer) => ({
  [action]: (state) => ({
    ...state,
    [key]: {
      ...state[key],
      ...getAsyncState.request(),
    },
  }),
  [`${action}_SUCCESS`]: (state, { payload }) => ({
    ...state,
    [key]: {
      ...state[key],
      ...getAsyncState.success(
        reducer ? reducer(state[key], payload) : payload,
      ),
    },
  }),
  [`${action}_FAILURE`]: (state, { payload }) => {
    // alert(payload.data.message.SequelizeError); TODO(covy): 정형화된 형식 만들기
    return {
      ...state,
      [key]: {
        ...state[key],
        ...getAsyncState.failure(payload),
      },
    };
  },
});
