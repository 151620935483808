import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 재활 데이터를 가져옵니다.
 * @param {String} diseaseTypeId
 */
export async function fetchAllRehabilitation(diseaseTypeId) {
  const res = await axios.get(`/v4/admin/${diseaseTypeId}/rehabilitations`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 재활을 생성합니다.
 * @param {object} body
 */
export async function postRehabilitation(body) {
  const res = await axios.post('/v4/admin/rehabilitations', body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 재활을 수정합니다.
 * @param {String} rehabilitationId
 * @param {object} body
 */
export async function patchRehabilitation(rehabilitationId, body) {
  const res = await axios.patch(
    `/v4/admin/rehabilitations/${rehabilitationId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 재활을 삭제합니다.
 * @param {String} rehabilitationId
 */
export async function deleteRehabilitation(rehabilitationId) {
  const res = await axios.delete(
    `/v4/admin/rehabilitations/${rehabilitationId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 모든 재활 디테일 데이터를 가져옵니다.
 * @param {String} diseaseTypeId
 * @param {String} rehabilitationId
 */
export async function fetchAllRehabilitationDetail({
  diseaseTypeId,
  rehabilitationId,
}) {
  const res = await axios.get(
    `/v4/admin/${diseaseTypeId}/rehabilitations/${rehabilitationId}/rehabilitationDetails`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 재활 디테일을 생성합니다.
 * @param {String} rehabilitationId
 * @param {object} body
 */
export async function postRehabilitationDetail(rehabilitationId, body) {
  const res = await axios.post(
    `/v4/admin/rehabilitations/${rehabilitationId}/rehabilitationDetails`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 재활 디테일을 수정합니다.
 * @param {String} rehabilitationId
 * @param {String} rehabilitationDetailId
 * @param {object} body
 */
export async function patchRehabilitationDetail(
  rehabilitationId,
  rehabilitationDetailId,
  body,
) {
  const res = await axios.patch(
    `/v4/admin/rehabilitations/${rehabilitationId}/rehabilitationDetails/${rehabilitationDetailId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 재활 디테일을 삭제합니다.
 * @param {String} rehabilitationId
 * @param {String} rehabilitationDetailId
 */
export async function deleteRehabilitationDetail(
  rehabilitationId,
  rehabilitationDetailId,
) {
  const res = await axios.delete(
    `/v4/admin/rehabilitations/${rehabilitationId}/rehabilitationDetails/${rehabilitationDetailId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
