import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout, Table, Input, Button, notification } from 'antd';
import 'antd/dist/antd.css';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { geneResearchCreators } from '../../../store/reducers/geneResearch.reducer';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import { fetchUnmaskedInfo as fetchUnmaskedInfoService } from '../../../services/userService';
import { useFetch } from '../../../hooks/useRequest';
import * as listMap from '../../../util/listMap';
import GeneExcelDown from './geneExcelDown';

const GeneResearch = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { geneResearch, geneResearchPagination } = useSelector((state) => {
    return {
      geneResearch: state?.geneResearchReducer?.geneResearch.data,
      geneResearchPagination: state?.paginationReducer?.geneResearchPagination,
    };
  }, shallowEqual);

  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const confirmRef = useRef(() => {});

  const getData = useCallback(() => {
    dispatch(geneResearchCreators.fetchAllGeneResearchs.request());
  }, [dispatch]);
  useEffect(getData, [getData]);

  useEffect(() => {
    if (geneResearch) {
      if (geneResearchPagination.total === null) setTotal(geneResearch?.length);
      else setTotal(geneResearchPagination.total);
      setPageUpdate(geneResearchPagination.page);
      confirmRef.current(geneResearchPagination.text);
    }
  }, [geneResearch]);

  const { Search } = Input;
  const { Content } = Layout;

  const [unmaskedType, setUnmaskedType] = useState('');
  const [unmaskedId, setUnmaskedId] = useState('');
  const [getMasked, setGetMasked] = useState('');

  const {
    done: fetchUnmaskedInfoDone,
    data: fetchUnmaskedInfoData,
    call: fetchUnmaskedInfo,
    initialize: initializeDone,
  } = useFetch(null, fetchUnmaskedInfoService, {
    userId: unmaskedId,
    type: unmaskedType,
  });

  const onChangeMasked = (record, type) => {
    if (record === '-') return;
    setUnmaskedType(type);
    setUnmaskedId(record.userId);
    setGetMasked(true);
  };

  useEffect(() => {
    if (getMasked) {
      fetchUnmaskedInfo();
      setGetMasked(false);
    }
  }, [getMasked]);

  useEffect(() => {
    if (fetchUnmaskedInfoDone) {
      initializeDone();
      notification.success({
        message: '마스킹을 해제하였습니다.',
        description: '마스킹을 해제하였습니다.',
      });
    }
  }, [fetchUnmaskedInfoDone]);

  const MaskedData = useCallback(
    ({ record, type }) => {
      return (
        <>
          <a onClick={() => onChangeMasked(record, type)}>
            {record?.userId === unmaskedId && type === unmaskedType
              ? fetchUnmaskedInfoData?.[type] ?? '-'
              : record?.[type] ?? '-'}
          </a>
        </>
      );
    },
    [fetchUnmaskedInfoDone],
  );

  const handleMoveDetailPage = (record) => {
    history.push('/geneResearchDetail', {
      userId: record?.userId,
      type: 'geneResearchDetail',
    });
  };

  const funnel = useMemo(() => {
    const filters = [];
    geneResearch
      ?.reduce((set, record) => set.add(record.funnel), new Set())
      ?.forEach((fun) => filters.push({ text: fun, value: fun }));
    return filters;
  }, [geneResearch]);

  const dnaTest = useMemo(() => {
    const filters = [];
    geneResearch
      ?.reduce((set, record) => set.add(record.dnaTest.join(', ')), new Set())
      ?.forEach((dna) => filters.push({ text: dna, value: dna }));
    return filters;
  }, [geneResearch]);

  const columns = [
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <>{listMap.statusMap[status]}</>,
      filters: Object.entries(listMap.statusMap).map(([value, text]) => ({
        text,
        value,
      })),
      filteredValue: geneResearchPagination.filter?.status || null,
      onFilter: (value, { status }) =>
        (value === 'null' ? null : value) === status,
    },
    {
      title: '신청일',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a, b) => a.updatedAt.localeCompare(b.updatedAt),
      },
    },
    {
      title: '계정',
      dataIndex: 'email',
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: geneResearchPagination.filter?.email || null,
      onFilter: (
        search,
        { email, updatedAt, status, name, phone, funnel, dnaTest },
      ) =>
        [email, updatedAt, status, name, phone, funnel, dnaTest].some((value) =>
          value?.includes(search),
        ),
      render: (text, record) => (
        <>
          <MaskedData record={text ? record : '-'} type="email" />
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => handleMoveDetailPage(record)}
          >
            이동
          </Button>
        </>
      ),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
      render: (text, record) =>
        text ? <MaskedData record={record} type="name" /> : '-',
    },
    {
      title: '14세 미만',
      dataIndex: 'isUnder14',
      key: 'isUnder14',
      render: (isUnder14) => <>{isUnder14 ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      filteredValue: geneResearchPagination.filter?.isUnder14 || null,
      onFilter: (value, record) => record.isUnder14 === value,
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        compare: (a, b) => a?.phone?.localeCompare(b?.phone),
      },
      render: (text, record) =>
        text ? <MaskedData record={record} type="phone" /> : '-',
    },
    {
      title: '유입경로',
      dataIndex: 'funnel',
      key: 'funnel',
      filters: funnel,
      filteredValue: geneResearchPagination.filter?.funnel || null,
      onFilter: (value, { funnel }) => value === funnel,
    },
    {
      title: '유전자 검사',
      dataIndex: 'dnaTest',
      key: 'dnaTest',
      render: (value) => value?.join(', '),
      filters: dnaTest,
      filteredValue: geneResearchPagination.filter?.dnaTest || null,
      onFilter: (value, { dnaTest }) => value === dnaTest?.join(', '),
    },
    {
      title: '동의',
      dataIndex: 'agreements',
      key: 'agreements',
      render: (agreements) => <>{agreements ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      filteredValue: geneResearchPagination.filter?.agreements || null,
      onFilter: (value, record) => record.agreements === value,
    },
    {
      title: '건강 설문',
      dataIndex: 'healthProfiles',
      key: 'healthProfiles',
      render: (healthProfiles) => <>{healthProfiles ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      filteredValue: geneResearchPagination.filter?.healthProfiles || null,
      onFilter: (value, record) => record.healthProfiles === value,
    },
    {
      title: '테스트ID',
      dataIndex: 'isTest',
      key: 'isTest',
      render: (isTest) => <>{isTest ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      filteredValue: geneResearchPagination.filter?.isTest || null,
      onFilter: (value, record) => record.isTest === value,
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabData" link="geneResearch" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="데이터"
            subTitle="카이안과 연구 신청"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <GeneExcelDown />
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (geneResearchPagination.text = value),
                  ),
                );
              }}
              defaultValue={geneResearchPagination.text}
            />
            <div className="searchResult">
              {geneResearch && `검색결과 ${total}개`}
            </div>
            <Table
              rowKey={(record) => record.id}
              columns={columns}
              loading={!geneResearch}
              dataSource={geneResearch}
              pagination={{
                onChange: (page) => {
                  setPageUpdate(page);
                },
                current: pageUpdate,
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(pagination, filters, sorter, extra) => {
                dispatch(
                  paginationCreators.setValue(
                    (geneResearchPagination.page = pagination?.current),
                    (geneResearchPagination.filter = filters),
                    (geneResearchPagination.total =
                      extra?.currentDataSource?.length),
                  ),
                );
                setTotal(extra?.currentDataSource?.length);
              }}
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default GeneResearch;
