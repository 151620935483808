import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

const initialState = {
  entryModals: getAsyncState.initial(null),
};

export const entryModalActions = {
  FETCH_ENTRY_MODALS: '@entryModal/FETCH_ENTRY_MODALS',
};

export const entryModalCreators = {
  fetchEntryModals: createAsyncActions(entryModalActions, 'FETCH_ENTRY_MODALS'),
};

const entryModalReducer = handleActions(
  {
    ...handleAsyncActions(entryModalActions.FETCH_ENTRY_MODALS, 'entryModals'),
  },
  initialState,
);

export default entryModalReducer;
