import { all, takeLatest } from 'redux-saga/effects';
import {
  treatmentStudyTimelineActions,
  treatmentStudyTimelineCreators,
} from '../reducers/treatmentStudyTimeline.reducer';
import { createAsyncSaga } from '../reducer.util';
import { fetchAllTreatmentStudyTimeline } from '../../services/treatmentStudyTimelineService';

const handleFetchAllTreatmentStudyTimeline = createAsyncSaga(
  treatmentStudyTimelineCreators.fetchAllTreatmentStudyTimeline,
  fetchAllTreatmentStudyTimeline,
);

export function* treatmentStudyTimelineSaga() {
  yield all([
    takeLatest(
      treatmentStudyTimelineActions.FETCH_ALL_TREATMENT_STUDY_TIMELINE,
      handleFetchAllTreatmentStudyTimeline,
    ),
  ]);
}
