import { useState, useCallback } from 'react';
import {
  INVALID_PARAMETER,
  // UNAUTHORIZED,
  CONFLICT,
  NO_MATCHING_DATA_FOUND,
  TRANSACTION_FAILED,
  INTERNAL_SERVER_ERROR,
  EXTERNAL_SERVER_ERROR,
  FORBIDDEN,
} from '../services/utils';

const axiosErrorMap = {
  400: INVALID_PARAMETER,
  404: NO_MATCHING_DATA_FOUND,
  500: INTERNAL_SERVER_ERROR,
};

export function handleError(err) {
  switch (err.data?.status ?? axiosErrorMap[err.response?.status]) {
    case INVALID_PARAMETER:
      return alert(
        `유효하지 않은 값이 있습니다.\nMessage: ${
          err.data?.message?.RequestError ??
          err.data?.message?.SequelizeError ??
          err.data?.data
        }`,
      );
    case CONFLICT:
      return alert(
        `중복된 데이터가 있습니다.\nMessage: ${err.data?.message?.SequelizeError}`,
      );
    case NO_MATCHING_DATA_FOUND:
      return alert(
        `요청한 데이터를 찾을 수 없습니다.\nMessage: ${err.data?.message?.SequelizeError}`,
      );
    case TRANSACTION_FAILED: // fallthrough
    case INTERNAL_SERVER_ERROR:
      return alert('알 수 없는 이유로 요청이 실패했습니다.');
    case EXTERNAL_SERVER_ERROR:
      return alert('외부 서비스 장애로 요청이 실패했습니다.');
    case FORBIDDEN:
      return alert('요청을 실행할 권한이 없습니다.');
    default:
  }
}

// fetch specific data (fetch one)
export function useFetch(initialState, func, ...params) {
  const [data, setData] = useState(initialState);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [done, setDone] = useState(false);
  const call = useCallback(async () => {
    try {
      const received = await func(...params);
      setData(received);
    } catch (err) {
      setError(err);
      handleError(err);
    } finally {
      setLoading(false);
      setDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [func, ...params]);
  const initialize = () => {
    setDone(false);
  };
  const initializeData = () => {
    setData(null);
  };
  return { done, loading, data, error, call, initialize, initializeData };
}

// post, patch, delete specific data (post, patch, delete one)
export function useMutate(func, ...params) {
  const [error, setError] = useState(null);
  const [done, setDone] = useState(false);
  const mutate = async () => {
    try {
      await func(...params);
      setDone(true);
    } catch (err) {
      setError(err);
      handleError(err);
    }
  };
  const initialize = () => {
    setDone(false);
  };
  return { done, error, mutate, initialize };
}

export function useLazyRequest(func, initialData, initialLoading = true) {
  const [data, setData] = useState(initialData);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(initialLoading);
  const call = useCallback(
    async (...params) => {
      try {
        setLoading(true);
        setData(await func(...params));
      } catch (err) {
        setError(err);
        handleError(err);
      } finally {
        setLoading(false);
      }
    },
    [func, setData, setLoading, setError],
  );
  return { data, loading, error, call };
}
