import { all, takeLatest } from 'redux-saga/effects';
import {
  translateWordCreators,
  translateWordActions,
} from '../reducers/translateWord.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchAllTranslateWords } from '../../services/translateWordService';

const handleFetchAllTranslateWords = createAsyncSaga(
  translateWordCreators.fetchAllTranslateWords,
  fetchAllTranslateWords,
);

export function* translateWordSaga() {
  yield all([
    takeLatest(
      translateWordActions.FETCH_ALL_TRANSLATE_WORDS,
      handleFetchAllTranslateWords,
    ),
  ]);
}
