import React, { useState } from 'react';
import { Tabs, Row, Col, Divider } from 'antd';
import 'antd/dist/antd.css';
import { WalletTwoTone } from '@ant-design/icons';
import DnaCounselingHistory from '../dnaCounselingRequest/dnaCounselingHistory';
import DnaRegisterHistory from '../dnaRegisterRequest/dnaRegisterHistory';
import ClinicalTrialCounselingHistory from '../clinicalTrialCounseling/clinicalTrialCounselingHistory';
import GeneResearchHistory from '../geneResearch/geneResearchHistory';
import UserWriter from './userWriter';
import UserComment from './userComment';

const UserCounseling = (userId) => {
  const [key, setKey] = useState(userId?.type);
  const { TabPane } = Tabs;

  return (
    <Row span={24} className="user_data">
      <Col span={24}>
        <h5>
          <WalletTwoTone className="userTitleIcon" />
          유저가 업로드한 데이터
        </h5>
        <Divider plain />
        <Tabs
          defaultActiveKey={key}
          onChange={setKey}
          className="userDetailTab"
        >
          <TabPane tab="등록 이력" key="mutationDnaReportRegisterRequest">
            <DnaRegisterHistory userId={userId?.userId} />
          </TabPane>
          <TabPane tab="유전자상담 이력" key="dnaCounselingRequest">
            <DnaCounselingHistory userId={userId?.userId} />
          </TabPane>
          <TabPane tab="임상상담 이력" key="clinicalTrialCounseling">
            <ClinicalTrialCounselingHistory userId={userId?.userId} />
          </TabPane>
          <TabPane tab="연구신청 이력" key="geneResearch">
            <GeneResearchHistory userId={userId?.userId} />
          </TabPane>
          <TabPane tab="게시글" key="userWriter">
            <UserWriter userId={userId?.userId} />
          </TabPane>
          <TabPane tab="댓글" key="userComment">
            <UserComment userId={userId?.userId} />
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
};

export default UserCounseling;
