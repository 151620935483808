import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Row,
  Col,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Spinner,
} from 'react-bootstrap';
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { roleCreators } from '../../../store/reducers/role.reducer';
import { permissionCreators } from '../../../store/reducers/permission.reducer';
import { postRole, patchRole, deleteRole } from '../../../services/roleService';
import {
  postPermission,
  patchPermission,
  deletePermission,
} from '../../../services/permissionService';
import { useMutate } from '../../../hooks/useRequest';
import { postRolePermission } from '../../../services/rolePermissionService';
import CrudTableView from '../../components/crudTableView';

const AdminSetting = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { roles, permissionInfo } = useSelector((state) => {
    return {
      roles: state?.roleReducer?.role?.data,
      permissionInfo: state?.permissionReducer?.permission?.data,
    };
  }, shallowEqual);

  const { Content } = Layout;
  const [selectedRoleId, setSelectedRoleId] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [initialPermissions, setInitialPermissions] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [mutateRoleId, setMutateRoleId] = useState(null);
  const [mutateName, setMutateName] = useState(null);
  const [mutatePermissionId, setMutatePermissionId] = useState(null);
  const [mutateNameDescription, setMutateNameDescription] = useState(null);
  const [newRole, setNewRole] = useState('');
  const [newPermission, setNewPermission] = useState({
    name: '',
    description: '',
  });
  const [loading, setLoading] = useState(true);
  const postRoleReady = useRef(false);
  const patchRoleReady = useRef(false);
  const deleteRoleReady = useRef(false);
  const putRoleReady = Boolean(mutateName);
  const changeRoleReady = Boolean(mutateRoleId && mutateName);
  const eraseRoleReady = Boolean(mutateRoleId);
  const postPermissionReady = useRef(false);
  const patchPermissionReady = useRef(false);
  const deletePermissionReady = useRef(false);
  const putPermissionReady = Boolean(mutateNameDescription);
  const changePermissionReady = Boolean(
    mutatePermissionId && mutateNameDescription,
  );
  const erasePermissionReady = Boolean(mutatePermissionId);
  const isPermissionChanged = initialPermissions.some(
    (initialPermission) =>
      selectedPermissions.find(({ id }) => id === initialPermission.id)
        ?.selected !== initialPermission.selected,
  );

  const selectedPermissionIds = selectedPermissions
    .filter((permission) => permission.selected)
    .map((permission) => permission.id);

  const adminLoginPermissionId = initialPermissions.find(
    (permission) => permission?.name === 'admin_login',
  )?.id;

  if (
    adminLoginPermissionId &&
    !selectedPermissionIds.includes(adminLoginPermissionId)
  ) {
    selectedPermissionIds.push(adminLoginPermissionId);
  }

  const [refetchReady, setRefetchReady] = useState(false);

  const getData = useCallback(() => {
    dispatch(roleCreators.fetchAllRoles.request());
    dispatch(permissionCreators.fetchAllPermissions.request());
    setLoading(false);
  }, [dispatch]);

  const { mutate: changeRolePermission } = useMutate(
    postRolePermission,
    selectedRoleId,
    selectedPermissionIds,
  );
  const {
    mutate: putRole,
    done: isRolePosted,
    initialize: rolePostInitialize,
  } = useMutate(postRole, {
    name: mutateName,
  });
  const {
    mutate: changeRole,
    done: isRolePatched,
    initialize: rolePatchInitialize,
  } = useMutate(patchRole, mutateRoleId, {
    name: mutateName,
  });
  const {
    mutate: eraseRole,
    done: isRoleDeleted,
    initialize: roleDeleteInitialize,
  } = useMutate(deleteRole, mutateRoleId);

  const {
    mutate: putPermission,
    done: isPermissionPosted,
    initialize: permissionPostInitialize,
  } = useMutate(postPermission, {
    name: mutateNameDescription?.name,
    description: mutateNameDescription?.description,
  });
  const {
    mutate: changePermission,
    done: isPermissionPatched,
    initialize: permissionPatchInitialize,
  } = useMutate(patchPermission, mutatePermissionId, {
    name: mutateNameDescription?.name,
    description: mutateNameDescription?.description,
  });
  const {
    mutate: erasePermission,
    done: isPermissionDeleted,
    initialize: permissionDeleteInitialize,
  } = useMutate(deletePermission, mutatePermissionId);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (roles) {
      setSelectedRoles(roles);
    }
  }, [roles]);

  useEffect(() => {
    if (permissionInfo) {
      setSelectedPermissions(
        permissionInfo.map((permission) => ({
          ...permission,
          selected: false,
        })),
      );
    }
  }, [permissionInfo]);

  useEffect(() => {
    if (selectedRoleId) {
      const newPermissions = permissionInfo.map((permissionItem) => ({
        ...permissionItem,
        selected:
          permissionItem.name === 'admin_login' ||
          Boolean(
            roles
              .find((role) => role.id === selectedRoleId)
              .permissions?.find(
                (permission) => permission.id === permissionItem.id,
              ),
          ),
      }));
      setInitialPermissions(newPermissions);
      setSelectedPermissions(newPermissions);
    }
  }, [selectedRoleId, permissionInfo, roles]);

  const showPermissions = ({ id }) => {
    if (id !== selectedRoleId) {
      setSelectedRoleId(id);
    }
  };

  const setRolePermission = async () => {
    if (isPermissionChanged) {
      await changeRolePermission();
      setSelectedRoleId(null);
      alert('권한이 변경되었습니다!');
      getData();
      setSelectedPermissions(selectedPermissions);
      return;
    }
    alert('변경된 권한이 없습니다!');
  };

  const putPermissionInfo = (permission) => {
    const isSamePermissionExist = permissionInfo.some(
      ({ name }) => permission?.name === name.trim(),
    );
    if (isSamePermissionExist) {
      alert('같은 이름을 생성할 수 없습니다!');
      return;
    }
    setMutateNameDescription({
      name: permission?.name,
      description: permission?.description,
    });
    postPermissionReady.current = true;
  };

  const changePermissionInfo = (permission) => {
    const isSamePermissionExist = permissionInfo.some(
      ({ description }) => permission?.description === description.trim(),
    );
    if (isSamePermissionExist) {
      alert('같은 이름으로 변경할 수 없습니다!');
      return;
    }
    setMutatePermissionId(permission?.id);
    setMutateNameDescription({
      name: permission?.name,
      description: permission?.description,
    });
    patchPermissionReady.current = true;
  };

  const erasePermissionInfo = (permission) => {
    const needToDelete = window.confirm('정말로 삭제하시겠습니까?');
    if (needToDelete) {
      setMutatePermissionId(permission?.id);
      deletePermissionReady.current = true;
    }
  };

  const putRoleName = (name) => {
    const isSameRoleExist = roles.some((role) => role.name === name.trim());
    if (isSameRoleExist) {
      alert('같은 이름을 생성할 수 없습니다!');
      return;
    }
    setMutateName(name);
    postRoleReady.current = true;
  };

  const changeRoleName = (role) => {
    const isSameRoleExist = roles.some(({ name }) => role.name.trim() === name);
    if (isSameRoleExist) {
      alert('존재하는 이름으로 변경할 수 없습니다!');
      getData();
      return;
    }
    setMutateRoleId(role?.id);
    setMutateName(role?.name);
    patchRoleReady.current = true;
  };

  const eraseRoleName = (role) => {
    const needToDelete = window.confirm('정말로 삭제하시겠습니까?');
    if (needToDelete) {
      setMutateRoleId(role?.id);
      deleteRoleReady.current = true;
    }
  };

  const initializeForm = () => {
    setMutateName(null);
    setMutateRoleId(null);
    setMutatePermissionId(null);
    setNewRole('');
    setNewPermission({ name: '', description: '' });
    setRefetchReady(true);
  };

  useEffect(() => {
    if (changeRoleReady && patchRoleReady.current) {
      changeRole();
      alert('이름이 확정되었습니다!');
      setSelectedRoleId(null);
      patchRoleReady.current = false;
      initializeForm();
    }
    if (changePermissionReady && patchPermissionReady.current) {
      changePermission();
      alert('이름이 확정되었습니다!');
      patchPermissionReady.current = false;
      initializeForm();
    }
    if (putRoleReady && postRoleReady.current) {
      putRole();
      alert('역할이 추가되었습니다!');
      setSelectedRoleId(null);
      postRoleReady.current = false;
      initializeForm();
    }
    if (putPermissionReady && postPermissionReady.current) {
      putPermission();
      alert('권한이 추가되었습니다!');
      postPermissionReady.current = false;
      initializeForm();
    }
    if (eraseRoleReady && deleteRoleReady.current) {
      eraseRole();
      alert('역할이 삭제되었습니다!');
      setSelectedRoleId(null);
      deleteRoleReady.current = false;
      initializeForm();
    }
    if (erasePermissionReady && deletePermissionReady.current) {
      erasePermission();
      alert('권한이 삭제되었습니다!');
      deletePermissionReady.current = false;
      initializeForm();
    }
  }, [
    changeRoleReady,
    changePermissionReady,
    putPermissionReady,
    putRoleReady,
    eraseRoleReady,
    erasePermissionReady,
    putRole,
    putPermission,
    changeRole,
    changePermission,
    eraseRole,
    erasePermission,
    getData,
  ]);

  const mutateDone =
    isRolePosted ||
    isRolePatched ||
    isRoleDeleted ||
    isPermissionPosted ||
    isPermissionPatched ||
    isPermissionDeleted;

  const initializeMutate = useCallback(() => {
    rolePostInitialize();
    rolePatchInitialize();
    roleDeleteInitialize();
    permissionPostInitialize();
    permissionPatchInitialize();
    permissionDeleteInitialize();
  }, [
    rolePostInitialize,
    rolePatchInitialize,
    roleDeleteInitialize,
    permissionPostInitialize,
    permissionPatchInitialize,
    permissionDeleteInitialize,
  ]);

  useEffect(() => {
    if (refetchReady && mutateDone) {
      getData();
      initializeMutate();
      setRefetchReady(false);
    }
  }, [mutateDone, refetchReady, initializeMutate, getData]);

  const moveToPage = () => {
    history.push('/admin');
  };

  if (loading) {
    return (
      <div className="loading">
        <Spinner style={{ margin: 'auto' }} animation="border" />
      </div>
    );
  }
  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabAccount" link="admin" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="계정"
            subTitle="어드민 역할/권한 설정"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button onClick={() => moveToPage()}>목록 돌아가기</Button>
            <div>
              <ButtonToolbar className="justify-content-end distribute">
                <ButtonGroup>
                  <Button
                    className="distributeButton"
                    variant={isPermissionChanged ? 'primary' : 'dark'}
                    onClick={setRolePermission}
                  >
                    권한 확정
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
              <div className="divider" />
              <Row>
                <Col>
                  <CrudTableView
                    headerText="어드민 역할"
                    tableViewCondition={true}
                    listData={selectedRoles}
                    setListData={setSelectedRoles}
                    listDataView={[
                      {
                        headerText: '역할 이름',
                        width: '55%',
                        type: 'textInput',
                        attribute: 'name',
                      },
                      {
                        headerText: '역할 이름 변경',
                        width: '15%',
                        type: 'patchButton',
                        patchElement: (item) => changeRoleName(item),
                        buttonText: '저장',
                      },
                      {
                        headerText: '역할 삭제',
                        width: '15%',
                        type: 'deleteButton',
                        deleteElement: (item) => eraseRoleName(item),
                        buttonText: '삭제',
                      },
                      {
                        headerText: '권한 확인',
                        width: '15%',
                        type: 'functionButton',
                        variant: (item) => {
                          return item.id === selectedRoleId
                            ? 'primary'
                            : 'outline-primary';
                        },
                        function: (item) =>
                          showPermissions({
                            id: item.id,
                          }),
                        buttonText: (item) =>
                          item.id === selectedRoleId ? '선택됨' : '확인',
                      },
                    ]}
                    postElementCondition={Boolean(selectedRoles)}
                    postElementFeatures={[
                      {
                        placeholder: '새 역할 이름을 입력하세요.',
                        value: newRole,
                        setValue: setNewRole,
                      },
                    ]}
                    postElement={() => putRoleName(newRole)}
                  />
                </Col>
                <Col>
                  <CrudTableView
                    headerText="어드민 권한"
                    tableViewCondition={Boolean(selectedRoleId)}
                    listData={selectedPermissions.filter(
                      ({ name }) => name !== 'admin_login',
                    )}
                    setListData={setSelectedPermissions}
                    listDataView={[
                      {
                        headerText: '권한 이름',
                        width: '26%',
                        type: 'textInput',
                        attribute: 'description',
                      },
                      {
                        headerText: '권한 영어 이름',
                        width: '29%',
                        type: 'textInput',
                        attribute: 'name',
                        readOnly: true,
                      },
                      {
                        headerText: '권한 이름 변경',
                        width: '15%',
                        type: 'patchButton',
                        patchElement: (item) => changePermissionInfo(item),
                        buttonText: '저장',
                      },
                      {
                        headerText: '권한 삭제',
                        width: '15%',
                        type: 'deleteButton',
                        deleteElement: (item) => erasePermissionInfo(item),
                        buttonText: '삭제',
                      },
                      {
                        headerText: '권한 여부',
                        width: '15%',
                        type: 'functionButton',
                        variant: (item) => {
                          return item.selected ? 'primary' : 'outline-primary';
                        },
                        function: (item) =>
                          setSelectedPermissions((prev) =>
                            prev.map((selectedPermission) =>
                              item.id === selectedPermission.id
                                ? {
                                    ...selectedPermission,
                                    selected: !item.selected,
                                  }
                                : selectedPermission,
                            ),
                          ),
                        buttonText: (item) => (item.selected ? 'O' : 'X'),
                      },
                    ]}
                    postElementCondition={Boolean(
                      selectedPermissions && selectedRoleId,
                    )}
                    postElementFeatures={[
                      {
                        placeholder: '새 권한 이름을 입력하세요.',
                        value: newPermission.description,
                        setValue: (newDescription) =>
                          setNewPermission((prev) => ({
                            ...prev,
                            description: newDescription,
                          })),
                      },
                      {
                        placeholder: '새 권한 영어 이름을 입력하세요.',
                        value: newPermission.name,
                        setValue: (newName) =>
                          setNewPermission((prev) => ({
                            ...prev,
                            name: newName,
                          })),
                      },
                    ]}
                    postElement={() => putPermissionInfo(newPermission)}
                  />
                </Col>
              </Row>
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AdminSetting;
