import { handleActions, createAction } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

const listAttibutes = [
  'id',
  'geneName',
  'nucleotide',
  'aminoAcid',
  'inheritance',
  'zygosity',
  'variantTypeAnnoVar',
  'variantTypeVep',
];

const extractAttibutes = (mutationDna) =>
  listAttibutes.reduce((obj, attr) => {
    obj[attr] = mutationDna[attr];
    return obj;
  }, {});

// initial states
const initialState = {
  mutationDna: getAsyncState.initial(null),
};

// action types
export const mutationDnaActions = {
  FETCH_ALL_MUTATION_DNA: '@mutationDna/FETCH_ALL_MUTATION_DNA',
  CREATE_MUTATION_DNA: '@mutationDna/CREATE_MUTATION_DNA',
  UPDATE_MUTATION_DNA: '@mutationDna/UPDATE_MUTATION_DNA',
  DELETE_MUTATION_DNA: '@mutationDna/DELETE_MUTATION_DNA',
};

// actions
export const mutationDnaCreators = {
  fetchAllMutationDna: createAsyncActions(
    mutationDnaActions,
    'FETCH_ALL_MUTATION_DNA',
  ),
  createMutationDna: createAction(
    mutationDnaActions.CREATE_MUTATION_DNA,
    extractAttibutes,
  ),
  updateMutationDna: createAction(
    mutationDnaActions.UPDATE_MUTATION_DNA,
    extractAttibutes,
  ),
  deleteMutationDna: createAction(mutationDnaActions.DELETE_MUTATION_DNA),
};

const mutationDnaReducer = handleActions(
  {
    ...handleAsyncActions(
      mutationDnaActions.FETCH_ALL_MUTATION_DNA,
      'mutationDna',
    ),
    [mutationDnaActions.CREATE_MUTATION_DNA]: (state, { payload }) => ({
      ...state,
      mutationDna: {
        ...state.mutationDna,
        data: [payload, ...state.mutationDna.data],
      },
    }),
    [mutationDnaActions.UPDATE_MUTATION_DNA]: (state, { payload }) => ({
      ...state,
      mutationDna: {
        ...state.mutationDna,
        data: state.mutationDna.data.map((mutationDna) =>
          mutationDna.id === payload.id ? payload : mutationDna,
        ),
      },
    }),
    [mutationDnaActions.DELETE_MUTATION_DNA]: (state, { payload }) => ({
      ...state,
      mutationDna: {
        ...state.mutationDna,
        data: state.mutationDna.data.filter(
          (mutationDna) => mutationDna.id !== payload.id,
        ),
      },
    }),
  },
  initialState,
);

export default mutationDnaReducer;
