import { all, takeLatest } from 'redux-saga/effects';
import {
  rehabilitationCreators,
  rehabilitationActions,
} from '../reducers/rehabilitation.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  fetchAllRehabilitation,
  fetchAllRehabilitationDetail,
} from '../../services/rehabilitationService';

const handleFetchAllRehabilitations = createAsyncSaga(
  rehabilitationCreators.fetchAllRehabilitations,
  fetchAllRehabilitation,
);

const handleFetchAllRehabilitationDetails = createAsyncSaga(
  rehabilitationCreators.fetchAllRehabilitationDetails,
  fetchAllRehabilitationDetail,
);

export function* rehabilitationSaga() {
  yield all([
    takeLatest(
      rehabilitationActions.FETCH_ALL_REHABILITATIONS,
      handleFetchAllRehabilitations,
    ),
    takeLatest(
      rehabilitationActions.FETCH_ALL_REHABILITATION_DETAILS,
      handleFetchAllRehabilitationDetails,
    ),
  ]);
}
