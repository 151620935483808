import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  mutationDnaReport: getAsyncState.initial(null),
};

// action types
export const mutationDnaReportActions = {
  FETCH_ALL_MUTATION_DNA_REPORT: '@mutationDna/FETCH_ALL_MUTATION_DNA_REPORT',
};

// actions
export const mutationDnaReportCreators = {
  fetchAllMutationDnaReport: createAsyncActions(
    mutationDnaReportActions,
    'FETCH_ALL_MUTATION_DNA_REPORT',
  ),
};

const mutationDnaReportReducer = handleActions(
  {
    ...handleAsyncActions(
      mutationDnaReportActions.FETCH_ALL_MUTATION_DNA_REPORT,
      'mutationDnaReport',
    ),
  },
  initialState,
);

export default mutationDnaReportReducer;
