import React, { useEffect, useRef, useState, useReducer } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  Form,
  InputNumber,
  Input,
  Upload,
  Spin,
  notification,
  Button,
  Alert,
  Row,
  Col,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import LoadingOverlay from 'react-loading-overlay';
import WindowHeader from '../../components/windowHeader';
import {
  postRehabilitationDetail,
  patchRehabilitationDetail,
  deleteRehabilitationDetail,
} from '../../../services/rehabilitationService';
import { useMutate } from '../../../hooks/useRequest';
import ElementLoading from '../../components/elementLoading';
import AntTinymceInput from '../../components/antTinymceInput';
import { ALERT_MESSAGES } from '../../../assets/alert';
import { useAntFileUpload } from '../../util/useAntFileImage';

const RehabilitationDetailWindow = () => {
  const dispatch = useDispatch();

  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [rehabilitationId, setRehabilitationId] = useState(null);
  const [rehabilitationDetailId, setRehabilitationDetailId] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const fileUploadSetting = useRef(false);
  const didCancel = useRef(false);
  const uploadCancel = useRef(false);

  const initialState = {
    title: '',
    contents: '',
    thumbnail: '',
    order: 0,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(stateReducer, initialState);

  const postPatchData = {
    title: state.title,
    contents: state.contents,
    thumbnail: state.thumbnail,
    order: state.order,
  };

  const { mutate: putRehabilitation, done: isPosted } = useMutate(
    postRehabilitationDetail,
    rehabilitationId,
    postPatchData,
  );
  const { mutate: changeRehabilitation, done: isPatched } = useMutate(
    patchRehabilitationDetail,
    rehabilitationId,
    rehabilitationDetailId,
    postPatchData,
  );
  const { mutate: eraseRehabilitation, done: isDeleted } = useMutate(
    deleteRehabilitationDetail,
    rehabilitationId,
    rehabilitationDetailId,
  );

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setRehabilitationDetailId(params.id);
      const paramData = JSON.parse(params.subId);
      setRehabilitationId(paramData.rehabilitationId);
      setState({
        title: paramData.title,
        contents: paramData.contents,
        thumbnail: paramData.thumbnail,
        order: paramData.order,
      });
      didCancel.current = true;
      setIsLoading(false);
    } else {
      const subParamData = JSON.parse(params.subId);
      setRehabilitationId(subParamData.stepValue);
      setRehabilitationDetailId('');
      didCancel.current = true;
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (rehabilitationDetailId) {
      setIsLoading(false);
    }
  }, [rehabilitationDetailId]);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  const {
    publicUrl: distinctPublicUrl,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useAntFileUpload(distinctFileInputEvent);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({
        message: 'JPG/PNG만 업로드 가능합니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      notification.error({
        message: '이미지가 10MB보다 큽니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    return isJpgOrPng && isLt10M;
  };

  const uploadImg = async (e) => {
    if (e !== undefined) {
      setDistinctFileInputEvent(e);
      uploadCancel.current = true;
      setLoading(true);
    }
  };

  const noop = () => {};

  const deleteThumbImg = () => {
    setState({
      thumbnail: '',
    });
    notification.success({
      message: '이미지를 삭제하였습니다.',
      description: '이미지를 다시 업로드해주세요.',
    });
  };

  const uploadProps = {
    customRequest: noop,
    onChange: uploadImg,
    onPreview: noop,
  };

  useEffect(() => {
    if (distinctFileInputEvent && uploadCancel.current) {
      distinctImageUpload();
      uploadCancel.current = false;
      fileUploadSetting.current = true;
    }
  }, [distinctFileInputEvent, distinctImageUpload]);

  useEffect(() => {
    if (distinctImageUploadDone && distinctPublicUrl) {
      notification.success({
        message: '이미지 업로드에 성공하였습니다.',
        description: '썸네일 등록에 성공하였습니다.',
      });
      setState({ thumbnail: distinctPublicUrl });
      distinctUploadInitialize();
      setLoading(false);
    }
  }, [distinctImageUploadDone, distinctPublicUrl, distinctUploadInitialize]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      title: values.title,
      order: values.order,
    });
    if (rehabilitationDetailId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      eraseRehabilitation();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeRehabilitation();
      setIsPatch(false);
    }
    if (isPost) {
      putRehabilitation();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  if (isLoading) return <ElementLoading type="재활" />;
  return (
    <>
      <WindowHeader title="재활" />

      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              title: state.title,
              order: state.order,
              thumbnail: state.thumbnail,
              contets: state.contets,
            }}
          >
            <Form.Item
              label="제목"
              name="title"
              rules={[
                {
                  required: true,
                  message: '제목을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="순서" name="order">
              <InputNumber
                min={0}
                max={10}
                onChange={(e) => {
                  setState({ order: e });
                }}
              />
            </Form.Item>
            <Form.Item label="썸네일" name="thumbnail">
              <Upload
                {...uploadProps}
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {state?.thumbnail ? (
                  <img
                    src={state?.thumbnail}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              {state?.thumbnail && (
                <Button
                  type="primary"
                  style={{ width: 104 }}
                  danger
                  onClick={deleteThumbImg}
                >
                  썸네일 삭제
                </Button>
              )}
            </Form.Item>
            <Form.Item
              label="내용"
              name="contents"
              className="treatmentContent"
            >
              <LoadingOverlay
                active={imageLoading}
                spinner={<Spin />}
                text={<p>이미지를 업로드 하는 중</p>}
              >
                <AntTinymceInput
                  content={state.contents}
                  setContent={(e) => {
                    setState({ contents: e });
                  }}
                  setImageLoading={setImageLoading}
                  onBlur={(e) => {}}
                  contentLinkData={{ setState }}
                />
              </LoadingOverlay>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {rehabilitationDetailId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default RehabilitationDetailWindow;
