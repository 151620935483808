import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';
import ListView from '../../components/listView';
import SearchButton from '../../components/searchButton';
import DeployButton from '../../components/deployButton';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { treatmentStudyTimelineCreators } from '../../../store/reducers/treatmentStudyTimeline.reducer';
import { useMutate } from '../../../hooks/useRequest';
import { patchDeployTreatmentStudyTimeline } from '../../../services/treatmentStudyTimelineService';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../../window/util/utils';

const NEW_MAINTAIN_DAY = 7;

const TreatmentStudyTimeline = () => {
  const dispatch = useDispatch();
  const { treatmentStudyTimelines } = useSelector(
    (state) => ({
      treatmentStudyTimelines:
        state?.treatmentStudyTimelineReducer?.treatmentStudyTimeline?.data,
    }),
    shallowEqual,
  );

  const loading = !treatmentStudyTimelines;
  const getData = useCallback(() => {
    dispatch(
      treatmentStudyTimelineCreators.fetchAllTreatmentStudyTimeline.request(),
    );
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const { Content } = Layout;
  const searchFilterContent = ['제목', '제품/프로젝트', '등록일'];
  const [searchFilter, setSearchFilter] = useState(searchFilterContent[0]);
  const [searchWord, setSearchWord] = useState('');
  const [deployChangeInfo, setDeployChangeInfo] = useState([]);

  const {
    done: patchDeployDone,
    mutate: patchDeployMutate,
    initialize: patchDeployInitialize,
  } = useMutate(patchDeployTreatmentStudyTimeline, {
    ids: deployChangeInfo.map((deployInfo) => deployInfo.id),
    changes: deployChangeInfo.map((deployInfo) => {
      return {
        isApp:
          deployInfo.isApp === undefined
            ? treatmentStudyTimelines.find(({ id }) => id === deployInfo.id)
                .isApp
            : deployInfo.isApp,
        newExpiresAt:
          deployInfo.isNew === undefined
            ? treatmentStudyTimelines.find(({ id }) => id === deployInfo.id)
                .newExpiresAt
            : deployInfo.isNew
            ? dayjs()
                .add(NEW_MAINTAIN_DAY + 1, 'day')
                .format('YYYY-MM-DD')
            : null,
      };
    }),
  });

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (treatmentStudyTimelineInfo) => {
    if (findWindowById(treatmentStudyTimelineInfo.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({
      id: String(treatmentStudyTimelineInfo.id),
      dataType: 'treatmentStudyTimeline',
      subId: treatmentStudyTimelineInfo.treatmentStudyId,
    });
  };

  const handleCreateNewWindow = () => {
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: 'treatmentStudyTimeline',
    });
  };

  const deploy = () => {
    if (window.confirm('앱에 변경된 사항을 반영하여 배포하시겠습니까?')) {
      patchDeployInitialize();
      patchDeploy();
    }
  };

  const patchDeploy = () => {
    patchDeployMutate();
    setDeployChangeInfo([]);
  };

  const cancelDeploy = () => {
    setDeployChangeInfo([]);
  };

  const handleChange = (e) => {
    setSearchFilter(e.target.value);
  };

  const onTableSearch = (e) => {
    setSearchWord(e.target.value.toLowerCase());
  };

  const isDeployReady = deployChangeInfo?.length > 0;

  const onClickDeploy = () => {
    if (!isDeployReady) {
      alert('배포할 변경사항이 없습니다.');
      return;
    }
    deploy();
  };

  const onClickCancelDeploy = () => {
    if (!isDeployReady) {
      alert('변경사항이 없습니다.');
      return;
    }
    cancelDeploy();
  };

  const listName = [
    { name: '앱 노출', width: '9%' },
    { name: 'NEW 노출', width: '9%' },
    { name: 'NEW 시작일', width: '9%' },
    { name: '제목', width: '24%' },
    { name: '제품/프로젝트', width: '20%' },
    { name: '대상질환', width: '20%' },
    { name: '등록일', width: '9%' },
  ];

  const listItemView = [
    { view: (item) => item.isApp, type: 'normal', deployOption: 'isApp' },
    { view: (item) => item.isNew, type: 'normal', deployOption: 'isNew' },
    { view: (item) => item.newStartedAt, type: 'normal' },
    { view: (item) => item.title, type: 'link' },
    { view: (item) => item.productName, type: 'normal' },
    { view: (item) => item.diseaseTypes, type: 'normal' },
    { view: (item) => item.registeredAt, type: 'normal' },
  ];

  useEffect(() => {
    getData();
  }, [patchDeployDone, getData]);

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="" link="treatmentStudyTimeline" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="정보"
            subTitle="치료제 타임라인"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <DeployButton
              onClickDeploy={onClickDeploy}
              onClickCancelDeploy={onClickCancelDeploy}
            />
            <div className="divider" />
            <Form className="form-inline justify-content-between">
              <Form.Group>
                <Button className="buttons" onClick={handleCreateNewWindow}>
                  치료제 타임라인 추가
                </Button>
              </Form.Group>
              <SearchButton
                searchFilterContent={searchFilterContent}
                searchFilter={searchFilter}
                handleChange={handleChange}
                onTableSearch={onTableSearch}
              />
            </Form>
            <ListView
              listData={treatmentStudyTimelines}
              loading={loading}
              searchFilter={searchFilter}
              searchWord={searchWord}
              editWindow={handleCreateEditWindow}
              listName={listName}
              listItemView={listItemView}
              cancelDeploy={cancelDeploy}
              deployChangeInfo={deployChangeInfo}
              setDeployChangeInfo={setDeployChangeInfo}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default TreatmentStudyTimeline;
