import React, { useEffect, useState, useCallback } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import ListView from '../../components/listView';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../../window/util/utils';
import { supportVersionCreators } from '../../../store/reducers/supportVersion.reducer';
import SearchButton from '../../components/searchButton';

const SupportVersion = () => {
  const dispatch = useDispatch();
  const { supportVersion } = useSelector(
    (state) => ({
      supportVersion: state.supportVersionReducer?.supportVersion?.data,
    }),
    shallowEqual,
  );

  const loading = !supportVersion;
  const getData = useCallback(() => {
    dispatch(supportVersionCreators.fetchAllSupportVersions.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const listName = [
    { name: '이메일', width: '30%' },
    { name: '버젼', width: '20%' },
    { name: '메모', width: '20%' },
    { name: '일시', width: '30%' },
  ];

  const listItemView = [
    { view: (item) => item.email, type: 'normal' },
    { view: (item) => item.minimum, type: 'link' },
    { view: (item) => item.memo, type: 'normal' },
    { view: (item) => item.createdAt, type: 'normal' },
  ];

  const { Content } = Layout;
  const searchFilterContent = ['이메일', '버젼', '메모', '일시'];
  const [searchFilter, setSearchFilter] = useState(searchFilterContent[0]);
  const [searchWord, setSearchWord] = useState('');

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (supportVersion) => {
    if (findWindowById(supportVersion.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }

    createWindow({
      id: supportVersion.id,
      dataType: 'supportVersion',
      subId: JSON.stringify(supportVersion),
    });
  };

  const handleCreateNewWindow = () => {
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: 'supportVersion',
    });
  };

  const handleChange = (e) => {
    setSearchFilter(e.target.value);
  };

  const onTableSearch = (e) => {
    setSearchWord(e.target.value.toLowerCase());
  };
  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabDevelop" link="supportVersion" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="개발"
            subTitle="강제 업데이트"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Form className="form-inline justify-content-between">
              <Form.Group>
                <Button className="buttons" onClick={handleCreateNewWindow}>
                  업데이트 추가
                </Button>
              </Form.Group>
              <SearchButton
                searchFilterContent={searchFilterContent}
                searchFilter={searchFilter}
                handleChange={handleChange}
                onTableSearch={onTableSearch}
              />
            </Form>
            <ListView
              listData={supportVersion}
              loading={loading}
              searchFilter={searchFilter}
              searchWord={searchWord}
              editWindow={handleCreateEditWindow}
              listName={listName}
              listItemView={listItemView}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default SupportVersion;
