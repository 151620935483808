import axios from 'axios';
import { convertToQuery } from '../hooks/usePagination';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 전체 임상을 가져옵니다
 * @param {object} pagination
 * @param {object} query
 */
export async function fetchAllClinicalTrials(pagination, { by, searchText }) {
  const res = await axios.get(
    `/v4/admin/clinicalTrials?by=${by}&${
      searchText ? `searchText=${searchText}&` : ''
    }${convertToQuery(pagination)}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 임상을 가져옵니다.
 * @param {number} ctkId
 */
export async function fetchClinicalTrial(ctkId) {
  const res = await axios.get(`/v4/admin/clinicalTrials/${ctkId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 질환의 관련 임상을 수정합니다.
 * @param {number} ctkId
 * @param {object} body
 */
export async function patchClinicalTrial(ctkId, body) {
  const res = await axios.patch(`/v4/admin/clinicalTrials/${ctkId}`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 질환과 임상의 관계를 삭제합니다.
 * @param {object} params
 */
export async function deleteDiseaseTypeClinicalTrial(params) {
  const res = await axios.delete(`/v4/admin/diseaseTypeClinicalTrials`, {
    params,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
