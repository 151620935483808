import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import { Table, Skeleton } from 'antd';
import 'antd/dist/antd.css';
import { useFetch } from '../../../hooks/useRequest';
import { fetchUserWriterList as fetchUserWriterListService } from '../../../services/userService';

const UserWriter = (userId) => {
  const userDataFetchDone = useRef(false);

  const {
    done: fetchUserWriterContentDone,
    data: fetchUserWriterContentData,
    call: fetchUserWriterList,
  } = useFetch(null, fetchUserWriterListService, userId?.userId);

  useEffect(() => {
    if (!fetchUserWriterContentDone && userId && !userDataFetchDone.current) {
      fetchUserWriterList();
      userDataFetchDone.current = true;
    }
  }, [
    fetchUserWriterContentDone,
    userId,
    userDataFetchDone,
    fetchUserWriterList,
  ]);

  const columns = [
    {
      title: '게시글 Id',
      dataIndex: 'id',
      key: 'id',
      width: 80,
    },
    {
      title: '시간',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 180,
      render: (text) => dayjs(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '내용',
      dataIndex: 'context',
      key: 'context',
    },
  ];

  if (!userDataFetchDone.current) return <Skeleton active />;
  return (
    <>
      {fetchUserWriterContentData?.feeds?.length > 0 ? (
        <Table
          columns={columns}
          dataSource={fetchUserWriterContentData?.feeds}
          pagination={{
            defaultPageSize: 20,
            pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
          }}
          size="small"
          bordered
        />
      ) : (
        <div style={{ marginLeft: 10, color: 'red' }}>게시글이 없습니다.</div>
      )}
    </>
  );
};

export default UserWriter;
