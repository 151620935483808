import React from 'react';
import { Spinner } from 'react-bootstrap';
import WindowHeader from './windowHeader';

const ElementLoading = ({ type }) => {
  return (
    <>
      <WindowHeader title={type} />
      <div className="windowLoading">
        <Spinner animation="border" />
        <div>정보를 불러오는 중입니다.</div>
      </div>
    </>
  );
};

export default ElementLoading;
