import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Layout, Table, Input, Button } from 'antd';
import 'antd/dist/antd.css';
import { NEW } from '../../../window/util/utils';
import useWindow from '../../../hooks/useWindow';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { ipAllowCreators } from '../../../store/reducers/ipAllow.reducer';

const IpAllow = () => {
  const dispatch = useDispatch();
  const { ipAllow } = useSelector(
    (state) => ({
      ipAllow: state.ipAllowReducer?.ipAllow?.data,
    }),
    shallowEqual,
  );

  const getData = useCallback(() => {
    dispatch(ipAllowCreators.fetchAllIpAllows.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setDataSource(ipAllow);
    setFilteredData(ipAllow?.length);
  }, [ipAllow]);

  const { Content } = Layout;
  const [dataSource, setDataSource] = useState('');
  const { Search } = Input;
  const [filteredData, setFilteredData] = useState(null);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (record) => {
    if (findWindowById(record.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }

    createWindow({
      id: record.id,
      dataType: 'ipAllow',
      subId: JSON.stringify(record),
    });
  };

  const handleCreateNewWindow = () => {
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: 'ipAllow',
    });
  };

  const onSearch = (e) => {
    if (e === '') {
      setDataSource(ipAllow);
      setFilteredData(ipAllow?.length);
    } else {
      const filteredDatas = dataSource?.filter((entry) =>
        Object?.values(entry).toString().includes(e),
      );
      setDataSource(filteredDatas);
      setFilteredData(filteredDatas.length);
    }
  };

  const columns = [
    {
      title: 'IP 주소',
      dataIndex: 'ip',
      key: 'ip',
      sorter: {
        compare: (a, b) => a?.ip?.localeCompare(b?.ip),
      },
      render: (text, record) => (
        <a
          style={{ color: '#40a9ff' }}
          onClick={() => handleCreateEditWindow(record)}
        >
          {text}
        </a>
      ),
    },
    {
      title: '장소 / 담당자',
      dataIndex: 'memo',
      key: 'memo',
      sorter: {
        compare: (a, b) => a?.memo?.localeCompare(b?.memo),
      },
    },
    {
      title: '만료 일시',
      dataIndex: 'expiresAt',
      key: 'expiresAt',
      sorter: {
        compare: (a, b) => a?.expiresAt?.localeCompare(b?.expiresAt),
      },
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabDevelop" link="ipAllow" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="개발"
            subTitle="개인정보 IP"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button
              type="primary"
              style={{ width: 100 }}
              onClick={handleCreateNewWindow}
            >
              추가
            </Button>

            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={onSearch}
            />
            <div className="searchResult">검색결과 {filteredData}개</div>

            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(_, __, ___, { currentDataSource }) =>
                setFilteredData(currentDataSource.length)
              }
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default IpAllow;
