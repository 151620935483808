import React from 'react';
import { Layout } from 'antd';
import 'antd/dist/antd.css';

const footerOption =
  process.env.REACT_APP_DEPLOY === 'production'
    ? '#343a40'
    : process.env.REACT_APP_DEPLOY === 'staging'
    ? '#009b5a'
    : '#22549e';
const Footer = () => {
  const { Footer } = Layout;

  return (
    <Footer
      style={{
        textAlign: 'center',
        color: 'white',
        paddingTop: 14,
        paddingBottom: 14,
        backgroundColor: footerOption,
      }}
    >
      Copyright &copy; 2020 휴먼스케이프. All right reserved.
    </Footer>
  );
};

export default Footer;
