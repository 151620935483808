import { all, takeLatest } from 'redux-saga/effects';
import { ipAllowCreators, ipAllowActions } from '../reducers/ipAllow.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchAllIpAllow } from '../../services/ipAllowService';

const handleFetchAllIpAllows = createAsyncSaga(
  ipAllowCreators.fetchAllIpAllows,
  fetchAllIpAllow,
);

export function* ipAllowSaga() {
  yield all([
    takeLatest(ipAllowActions.FETCH_ALL_IPALLOWS, handleFetchAllIpAllows),
  ]);
}
