import { all, takeLatest } from 'redux-saga/effects';
import { roleActions, roleCreators } from '../reducers/role.reducer';
import { createAsyncSaga } from '../reducer.util';
import { fetchAllRoles } from '../../services/roleService';

const handleFetchRole = createAsyncSaga(
  roleCreators.fetchAllRoles,
  fetchAllRoles,
);

export function* roleSaga() {
  yield all([takeLatest(roleActions.FETCH_ALL_ROLE, handleFetchRole)]);
}
