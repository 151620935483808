import React from 'react';
import {
  Form,
} from 'react-bootstrap';

const SearchButton = ({
  searchFilter,
  handleChange,
  onTableSearch,
  searchFilterContent,
}) => {
  const ListContent = ({ contentList }) => {
    return contentList.map((contentData) => (
      <option value={contentData}>{contentData}</option>
    ));
  };
  
  return (        
    <Form.Group>
      <Form.Control
        as="select"
        value={searchFilter}
        onChange={handleChange}
        id="selectCategory"
      >
        <ListContent
          contentList={searchFilterContent}
        />
      </Form.Control>
      &nbsp;
      <Form.Control
        type="text"
        onChange={onTableSearch}
        placeholder={`${searchFilter} 검색`}
        id="searchItem"
      />
    </Form.Group>
  );
};

export default SearchButton;
