import { all, takeLatest } from 'redux-saga/effects';
import {
  papProjectCreators,
  papProjectActions,
} from '../reducers/papProject.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchAllPapProjects } from '../../services/papService';

const handleFetchAllTreatmentNews = createAsyncSaga(
  papProjectCreators.fetchAllPapProjects,
  fetchAllPapProjects,
);

export function* papProjectSaga() {
  yield all([
    takeLatest(
      papProjectActions.FETCH_ALL_PAP_PROJECTS,
      handleFetchAllTreatmentNews,
    ),
  ]);
}
