import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  pushList: getAsyncState.initial(null),
  notification: getAsyncState.initial(null),
  notificationIndividual: getAsyncState.initial([]),
  contents: getAsyncState.initial(null),
  postResult: getAsyncState.initial([]),
  newsFeedList: getAsyncState.initial([]),
};

// action types
export const notificationActions = {
  FETCH_PUSH_MESSAGE_LIST: '@notification/FETCH_PUSH_MESSAGE_LIST',
  FETCH_ALL_NOTIFICATION_HISTORY:
    '@notification/FETCH_ALL_NOTIFICATION_HISTORY',
  FETCH_ALL_NOTIFICATION_INDIVIDUAL:
    '@notification/FETCH_ALL_NOTIFICATION_INDIVIDUAL',
  POST_NOTIFICATION: '@notification/POST_NOTIFICATION',
  FETCH_ALL_NOTIFICATION_CONTENTS:
    '@notification/FETCH_ALL_NOTIFICATION_CONTENTS',
  FETCH_NEWS_FEED_LIST: '@notification/FETCH_NEWS_FEED_LIST',
};

// actions
export const notificationCreators = {
  fetchPushMessageList: createAsyncActions(
    notificationActions,
    'FETCH_PUSH_MESSAGE_LIST',
  ),
  fetchAllNotificationHistory: createAsyncActions(
    notificationActions,
    'FETCH_ALL_NOTIFICATION_HISTORY',
  ),
  fetchAllNotificationIndividual: createAsyncActions(
    notificationActions,
    'FETCH_ALL_NOTIFICATION_INDIVIDUAL',
  ),
  postNotification: createAsyncActions(
    notificationActions,
    'POST_NOTIFICATION',
  ),
  fetchAllNotificationContents: createAsyncActions(
    notificationActions,
    'FETCH_ALL_NOTIFICATION_CONTENTS',
  ),
  fetchNewsFeedList: createAsyncActions(
    notificationActions,
    'FETCH_NEWS_FEED_LIST',
  ),
};

const notificationReducer = handleActions(
  {
    ...handleAsyncActions(
      notificationActions.FETCH_PUSH_MESSAGE_LIST,
      'pushList',
    ),
    ...handleAsyncActions(
      notificationActions.FETCH_ALL_NOTIFICATION_HISTORY,
      'notification',
    ),
    ...handleAsyncActions(
      notificationActions.FETCH_ALL_NOTIFICATION_INDIVIDUAL,
      'notificationIndividual',
    ),
    ...handleAsyncActions(notificationActions.POST_NOTIFICATION, 'postResult'),
    ...handleAsyncActions(
      notificationActions.FETCH_ALL_NOTIFICATION_CONTENTS,
      'contents',
    ),
    ...handleAsyncActions(
      notificationActions.FETCH_NEWS_FEED_LIST,
      'newsFeedList',
    ),
  },
  initialState,
);

export default notificationReducer;
