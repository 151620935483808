import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Table, Input } from 'antd';
import 'antd/dist/antd.css';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { userCreators } from '../../../store/reducers/user.reducer';

const UserWithdrawal = () => {
  const dispatch = useDispatch();
  const { userWithdrawal } = useSelector((state) => ({
    userWithdrawal: state.userReducer?.userWithdrawal?.data,
  }));

  const getData = useCallback(() => {
    dispatch(userCreators.fetchAllUserWithdrawal.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setDataSource(userWithdrawal);
    setFilteredData(userWithdrawal?.length);
  }, [userWithdrawal]);

  const { Content } = Layout;
  const [dataSource, setDataSource] = useState('');
  const { Search } = Input;
  const [filteredData, setFilteredData] = useState(null);

  const reasonOption = useMemo(() => {
    const filters = [];
    userWithdrawal
      ?.reduce((set, record) => set.add(record.reason), new Set())
      ?.forEach((reason) => filters.push({ text: reason, value: reason }));
    return filters;
  }, [userWithdrawal]);

  const onSearch = (e) => {
    if (e === '') {
      setDataSource(userWithdrawal);
      setFilteredData(userWithdrawal?.length);
    } else {
      const filteredDatas = dataSource?.filter((entry) =>
        Object?.values(entry).toString().includes(e),
      );
      setDataSource(filteredDatas);
      setFilteredData(filteredDatas.length);
    }
  };

  const columns = [
    {
      title: '일시',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
    },
    {
      title: '버전',
      dataIndex: 'appVersion',
      key: 'appVersion',
      width: 200,
      sorter: {
        compare: (a, b) => a?.appVersion?.localeCompare(b?.appVersion),
      },
    },
    {
      title: '사유',
      dataIndex: 'reason',
      key: 'reason',
      width: 600,
      sorter: {
        compare: (a, b) => a?.reason?.localeCompare(b?.reason),
      },
      filters: reasonOption,
      onFilter: (value, record) => record.reason === value,
    },
    {
      title: '자세한 사유',
      dataIndex: 'reasonDetail',
      key: 'reasonDetail',
      width: 200,
      sorter: {
        compare: (a, b) => a?.reasonDetail?.localeCompare(b?.reasonDetail),
      },
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabAccount" link="userWithdrawal" />
        <Layout className="right-layout">
          <TitleBreadcrumb title="계정" subTitle="탈퇴" className="white-bg" />
          <Content className="site-layout-background contentStyle">
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={onSearch}
            />
            <div className="searchResult">검색결과 {filteredData}개</div>

            <Table
              columns={columns}
              dataSource={dataSource}
              loading={!dataSource}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(_, __, ___, { currentDataSource }) =>
                setFilteredData(currentDataSource.length)
              }
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default UserWithdrawal;
