import React, { useEffect, useState, useReducer, useCallback } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Select, Button, Alert, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import WindowHeader from '../../components/windowHeader';
import { notificationCreators } from '../../../store/reducers/notification.reducer';
import {
  postPopup,
  patchPopup,
  deletePopup,
} from '../../../services/popupService';
import { useMutate } from '../../../hooks/useRequest';
import ElementLoading from '../../components/elementLoading';
import { ALERT_MESSAGES } from '../../../assets/alert';

const PopupWindow = () => {
  const dispatch = useDispatch();
  const { notificationInfo } = useSelector((state) => ({
    notificationInfo: state.notificationReducer?.contents?.data?.공지,
  }));

  const getData = useCallback(() => {
    dispatch(notificationCreators.fetchAllNotificationContents.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [popupId, setPopupId] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);

  const initialState = {
    title: '',
    content: '',
    relatedId: '',
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(stateReducer, initialState);

  const postPatchData = {
    title: state.title,
    content: state.content,
    type: 'NoticeDetail',
    relatedId: state.relatedId,
    isApp: true,
    diseaseGroupIds: [],
    diseaseTypeIds: [],
  };

  const { mutate: putPopup, done: isPosted } = useMutate(
    postPopup,
    postPatchData,
  );
  const { mutate: changePopup, done: isPatched } = useMutate(
    patchPopup,
    popupId,
    postPatchData,
  );
  const { mutate: erasePopup, done: isDeleted } = useMutate(
    deletePopup,
    popupId,
  );

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setPopupId(params.id);
      const paramData = JSON.parse(params.subId);
      setState({
        title: paramData.title,
        content: paramData.content,
        relatedId: paramData.relatedId,
      });
      setIsLoading(false);
    } else {
      setPopupId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (popupId) {
      setIsLoading(false);
    }
  }, [popupId]);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      title: values.title,
      content: values.content,
      relatedId: values.select,
    });
    if (popupId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      erasePopup();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changePopup();
      setIsPatch(false);
    }
    if (isPost) {
      putPopup();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  if (isLoading) return <ElementLoading type="팝업" />;
  return (
    <>
      <WindowHeader title="팝업" />

      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              title: state.title,
              content: state.content,
              select: state.relatedId,
            }}
          >
            <Form.Item
              name="select"
              label="Select"
              hasFeedback
              rules={[{ required: true, message: '공지를 선택해주세요' }]}
            >
              <Select placeholder="공지를 선택해주세요">
                <Option value="">없음</Option>
                {notificationInfo?.map((notification) => {
                  return (
                    <Option value={notification.id}>
                      {notification.title}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="제목"
              name="title"
              rules={[
                {
                  required: true,
                  message: '제목을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="내용"
              name="content"
              rules={[
                {
                  required: true,
                  message: '내용를 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {popupId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PopupWindow;
