import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';
import { convertToQuery } from '../hooks/usePagination';

/**
 * 모든 글로벌 뉴스 데이터를 가져옵니다.
 */
export async function fetchAllRssFeed() {
  const res = await axios.get(`/v4/admin/rssNews`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 글로벌 뉴스 목록을 가져옵니다. (with pagination)
 * @param {object} pagination { current, pageSize }
 * @param {object} filter { diseaseTypeName[], lang[], tag[], isApp[], title[] }
 * @returns {object} { data, total, page, pageCount }
 */
export async function fetchRssFeedList(pagination, filter, sort) {
  const buildQueryFilter = (filters) => {
    const result = [];
    if (filters.diseaseTypeName) {
      filters.diseaseTypeName.forEach((d, idx) => {
        if (d !== 'common')
          result.push(
            `${idx === 0 ? 'filter' : 'or'}=diseaseTypeId||$eq||${d}`,
          );
      });
    }
    if (filters.lang) {
      // NOTE(reo): filter=lang||$eq||ko&or=lang||$ne||ko 필터는 필터가 없을 때와 결과가 같아야 하지만 실제로는 일부 기사가 누락되어 length=1일 때만 쿼리를 추가함
      if (filters.lang.length === 1)
        filters.lang.forEach((l, idx) => {
          if (l === 'ko')
            result.push(`${idx === 0 ? 'filter' : 'or'}=lang||$eq||ko`);
          else result.push(`${idx === 0 ? 'filter' : 'or'}=lang||$ne||ko`);
        });
    }
    if (filters.tag) {
      filters.tag.forEach((t, idx) => {
        result.push(
          `${idx === 0 ? 'filter' : 'or'}=tag||$cont||${encodeURIComponent(t)}`,
        );
      });
    }
    if (filters.isApp) {
      filters.isApp.forEach((a, idx) => {
        result.push(
          `${idx === 0 ? 'filter' : 'or'}=isApp||$eq||${a.toString()}`,
        );
      });
    }
    if (filters.title) {
      filters.title.forEach((t, idx) => {
        if (t.length === 0) return;
        result.push(
          `${idx === 0 ? 'filter' : 'or'}=title||$cont||${encodeURIComponent(
            t,
          )}`,
        );
        result.push(`or=description||$cont||${encodeURIComponent(t)}`);
      });
    }
    if (
      filters.diseaseTypeName &&
      filters.diseaseTypeName.find((d) => d === 'common')
    ) {
      result.push(`filter=diseaseTypeName||$eq||${encodeURIComponent('공통')}`);
    }
    return result.join('&');
  };
  const buildQuerySorter = (sorter) => {
    if (!sorter.field) return ``;
    return `sort=${sorter.field},${sorter.order === 'ascend' ? 'ASC' : 'DESC'}`;
  };
  const res = await axios.get(
    `/v1/admin/globalArticle?${convertToQuery(pagination)}&${buildQueryFilter(
      filter,
    )}&${buildQuerySorter(sort)}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 글로벌 뉴스를 수정합니다.
 * @param {String} rssFeedId
 * @param {object} body
 */
export async function patchRssFeed(rssFeedId, body) {
  const res = await axios.patch(`/v4/admin/rssNews/${rssFeedId}`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
