import { all, takeLatest } from 'redux-saga/effects';
import {
  mutationDnaActions,
  mutationDnaCreators,
} from '../reducers/mutationDna.reducer';
import { createAsyncSaga } from '../reducer.util';
import { fetchAllMutationDnas } from '../../services/mutationDnaService';

const handleFetchAllMutationDna = createAsyncSaga(
  mutationDnaCreators.fetchAllMutationDna,
  fetchAllMutationDnas,
);

export function* mutationDnaSaga() {
  yield all([
    takeLatest(
      mutationDnaActions.FETCH_ALL_MUTATION_DNA,
      handleFetchAllMutationDna,
    ),
  ]);
}
