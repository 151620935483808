import React, { useEffect, useState, useRef, useReducer } from 'react';
import {
  Form,
  Input,
  Button,
  Alert,
  Row,
  Col,
  Upload,
  Select,
  Checkbox,
  Radio,
  notification,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../public/css/window.css';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import WindowHeader from '../../components/windowHeader';
import ElementLoading from '../../components/elementLoading';

import {
  fetchHealthProfile,
  postHealthProfile,
  patchHealthProfile,
  deleteHealthProfile,
} from '../../../services/healthProfileService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import { ALERT_MESSAGES } from '../../../assets/alert';
import { useAntFileUpload } from '../../util/useAntFileImage';
import * as listMap from '../../../util/listMap';

const HealthProfileWindow = () => {
  const plainOptions = ['환자', '보호자'];
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { diseaseTypeInfo } = useSelector((state) => {
    return {
      diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data
        ? state.diseaseTypeReducer.diseaseTypes.data.map(({ id, krName }) => ({
            id,
            krName,
          }))
        : null,
    };
  }, shallowEqual);

  const initialState = {
    name: null,
    description: null,
    order: null,
    diseaseTypeIds: [],
    accountTypes: plainOptions,
    necessaryTime: null,
    introText: null,
    endingText: null,
    photo: null,
    photoPng: null,
    isDisease: false,
    isRequestSurvey: false,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);
  const [windowId, setWindowId] = useState('');
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [healthProfileId, setHealthProfileId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const fileUploadSetting = useRef(false);
  const didCancel = useRef(false);
  const uploadCancel = useRef(false);

  const {
    publicUrl: distinctPublicUrl,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useAntFileUpload(distinctFileInputEvent);

  const postPatchData = {
    type: state.name,
    description: state.description,
    order: state.order,
    accountTypes: state.accountTypes,
    diseaseTypeIds: state.isDisease ? state.diseaseTypeIds : [],
    necessaryTime: state.necessaryTime,
    introText: state.introText,
    endingText: state.endingText,
    photo: state.photo,
    photoPng: state.photoPng,
    isRequestSurvey: state.isRequestSurvey,
  };

  const { data: fetchHealthProfileData, call: callHealthProfile } = useFetch(
    null,
    fetchHealthProfile,
    healthProfileId,
  );

  const { mutate: putHealthProfile, done: isPosted } = useMutate(
    postHealthProfile,
    postPatchData,
  );

  const { mutate: changeHealthProfile, done: isPatched } = useMutate(
    patchHealthProfile,
    healthProfileId,
    postPatchData,
  );

  const { mutate: eraseHealthProfile, done: isDeleted } = useMutate(
    deleteHealthProfile,
    healthProfileId,
  );

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    if (params.id) {
      setHealthProfileId(params.id);
    } else {
      setHealthProfileId('');
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (healthProfileId) callHealthProfile();
  }, [healthProfileId, callHealthProfile]);
  useEffect(() => {
    if (fetchHealthProfileData && !didCancel.current) {
      setState({
        order: fetchHealthProfileData.order,
        name: fetchHealthProfileData.type,
        accountTypes: fetchHealthProfileData.accountTypes,
        description: fetchHealthProfileData.description,
        necessaryTime: fetchHealthProfileData.necessaryTime,
        introText: fetchHealthProfileData.introText,
        endingText: fetchHealthProfileData.endingText,
        diseaseTypeIds:
          fetchHealthProfileData?.diseaseTypeIds !== [] &&
          fetchHealthProfileData?.diseaseTypeIds !== null
            ? fetchHealthProfileData.diseaseTypeIds
            : [],
        photo: fetchHealthProfileData.photo,
        photoPng: fetchHealthProfileData.photoPng,
        isRequestSurvey: fetchHealthProfileData.isRequestSurvey,
        isDisease:
          fetchHealthProfileData?.diseaseTypeIds !== [] &&
          fetchHealthProfileData?.diseaseTypeIds !== null,
      });
      didCancel.current = true;
      setIsLoading(false);
    }
    if (healthProfileId === '' && !didCancel.current) {
      didCancel.current = true;
      setIsLoading(false);
    }
  }, [fetchHealthProfileData, diseaseTypeInfo, healthProfileId]);

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      name: values.name,
      description: values.description,
      accountTypes: values.accountTypes,
      necessaryTime: values.necessaryTime,
      introText: values.introText,
      endingText: values.endingText,
    });
    if (healthProfileId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      eraseHealthProfile();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeHealthProfile();
      setIsPatch(false);
    }
    if (isPost) {
      putHealthProfile();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  const beforeUpload = (file) => {
    const isSupportedImageType =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/svg+xml';
    if (!isSupportedImageType) {
      notification.error({
        message: 'JPG/PNG/SVG만 업로드 가능합니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      notification.error({
        message: '이미지가 10MB보다 큽니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    return isSupportedImageType && isLt10M;
  };

  const uploadImg = async (e) => {
    if (e !== undefined) {
      setDistinctFileInputEvent(e);
      uploadCancel.current = true;
      setLoading(true);
    }
  };

  const noop = () => {};

  const deleteThumbImg = () => {
    setState({
      photo: '',
    });
    notification.success({
      message: '이미지를 삭제하였습니다.',
      description: '이미지를 다시 업로드해주세요.',
    });
  };

  const uploadProps = {
    customRequest: noop,
    onChange: uploadImg,
    onPreview: noop,
  };

  useEffect(() => {
    if (distinctFileInputEvent && uploadCancel.current) {
      distinctImageUpload();
      uploadCancel.current = false;
      fileUploadSetting.current = true;
    }
  }, [distinctFileInputEvent, distinctImageUpload]);

  useEffect(() => {
    if (distinctImageUploadDone && distinctPublicUrl) {
      notification.success({
        message: '이미지 업로드에 성공하였습니다.',
        description: '설문 이미지 등록에 성공하였습니다.',
      });
      setState({ photo: distinctPublicUrl });
      distinctUploadInitialize();
      setLoading(false);
    }
  }, [distinctImageUploadDone, distinctPublicUrl, distinctUploadInitialize]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  if (isLoading) return <ElementLoading type="설문 항목" />;
  return (
    <>
      <WindowHeader title="설문 항목" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              order: state.order,
              name: state.name,
              description: state.description,
              necessaryTime: state.necessaryTime,
              introText: state.introText,
              endingText: state.endingText,
              photo: state.photo,
              photoPng: state.photoPng,
              accountTypes: state.accountTypes,
              diseaseTypeIds: state.diseaseTypeIds,
              isDisease: state.isDisease,
              isRequestSurvey: state.isRequestSurvey,
            }}
          >
            <Form.Item label="질환 전체선택" name="isDisease">
              <Checkbox
                onChange={(e) =>
                  setState({
                    isDisease: e.target.checked,
                  })
                }
                checked={state?.isDisease}
              >
                직접 선택하기 (직접 선택을 체크하지 않으면 전체 질환선택입니다.)
              </Checkbox>
            </Form.Item>
            {state.isDisease && (
              <Form.Item label="질환 태그" name="diseaseTypeIds">
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="질환 태그를 선택해주세요"
                  filterOption={(input, option) =>
                    option.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option.props.key
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) =>
                    setState({
                      diseaseTypeIds: e,
                    })
                  }
                >
                  {diseaseTypeInfo?.map((diseaseType) => (
                    <Select.Option
                      key={diseaseType?.krName}
                      id={diseaseType?.krName}
                      value={diseaseType?.id}
                    >
                      {diseaseType.krName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Form.Item label="계정 유형" name="accountTypes">
              <Checkbox.Group
                options={plainOptions}
                value={state.accountTypes}
                onChange={(value) => setState({ accountTypes: value })}
              />
            </Form.Item>

            <Form.Item
              label="항목 이름"
              name="name"
              rules={[
                {
                  required: true,
                  message: '항목 이름을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="순서" name="order">
              <Input
                placeholder="순서를 생성 후 메인 페이지에서 지정해주세요!"
                disabled
              />
            </Form.Item>

            <Form.Item label="항목 설명" name="description">
              <Input />
            </Form.Item>

            <Form.Item label="적정 시간" name="necessaryTime">
              <Input />
            </Form.Item>

            <Form.Item label="인트로 문구" name="introText">
              <Input />
            </Form.Item>

            <Form.Item label="엔딩 문구" name="endingText">
              <Input />
            </Form.Item>

            <Form.Item label="건강 설문 타입" name="isRequestSurvey">
              <Radio.Group
                disabled
                onChange={(e) =>
                  setState({
                    isRequestSurvey: e.target.value,
                  })
                }
                value={state.isRequestSurvey}
                options={listMap.isRequestSurveyOptions}
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <div
                className="fade_in_box"
                style={{ marginBottom: 10, marginLeft: 0 }}
              >
                설문 이미지는 svg 파일로 업로드해 주세요
              </div>
            </Form.Item>

            <Form.Item label="설문 이미지" name="photo">
              <Upload
                {...uploadProps}
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {state?.photo ? (
                  <img
                    src={state?.photo}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              {state?.photo && (
                <Button
                  type="primary"
                  style={{ width: 104 }}
                  danger
                  onClick={deleteThumbImg}
                >
                  이미지 삭제
                </Button>
              )}
            </Form.Item>

            {state?.photoPng && (
              <Form.Item label="구 설문 이미지" name="photoPng">
                <Upload listType="picture-card" showUploadList={false} disabled>
                  <img
                    src={state?.photoPng}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                </Upload>
              </Form.Item>
            )}
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {healthProfileId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default HealthProfileWindow;
