import React, { useEffect, useState, useCallback } from 'react';
import {
  Row,
  Col,
  Form,
  Table,
  Button,
  ButtonToolbar,
  ButtonGroup,
  ToggleButtonGroup,
  ToggleButton,
  Spinner,
  Nav,
} from 'react-bootstrap';
import { Layout, notification, Select, Tabs } from 'antd';
import 'antd/dist/antd.css';
import { batch, useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { healthProfileCreators } from '../../../store/reducers/healthProfile.reducer';
import {
  patchHealthProfile,
  patchHealthProfileQuestion,
  patchHealthProfileOption,
  fetchHealthProfileExcel,
  patchHealthProfileBulk as patchHealthProfileBulkService,
  postHealthProfileCopy as postHealthProfileCopyService,
} from '../../../services/healthProfileService';
import { useFetch } from '../../../hooks/useRequest';
import { NEW } from '../../../window/util/utils';
import useWindow from '../../../hooks/useWindow';

const HealthProfile = () => {
  // declare dispatch function & variables for fetching data from store
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    healthProfileInfo,
    healthProfileQuestionInfo,
    healthProfileOptionInfo,
  } = useSelector((state) => {
    return {
      healthProfileInfo: state?.healthProfileReducer?.healthProfile?.data,
      healthProfileQuestionInfo:
        state?.healthProfileReducer?.healthProfileQuestion?.data,
      healthProfileOptionInfo:
        state?.healthProfileReducer?.healthProfileOption?.data,
    };
  }, shallowEqual);

  const loading = !healthProfileInfo;

  const defaultHealthProfile = healthProfileInfo?.filter(
    (healthProfileData) =>
      healthProfileData?.patientGroups?.length === 0 &&
      healthProfileData?.research?.length === 0 &&
      healthProfileData?.category !== 'request',
  );

  const patientHealthProfile = healthProfileInfo?.filter(
    (healthProfileData) => healthProfileData?.patientGroups?.length !== 0,
  );

  const researchHealthProfile = healthProfileInfo?.filter(
    (healthProfileData) => healthProfileData?.research?.length !== 0,
  );

  const requestHealthProfile = healthProfileInfo?.filter(
    (healthProfileData) => healthProfileData?.category === 'request',
  );

  // component state
  const { Content } = Layout;
  const { TabPane } = Tabs;
  const [key, setKey] = useState('default');
  const [selectedHealthProfileId, setSelectedHealthProfileId] = useState('');
  const [
    selectedHealthProfileQuestionId,
    setSelectedHealthProfileQuestionId,
  ] = useState('');
  const [orderPatchInfo, setOrderPatchInfo] = useState([]);
  const [isApp, setIsApp] = useState([]);
  const [done, setDone] = useState(false);
  const [selectHealthProfileId, setSelectHealthProfileId] = useState('');

  const [healthProfileQuestion, setHealthProfileQuestion] = useState([]);
  const [healthProfileOption, setHealthProfileOption] = useState([]);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [optionLoading, setOptionLoading] = useState(true);

  // dispatch to fetch data
  const getData = useCallback(() => {
    dispatch(healthProfileCreators.fetchAllHealthProfiles.request());
    if (selectedHealthProfileId !== '') {
      dispatch(
        healthProfileCreators.fetchAllHealthProfileQuestions.request(
          selectedHealthProfileId,
        ),
      );
      if (selectedHealthProfileQuestionId !== '') {
        dispatch(
          healthProfileCreators.fetchAllHealthProfileOptions.request({
            healthProfileId: selectedHealthProfileId,
            healthProfileQuestionId: selectedHealthProfileQuestionId,
          }),
        );
      }
    }
  }, [dispatch, selectedHealthProfileId, selectedHealthProfileQuestionId]);

  // fetching data on mount
  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setQuestionLoading(false);
    setHealthProfileQuestion(healthProfileQuestionInfo);
  }, [healthProfileQuestionInfo]);

  useEffect(() => {
    setOptionLoading(false);
    setHealthProfileOption(healthProfileOptionInfo);
  }, [healthProfileOptionInfo]);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  // receive messages from dnaAnalysisStats Window
  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  // add event listner(to receive message) & fetch data at mount
  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  // patch logic for setting order
  const patchOrder = async ({ type, id, order }) => {
    try {
      if (type === 'healthProfile') {
        const patchData = { order };
        await patchHealthProfile(id, patchData);
      }
      if (type === 'healthProfileQuestion') {
        const patchData = { order };
        await patchHealthProfileQuestion(id, patchData);
      }
      if (type === 'healthProfileOption') {
        const patchData = { order };
        await patchHealthProfileOption(id, patchData);
      }
      setDone(true);
    } catch (err) {
      alert(err);
    }
  };
  const initializeDone = () => {
    setDone(false);
  };

  const {
    done: postHealthProfileCopyDone,
    call: postHealthProfileCopy,
    initialize: initializePostHealthProfileCopy,
  } = useFetch(null, postHealthProfileCopyService, selectedHealthProfileId);

  useEffect(() => {
    if (postHealthProfileCopyDone) {
      notification.success({
        message: '설문 복사에 성공하였습니다.',
        description: '설문 리스트를 확인해 주세요.',
      });
      initializePostHealthProfileCopy();
      dispatch(healthProfileCreators.fetchAllHealthProfiles.request());
    }
  }, [postHealthProfileCopyDone, initializePostHealthProfileCopy, dispatch]);

  const {
    done: patchHealthProfileBulkDone,
    call: patchHealthProfileBulk,
    initialize: initializePatchHealthProfileBulk,
  } = useFetch(null, patchHealthProfileBulkService, isApp);

  useEffect(() => {
    if (patchHealthProfileBulkDone) {
      notification.success({
        message: '설문 앱 노출 업데이트에 성공하였습니다.',
        description: '설문 리스트를 확인해 주세요.',
      });
      initializePatchHealthProfileBulk();
      setIsApp([]);
      dispatch(healthProfileCreators.fetchAllHealthProfiles.request());
    }
  }, [patchHealthProfileBulkDone, initializePatchHealthProfileBulk, dispatch]);

  // handling the case of click link to edit healthProfile
  const handleCreateEditHealthProfileWindow = (healthProfileData) => {
    if (findWindowById(healthProfileData.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({ id: healthProfileData.id, dataType: 'healthProfile' });
  };

  // handling the case of click link to edit healthProfileQuestion
  const handleCreateEditHealthProfileQuestionWindow = (
    healthProfileQuestionData,
  ) => {
    if (findWindowById(healthProfileQuestionData.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({
      id: healthProfileQuestionData.id,
      dataType: 'healthProfileQuestion',
      subId: selectedHealthProfileId,
    });
  };
  // handling the case of click link to edit healthProfileOption
  const handleCreateEditHealthProfileOptionWindow = (
    healthProfileOptionData,
  ) => {
    if (findWindowById(healthProfileOptionData.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({
      id: healthProfileOptionData.id,
      dataType: 'healthProfileOption',
      subId: selectedHealthProfileId,
      extraId: selectedHealthProfileQuestionId,
    });
  };
  // 설문 복사
  const handleHealthProfileCopy = () => {
    if (window.confirm('설문을 복사하시겠습니까?')) {
      if (selectedHealthProfileId === '') {
        alert('복사할 설문을 선택해주세요.');
        return;
      }
      postHealthProfileCopy();
    }
  };

  const handleIsAppChange = () => {
    if (window.confirm('설문을 배포하시겠습니까?')) {
      if (isApp?.length === 0) {
        alert('배포할 설문을 선택해주세요.');
      }
      patchHealthProfileBulk();
    }
  };

  const handleIsAppClear = () => {
    setIsApp([]);
  };

  // handling case of click button to create healthProfile
  const handleCreateNewHealthProfileWindow = () => {
    createWindow({ id: `${NEW}${Date.now()}`, dataType: 'healthProfile' });
  };
  // handling case of click button to create healthProfileQuestion
  const handleCreateNewHealthProfileQuestionWindow = () => {
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: 'healthProfileQuestion',
      subId: selectedHealthProfileId,
    });
  };
  // handling case of click button to create healthProfileOption
  const handleCreateNewHealthProfileOptionWindow = () => {
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: 'healthProfileOption',
      subId: selectedHealthProfileId,
      extraId: selectedHealthProfileQuestionId,
    });
  };

  // select one of healthProfile/healthProfileQuestion/healthProfileOption
  const selectOne = ({ id, type }) => {
    if (type === 'healthProfile' && id !== selectedHealthProfileId) {
      setSelectedHealthProfileId(id);
      setSelectedHealthProfileQuestionId('');
      setQuestionLoading(true);
      setHealthProfileQuestion([]);
      setHealthProfileOption([]);
    }
    if (
      type === 'healthProfileQuestion' &&
      id !== selectedHealthProfileQuestionId
    ) {
      setSelectedHealthProfileQuestionId(id);
      setOptionLoading(true);
      setHealthProfileOption([]);
    }
  };

  // change orderPatchInfo when order changed
  const changeOrderPatchInfo = ({ changedValue, id, type }) => {
    const a = orderPatchInfo.filter((patchInfo) => patchInfo.id === id);
    if (a.length === 1) {
      const b = orderPatchInfo.filter((patchInfo) => patchInfo.id !== id);
      setOrderPatchInfo([...b, { changedValue, id, type }]);
    } else {
      setOrderPatchInfo([...orderPatchInfo, { changedValue, id, type }]);
    }
  };

  // check before setting order
  const checkBeforeSetOrder = () => {
    if (window.confirm('변경된 순서를 확정하시겠습니까?')) {
      initializeDone();
      setOrder();
    }
  };

  // updating order info in database
  const setOrder = () => {
    batch(() => {
      for (let i = 0; i < orderPatchInfo.length; i += 1) {
        patchOrder({
          type: orderPatchInfo[i].type,
          order: parseInt(orderPatchInfo[i].changedValue, 10),
          id: orderPatchInfo[i].id,
        });
      }
    });
    setOrderPatchInfo([]);
  };

  // re-fetching after order information patched
  useEffect(() => {
    getData();
  }, [done, getData]);

  const visualizeOrder = ({ data, type }) => {
    return orderPatchInfo.filter((patchInfo) => patchInfo.id === data.id)
      .length === 1 &&
      orderPatchInfo.filter((patchInfo) => patchInfo.id === data.id)[0].type ===
        type
      ? orderPatchInfo.filter((patchInfo) => patchInfo.id === data.id)[0]
          .changedValue
      : data.order;
  };

  const healthProfileUsersWindow = (healthProfileId) => {
    history.push('/healthProfileUsers', {
      healthProfileId,
    });
  };

  const downloadExcel = useCallback((data) => {
    const firstKey = Object.keys(data)[0];
    const firstValue = data[firstKey];

    const xlsx = require('xlsx');
    const wb = xlsx.utils.book_new();
    const ws = xlsx.utils.aoa_to_sheet(firstValue);

    xlsx.utils.book_append_sheet(wb, ws, firstKey?.substring(0, 20));

    xlsx.writeFile(wb, `${firstKey?.substring(0, 20)}.xlsx`);
    notification.success({
      message: '건강설문 다운로드가 완료 되었습니다.',
      description: '건강설문 다운로드가 완료 되었습니다. ',
    });
  }, []);

  const excelRequest = useCallback(
    (healthProfileId) => () =>
      fetchHealthProfileExcel(healthProfileId)
        .then((response) => downloadExcel(response))
        .catch(({ _data, message = `건강설문 다운로드에 실패했습니다.` }) => {
          notification.warn({
            message: '건강설문 다운로드에 실패했습니다.',
            description: message,
          });
        }),
    [downloadExcel],
  );

  const HealthProfileCol = ({ selectHealthProfile, type }) => {
    return (
      <>
        <Col style={{ maxWidth: '40%' }}>
          <Form.Group>
            <Form.Group className="formContent" as={Row}>
              <h6 className="title">설문 항목</h6>
              <Button
                size="sm"
                className="add-button"
                onClick={handleCreateNewHealthProfileWindow}
              >
                추가
              </Button>
              <Button
                size="sm"
                style={{ marginLeft: '4px' }}
                onClick={handleHealthProfileCopy}
                disabled={selectedHealthProfileId === ''}
              >
                복사
              </Button>
              <Button
                size="sm"
                style={{ marginLeft: '4px' }}
                onClick={handleIsAppChange}
                disabled={isApp?.length === 0}
              >
                배포
              </Button>
              <Button
                size="sm"
                style={{ marginLeft: '4px' }}
                onClick={handleIsAppClear}
                disabled={isApp?.length === 0}
              >
                되돌리기
              </Button>
            </Form.Group>
            <div className="tableFixHead">
              <Table id="table" hover size="sm">
                <thead>
                  <tr
                    style={{
                      fontSize: 12,
                      whiteSpace: 'break-spaces',
                    }}
                  >
                    <th style={{ width: '10%' }}>선택</th>
                    <th style={{ width: '20%' }}>설문 구분</th>
                    <th style={{ width: '30%' }}>항목 이름</th>
                    <th style={{ width: '12%' }}>순서</th>
                    <th style={{ width: '13%' }}>답변</th>
                    <th style={{ width: 70 }}>앱 노출</th>
                  </tr>
                </thead>
                <tbody>
                  {selectHealthProfile &&
                    selectHealthProfile.map((healthProfileData) =>
                      healthProfileData?.isRequestSurvey ? (
                        <tr
                          key={healthProfileData.id}
                          style={{
                            fontSize: 12,
                            whiteSpace: 'break-spaces',
                          }}
                        >
                          <td />
                          <td>
                            <div
                              style={{
                                fontSize: 12,
                                whiteSpace: 'break-spaces',
                              }}
                            >
                              <span
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                요청 설문
                              </span>
                            </div>
                          </td>
                          <td>
                            <div
                              style={{
                                whiteSpace: 'break-spaces',
                              }}
                              title={healthProfileData.type}
                            >
                              {healthProfileData.type}
                            </div>
                          </td>
                          <td />
                          <td>
                            <Button
                              size="md"
                              className="exposureButton"
                              variant="outline-primary"
                              style={{
                                fontSize: 12,
                                lineHeight: '26px',
                                whiteSpace: 'break-spaces',
                              }}
                              onClick={() =>
                                healthProfileUsersWindow(healthProfileData.id)
                              }
                            >
                              리스트
                            </Button>
                          </td>
                          <td />
                        </tr>
                      ) : (
                        <tr
                          key={healthProfileData.id}
                          style={{
                            fontSize: 12,
                            whiteSpace: 'break-spaces',
                          }}
                        >
                          <td>
                            <Form.Check
                              className="form-check-input"
                              inline
                              type="radio"
                              checked={
                                healthProfileData.id === selectedHealthProfileId
                              }
                              name={`healthProfileChecked${type}`}
                              id={`healthProfileChecked${type}`}
                              style={{
                                width: '16px',
                                height: '16px',
                                position: 'relative',
                                margin: '0 auto',
                                top: '2px',
                              }}
                              onClick={() =>
                                selectOne({
                                  id: healthProfileData.id,
                                  type: 'healthProfile',
                                })
                              }
                            />
                          </td>
                          <td>
                            {healthProfileData?.patientGroups?.length > 0 && (
                              <div
                                style={{
                                  fontSize: 12,
                                  whiteSpace: 'break-spaces',
                                }}
                              >
                                {healthProfileData?.patientGroups?.[0]
                                  ?.deletedAt !== null && '[삭제됨] '}
                                {healthProfileData?.patientGroups?.[0]?.name}
                              </div>
                            )}
                            {healthProfileData?.research?.length > 0 && (
                              <div
                                style={{
                                  fontSize: 12,
                                  whiteSpace: 'break-spaces',
                                }}
                              >
                                {healthProfileData?.research?.[0]?.deletedAt !==
                                  null && '[삭제됨] '}
                                {healthProfileData?.research?.[0]?.title}
                              </div>
                            )}
                            {healthProfileData?.patientGroups?.length === 0 &&
                              healthProfileData?.research?.length === 0 && (
                                <>-</>
                              )}
                          </td>
                          <td>
                            <Nav.Link
                              onClick={() =>
                                handleCreateEditHealthProfileWindow(
                                  healthProfileData,
                                )
                              }
                            >
                              <div
                                style={{
                                  whiteSpace: 'break-spaces',
                                }}
                                title={healthProfileData.type}
                              >
                                {healthProfileData.type}
                              </div>
                            </Nav.Link>
                          </td>
                          <td>
                            <Form.Control
                              className="hide-spinner"
                              size="sm"
                              type="number"
                              style={{
                                fontSize: 12,
                                whiteSpace: 'break-spaces',
                                textAlign: 'center',
                              }}
                              placeholder={healthProfileData.order ? '' : '-'}
                              defaultValue={visualizeOrder({
                                data: healthProfileData,
                                type: 'healthProfile',
                              })}
                              onBlur={(e) =>
                                changeOrderPatchInfo({
                                  changedValue: e.target.value,
                                  id: healthProfileData.id,
                                  type: 'healthProfile',
                                })
                              }
                            />
                          </td>
                          <td>
                            <Button
                              size="md"
                              className="exposureButton"
                              variant="outline-primary"
                              style={{
                                fontSize: 12,
                                lineHeight: '26px',
                                whiteSpace: 'break-spaces',
                              }}
                              onClick={() =>
                                healthProfileUsersWindow(healthProfileData.id)
                              }
                            >
                              리스트
                            </Button>
                          </td>
                          <td>
                            <ToggleButtonGroup
                              type="radio"
                              name="options"
                              value={
                                isApp.find(
                                  (item) => item.id === healthProfileData.id,
                                )
                                  ? isApp.find(
                                      (item) =>
                                        item.id === healthProfileData.id,
                                    )?.isApp
                                  : healthProfileData?.isApp
                              }
                              onChange={(e) => {
                                const itemExists = isApp.find(
                                  (item) => item.id === healthProfileData.id,
                                );
                                if (itemExists) {
                                  const updatedArray = isApp.filter(
                                    (item) => item.id !== healthProfileData.id,
                                  );
                                  setIsApp(updatedArray);
                                } else {
                                  setIsApp([
                                    ...isApp,
                                    {
                                      id: healthProfileData.id,
                                      isApp: e,
                                    },
                                  ]);
                                }
                              }}
                            >
                              <ToggleButton
                                size="sm"
                                style={{
                                  fontSize: 12,
                                  whiteSpace: 'break-spaces',
                                }}
                                id={`${healthProfileData.id}true`}
                                value={true}
                                variant="outline-primary"
                              >
                                {healthProfileData.isApp}on
                              </ToggleButton>
                              <ToggleButton
                                size="sm"
                                style={{
                                  fontSize: 12,
                                  whiteSpace: 'break-spaces',
                                }}
                                id={`${healthProfileData.id}false`}
                                value={false}
                                variant="outline-primary"
                              >
                                off
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </td>
                        </tr>
                      ),
                    )}
                </tbody>
              </Table>
            </div>
          </Form.Group>
        </Col>
      </>
    );
  };

  const HealthProfileQuestionCol = (type) => {
    return (
      <>
        <Col style={{ maxWidth: '35%' }}>
          <Form.Group className="formContent" as={Row}>
            <h6 className="title">설문 질문</h6>
            <Button
              size="sm"
              className="add-button"
              onClick={() =>
                selectedHealthProfileId !== ''
                  ? handleCreateNewHealthProfileQuestionWindow()
                  : alert('설문 항목을 선택해주세요!')
              }
            >
              추가
            </Button>
          </Form.Group>
          <div className="tableFixHead">
            <Table id="table" hover size="sm">
              <thead>
                <tr
                  style={{
                    fontSize: 12,
                    whiteSpace: 'break-spaces',
                  }}
                >
                  <th style={{ width: '20%' }}>선택</th>
                  <th style={{ width: '60%' }}>질문 내용</th>
                  <th style={{ width: '20%' }}>순서</th>
                </tr>
              </thead>
              <tbody>
                {questionLoading ? (
                  <tr>
                    <td colSpan={3}>
                      <Spinner style={{ margin: 'auto' }} animation="border" />
                    </td>
                  </tr>
                ) : (
                  <>
                    {selectedHealthProfileId !== '' &&
                      healthProfileQuestion &&
                      healthProfileQuestion.map((healthProfileQuestionData) => (
                        <tr
                          key={healthProfileQuestionData.id}
                          style={{
                            fontSize: 12,
                            whiteSpace: 'break-spaces',
                          }}
                        >
                          <td>
                            <Form.Check
                              inline
                              className="form-check-input"
                              type="radio"
                              checked={
                                healthProfileQuestionData.id ===
                                selectedHealthProfileQuestionId
                              }
                              name={`healthProfileQuestionChecked${type}`}
                              id={`healthProfileQuestionChecked${type}`}
                              style={{
                                width: '16px',
                                height: '16px',
                                position: 'relative',
                                margin: '0 auto',
                                top: '2px',
                              }}
                              onClick={() =>
                                selectOne({
                                  id: healthProfileQuestionData.id,
                                  type: 'healthProfileQuestion',
                                })
                              }
                            />
                          </td>
                          <td>
                            <Nav.Link
                              onClick={() =>
                                handleCreateEditHealthProfileQuestionWindow(
                                  healthProfileQuestionData,
                                )
                              }
                            >
                              <span
                                title={healthProfileQuestionData.mainQuestion}
                              >
                                {healthProfileQuestionData.mainQuestion}
                              </span>
                            </Nav.Link>
                          </td>
                          <td>
                            <Form.Control
                              className="hide-spinner"
                              size="sm"
                              type="number"
                              style={{
                                fontSize: 12,
                                whiteSpace: 'break-spaces',
                                textAlign: 'center',
                              }}
                              placeholder={
                                healthProfileQuestionData.order ? '' : '-'
                              }
                              defaultValue={visualizeOrder({
                                data: healthProfileQuestionData,
                                type: 'healthProfileQuestion',
                              })}
                              onBlur={(e) =>
                                changeOrderPatchInfo({
                                  changedValue: e.target.value,
                                  id: healthProfileQuestionData.id,
                                  type: 'healthProfileQuestion',
                                })
                              }
                            />
                          </td>
                        </tr>
                      ))}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      </>
    );
  };

  const HealthProfileOptionCol = () => {
    return (
      <>
        <Col style={{ maxWidth: '25%' }}>
          <Form.Group>
            <Form.Group className="formContent" as={Row}>
              <h6 className="title">설문 선택지</h6>
              <Button
                size="sm"
                className="add-button"
                onClick={() =>
                  selectedHealthProfileQuestionId !== ''
                    ? handleCreateNewHealthProfileOptionWindow()
                    : alert('설문 질문을 선택해주세요!')
                }
              >
                추가
              </Button>
            </Form.Group>
            <div className="tableFixHead">
              <Table id="table" hover size="sm">
                <thead>
                  <tr
                    style={{
                      fontSize: 12,
                      whiteSpace: 'break-spaces',
                    }}
                  >
                    <th style={{ width: '70%' }}>선택지 내용</th>
                    <th style={{ width: '30%' }}>순서</th>
                  </tr>
                </thead>
                <tbody>
                  {optionLoading ? (
                    <tr>
                      <td colSpan={3}>
                        <Spinner
                          style={{ margin: 'auto' }}
                          animation="border"
                        />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {selectedHealthProfileQuestionId !== '' &&
                        healthProfileOption &&
                        healthProfileOption.map((healthProfileOptionData) => (
                          <tr
                            key={healthProfileOptionData.id}
                            style={{
                              fontSize: 12,
                              whiteSpace: 'break-spaces',
                            }}
                          >
                            <td>
                              <Nav.Link
                                onClick={() =>
                                  handleCreateEditHealthProfileOptionWindow(
                                    healthProfileOptionData,
                                  )
                                }
                              >
                                <span title={healthProfileOptionData.label}>
                                  {healthProfileOptionData.label}
                                </span>
                              </Nav.Link>
                            </td>
                            <td>
                              <Form.Control
                                className="hide-spinner"
                                size="sm"
                                type="number"
                                style={{
                                  fontSize: 12,
                                  whiteSpace: 'break-spaces',
                                  textAlign: 'center',
                                }}
                                placeholder={
                                  healthProfileOptionData.order ? '' : '-'
                                }
                                defaultValue={visualizeOrder({
                                  data: healthProfileOptionData,
                                  type: 'healthProfileOption',
                                })}
                                onBlur={(e) =>
                                  changeOrderPatchInfo({
                                    changedValue: e.target.value,
                                    id: healthProfileOptionData.id,
                                    type: 'healthProfileOption',
                                  })
                                }
                              />
                            </td>
                          </tr>
                        ))}
                    </>
                  )}
                </tbody>
              </Table>
            </div>
          </Form.Group>
        </Col>
      </>
    );
  };

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="healthProfile" />
        <Layout className="right-layout">
          <TitleBreadcrumb title="정보" subTitle="설문" className="white-bg" />
          <Content className="site-layout-background contentStyle">
            {loading ? (
              <div className="loading">
                <Spinner style={{ margin: 'auto' }} animation="border" />
              </div>
            ) : (
              <div>
                <div style={{ float: 'left', display: 'flex' }}>
                  <Select
                    allowClear
                    style={{ width: 250, marginRight: 10 }}
                    size="sm"
                    placeholder="액셀 추출할 설문 항목 선택"
                    onChange={(_, data) => {
                      setSelectHealthProfileId(data?.id);
                    }}
                  >
                    {healthProfileInfo &&
                      healthProfileInfo.map((healthProfileData) => (
                        <Select.Option
                          id={healthProfileData?.id}
                          key={healthProfileData?.id}
                          value={healthProfileData?.id}
                        >
                          {healthProfileData?.type}
                        </Select.Option>
                      ))}
                  </Select>
                  <Button
                    variant="primary"
                    size="sm"
                    style={{ marginRight: 10 }}
                    onClick={excelRequest(selectHealthProfileId)}
                    disabled={!selectHealthProfileId}
                  >
                    설문 다운
                  </Button>
                </div>
                <ButtonToolbar className="justify-content-end distribute">
                  <ButtonGroup>
                    <Button
                      className="distributeButton"
                      size="sm"
                      variant={orderPatchInfo.length > 0 ? 'primary' : 'dark'}
                      onClick={() =>
                        orderPatchInfo.length > 0
                          ? checkBeforeSetOrder()
                          : alert('배포할 변경사항이 없습니다.')
                      }
                    >
                      순서 확정
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
                <div className="divider" />
                <Tabs
                  defaultActiveKey={key}
                  onChange={(e) => {
                    setKey(e);
                    setSelectedHealthProfileId('');
                    setSelectedHealthProfileQuestionId('');
                    setHealthProfileQuestion([]);
                    setHealthProfileOption([]);
                  }}
                  className="userDetailTab"
                >
                  <TabPane tab="일반" key="default">
                    <Row>
                      <HealthProfileCol
                        selectHealthProfile={defaultHealthProfile}
                        type="default"
                      />
                      <HealthProfileQuestionCol type="default" />
                      <HealthProfileOptionCol />
                    </Row>
                  </TabPane>
                  <TabPane tab="연구" key="research">
                    <Row>
                      <HealthProfileCol
                        selectHealthProfile={researchHealthProfile}
                        type="research"
                      />
                      <HealthProfileQuestionCol type="research" />
                      <HealthProfileOptionCol />
                    </Row>
                  </TabPane>
                  <TabPane tab="환우회" key="patient">
                    <Row>
                      <HealthProfileCol
                        selectHealthProfile={patientHealthProfile}
                        type="patient"
                      />
                      <HealthProfileQuestionCol type="patient" />
                      <HealthProfileOptionCol />
                    </Row>
                  </TabPane>
                  <TabPane tab="앱 콘텐츠" key="request">
                    <Row>
                      <HealthProfileCol
                        selectHealthProfile={requestHealthProfile}
                        type="request"
                      />
                      <HealthProfileQuestionCol type="request" />
                      <HealthProfileOptionCol />
                    </Row>
                  </TabPane>
                </Tabs>
              </div>
            )}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default HealthProfile;
