import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

// NOTE(reo): 태그 타입에 대한 기획이 확정되면 태그 타입을 유동적으로 변경 가능해야 할 수도 있다
export const TOPIC_TAG_TYPE = 'contentTopic';

/**
 * 주제별 태그 목록을 가져옵니다.
 */
export async function fetchTopicTags() {
  const res = await axios.get(`/v1/admin/tags?type=${TOPIC_TAG_TYPE}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 모든 태그를 가져옵니다.
 * @deprecated: fetchTopicTags와 완전히 같은 역할을 함
 */
export const fetchAllTags = fetchTopicTags;

/**
 * 구버전 태그 목록을 가져옵니다.
 * @deprecated: 레어노트 콘텐츠의 질환 태그를 위해서만 사용
 */
export async function fetchLegacyTags() {
  const res = await axios.get(`/v1/admin/tags`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 주제별 태그를 생성합니다.
 * @param {{name: string, type?: string}} body
 */
export async function postTopicTag(body) {
  const res = await axios.post(`/v1/admin/tags`, {
    type: TOPIC_TAG_TYPE, // by default
    ...body,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 주제별 태그의 정보를 변경합니다.
 * @param {string} tagId
 * @param {{name: string, type?: string}} body
 */
export async function patchTopicTag(tagId, body) {
  const res = await axios.patch(`/v1/admin/tags/${tagId}`, {
    type: TOPIC_TAG_TYPE, // by default
    ...body,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 주제별 태그의 정보를 삭제합니다.
 * @param {string} tagId
 */
export async function deleteTopicTag(tagId) {
  const res = await axios.delete(`/v1/admin/tags/${tagId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
