export const tinymceInit = {
  inline_styles: true,
  height: 500,
  menubar: false,
  paste_data_images: true,
  content_style:
    "@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap'); body { font-family: 'Noto Sans KR', roboto, arial, sans-serif !important;} a {color: #0089a3 !important; text-decoration: underline;} u {color: #0089a3 !important; text-decoration: underline;}",
  formats: {
    underline: {
      inline: 'u',
      exact: true,
    },
    img: {
      block: 'div',
      classes: 'editor-common editor-img',
      attributes: { class: 'editor-common editor-img' },
      styles: {
        fontFamily: 'Noto Sans KR',
        fontWeight: '500',
        fontSize: '2.6rem',
        fontStyle: 'normal',
        lineHeight: '3.5rem',
        letterSpacing: '-0.6px',
        color: '#04070b',
        marginTop: '24px',
        marginBottom: '0px',
      },
    },
    heading1: {
      block: 'div',
      classes: 'editor-common editor-heading-1',
      attributes: { class: 'editor-common editor-heading-1' },
      styles: {
        fontFamily: 'Noto Sans KR',
        fontWeight: '700',
        fontSize: '2.2rem',
        fontStyle: 'normal',
        lineHeight: '3rem',
        letterSpacing: '-0.41px',
        color: '#04070b',
        marginTop: '36px',
        marginBottom: '0px',
        textAlign: 'left',
      },
    },
    heading2: {
      block: 'div',
      classes: 'editor-common editor-heading-2',
      attributes: { class: 'editor-common editor-heading-2' },
      styles: {
        fontFamily: 'Noto Sans KR',
        fontWeight: '500',
        fontSize: '2rem',
        fontStyle: 'normal',
        lineHeight: '2.7rem',
        letterSpacing: '-0.5px',
        color: '#04070b',
        marginTop: '24px',
        marginBottom: '0px',
        textAlign: 'left',
      },
    },
    heading3: {
      block: 'div',
      classes: 'editor-common editor-heading-3',
      attributes: { class: 'editor-common editor-heading-3' },
      styles: {
        fontFamily: 'Noto Sans KR',
        fontWeight: '500',
        fontSize: '1.8rem',
        fontStyle: 'normal',
        lineHeight: '2.7rem',
        letterSpacing: '-0.3px',
        color: '#04070b',
        marginTop: '12px',
        marginBottom: '0px',
        textAlign: 'left',
      },
    },
    heading4: {
      block: 'div',
      classes: 'editor-common editor-heading-4',
      attributes: { class: 'editor-common editor-heading-4' },
      styles: {
        fontFamily: 'Noto Sans KR',
        fontWeight: '500',
        fontSize: '1.6rem',
        fontStyle: 'normal',
        lineHeight: '2.2rem',
        letterSpacing: '-0.3px',
        color: '#04070b',
        marginTop: '12px',
        marginBottom: '0px',
        textAlign: 'left',
      },
    },
    heading5: {
      block: 'div',
      classes: 'editor-common editor-heading-5',
      attributes: { class: 'editor-common editor-heading-5' },
      styles: {
        fontFamily: 'Noto Sans KR',
        fontWeight: '500',
        fontSize: '1.4rem',
        fontStyle: 'normal',
        lineHeight: '2rem',
        letterSpacing: '-0.3px',
        color: '#04070b',
        marginTop: '12px',
        marginBottom: '0px',
        textAlign: 'left',
      },
    },
    paragraph: {
      block: 'div',
      classes: 'editor-common editor-paragraph',
      attributes: { class: 'editor-common editor-paragraph' },
      styles: {
        fontFamily: 'Noto Sans KR',
        fontWeight: '400',
        fontSize: '1.6rem',
        fontStyle: 'normal',
        lineHeight: '2.7rem',
        letterSpacing: '-0.3px',
        color: 'rgba(4, 7, 11, 0.87)',
        marginTop: '24px',
        marginBottom: '0px',
        textAlign: 'left',
      },
    },
    blockquote: {
      block: 'blockquote',
      classes: 'editor-common editor-blockquote',
      attributes: { class: 'editor-common editor-blockquote' },
      styles: {
        fontFamily: 'Noto Sans KR',
        fontWeight: '400',
        fontSize: '1.6rem',
        fontStyle: 'normal',
        lineHeight: '2.7rem',
        letterSpacing: '-0.3px',
        color: 'rgba(4, 7, 11, 0.54)',
        marginTop: '24px',
        marginBottom: '0px',
      },
    },
    superscript: {
      inline: 'sup',
      style: {
        fontFamily: 'Noto Sans KR',
        fontWeight: '400',
        fontSize: '1.2rem',
        color: 'rgba(4, 7, 11, 0.87)',
        verticalAlign: 'text-top',
      },
    },
    subscript: {
      inline: 'sub',
      style: {
        fontFamily: 'Noto Sans KR',
        fontWeight: '400',
        fontSize: '1.2rem',
        color: 'rgba(4, 7, 11, 0.87)',
        verticalAlign: 'baseline',
      },
    },
    small: {
      block: 'div',
      classes: 'editor-common editor-small',
      attributes: { class: 'editor-common editor-small' },
      styles: {
        fontFamily: 'Noto Sans KR',
        fontWeight: '400',
        fontSize: '1.4rem',
        fontStyle: 'normal',
        lineHeight: '2rem',
        letterSpacing: '-0.31px',
        color: 'rgba(9, 15, 25, 0.54)',
        marginTop: '8px',
        marginBottom: '0px',
      },
    },
    rarenote1: {
      block: 'p',
      classes: 'editor-common editor-title-1',
      attributes: { class: 'editor-common editor-title-1' },
    },
    rarenote2: {
      block: 'p',
      classes: 'editor-common editor-title-2',
      attributes: { class: 'editor-common editor-title-2' },
    },
    rarenote3: {
      block: 'p',
      classes: 'editor-common editor-title-3',
      attributes: { class: 'editor-common editor-title-3' },
    },
    rarenote4: {
      block: 'p',
      classes: 'editor-common editor-content',
      attributes: { class: 'editor-common editor-content' },
    },
    rarenote5: {
      block: 'p',
      classes: 'editor-common editor-etc',
      attributes: { class: 'editor-common editor-etc' },
    },
  },
  style_formats: [
    { title: 'image', format: 'img' },
    { title: 'Heading 1', format: 'heading1' },
    { title: 'Heading 2', format: 'heading2' },
    { title: 'Heading 3', format: 'heading3' },
    { title: 'Heading 4', format: 'heading4' },
    { title: 'Heading 5', format: 'heading5' },
    { title: 'Rareparagraph', format: 'paragraph' },
    { title: 'Blockquote', format: 'blockquote' },
    { title: 'Small', format: 'small' },
    {
      title: 'Unused',
      items: [
        { title: 'Rarenote Title 1', format: 'rarenote1' },
        { title: 'Rarenote Title 2', format: 'rarenote2' },
        { title: 'Rarenote Title 3', format: 'rarenote3' },
        { title: 'Rarenote content', format: 'rarenote4' },
        { title: 'Rarenote etc', format: 'rarenote5' },
      ],
    },
  ],
  plugins: ['link searchreplace code image paste fullscreen autoresize'],
  toolbar:
    'undo redo | styleselect | link bold italic underline strikethrough subscript superscript | image code | fullscreen | searchreplace | alignleft aligncenter alignright alignjustify | fontselect fontsizeselect | powerpaste',
};
