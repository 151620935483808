import { all, takeLatest, call } from 'redux-saga/effects';
import { noticeCreators, noticeActions } from '../reducers/notice.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  fetchAllNotice,
  fetchNotice,
  postNotice,
  patchNotice,
  deleteNotice,
  patchDistributeNotice,
} from '../../services/noticeService';

const handleFetchAllNotices = createAsyncSaga(
  noticeCreators.fetchNotices,
  fetchAllNotice,
);

const handleFetchNotice = createAsyncSaga(
  noticeCreators.fetchNotice,
  fetchNotice,
);

const handlePostNotice = createAsyncSaga(noticeCreators.postNotice, postNotice);

const handlePatchhNotice = createAsyncSaga(
  noticeCreators.patchNotice,
  patchNotice,
);

const handleDeleteNotice = createAsyncSaga(
  noticeCreators.deleteNotice,
  deleteNotice,
);

function* handlePatchDistrubuteNotices({ payload }) {
  try {
    yield call(patchDistributeNotice, payload);
  } catch (err) {
    console.log(err);
  }
}
export function* noticeSaga() {
  yield all([
    takeLatest(noticeActions.FETCH_NOTICES, handleFetchAllNotices),
    takeLatest(noticeActions.FETCH_NOTICE, handleFetchNotice),
    takeLatest(noticeActions.POST_NOTICE, handlePostNotice),
    takeLatest(noticeActions.PATCH_NOTICE, handlePatchhNotice),
    takeLatest(noticeActions.DELETE_NOTICE, handleDeleteNotice),
    takeLatest(
      noticeActions.PATCH_DISTRIBUTE_NOTICES,
      handlePatchDistrubuteNotices,
    ),
  ]);
}
