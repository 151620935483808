import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Divider,
  Skeleton,
} from 'antd';
import 'antd/dist/antd.css';
import * as listMap from '../../../util/listMap';
import DisabledInput from '../../components/disabledInput';
import { useMutate, useFetch } from '../../../hooks/useRequest';
import {
  fetchGeneResearchContent as fetchGeneResearchContentService,
  patchGeneResearchContent as patchGeneResearchContentService,
} from '../../../services/geneResearchService';

const GeneResearchHistory = (userId) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [patchGeneResearchId, setPatchGeneResearchId] = useState(null);
  const [geneResearchMemo, setGeneResearchMemo] = useState({});
  const geneResearchMemoDone = useRef(false);
  const userDataFetchDone = useRef(false);

  const {
    done: fetchGeneResearchContentDone,
    data: fetchGeneResearchContentData,
    call: fetchGeneResearchContent,
  } = useFetch(null, fetchGeneResearchContentService, userId?.userId);

  useEffect(() => {
    if (!fetchGeneResearchContentDone && userId && !userDataFetchDone.current) {
      fetchGeneResearchContent();
      userDataFetchDone.current = true;
    }
  }, [fetchGeneResearchContentDone, userId, userDataFetchDone]);

  useEffect(() => {
    if (fetchGeneResearchContentData && !geneResearchMemoDone.current) {
      const geneResearchMemoList = {};
      fetchGeneResearchContentData.forEach((geneResearchHistory) => {
        geneResearchMemoList[geneResearchHistory.id] = geneResearchHistory.memo;
      });
      setGeneResearchMemo(geneResearchMemoList);
      geneResearchMemoDone.current = true;
    }
  }, [fetchGeneResearchContentData]);

  const {
    mutate: patchGeneResearchContent,
    done: isGeneResearchPatched,
    initialize: initializeGeneResearchPatch,
  } = useMutate(patchGeneResearchContentService, patchGeneResearchId, {
    memo: geneResearchMemo[patchGeneResearchId],
  });

  useEffect(() => {
    if (patchGeneResearchId) {
      geneResearchMemoDone.current = false;
      patchGeneResearchContent();
      setPatchGeneResearchId(null);
    }
  }, [patchGeneResearchId, patchGeneResearchContent]);

  useEffect(() => {
    if (isGeneResearchPatched) {
      fetchGeneResearchContent();
      initializeGeneResearchPatch();
      notification.success({
        message: '수정이 완료되었습니다.',
        description: '수정이 완료되었습니다.',
      });
    }
  }, [
    isGeneResearchPatched,
    fetchGeneResearchContent,
    initializeGeneResearchPatch,
  ]);

  if (!userDataFetchDone.current) return <Skeleton active />;
  return (
    <>
      {fetchGeneResearchContentData?.length > 0 ? (
        <>
          {fetchGeneResearchContentData?.map((geneResearchContent) => (
            <>
              <Form name="basic" form={form} {...listMap.layout}>
                <Row span={24}>
                  <Col span={12}>
                    <DisabledInput
                      label="상태"
                      type="status"
                      value={listMap.statusMap[geneResearchContent.status]}
                    />
                    <DisabledInput
                      label="유입경로"
                      type="funnel"
                      value={geneResearchContent.funnel}
                    />
                    <DisabledInput
                      label="동의"
                      type="agreements"
                      value={listMap.agreeMap[geneResearchContent.agreements]}
                    />
                  </Col>
                  <Col span={12}>
                    <DisabledInput
                      label="신청일"
                      type="updatedAt"
                      value={geneResearchContent.updatedAt}
                    />
                    <DisabledInput
                      label="유전자검사"
                      type="diseaseType"
                      value={geneResearchContent.dnaTest.join(', ')}
                    />
                    <DisabledInput
                      label="건강설문"
                      type="healthProfiles"
                      value={
                        listMap.agreeMap[geneResearchContent.healthProfiles]
                      }
                    />
                  </Col>
                </Row>
                <Row span={24}>
                  <Col span={24}>
                    <Form.Item
                      label="메모"
                      labelCol={listMap.largeLayout.labelCol}
                      wrapperCol={listMap.largeLayout.wrapperCol}
                    >
                      <TextArea
                        rows={4}
                        value={geneResearchMemo[geneResearchContent.id]}
                        onChange={(e) =>
                          setGeneResearchMemo({
                            ...geneResearchMemo,
                            [geneResearchContent.id]: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      wrapperCol={{
                        offset: 4,
                        span: 20,
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: 100 }}
                        onClick={() =>
                          setPatchGeneResearchId(geneResearchContent.id)
                        }
                      >
                        저장
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Divider plain />
            </>
          ))}
        </>
      ) : (
        <div style={{ marginLeft: 10, color: 'red' }}>
          연구신청 이력이 없습니다.
        </div>
      )}
    </>
  );
};

export default GeneResearchHistory;
