import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

const initialState = {
  role: getAsyncState.initial([]),
};

export const roleActions = {
  FETCH_ALL_ROLE: '@role/FETCH_ALL_ROLE',
};

export const roleCreators = {
  fetchAllRoles: createAsyncActions(roleActions, 'FETCH_ALL_ROLE'),
};

const roleReducer = handleActions(
  {
    ...handleAsyncActions(roleActions.FETCH_ALL_ROLE, 'role'),
  },
  initialState,
);

export default roleReducer;
