import { all, takeLatest } from 'redux-saga/effects';
import {
  doctorProfileCreators,
  doctorProfileActions,
} from '../reducers/doctorProfile.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchAllDoctorProfiles } from '../../services/doctorProfileService';

const handleFetchAllDoctorProfiles = createAsyncSaga(
  doctorProfileCreators.fetchAllDoctorProfiles,
  fetchAllDoctorProfiles,
);

export function* doctorProfileSaga() {
  yield all([
    takeLatest(
      doctorProfileActions.FETCH_ALL_DOCTORPROFILES,
      handleFetchAllDoctorProfiles,
    ),
  ]);
}
