import axios from 'axios';
import { HTTP_SUCCESS, SUCCESS } from './utils';

/**
 * 모든 번역 대치어를 가져옵니다.
 */
export async function fetchAllTranslateWords() {
  const res = await axios.get('/v1/admin/translate-words');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 번역 대치어를 생성합니다.
 * @param {{krWord?: string, enWord?: string}} body
 */
export async function postTranslateWord(body) {
  const res = await axios.post(`/v1/admin/translate-words`, {
    ...body,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 번역 대치어의 정보를 변경합니다.
 * @param {string} translateWordId
 * @param {{krWord?: string, enWord?: string}} body
 */
export async function patchTranslateWord(translateWordId, body) {
  const res = await axios.patch(
    `/v1/admin/translate-words/${translateWordId}`,
    {
      ...body,
    },
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 번역 대치어의 정보를 삭제합니다.
 * @param {string} translateWordId
 */
export async function deleteTranslateWord(translateWordId) {
  const res = await axios.delete(
    `/v1/admin/translate-words/${translateWordId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
