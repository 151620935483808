import { all, takeLatest } from 'redux-saga/effects';
import {
  clinicalTrialCounselingCreators,
  clinicalTrialCounselingActions,
} from '../reducers/clinicalTrialCounseling.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  fetchAllClinicalTrialCounselingRequest,
  fetchAllClinicalTrialCounselingSetting,
} from '../../services/clinicalTrialCounselingService';

const handleFetchAllClinicalTrialCounselings = createAsyncSaga(
  clinicalTrialCounselingCreators.fetchAllClinicalTrialCounselingRequests,
  fetchAllClinicalTrialCounselingRequest,
);

const handleFetchAllClinicalTrialCounselingSetting = createAsyncSaga(
  clinicalTrialCounselingCreators.fetchAllClinicalTrialCounselingSetting,
  fetchAllClinicalTrialCounselingSetting,
);

export function* clinicalTrialCounselingSaga() {
  yield all([
    takeLatest(
      clinicalTrialCounselingActions.FETCH_ALL_CLINICALTRIAL_COUNSELING,
      handleFetchAllClinicalTrialCounselings,
    ),
    takeLatest(
      clinicalTrialCounselingActions.FETCH_ALL_CLINICALTRIAL_COUNSELING_SETTING,
      handleFetchAllClinicalTrialCounselingSetting,
    ),
  ]);
}
