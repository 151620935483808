import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 변이 유전자를 가져옵니다.
 */
export async function fetchAllMutationDnas() {
  const res = await axios.get('/v3/admin/mutationDnas');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 변이 유전자를 가져옵니다.
 * @param {String} mutationDnaId
 */
export async function fetchMutationDna(mutationDnaId) {
  const res = await axios.get(`/v3/admin/mutationDnas/${mutationDnaId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 변이 유전자를 생성합니다.
 * @param {object} body
 */
export async function postMutationDna(body) {
  const res = await axios.post(`/v3/admin/mutationDnas`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 변이 유전자를 수정합니다.
 * @param {String} mutationDnaId
 * @param {object} body
 */
export async function patchMutationDna(mutationDnaId, body) {
  const res = await axios.patch(
    `/v3/admin/mutationDnas/${mutationDnaId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 변이 유전자를 삭제합니다.
 * @param {String} mutationDnaId
 */
export async function deleteMutationDna(mutationDnaId) {
  const res = await axios.delete(`/v3/admin/mutationDnas/${mutationDnaId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
