import { useCallback, useState } from 'react';
import { handleError } from './useRequest';

const setLoading = (prevState) => ({ ...prevState, loading: true });
const resetLoading = (prevState) => ({ ...prevState, loading: false });

export const convertToQuery = ({ current, pageSize }) =>
  `limit=${pageSize}${--current ? `&offset=${current * pageSize}` : ''}`;

export const userConvertToQuery = ({ filters, sorters }) => {
  const queryParts = [];

  const pushFilter = (key, value) => {
    if (value?.[0]) {
      queryParts.push(`&${key}=${value[0]}`);
    }
  };

  const pushBooleanFilter = (key, value) => {
    if (value?.[0] === true && value?.[1] !== false) {
      queryParts.push(`&${key}=true`);
    } else if (value?.[0] === false) {
      queryParts.push(`&${key}=false`);
    }
  };

  const filterKeys = ['email', 'name', 'nickname', 'phone', 'gender'];
  const booleanFilterKeys = [
    'isInfluencer',
    'isCertified',
    'isIdentified',
    'agreementMarketing',
    'isTest',
  ];

  filterKeys.forEach((key) => {
    pushFilter(key, filters?.[key]);
  });

  booleanFilterKeys.forEach((key) => {
    pushBooleanFilter(key, filters?.[key]);
  });

  if (filters?.relationship?.[0]) {
    const relationship = filters.relationship?.join(', ');
    queryParts.push(`&relationship=${relationship}`);
  }
  if (filters?.diseaseType?.[0]) {
    const diseaseType = filters.diseaseType?.join(', ');
    queryParts.push(`&diseaseTypeIds=${diseaseType}`);
  }
  if (filters?.socialAccounts?.[0]) {
    const social = filters.socialAccounts?.join(', ');
    queryParts.push(`&social=${social}`);
  }
  if (filters?.createdAt?.[0]) {
    const startAt = filters.createdAt[0];
    const endAt = filters.createdAt[1];
    queryParts.push(`&startAt=${startAt}&endAt=${endAt}`);
  }

  if (sorters?.order) {
    queryParts.push(
      `&orders=${sorters?.order === 'ascend' ? '' : '-'}${sorters?.field}`,
    );
  }

  return queryParts.join('');
};

export function usePagination({
  initialApiParams = [],
  initialPageState = {
    current: 1,
    pageSize: 20,
    showSizeChanger: true,
    total: 0,
    count: 0,
    pageSizeOptions: [10, 20, 50, 100, 1000, 10000, 100000],
  },
  initialType = true,
  call,
}) {
  const [{ data, loading, apiParams }, setApiState] = useState({
    data: [],
    loading: true,
    apiParams: initialApiParams,
  });

  const [pageState, setPageState] = useState(initialPageState);

  const handleCall = useCallback(
    (targetParams, targetPage) => {
      call(targetPage, ...targetParams)
        .then(({ rows, users, total, count }) => {
          targetPage.total = count
            ? Number.parseInt(count, 10)
            : total
            ? Number.parseInt(total, 10)
            : 0;
          setPageState(targetPage);
          setApiState({
            data: initialType ? rows : users,
            loading: false,
            apiParams: targetParams,
          });
        })
        .catch((err) => {
          handleError(err);
          setApiState(resetLoading);
        });
    },
    [call, setPageState, setApiState],
  );

  const initialize = useCallback(
    () => handleCall(initialApiParams, initialPageState),
    [handleCall, initialApiParams, initialPageState],
  );

  const setApiParams = useCallback(
    (newParams, newPageState = { ...initialPageState, current: 1 }) => {
      setApiState(setLoading);
      handleCall(newParams, newPageState);
    },
    [initialPageState, handleCall],
  );

  const goToPage = useCallback(
    (current, pageSize) => {
      if (pageSize === pageState.pageSize) {
        if (current === pageState.current) return;
      } else
        current =
          Math.floor(
            ((pageState.current - 1) * pageState.pageSize) / pageSize,
          ) + 1;

      setApiState(setLoading);
      handleCall(apiParams, { ...pageState, current, pageSize });
    },
    [apiParams, pageState, handleCall],
  );

  const setData = useCallback(
    (newData) => setApiState((prevState) => ({ ...prevState, data: newData })),
    [setApiState],
  );

  return {
    apiParams,
    setApiParams,
    initialize,
    data,
    setData,
    loading,
    pageState,
    pagination: { ...pageState, onChange: goToPage },
  };
}
