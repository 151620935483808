import { all, takeLatest } from 'redux-saga/effects';
import {
  treatmentStudyCreators,
  treatmentStudyActions,
} from '../reducers/treatmentStudy.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  fetchAllTreatmentStudy,
  fetchAllTreatmentStudyByDiseaseGroup,
} from '../../services/treatmentStudyService';

const handleFetchAllTreatmentStudies = createAsyncSaga(
  treatmentStudyCreators.fetchAllTreatmentStudies,
  fetchAllTreatmentStudy,
);

const handleFetchAllTreatmentStudiesByDiseaseGroup = createAsyncSaga(
  treatmentStudyCreators.fetchAllTreatmentStudiesByDiseaseGroup,
  fetchAllTreatmentStudyByDiseaseGroup,
);

export function* treatmentStudySaga() {
  yield all([
    takeLatest(
      treatmentStudyActions.FETCH_ALL_TREATMENT_STUDIES,
      handleFetchAllTreatmentStudies,
    ),
    takeLatest(
      treatmentStudyActions.FETCH_ALL_TREATMENT_STUDIES_BY_DISEASE_GROUP,
      handleFetchAllTreatmentStudiesByDiseaseGroup,
    ),
  ]);
}
