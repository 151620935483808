import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 유전자를 가져옵니다.
 */
export async function fetchAllDna() {
  const res = await axios.get(`/v3/admin/dna`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유전자 정보를 가져옵니다.
 * @param {String} dnaId
 */
export async function fetchDna(dnaId) {
  const res = await axios.get(`/v3/admin/dna/${dnaId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유전자를 생성합니다.
 * @param {String} dnaId
 * @param {object} body
 */
export async function postDna(body) {
  const res = await axios.post(`/v3/admin/dna`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유전자를 수정합니다.
 * @param {String} dnaId
 * @param {object} body
 */
export async function patchDna(dnaId, body) {
  const res = await axios.patch(`/v3/admin/dna/${dnaId}`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유전자를 삭제합니다.
 * @param {String} dnaId
 * @param {object} body
 */
export async function deleteDna(dnaId, body) {
  const res = await axios.delete(`/v3/admin/dna/${dnaId}`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
