import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Layout, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  DashboardOutlined,
  UserOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  NotificationOutlined,
  AndroidOutlined,
} from '@ant-design/icons';
import * as permissions from '../util/permission';
import PAGE_URL from '../assets/pageUrl';
import 'antd/dist/antd.css';

import { paginationCreators } from '../store/reducers/pagination.reducer';

const SideBar = ({ link, tab }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { permission, tabPage } = useSelector((state) => {
    return {
      permission: state.userReducer.permission?.data,
      tabPage: state?.paginationReducer?.tabPage,
    };
  }, shallowEqual);

  const getData = useCallback(() => {
    dispatch(
      paginationCreators.setValue({
        tabPage: [...tabPage],
      }),
    );
  }, [dispatch]);

  useEffect(getData, [getData]);

  const setTab = new Set(tabPage);
  const [openKeys, setOpenKeys] = useState([...setTab]);

  const { Sider } = Layout;
  const { SubMenu } = Menu;

  const sideElementAccount = [
    { name: '유저', link: 'user', permission: permissions.ACCOUNT_USER },
    {
      name: '탈퇴',
      link: 'userWithdrawal',
      permission: permissions.WITHDRAWAL_USER,
    },
    {
      name: '관리자 설정',
      link: 'admin',
      permission: permissions.ACCOUNT_ADMIN,
    },
  ];

  const sideElementData = [
    {
      name: 'PAP',
      link: PAGE_URL.PAP,
      permission: permissions.PAP,
    },
    {
      name: '등록_유전자 검사',
      link: PAGE_URL.MUTATION_DNA_REPORT_REGISTER_REQUEST,
      permission: permissions.DNA_REPORT,
    },
    {
      name: '등록_병원 검사',
      link: PAGE_URL.HOSPITALEXAMINATION_RECORDS_REQUEST,
      permission: permissions.DNA_REPORT,
    },
    {
      name: '등록_질환 인증',
      link: PAGE_URL.DISEASETYPECERTIFICATION_REQUEST,
      permission: permissions.DNA_REPORT,
    },
    {
      name: '카이안과 연구 신청',
      link: PAGE_URL.GENE_RESEARCH,
      permission: permissions.GENE_RESEARCH,
    },
    {
      name: '변이 유전자',
      link: PAGE_URL.MUTATION_DNA,
      permission: permissions.DNA_REPORT,
    },
    {
      name: '유전자 검사 리포트',
      link: PAGE_URL.MUTATION_DNA_REPORT,
      permission: permissions.DNA_REPORT,
    },
  ];

  const sideElementContent = [
    {
      name: '레어노트 콘텐츠',
      link: PAGE_URL.CONTENTS,
      permission: permissions.CONTENT_TREATMENT_NEWS,
    },
    {
      name: '글로벌 뉴스',
      link: PAGE_URL.RSS_FEED,
      permission: permissions.CONTENT_RSS_FEED,
    },
    {
      name: '질환 정보',
      link: PAGE_URL.DISEASEINFO,
      permission: permissions.CONTENT_TREATMENT_NEWS,
    },
    {
      name: '임상시험 현황',
      link: PAGE_URL.CLINICAL_TRIAL,
      permission: permissions.ADMIN_LOGIN,
    },
    {
      name: '환자 모임 정보',
      link: 'patientGroup',
      permission: permissions.ACCOUNT_PATIENT_GROUP,
    },
    {
      name: '공지/소식',
      link: PAGE_URL.NOTICE,
      permission: permissions.MANAGE_NOTICE,
    },
  ];

  const sideElementSubContent = [
    {
      name: '용어 사전',
      link: PAGE_URL.DICTIONARY,
      permission: permissions.CONTENT_TERMS,
    },
    { name: '유전자', link: 'dna', permission: permissions.CONTENT_DNA },
    {
      name: '병원 검사',
      link: PAGE_URL.HOSPITAL_RECORD,
      permission: permissions.CONTENT_HOSPITAL_EXAMINATION,
    },
    {
      name: '의료진 프로필',
      link: PAGE_URL.DOCTORPROFILE,
      permission: permissions.DOCTORPROFILE,
    },
    {
      name: '주제별 태그',
      link: PAGE_URL.TOPIC_TAG,
      permission: permissions.TOPIC_TAG,
    },
    {
      name: '번역 대치어',
      link: PAGE_URL.TRANSLATE_WORD,
      permission: permissions.TRANSLATE_WORD,
    },
  ];

  const sideElementOperation = [
    {
      name: '질환',
      link: PAGE_URL.DISEASE_TYPE,
      permission: permissions.CONTENT_DISEASE,
    },
    {
      name: '앱 진입 모달',
      link: PAGE_URL.ENTRY_MODAL,
      permission: permissions.ENTRY_MODAL,
    },
    {
      name: '연구 소개',
      link: PAGE_URL.RESEARCH,
      permission: permissions.GENE_RESEARCH,
    },
    {
      name: '설문',
      link: PAGE_URL.HEALTH_PROFILE,
      permission: permissions.CONTENT_HEALTH_PROFILE,
    },
    {
      name: '콘텐츠 알림/푸시',
      link: PAGE_URL.NOTIFICATION,
      permission: permissions.MANAGE_NOTIFICATION,
    },
    {
      name: '메세지 알림/푸시',
      link: PAGE_URL.INDIVIDUAL_NOTIFICATION,
      permission: permissions.MANAGE_INDIVIDUAL_NOTIFICATION,
    },
  ];

  const sideElementDashboard = [
    {
      name: '전체',
      link: PAGE_URL.DASHBOARD,
      permission: permissions.DASHBOARD,
    },
    {
      name: '데이터 내역',
      link: PAGE_URL.DASHBOARD_DATA,
      permission: permissions.ACCOUNT_USER,
    },
  ];

  const sideElementDevelop = [
    {
      name: '강제업데이트',
      link: PAGE_URL.SUPPORT_VERSION,
      permission: permissions.SUPPORT_VERSION,
    },
    {
      name: '리모트 컨피그',
      link: PAGE_URL.REMOTE_CONFIG,
      permission: permissions.MANAGE_REMOTE_CONFIG,
    },
    {
      name: '개인정보 IP',
      link: PAGE_URL.IPALLOW,
      permission: permissions.IP_ALLOW,
    },
  ];

  const isSideListEssential = (list) => {
    return list.some((element) => permission?.includes(element.permission));
  };

  const AntSideList = ({ text, list }) => {
    if (!isSideListEssential(list)) return null;
    return (
      <>
        <Menu.ItemGroup key={text}>
          {list.map((element) =>
            permission?.includes(element.permission) ? (
              <Menu.Item key={element.link}>{element.name}</Menu.Item>
            ) : null,
          )}
        </Menu.ItemGroup>
      </>
    );
  };

  const onOpenChange = (items) => {
    setOpenKeys(items);
    dispatch(
      paginationCreators.setValue({
        tabPage: items,
      }),
    );
  };

  const handleClick = (e) => {
    history.push(`/${e.key}`);
  };

  return (
    <Sider className="siderStyle">
      <Menu
        onClick={handleClick}
        defaultSelectedKeys={[link]}
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
      >
        <SubMenu
          key="tabDashboard"
          icon={<DashboardOutlined />}
          title="대시보드"
        >
          <AntSideList text="대시보드" list={sideElementDashboard} />
        </SubMenu>
        <SubMenu key="tabAccount" icon={<UserOutlined />} title="계정">
          <AntSideList text="계정" list={sideElementAccount} />
        </SubMenu>
        <SubMenu key="tabData" icon={<DatabaseOutlined />} title="데이터">
          <AntSideList text="데이터" list={sideElementData} />
        </SubMenu>
        <SubMenu key="tabContent" icon={<FileTextOutlined />} title="콘텐츠">
          <AntSideList text="콘텐츠" list={sideElementContent} />
        </SubMenu>
        <SubMenu
          key="tabSubContent"
          icon={<FileTextOutlined />}
          title="콘텐츠 보조 요소"
        >
          <AntSideList text="콘텐츠 보조 요소" list={sideElementSubContent} />
        </SubMenu>
        <SubMenu
          key="tabOperation"
          icon={<NotificationOutlined />}
          title="운영"
        >
          <AntSideList text="운영" list={sideElementOperation} />
        </SubMenu>
        <SubMenu key="tabDevelop" icon={<AndroidOutlined />} title="개발">
          <AntSideList text="개발" list={sideElementDevelop} />
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default SideBar;
