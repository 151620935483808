import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { windowActionCreators as windowCreators } from '../store/reducers/window.reducer';

function useWindow() {
  const dispatch = useDispatch();
  const activeWindows = useSelector(
    (state) => state.windowReducer.activeWindows,
  );

  const findWindowById = useCallback(
    (id) => {
      return activeWindows.find((activeWindow) => activeWindow.id === id);
    },
    [activeWindows],
  );

  const createWindow = useCallback(
    (windowData) => {
      dispatch(windowCreators.addWindow(windowData));
    },
    [dispatch],
  );

  const destroyWindowById = useCallback(
    (id) => {
      dispatch(windowCreators.removeWindowById(id));
    },
    [dispatch],
  );

  return {
    activeWindows,
    findWindowById,
    createWindow,
    destroyWindowById,
  };
}

export default useWindow;
