import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Layout, Table, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { dictionaryCreators } from '../../../store/reducers/dictionary.reducer';
import { NEW } from '../../../window/util/utils';
import useWindow from '../../../hooks/useWindow';

const Dictionary = () => {
  const dispatch = useDispatch();
  const { dictionaries } = useSelector(
    (state) => ({
      dictionaries: state.dictionaryReducer.dictionary.data ?? [],
    }),
    shallowEqual,
  );

  const loading = !dictionaries;
  const getData = useCallback(() => {
    dispatch(dictionaryCreators.fetchAllDictionary.request());
  }, [dispatch]);

  const { Content } = Layout;
  const { Search } = Input;
  const [total, setTotal] = useState(null);
  const confirmRef = useRef(() => {});

  useEffect(getData, [getData]);

  useEffect(() => {
    setTotal(dictionaries?.length);
  }, [dictionaries]);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (dictionary) => {
    if (findWindowById(dictionary.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({ id: dictionary.id, dataType: 'dictionary' });
  };

  const handleCreateNewWindow = () => {
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: 'dictionary',
    });
  };

  const columns = [
    {
      title: '단어(한글)',
      dataIndex: 'krName',
      key: 'krName',
      width: '20%',
      sorter: {
        compare: (a, b) => a?.krName?.localeCompare(b?.krName),
      },
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord.toLowerCase()]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      onFilter: (search, { krName, enName, description }) =>
        [krName, enName, description].some((value) =>
          value?.toLowerCase()?.includes(search),
        ),
      render: (text, record) => (
        <a
          style={{ color: '#40a9ff' }}
          onClick={() => handleCreateEditWindow(record)}
        >
          {text}
        </a>
      ),
    },
    {
      title: '단어(영어)',
      dataIndex: 'enName',
      key: 'enName',
      width: '20%',
      sorter: {
        compare: (a, b) => a?.enName?.localeCompare(b?.enName),
      },
    },
    {
      title: '설명',
      dataIndex: 'description',
      key: 'description',
      sorter: {
        compare: (a, b) => a?.description?.localeCompare(b?.description),
      },
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="dictionary" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="정보"
            subTitle="용어 사전"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button
              type="primary"
              style={{ width: 100 }}
              onClick={handleCreateNewWindow}
            >
              추가
            </Button>

            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => confirmRef.current(value)}
            />
            <div className="searchResult">
              {!loading && `검색결과 ${total}개`}
            </div>

            <Table
              columns={columns}
              dataSource={dictionaries}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(_, __, ___, { currentDataSource }) =>
                setTotal(currentDataSource.length)
              }
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Dictionary;
