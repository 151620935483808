import { all, takeLatest } from 'redux-saga/effects';
import { userCreators, userActions } from '../reducers/user.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  fetchAllUser,
  fetchPermission,
  fetchAdminUser,
  fetchUnmaskedInfo,
  fetchAllUserWithdrawal,
} from '../../services/userService';

const handleFetchAllUser = createAsyncSaga(
  userCreators.fetchAllUser,
  fetchAllUser,
);

const handleFetchAllClientUser = createAsyncSaga(
  userCreators.fetchAdminUser,
  fetchAdminUser,
);

const handleFetchAllClientNotUser = createAsyncSaga(
  userCreators.fetchNotAdminUser,
  fetchAdminUser,
);

const handleFetchPermission = createAsyncSaga(
  userCreators.fetchPermission,
  fetchPermission,
);

const handleFetchUnmaskedInfo = createAsyncSaga(
  userCreators.fetchUnmaskedInfo,
  fetchUnmaskedInfo,
);

const handleFetchAllUserWithdrawal = createAsyncSaga(
  userCreators.fetchAllUserWithdrawal,
  fetchAllUserWithdrawal,
);

export function* userSaga() {
  yield all([
    takeLatest(userActions.FETCH_ALL_USER, handleFetchAllUser),
    takeLatest(userActions.FETCH_ADMIN_USER, handleFetchAllClientUser),
    takeLatest(userActions.FETCH_NOT_ADMIN_USER, handleFetchAllClientNotUser),
    takeLatest(userActions.FETCH_PERMISSION, handleFetchPermission),
    takeLatest(userActions.FETCH_UNMASKEDINFO, handleFetchUnmaskedInfo),
    takeLatest(
      userActions.FETCH_ALL_USER_WITHDRAWAL,
      handleFetchAllUserWithdrawal,
    ),
  ]);
}
