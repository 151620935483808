export const PAP_STATUS = {
  ready: '신청 대기',
  process: '진행 중',
  complete: '신청 마감',
};

export const PAP_REQUEST_STATUS = {
  requesting: '신청 중',
  request_complete: '신청 완료',
  request_complete_edit: '신청 완료 후 수정',
  examining: '심사 중',
  document_missing: '서류 보완 필요',
  document_complete: '서류 보완 완료',
  eligible: '심사 완료 (환급 가능)',
  ineligible: '심사 완료 (환급 불가)',
  account_missing: '계좌 보완 필요',
  account_complete: '계좌 보완 완료',
  refund_complete: '환급 완료',
};
