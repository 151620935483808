import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 치료제를 가져옵니다.
 */
export async function fetchAllTreatmentStudy() {
  const res = await axios.get(`/v3/admin/treatmentStudies`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 질환 그룹에 속한 치료제들을 가져옵니다.
 * @param {Sring} diseaseGroupId
 */
export async function fetchAllTreatmentStudyByDiseaseGroup(diseaseGroupId) {
  const res = await axios.get(
    `/v3/admin/diseaseGroups/${diseaseGroupId}/treatmentStudies`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 치료제의 배포 여부를 변경합니다.
 * @param {array} ids
 * @param {array} changes
 */
export async function patchDeployTreatmentStudy({ ids, changes }) {
  const res = await axios.patch(`/v3/admin/treatmentStudies`, { ids, changes });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 치료제를 가져옵니다.
 * @param {String} treatmentStudyId
 */
export async function fetchTreatmentStudy(treatmentStudyId) {
  const res = await axios.get(`/v3/admin/treatmentStudies/${treatmentStudyId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 치료제를 생성합니다.
 * @param {String} diseaseGroupId
 * @param {object} body
 */
export async function postTreatmentStudy(diseaseGroupId, body) {
  const res = await axios.post(
    `/v3/admin/diseaseGroups/${diseaseGroupId}/treatmentStudies`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 치료제를 수정합니다.
 * @param {String} treatmentStudyId
 * @param {object} body
 */
export async function patchTreatmentStudy(treatmentStudyId, body) {
  const res = await axios.patch(
    `/v3/admin/treatmentStudies/${treatmentStudyId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 치료제를 삭제합니다.
 * @param {String} treatmentStudyId
 */
export async function deleteTreatmentStudy(treatmentStudyId) {
  const res = await axios.delete(
    `/v3/admin/treatmentStudies/${treatmentStudyId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
