import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 변이 유전자 리포트를 가져옵니다.
 */
export async function fetchAllMutationDnaReports() {
  const res = await axios.get('/v3/admin/mutationDnaReports');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 변이 유전자 리포트를 가져옵니다.
 * @param {String} mutationDnaReportId
 */
export async function fetchMutationDnaReport(mutationDnaReportId) {
  const res = await axios.get(
    `/v3/admin/mutationDnaReports/${mutationDnaReportId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 변이 유전자 리포트를 생성합니다.
 * @param {object} body
 */
export async function postMutationDnaReport(body) {
  const res = await axios.post(`/v3/admin/mutationDnaReports`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 변이 유전자 리포트를 수정합니다.
 * @param {String} mutationDnaReportId
 * @param {object} body
 */
export async function patchMutationDnaReport(mutationDnaReportId, body) {
  const res = await axios.patch(
    `/v3/admin/mutationDnaReports/${mutationDnaReportId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 변이 유전자 리포트를 삭제합니다.
 * @param {String} mutationDnaReportId
 */
export async function deleteMutationDnaReport(mutationDnaReportId) {
  const res = await axios.delete(
    `/v3/admin/mutationDnaReports/${mutationDnaReportId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 변이 유전자 리포트 연결 여부를 확인합니다.
 * @param {String} name
 * @param {Date} birthday
 * @param {String} phone
 */
export async function checkMutationDnaReportConnection({
  name,
  birthday,
  phone,
}) {
  const res = await axios.get(
    `/v3/admin/mutationDnaReportCheck?&name=${name}&birthday=${birthday}&phone=${phone}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
