import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  dashboardUser: getAsyncState.initial(null),
  dashboardKey: getAsyncState.initial(null),
  dashboardDevice: getAsyncState.initial(null),
  dashboardContent: getAsyncState.initial(null),
  dashboardFunnel: getAsyncState.initial(null),
  dashboardUserData: getAsyncState.initial(null),
  dashboardUserCountByDisease: getAsyncState.initial(null),
};

// action types
export const dashboardActions = {
  FETCH_DASHBOARD_USERS: '@dashboard/FETCH_DASHBOARD_USERS',
  FETCH_DASHBOARD_KEYS: '@dashboard/FETCH_DASHBOARD_KEYS',
  FETCH_DASHBOARD_DEVICES: '@dashboard/FETCH_DASHBOARD_DEVICES',
  FETCH_DASHBOARD_CONTENTS: '@dashboard/FETCH_DASHBOARD_CONTENTS',
  FETCH_DASHBOARD_POINTS: '@dashboard/FETCH_DASHBOARD_POINTS',
  FETCH_DASHBOARD_FUNNELS: '@dashboard/FETCH_DASHBOARD_FUNNELS',
  FETCH_DASHBOARD_USER_DATA: '@dashboard/FETCH_DASHBOARD_USER_DATA',
  FETCH_DASHBOARD_USER_COUNT_BY_DISEASES:
    '@dashboard/FETCH_DASHBOARD_USER_COUNT_BY_DISEASES',
};

// actions
export const dashboardCreators = {
  fetchDashboardUsers: createAsyncActions(
    dashboardActions,
    'FETCH_DASHBOARD_USERS',
  ),
  fetchDashboardKeys: createAsyncActions(
    dashboardActions,
    'FETCH_DASHBOARD_KEYS',
  ),
  fetchDashboardDevices: createAsyncActions(
    dashboardActions,
    'FETCH_DASHBOARD_DEVICES',
  ),
  fetchDashboardFunnels: createAsyncActions(
    dashboardActions,
    'FETCH_DASHBOARD_FUNNELS',
  ),
  fetchDashboardUserData: createAsyncActions(
    dashboardActions,
    'FETCH_DASHBOARD_USER_DATA',
  ),
  fetchDashboardUserCountByDiseases: createAsyncActions(
    dashboardActions,
    'FETCH_DASHBOARD_USER_COUNT_BY_DISEASES',
  ),
};

const dashboardReducer = handleActions(
  {
    ...handleAsyncActions(
      dashboardActions.FETCH_DASHBOARD_USERS,
      'dashboardUsers',
    ),
    ...handleAsyncActions(
      dashboardActions.FETCH_DASHBOARD_KEYS,
      'dashboardKeys',
    ),
    ...handleAsyncActions(
      dashboardActions.FETCH_DASHBOARD_DEVICES,
      'dashboardDevices',
    ),
    ...handleAsyncActions(
      dashboardActions.FETCH_DASHBOARD_FUNNELS,
      'dashboardFunnels',
    ),
    ...handleAsyncActions(
      dashboardActions.FETCH_DASHBOARD_USER_DATA,
      'dashboardUserData',
    ),
    ...handleAsyncActions(
      dashboardActions.FETCH_DASHBOARD_USER_COUNT_BY_DISEASES,
      'dashboardUserCountByDiseases',
    ),
  },
  initialState,
);

export default dashboardReducer;
