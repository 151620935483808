import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 IP데이터를 가져옵니다.
 */
export async function fetchAllIpAllow() {
  const res = await axios.get(`/v4/admin/adminAllowedAddresses`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * Ip를 생성합니다.
 * @param {object} body
 */
export async function postIpAllow(body) {
  const res = await axios.post('/v4/admin/adminAllowedAddresses', body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * Ip를 수정합니다.
 * @param {String} ipAllowId
 * @param {object} body
 */
export async function patchIpAllow(ipAllowId, body) {
  const res = await axios.patch(
    `/v4/admin/adminAllowedAddresses/${ipAllowId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * Ip를 삭제합니다.
 * @param {String} ipAllowId
 */
export async function deleteIpAllow(ipAllowId) {
  const res = await axios.delete(
    `/v4/admin/adminAllowedAddresses/${ipAllowId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
