import React, { useState } from 'react';
import {
  Form,
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
  Button,
} from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react';
import { tinymceInit } from '../../util/tinymceInit';
import {
  getSignedUrl,
  changeAccessRangeToPublic,
} from '../../services/fileUploadService';

const tinymceApiKey = process.env.REACT_APP_TINYMCE_API_KEY || '';
const TinymceInput = ({
  content,
  setContent,
  setUploadUrl,
  onBlur,
  setImageLoading,
  contentLinkData,
  windowId,
  name,
  showLabel = true,
  showApplyContent = true,
  antd = false,
}) => {
  const [editorContents, setEditorContents] = useState(content);
  const images_upload_handler = async (blobInfo, success, failure) => {
    setImageLoading(true);
    const newSignedUrlUploadData = await getSignedUrl({
      params: {
        functionType: 'PUT',
        fileName: blobInfo ? blobInfo.filename() : null,
        contentType: blobInfo ? blobInfo.blob().type : null,
      },
    });
    const data = newSignedUrlUploadData;
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', data.signedUrl);
    xhr.onload = async () => {
      await changeAccessRangeToPublic({
        filePath: newSignedUrlUploadData?.filePath,
      });
      success(newSignedUrlUploadData.publicUrl);
      if (setUploadUrl) setUploadUrl(newSignedUrlUploadData.publicUrl);
      setImageLoading(false);
    };
    xhr.onerror = () => {
      alert('이미지 업로드에 실패했습니다!');
      failure(newSignedUrlUploadData.publicUrl);
      setImageLoading(false);
    };
    xhr.setRequestHeader('Content-Type', blobInfo.blob().type);
    xhr.send(blobInfo.blob());
  };

  const isInsideTag = (tag, string) => {
    return (
      string.indexOf(`</${tag}>`) !== -1 &&
      (string.indexOf(`<${tag}>`) === -1 ||
        string.indexOf(`</${tag}>`) < string.indexOf(`<${tag}>`))
    );
  };

  const isValidPosition = (contents, index) => {
    if (
      index > 3 &&
      contents[index - 1] !== ' ' &&
      contents.slice(index - 3, index) !== '<p>'
    )
      return false;
    return true;
  };

  const autoFill = ({
    list,
    selectedList,
    item,
    listAttribute,
    selectedListAttribute,
  }) => {
    if (
      list.some(
        (listItem) => listItem[listAttribute] === item[selectedListAttribute],
      )
    ) {
      if (!selectedList.some(({ id }) => id === item.id)) {
        selectedList.push(item);
      }
    }
  };

  const updateContentLink = ({
    linkList,
    selectedDictionaryList,
    selectedDiseaseTypeList,
    selectedDnaTypeList,
    contents,
    attribute,
  }) => {
    let editorContent = contents.contentString;
    linkList.forEach((listItem) => {
      const lowerCasedEditorContent = editorContent.toLowerCase();
      const findWord = listItem[attribute].toLowerCase();
      const wordSize = findWord.length;
      const contentSize = editorContent.length;
      let index = lowerCasedEditorContent.indexOf(findWord);
      while (
        index !== -1 &&
        index < contentSize &&
        (isInsideTag('u', editorContent.slice(index)) ||
          isInsideTag(windowId, editorContent.slice(index)) ||
          !isValidPosition(editorContent, index))
      ) {
        if (!isValidPosition(editorContent, index)) {
          index = lowerCasedEditorContent.indexOf(findWord, index + wordSize);
        } else {
          let size = lowerCasedEditorContent.indexOf(`</${windowId}>`, index);
          if (isInsideTag('u', editorContent.slice(index))) {
            size = lowerCasedEditorContent.indexOf('</u>', index);
          }
          index = lowerCasedEditorContent.indexOf(findWord, size + 4);
        }
      }
      if (index !== -1) {
        autoFill({
          list: contentLinkData.dictionaryInfo,
          selectedList: selectedDictionaryList,
          item: listItem,
          listAttribute: 'krName',
          selectedListAttribute: attribute,
        });
        autoFill({
          list: contentLinkData.diseaseTypeInfo,
          selectedList: selectedDiseaseTypeList,
          item: listItem,
          listAttribute: 'krName',
          selectedListAttribute: attribute,
        });
        autoFill({
          list: contentLinkData.dnaInfo,
          selectedList: selectedDnaTypeList,
          item: listItem,
          listAttribute: 'krName',
          selectedListAttribute: attribute,
        });
        const replaceWord = editorContent.slice(
          index,
          index + listItem[attribute].length,
        );
        editorContent =
          editorContent.slice(0, index) +
          editorContent
            .slice(index)
            .replace(replaceWord, `<u>${replaceWord}</u>`);
        editorContent = editorContent.replaceAll(
          replaceWord,
          `<${windowId}>${replaceWord}</${windowId}>`,
        );
      }
    });
    editorContent = editorContent.replaceAll(`<${windowId}>`, '');
    editorContent = editorContent.replaceAll(`</${windowId}>`, '');
    contents.contentString = editorContent;
  };

  const applyContentLink = () => {
    const newContent = { contentString: editorContents };
    const newSelectedDictionaries = [];
    const newSelectedDiseaseTypes = [];
    const newSelectedDnaTypes = [];
    newContent.contentString = newContent.contentString.replaceAll('<u>', '');
    newContent.contentString = newContent.contentString.replaceAll('</u>', '');

    const linkingList = [
      ...contentLinkData.dictionaryInfo,
      ...contentLinkData.diseaseTypeInfo,
      ...contentLinkData.dnaInfo,
    ];

    updateContentLink({
      linkList: linkingList.sort((first, second) =>
        first.krName.length < second.krName.length ? 1 : -1,
      ),
      selectedDictionaryList: newSelectedDictionaries,
      selectedDiseaseTypeList: newSelectedDiseaseTypes,
      selectedDnaTypeList: newSelectedDnaTypes,
      contents: newContent,
      attribute: 'krName',
    });
    if (antd) {
      contentLinkData.setState({
        content: newContent.contentString,
        selectedDictionaries: newSelectedDictionaries?.map(
          (dictionary) => dictionary.krName,
        ),
        selectedDiseaseTypes: newSelectedDiseaseTypes,
        selectedDnaTypes: newSelectedDnaTypes?.map((dna) => dna.krName),
      });
    } else {
      contentLinkData.setState({
        content: newContent.contentString,
        selectedDictionaries: newSelectedDictionaries,
        selectedDiseaseTypes: newSelectedDiseaseTypes,
        selectedDnaTypes: newSelectedDnaTypes,
      });
    }
  };

  return (
    <>
      <Form.Group className="formContent" as={Row}>
        {showLabel && (
          <Form.Label column sm="3" md="2" xl="1">
            내용 <span className="required-tag">*필수</span>
          </Form.Label>
        )}
        <Col
          {...(showLabel
            ? { sm: '9', md: '10', xl: '11' }
            : { sm: '12', md: '12', xl: '12' })}
        >
          <Editor
            value={content}
            apiKey={tinymceApiKey}
            init={{
              ...tinymceInit,
              images_upload_handler,
              setup(editor) {
                if (onBlur) {
                  editor.on('blur', () => {
                    onBlur(editor.getDoc());
                  });
                }
                editor.on('NodeChange', () => {
                  const imageList = editor.getDoc().getElementsByTagName('img');
                  for (let i = 0; i < imageList.length; i += 1) {
                    editor.dom.setAttribs(imageList[i], {
                      width: '100%',
                      height: null,
                      alt: null,
                    });
                  }
                });

                editor.addShortcut('meta+m', 'addShortcut subscript.', () => {
                  editor.execCommand('subscript');
                });
              },
            }}
            onEditorChange={(newContent) => {
              setEditorContents(newContent);
              setContent({ target: { name, value: newContent } });
            }}
          />
        </Col>
      </Form.Group>
      {showApplyContent && contentLinkData && (
        <Form.Group className="formContent" as={Row}>
          <Form.Label column sm="3" md="2" xl="1" />
          <Col sm="9" md="10" xl="11">
            <ButtonToolbar className="justify-content-end">
              <ButtonGroup>
                <Button className="buttons" onClick={applyContentLink}>
                  본문 링크 적용
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Form.Group>
      )}
    </>
  );
};
export default TinymceInput;
