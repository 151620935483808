import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 리모트 컨피그 데이터를 가져옵니다.
 */
export async function fetchAllRemoteConfig() {
  const res = await axios.get(`/v4/remoteConfigs`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 리모트 컨피그를 생성합니다.
 * @param {object} body
 */
export async function postRemoteConfig(body) {
  const res = await axios.post('/v4/admin/remoteConfigs', body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 리모트 컨피그를 수정합니다.
 * @param {String} remoteConfigId
 * @param {object} body
 */
export async function patchRemoteConfig(remoteConfigId, body) {
  const res = await axios.put(
    `/v4/admin/remoteConfigs/${remoteConfigId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 리모트 컨피그를 삭제합니다.
 * @param {String} remoteConfigId
 */
export async function deleteRemoteConfig(remoteConfigId) {
  const res = await axios.delete(`/v4/admin/remoteConfigs/${remoteConfigId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 리모트 컨피그를 가져옵니다.
 * @param {String} remoteConfigKey
 */
export async function fetchRemoteConfig(remoteConfigKey) {
  const res = await axios.get(`/v4/remoteConfigs?keys=${remoteConfigKey}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
