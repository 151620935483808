import React, { useCallback } from 'react';
import Window from 'react-new-window';
import useWindow from '../hooks/useWindow';
import { DEFAULT_WINDOW_SIZE, getWindowURL } from './util/utils';

function WindowManager() {
  const { activeWindows, destroyWindowById } = useWindow();

  const renderWindow = useCallback(
    (activeWindow) => (
      <Window
        key={activeWindow.id}
        url={getWindowURL(activeWindow)}
        copyStyles={false}
        features={DEFAULT_WINDOW_SIZE}
        onUnload={() => destroyWindowById(activeWindow.id)}
      />
    ),
    [destroyWindowById],
  );

  return activeWindows.map(renderWindow);
}

export default WindowManager;
