import React, { useEffect, useState, useRef, useReducer } from 'react';
import '../../../public/css/window.css';
import 'bootstrap';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Form, Input, Select, Button, Alert, Row, Col } from 'antd';
import WindowHeader from '../../components/windowHeader';
import { userCreators } from '../../../store/reducers/user.reducer';
import ElementLoading from '../../components/elementLoading';
import { patchAdminUser } from '../../../services/userService';
import { useMutate } from '../../../hooks/useRequest';
import { descending } from '../../../util/sort';
import { roleCreators } from '../../../store/reducers/role.reducer';
import 'antd/dist/antd.css';

const clientId = process.env.REACT_APP_CLIENT_ID || '';

const AdminWindow = () => {
  const dispatch = useDispatch();
  const { adminUsers, roles } = useSelector((state) => {
    return {
      adminUsers: state.userReducer.adminUser.data?.sort(
        descending('createdAt'),
      ),
      roles: state.roleReducer.role?.data,
    };
  }, shallowEqual);

  const initialState = {
    selectedAccount: null,
    selectedRolePermission: null,
    adminMemo: '',
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const { search } = useLocation();
  const [state, setState] = useReducer(stateReducer, initialState);
  const [windowId, setWindowId] = useState('');
  const [windowClose, setWindowClose] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  const [isPatch, setIsPatch] = useState(false);
  const didCancel = useRef(false);
  const adminUser = adminUsers?.find(
    (adminUserInfo) => adminUserInfo.id === userId,
  );

  const { mutate: changeAdminUser, done: isPatched } = useMutate(
    patchAdminUser,
    clientId,
    state.selectedAccount,
    Boolean(state.selectedRolePermission),
    {
      role: state.selectedRolePermission,
      accessDiseaseGroupIds: [],
      adminMemo: state.adminMemo,
    },
  );

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    if (params.id)
      dispatch(
        userCreators.fetchAdminUser.request({ clientId, isAdmin: true }),
      );
    else {
      dispatch(
        userCreators.fetchAdminUser.request({ clientId, isAdmin: false }),
      );
    }
    dispatch(roleCreators.fetchAllRoles.request());
    if (params.id) {
      setUserId(params.id);
    } else {
      setUserId('');
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, setUserId, windowId, dispatch]);

  useEffect(() => {
    if (adminUser && userId && !didCancel.current && roles?.length > 0) {
      const selectedRole = roles?.find((role) => role.name === adminUser?.role);
      setState({
        selectedAccount: adminUser.id,
        selectedRolePermission: selectedRole?.name,
        adminMemo: adminUser.adminMemo,
      });
      didCancel.current = true;
      setIsLoading(false);
    }
    if (adminUsers && !didCancel.current && roles?.length > 0) {
      didCancel.current = true;
      setIsLoading(false);
    }
  }, [adminUsers, userId, adminUser, roles]);

  useEffect(() => {
    const message = '권한이 변경되었습니다!!';
    if (isPatched) {
      setWindowClose(false);
      alert(message);
      window.close();
    }
  }, [windowClose, isPatched]);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      selectedAccount: values.selectedAccount,
      selectedRolePermission: values.selectedRolePermission,
      adminMemo: values.adminMemo,
    });
    if (window.confirm('수정하시겠습니까?')) {
      setIsPatch(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      setWindowClose(false);
      window.close();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeAdminUser();
      setIsPatch(false);
    }
  }, [isPatch]);

  const emailOnChange = (value) => {
    setState({ selectedAccount: value });
  };

  if (isLoading) return <ElementLoading type="어드민" />;
  return (
    <>
      <WindowHeader title="어드민" />

      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              selectedAccount: state.selectedAccount,
              selectedRolePermission: state.selectedRolePermission,
              adminMemo: state.adminMemo,
            }}
          >
            <Form.Item
              name="selectedAccount"
              label="계정"
              hasFeedback
              rules={[{ required: true, message: '계정을 선택해주세요' }]}
            >
              <Select
                showSearch
                placeholder="계정을 선택해주세요"
                optionFilterProp="children"
                onChange={emailOnChange}
              >
                <Select.Option value="">없음</Select.Option>
                {adminUsers?.map((user) => {
                  return (
                    <Select.Option value={user.id}>{user.email}</Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="selectedRolePermission"
              label="접근 권한"
              hasFeedback
              rules={[{ message: '접근 권한을 선택해주세요' }]}
            >
              <Select placeholder="접근 권한을 선택해주세요">
                <Select.Option value="">없음</Select.Option>
                {roles?.map((role) => {
                  return (
                    <Select.Option value={role.name}>{role.name}</Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              label="비고"
              name="adminMemo"
              rules={[
                {
                  required: true,
                  message: '비고를 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default AdminWindow;
