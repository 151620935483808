import { all, takeLatest } from 'redux-saga/effects';
import {
  mutationDnaReportRegisterRequestActions,
  mutationDnaReportRegisterRequestCreators,
} from '../reducers/mutationDnaReportRegisterRequest.reducer';
import { createAsyncSaga } from '../reducer.util';
import { fetchAllMutationDnaReportRegisterRequest } from '../../services/mutationDnaReportRegisterRequestService';

const handleFetchAllMutationDnaReportRegisterRequest = createAsyncSaga(
  mutationDnaReportRegisterRequestCreators.fetchAllMutationDnaReportRegisterRequest,
  fetchAllMutationDnaReportRegisterRequest,
);

export function* mutationDnaReportRegisterRequestSaga() {
  yield all([
    takeLatest(
      mutationDnaReportRegisterRequestActions.FETCH_ALL_MUTATION_DNA_REPORT_REGISTER_REQUEST,
      handleFetchAllMutationDnaReportRegisterRequest,
    ),
  ]);
}
