import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  dnaCounselingRequest: getAsyncState.initial(null),
  dnaCounselingSetting: getAsyncState.initial(null),
};

// action types
export const dnaCounselingRequestActions = {
  FETCH_ALL_DNA_COUNSELING_REQUEST:
    '@dnaCounselingRequest/FETCH_ALL_DNA_COUNSELING_REQUEST',
  FETCH_ALL_DNA_COUNSELING_SETTING:
    '@dnaCounselingRequest/FETCH_ALL_DNA_COUNSELING_SETTING',
};

// actions
export const dnaCounselingRequestCreators = {
  fetchAllDnaCounselingRequest: createAsyncActions(
    dnaCounselingRequestActions,
    'FETCH_ALL_DNA_COUNSELING_REQUEST',
  ),
  fetchAllDnaCounselingSetting: createAsyncActions(
    dnaCounselingRequestActions,
    'FETCH_ALL_DNA_COUNSELING_SETTING',
  ),
};

const dnaCounselingRequestReducer = handleActions(
  {
    ...handleAsyncActions(
      dnaCounselingRequestActions.FETCH_ALL_DNA_COUNSELING_REQUEST,
      'dnaCounselingRequest',
    ),
    ...handleAsyncActions(
      dnaCounselingRequestActions.FETCH_ALL_DNA_COUNSELING_SETTING,
      'dnaCounselingSetting',
    ),
  },
  initialState,
);

export default dnaCounselingRequestReducer;
