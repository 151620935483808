import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 앱 진입 모달 목록을 가져옵니다.
 */
export async function fetchEntryModals() {
  const res = await axios.get(`/v1/admin/entryModal`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 앱 진입 모달을 생성합니다.
 * @param {{name: string, type?: string}} body
 */
export async function postEntryModal(body) {
  const res = await axios.post(`/v1/admin/entryModal`, {
    ...body,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 앱 진입 모달의 정보를 변경합니다.
 * @param {string} entryModalId
 * @param {{name: string, type?: string}} body
 */
export async function patchEntryModal(entryModalId, body) {
  const res = await axios.patch(`/v1/admin/entryModal/${entryModalId}`, {
    ...body,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 앱 진입 모달의 정보를 삭제합니다.
 * @param {string} entryModalId
 */
export async function deleteEntryModal(entryModalId) {
  const res = await axios.delete(`/v1/admin/entryModal/${entryModalId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 앱 진입 모달 배포 여부를 변경합니다.
 * @param {object} body
 */
export async function patchDeployEntryModal(body) {
  const res = await axios.patch('/v1/admin/entryModal', body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
