import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  fileUploadSignedUrl: getAsyncState.initial(null),
  fileUploadMultiSignedUrl: getAsyncState.initial(null),
  fileUploadPublic: getAsyncState.initial(null),
};

// action types
export const fileUploadActions = {
  GET_SIGNED_URL: '@fileUpload/GET_SIGNED_URL',
  GET_MULTI_SIGNED_URL: '@fileUpload/GET_MULTI_SIGNED_URL',
  CHANGE_ACCESS_RANGE_TO_PUBLIC: '@fileUpload/CHANGE_ACCESS_RANGE_TO_PUBLIC',
};

// actions
export const fileUploadCreators = {
  getSignedUrl: createAsyncActions(fileUploadActions, 'GET_SIGNED_URL'),
  getMultiSignedUrl: createAsyncActions(
    fileUploadActions,
    'GET_MULTI_SIGNED_URL',
  ),
  changeAccessRangeToPublic: createAsyncActions(
    fileUploadActions,
    'CHANGE_ACCESS_RANGE_TO_PUBLIC',
  ),
};

const fileUploadReducer = handleActions(
  {
    ...handleAsyncActions(
      fileUploadActions.GET_SIGNED_URL,
      'fileUploadSignedUrl',
    ),
    ...handleAsyncActions(
      fileUploadActions.GET_MULTI_SIGNED_URL,
      'fileUploadMultiSignedUrl',
    ),
    ...handleAsyncActions(
      fileUploadActions.CHANGE_ACCESS_RANGE_TO_PUBLIC,
      'fileUploadPublic',
    ),
  },
  initialState,
);

export default fileUploadReducer;
