import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 대분류 목록을 조회합니다.
 */
export async function fetchAllChapters() {
  const res = await axios.get('/v4/admin/standardDiseases/chapters');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 질환코드로 표준 질환을 조회합니다.
 * @param {object} params
 * @param {string} params.code
 * @param {string} params.codePrefix
 * @param {integer} params.chapterId
 */
export async function fetchByCode(params) {
  const res = await axios.get('/v4/admin/standardDiseases', { params });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
