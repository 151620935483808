import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Layout, Table, Input, Button } from 'antd';
import 'antd/dist/antd.css';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { hospitalExaminationCreators } from '../../../store/reducers/hospitalExamination.reducer';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../../window/util/utils';

const HospitalRecord = () => {
  const dispatch = useDispatch();
  const { hospitalExaminations, hospitalRecordPagination } = useSelector(
    (state) => ({
      hospitalExaminations:
        state.hospitalExaminationReducer?.hospitalExamination?.data,
      hospitalRecordPagination:
        state?.paginationReducer?.hospitalRecordPagination,
    }),
    shallowEqual,
  );

  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const confirmRef = useRef(() => {});

  const getData = useCallback(() => {
    dispatch(
      hospitalExaminationCreators.fetchAllHospitalExaminations.request(),
    );
  }, [dispatch]);
  useEffect(getData, [getData]);

  useEffect(() => {
    if (hospitalExaminations) {
      if (hospitalRecordPagination.total === null)
        setTotal(hospitalExaminations?.length);
      else setTotal(hospitalRecordPagination.total);
      setPageUpdate(hospitalRecordPagination.page);
      confirmRef.current(hospitalRecordPagination.text);
    }
  }, [hospitalExaminations]);

  const { Content } = Layout;
  const { Search } = Input;

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (hospitalExamination) => {
    if (findWindowById(hospitalExamination.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({
      id: hospitalExamination.id,
      dataType: 'hospitalExamination',
    });
  };

  const handleCreateNewWindow = () => {
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: 'hospitalExamination',
    });
  };

  const columns = [
    {
      title: '이름',
      dataIndex: 'krName',
      sorter: (a, b) => a?.krName?.localeCompare(b?.krName),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: hospitalRecordPagination.filter?.krName || null,
      onFilter: (search, { krName, enName, diseaseTypes }) =>
        [krName, enName, ...(diseaseTypes ?? [])].some((value) =>
          value?.includes(search),
        ),
      render: (text, record) =>
        text ? (
          <a onClick={() => handleCreateEditWindow(record)}>{text}</a>
        ) : (
          '-'
        ),
    },
    {
      title: '영어',
      dataIndex: 'enName',
      key: 'enName',
      sorter: {
        compare: (a, b) => a?.enName?.localeCompare(b?.enName),
      },
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypes',
      key: 'diseaseTypes',
      render: (value) => value?.join(', '),
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="hospitalRecord" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="정보"
            subTitle="병원 검사"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button
              type="primary"
              style={{ width: 100 }}
              onClick={handleCreateNewWindow}
            >
              추가
            </Button>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (hospitalRecordPagination.text = value),
                  ),
                );
              }}
              defaultValue={hospitalRecordPagination.text}
            />
            <div className="searchResult">
              {hospitalExaminations && `검색결과 ${total}개`}
            </div>

            <Table
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={hospitalExaminations}
              pagination={{
                onChange: (page) => {
                  setPageUpdate(page);
                },
                current: pageUpdate,
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(pagination, filters, sorter, extra) => {
                dispatch(
                  paginationCreators.setValue(
                    (hospitalRecordPagination.page = pagination?.current),
                    (hospitalRecordPagination.filter = filters),
                    (hospitalRecordPagination.total =
                      extra?.currentDataSource?.length),
                  ),
                );
                setTotal(extra?.currentDataSource?.length);
              }}
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default HospitalRecord;
