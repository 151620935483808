import axios from 'axios';
import { convertToQuery } from '../hooks/usePagination';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * @typedef {object} DiseaseType
 * @property {string} krName
 * @property {string} enName
 * @property {string[]} krSynonyms
 * @property {string[]} enSynonyms
 * @property {string} content
 * @property {string} mainSymptom
 * @property {string} feature
 * @property {string} exemptionCode
 * @property {string} helplineLink
 * @property {boolean} isRegular
 * @property {boolean} isFrequentlySelected
 * @property {boolean} isApp
 * @property {uuid[]} dnaIds
 * @property {uuid[]} dictionaryIds
 * @property {uuid[]} standardDiseaseIds
 */

/**
 * 기존 질환 목록을 가져옵니다.
 */
export async function fetchAllDiseaseType() {
  const res = await axios.get(`/v4/admin/diseaseTypes?simple=true`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 질환 목록을 가져옵니다.
 * @param {object} pagination
 * @param {string} searchText
 */
export async function fetchAllDiseaseTypeList(pagination, searchText) {
  const res = await axios.get(
    `/v4/admin/diseaseTypes?${
      searchText ? `searchText=${searchText}&` : ''
    }${convertToQuery(pagination)}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 질환을 가져옵니다.
 * @param {uuid} diseaseTypeId
 */
export async function fetchDiseaseType(diseaseTypeId) {
  const res = await axios.get(`/v4/admin/diseaseTypes/${diseaseTypeId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 질환의 관련 임상을 가져옵니다.
 * @param {object} pagination
 * @param {uuid} diseaseTypeId
 * @param {boolean|null} isApp
 */
export async function fetchAllDiseaseTypeClinicalTrial(
  pagination,
  diseaseTypeId,
  isApp,
) {
  const res = await axios.get(
    `/v4/admin/diseaseTypes/${diseaseTypeId}/clinicalTrials?${
      isApp === null ? '' : `isApp=${isApp}&`
    }${convertToQuery(pagination)}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 질환 그룹의 질환 목록을 가져옵니다.
 * @param {String} diseaseGroupId
 */
export async function fetchDiseaseGroupDiseaseType(diseaseGroupId) {
  const res = await axios.get(
    `/v3/admin/diseaseGroups/${diseaseGroupId}/diseaseTypes`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 질환을 생성합니다.
 *
 * @param {DiseaseType} body
 */
export async function postDiseaseType(body) {
  const res = await axios.post(`/v4/admin/diseaseTypes`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 질환을 수정합니다.
 * @param {DiseaseType} body
 *
 */
export async function patchDiseaseType(diseaseTypeId, body) {
  const res = await axios.patch(
    `/v4/admin/diseaseTypes/${diseaseTypeId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 질환의 배포 정보(isMain)를 수정합니다.
 * @param {array} ids
 * @param {array} changes
 */
export async function patchDistributeDiseaseType(ids) {
  const res = await axios.patch(`/v3/admin/diseaseType`, { ids });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 질환을 삭제합니다.
 * @param {uuid} diseaseTypeId
 */
export async function deleteDiseaseType(diseaseTypeId) {
  const res = await axios.delete(`/v4/admin/diseaseTypes/${diseaseTypeId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
