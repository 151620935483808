import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 치료제 타임라인을 가져옵니다.
 */
export async function fetchAllTreatmentStudyTimeline() {
  const res = await axios.get(`/v3/admin/treatmentStudyTimelines`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 치료제 타임라인을 가져옵니다.
 * @param {String} treatmentStudyTimelineId
 * @param {String} treatmentStudyId
 */
export async function fetchTreatmentStudyTimeline(
  treatmentStudyTimelineId,
  treatmentStudyId,
) {
  const res = await axios.get(
    `/v3/admin/treatmentStudies/${treatmentStudyId}/treatmentStudyTimelines/${treatmentStudyTimelineId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 치료제 타임라인을 생성합니다.
 * @param {String} treatmentStudyId
 * @param {object} body
 */
export async function postTreatmentStudyTimeline(treatmentStudyId, body) {
  const res = await axios.post(
    `/v3/admin/treatmentStudies/${treatmentStudyId}/treatmentStudyTimelines`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 치료제 타임라인을 수정합니다.
 * @param {String} treatmentStudyId
 * @param {object} body
 */
export async function patchTreatmentStudyTimeline(
  treatmentStudyTimelineId,
  treatmentStudyId,
  body,
) {
  const res = await axios.patch(
    `/v3/admin/treatmentStudies/${treatmentStudyId}/treatmentStudyTimelines/${treatmentStudyTimelineId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 치료제 타임라인의 배포여부를 수정합니다.
 * @param {array} ids
 * @param {array} changes
 */
export async function patchDeployTreatmentStudyTimeline({ ids, changes }) {
  const res = await axios.patch(`/v3/admin/treatmentStudyTimelines`, {
    ids,
    changes,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 치료제 타임라인을 삭제합니다.
 * @param {String} treatmentStudyId
 */
export async function deleteTreatmentStudyTimeline(
  treatmentStudyTimelineId,
  treatmentStudyId,
) {
  const res = await axios.delete(
    `/v3/admin/treatmentStudies/${treatmentStudyId}/treatmentStudyTimelines/${treatmentStudyTimelineId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
