import { handleActions } from 'redux-actions';
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  translateWords: getAsyncState.initial([]),
};

// action types
export const translateWordActions = {
  FETCH_ALL_TRANSLATE_WORDS: '@translateWords/FETCH_ALL_TRANSLATE_WORDS',
};

// actions
export const translateWordCreators = {
  fetchAllTranslateWords: createAsyncActions(
    translateWordActions,
    'FETCH_ALL_TRANSLATE_WORDS',
  ),
};

const translateWordReducer = handleActions(
  {
    ...handleAsyncActions(
      translateWordActions.FETCH_ALL_TRANSLATE_WORDS,
      'translateWords',
    ),
  },
  initialState,
);

export default translateWordReducer;
