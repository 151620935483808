import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 공지를 가져옵니다.
 */
export async function fetchAllNotice() {
  const res = await axios.get('/v1/admin/contents/notices');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 공지를 가져옵니다.
 * @param {uuid} noticeId
 */
export async function fetchNotice(noticeId) {
  const res = await axios.get(`/v1/contents/notice/${noticeId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 공지를 생성합니다.
 * @param {String} title
 * @param {String} content
 * @param {array} diseaseTypeIds
 * @param {String} styleVersion
 * @param {String} type
 * @param {String} photo
 * @param {String} wroteAt
 */
export async function postNotice({
  title,
  content,
  diseaseTypeIds,
  styleVersion,
  type,
  photo,
  wroteAt,
  tagIds,
  isApp,
}) {
  const res = await axios.post('/v1/admin/contents/notices', {
    title,
    content,
    diseaseTypeIds,
    styleVersion,
    type,
    photo,
    wroteAt,
    tagIds,
    isApp,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 공지를 수정합니다.
 * @param {uuid} noticeId
 * @param {String} title
 * @param {String} content
 * @param {array} diseaseTypeIds
 * @param {String} styleVersion
 * @param {String} type
 * @param {String} photo
 * @param {String} wroteAt
 */
export async function patchNotice({
  noticeId,
  title,
  content,
  diseaseTypeIds,
  styleVersion,
  type,
  photo,
  wroteAt,
  tagIds,
  isApp,
  isInfo,
}) {
  const res = await axios.put(`/v1/admin/contents/notices/${noticeId}`, {
    title,
    content,
    diseaseTypeIds,
    styleVersion,
    type,
    photo,
    wroteAt,
    tagIds,
    isApp,
    isInfo,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 공지의 배포 정보(isApp, isNew)를 수정합니다.
 * @param {array} ids
 * @param {array} changes
 */
export async function patchDistributeNotice(body) {
  const res = await axios.patch(`/v1/admin/contents/notices`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 공지를 삭제합니다.
 * @param {uuid} noticeId
 */
export async function deleteNotice(noticeId) {
  const res = await axios.delete(`/v1/admin/contents/notices/${noticeId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
