import React from 'react';

import UserWithHealthProfile from '../../containers/userWithHealthProfile';
import PAGE_URL from '../../../assets/pageUrl';

const PapAuditDetail = () => {
  return <UserWithHealthProfile type="papProject" tabLink={PAGE_URL.PAP} />;
};

export default PapAuditDetail;
