import { all, takeLatest } from 'redux-saga/effects';
import {
  dashboardCreators,
  dashboardActions,
} from '../reducers/dashboard.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  fetchDashboardUser,
  fetchDashboardKey,
  fetchDashboardDevice,
  fetchDashboardFunnel,
  fetchDashboardUserData,
  fetchDashboardUserCountByDisease,
} from '../../services/dashboardService';

const handleFetchDashboardUsers = createAsyncSaga(
  dashboardCreators.fetchDashboardUsers,
  fetchDashboardUser,
);

const handleFetchDashboardKeys = createAsyncSaga(
  dashboardCreators.fetchDashboardKeys,
  fetchDashboardKey,
);

const handleFetchDashboardDevices = createAsyncSaga(
  dashboardCreators.fetchDashboardDevices,
  fetchDashboardDevice,
);

const handleFetchDashboardFunnels = createAsyncSaga(
  dashboardCreators.fetchDashboardFunnels,
  fetchDashboardFunnel,
);

const handleFetchDashboardUserData = createAsyncSaga(
  dashboardCreators.fetchDashboardUserData,
  fetchDashboardUserData,
);

const handleFetchDashboardUserCountByDiseases = createAsyncSaga(
  dashboardCreators.fetchDashboardUserCountByDiseases,
  fetchDashboardUserCountByDisease,
);

export function* dashboardSaga() {
  yield all([
    takeLatest(
      dashboardActions.FETCH_DASHBOARD_USERS,
      handleFetchDashboardUsers,
    ),
    takeLatest(dashboardActions.FETCH_DASHBOARD_KEYS, handleFetchDashboardKeys),
    takeLatest(
      dashboardActions.FETCH_DASHBOARD_DEVICES,
      handleFetchDashboardDevices,
    ),
    takeLatest(
      dashboardActions.FETCH_DASHBOARD_FUNNELS,
      handleFetchDashboardFunnels,
    ),
    takeLatest(
      dashboardActions.FETCH_DASHBOARD_USER_DATA,
      handleFetchDashboardUserData,
    ),
    takeLatest(
      dashboardActions.FETCH_DASHBOARD_USER_COUNT_BY_DISEASES,
      handleFetchDashboardUserCountByDiseases,
    ),
  ]);
}
