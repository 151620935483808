import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, notification } from 'antd';
import dayjs from 'dayjs';
import 'antd/dist/antd.css';
import { DownloadOutlined } from '@ant-design/icons';
import { userCreators } from '../../../store/reducers/user.reducer';
import { fetchPatientGroupUserInfo } from '../../../services/patientGroupService';

const userInfoCommonQuestions = [
  {
    text: '이름',
    value: 'name',
    type: 'userInfo',
  },
  {
    text: '이메일',
    value: 'email',
    type: 'userInfo',
  },
  {
    text: '연락처',
    value: 'phone',
    type: 'userInfo',
  },
  {
    text: '질환',
    value: 'diseaseTypeName',
    type: 'userInfo',
  },
  {
    text: '성별',
    value: 'gender',
    type: 'userInfo',
  },
  {
    text: '관계',
    value: 'relationship',
    type: 'userInfo',
  },
  {
    text: '건강 설문',
    value: 'healthProfileStatus',
    type: 'userInfo',
  },
  {
    text: '계정유형',
    value: 'socialAccounts',
    type: 'userInfo',
  },
  {
    text: '테스트ID',
    value: 'isTest',
    type: 'userInfo',
  },
];

const PatientGroupExcelDown = (project) => {
  const dispatch = useDispatch();
  const request = useCallback(
    () => () =>
      fetchPatientGroupUserInfo(project?.projectName)
        .then((data) => excelDown(data, project?.text))
        .catch(
          ({
            _data,
            message = `${project?.text} 환우회 리스트 다운로드에 실패했습니다.`,
          }) =>
            notification.warn({
              message: '환우회 리스트 다운로드에 실패했습니다.',
              description: message,
            }),
        ),
    [],
  );

  const { permission } = useSelector((state) => ({
    permission: state?.userReducer.permission.data,
  }));

  const getData = useCallback(() => {
    dispatch(userCreators.fetchAllUser.request());
  }, [dispatch]);
  useEffect(getData, [getData]);

  const excelDown = (data, excelName) => {
    const xlsx = require('xlsx');
    const book = xlsx.utils.book_new();

    const rows = [];

    const transpose = (matrix) => {
      const [row] = matrix;
      return row.map((value, column) => matrix.map((trans) => trans[column]));
    };

    // excelHeader
    rows.push([excelName]);
    rows.push([
      ...userInfoCommonQuestions.map((answerData) => answerData?.text),
    ]);

    // excelBody
    data.forEach((answers) => {
      const values = [];
      userInfoCommonQuestions.forEach((question) => {
        values.push(answers[question?.value]);
      });
      rows?.push(values);
    });

    const excelData = xlsx.utils.aoa_to_sheet(rows);
    xlsx.utils.book_append_sheet(book, excelData, excelName);

    xlsx.writeFile(book, `${dayjs().format(`${excelName}_YYYY_MM_DD`)}.xlsx`);
    notification.success({
      message: '환우회리스트 다운로드가 완료 되었습니다.',
      description: '환우회리스트 다운로드가 완료 되었습니다. ',
    });
  };

  return (
    <div style={{ display: 'inline-block', marginLeft: 10 }}>
      <div style={{ marginBottom: '10px' }}>
        {permission?.includes('account_admin') && (
          <Button
            type="primary"
            icon={<DownloadOutlined style={{ verticalAlign: 'baseline' }} />}
            onClick={request()}
            style={{ marginRight: 10 }}
          >
            환우회 리스트 다운
          </Button>
        )}
      </div>
    </div>
  );
};

export default PatientGroupExcelDown;
