import { createAction, handleActions } from 'redux-actions';
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  user: getAsyncState.initial(null),
  adminUser: getAsyncState.initial(null),
  notAdminUser: getAsyncState.initial(null),
  permission: getAsyncState.initial(null),
  unmaskedInfo: getAsyncState.initial(null),
  userWithdrawal: getAsyncState.initial(null),
};

// action types
export const userActions = {
  FETCH_ALL_USER: '@user/FETCH_ALL_USER',
  FETCH_ADMIN_USER: '@user/FETCH_ADMIN_USER',
  FETCH_NOT_ADMIN_USER: '@user/FETCH_NOT_ADMIN_USER',
  FETCH_PERMISSION: '@user/FETCH_PERMISSION',
  PERMISSION_LOGOUT: '@user/PERMISSION_LOGOUT',
  FETCH_UNMASKEDINFO: '@user/FETCH_UNMASKEDINFO',
  FETCH_ALL_USER_WITHDRAWAL: '@user/FETCH_ALL_USER_WITHDRAWAL',
};

// actions
export const userCreators = {
  fetchAllUser: createAsyncActions(userActions, 'FETCH_ALL_USER'),
  fetchAdminUser: createAsyncActions(userActions, 'FETCH_ADMIN_USER'),
  fetchNotAdminUser: createAsyncActions(userActions, 'FETCH_NOT_ADMIN_USER'),
  fetchPermission: createAsyncActions(userActions, 'FETCH_PERMISSION'),
  permissionLogout: createAction(userActions?.PERMISSION_LOGOUT),
  fetchUnmaskedInfo: createAsyncActions(userActions, 'FETCH_UNMASKEDINFO'),
  fetchAllUserWithdrawal: createAsyncActions(
    userActions,
    'FETCH_ALL_USER_WITHDRAWAL',
  ),
};

const userReducer = handleActions(
  {
    [userActions.PERMISSION_LOGOUT]: (state) => {
      return {
        ...state,
        permission: getAsyncState.initial(null),
      };
    },
    ...handleAsyncActions(userActions.FETCH_ALL_USER, 'user'),
    ...handleAsyncActions(userActions.FETCH_ADMIN_USER, 'adminUser'),
    ...handleAsyncActions(userActions.FETCH_NOT_ADMIN_USER, 'notAdminUser'),
    ...handleAsyncActions(userActions.FETCH_PERMISSION, 'permission'),
    ...handleAsyncActions(userActions.FETCH_UNMASKEDINFO, 'unmaskedInfo'),
    ...handleAsyncActions(
      userActions.FETCH_ALL_USER_WITHDRAWAL,
      'userWithdrawal',
    ),
  },
  initialState,
);

export default userReducer;
