import { createAction, handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  notices: getAsyncState.initial(null),
};

// action types
export const noticeActions = {
  SET_NOTICES: '@notice/SET_NOTICES',
  FETCH_NOTICES: '@notice/FETCH_NOTICES',
  FETCH_NOTICE: '@notice/FETCH_NOTICE',
  POST_NOTICE: '@notice/POST_NOTICE',
  PATCH_NOTICE: '@notice/PATCH_NOTICE',
  DELETE_NOTICE: '@notice/DELETE_NOTICE',
  PATCH_DISTRIBUTE_NOTICES: '@notice/PATCH_DISTRIBUTE_NOTICES',
};

// actions
export const noticeCreators = {
  setNotices: createAction(noticeActions.SET_NOTICES),
  fetchNotices: createAsyncActions(noticeActions, 'FETCH_NOTICES'),
  fetchNotice: createAsyncActions(noticeActions, 'FETCH_NOTICE'),
  postNotice: createAsyncActions(noticeActions, 'POST_NOTICE'),
  patchNotice: createAsyncActions(noticeActions, 'PATCH_NOTICE'),
  deleteNotice: createAsyncActions(noticeActions, 'DELETE_NOTICE'),
  patchDistributeNotices: createAsyncActions(
    noticeActions,
    'PATCH_DISTRIBUTE_NOTICES',
  ),
};

const setNoticeReducer = (state, { payload }) => ({
  notices: { data: payload, error: null, loading: false },
});

const noticeReducer = handleActions(
  {
    [noticeActions.SET_NOTICES]: setNoticeReducer,
    ...handleAsyncActions(noticeActions.FETCH_NOTICES, 'notices'),
    ...handleAsyncActions(noticeActions.FETCH_NOTICE, 'notices'),
    ...handleAsyncActions(noticeActions.POST_NOTICE, 'notices'),
    ...handleAsyncActions(noticeActions.PATCH_NOTICE, 'notices'),
    ...handleAsyncActions(noticeActions.DELETE_NOTICE, 'notices'),
    ...handleAsyncActions(noticeActions.PATCH_DISTRIBUTE_NOTICES, 'notices'),
  },
  initialState,
);

export default noticeReducer;
