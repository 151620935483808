import axios from 'axios';
import { convertToQuery } from '../hooks/usePagination';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 임상시험 업데이트 내역을 가져옵니다.
 * @param {object} pagination
 * @param {boolean|null} notified
 */
export async function fetchAllClinicalTrialUpdateHistory(
  pagination,
  notified,
  diseaseTypeId,
) {
  const res = await axios.get(
    `/v4/admin/clinicalTrialupdateHistories?${
      typeof notified === 'boolean' ? `notified=${notified}&` : ''
    }${diseaseTypeId ? `diseaseTypeId=${diseaseTypeId}&` : ''}${convertToQuery(
      pagination,
    )}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 임상시험 업데이트 내역을 수정합니다.
 * @param {string} id
 * @param {object} body
 */
export async function patchClinicalTrialUpdateHistory(id, body) {
  const res = await axios.patch(
    `/v4/admin/clinicalTrialUpdateHistories/${id}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 임상시험 업데이트 내역을 삭제합니다.
 * @param {string} id
 */
export async function deleteClinicalTrialUpdateHistory(id) {
  const res = await axios.delete(
    `/v4/admin/clinicalTrialUpdateHistories/${id}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 임상시험 업데이트 내역을 여러 개 삭제합니다.
 * @param {string[]} ids
 */
export async function deleteClinicalTrialUpdateHistories(ids) {
  const res = await axios.delete(`/v4/admin/clinicalTrialUpdateHistories`, {
    data: { clinicalTrialUpdateHistoryIds: ids },
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
