import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Layout, Input, Button } from 'antd';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';
import AntList from '../../components/antList';
import DeployButton from '../../components/deployButton';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { NEW } from '../../../window/util/utils';
import { useMutate } from '../../../hooks/useRequest';
import { patchDeployEntryModal } from '../../../services/entryModalService';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { entryModalCreators } from '../../../store/reducers/entryModal.reducer';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import useWindow from '../../../hooks/useWindow';

const MODAL_STATUS = {
  ACTIVE: '노출 중',
  PENDING: '노출 대기',
  INACTIVE: '노출 끔',
  EXPIRED: '기간 만료',
};

const EntryModal = () => {
  const dispatch = useDispatch();
  const { entryModals, entryModalPagination, diseaseTypesInfo } = useSelector(
    (state) => {
      return {
        entryModals: state.entryModalReducer.entryModals?.data,
        entryModalPagination: state?.paginationReducer?.entryModalPagination,
        diseaseTypesInfo: state.diseaseTypeReducer.diseaseTypes?.data
          ? state.diseaseTypeReducer.diseaseTypes.data.map(
              ({ id, krName }) => ({
                id,
                krName,
              }),
            )
          : null,
      };
    },
    shallowEqual,
  );

  const [tableData, setTableData] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const confirmRef = useRef(() => {});

  const makeTableData = useCallback((entryModalList = []) => {
    const NOW = new Date();
    return entryModalList.map((entryModal) => ({
      ...entryModal,
      status:
        dayjs(entryModal.isAppStartAt) <= NOW
          ? dayjs(entryModal.isAppEndAt) >= NOW
            ? entryModal.isApp
              ? MODAL_STATUS.ACTIVE
              : MODAL_STATUS.INACTIVE
            : MODAL_STATUS.EXPIRED
          : MODAL_STATUS.PENDING,
      period: `${dayjs(entryModal.isAppStartAt).format('YY-MM-DD')} ~ 
        ${dayjs(entryModal.isAppEndAt).format('YY-MM-DD')}`,
    }));
  }, []);

  const getData = useCallback(() => {
    dispatch(entryModalCreators.fetchEntryModals.request());
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);
  useEffect(getData, [getData]);

  useEffect(() => {
    if (entryModals) {
      if (entryModalPagination.total === null) setTotal(entryModals?.length);
      else setTotal(entryModalPagination.total);
      setPageUpdate(entryModalPagination.page);
      confirmRef.current(entryModalPagination.text);
      setTableData(makeTableData(entryModals));
    }
  }, [entryModals]);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();
  const { Search } = Input;
  const { Content } = Layout;
  const [deployChangeInfo, setDeployChangeInfo] = useState([]);

  const {
    done: patchDeployDone,
    mutate: patchDeployMutate,
    initialize: patchDeployInitialize,
  } = useMutate(patchDeployEntryModal, {
    ids: deployChangeInfo.map((deployInfo) => deployInfo.id),
    changes: deployChangeInfo.map((deployInfo) => {
      return {
        isApp:
          deployInfo.isApp === undefined
            ? entryModals.find(({ id }) => id === deployInfo.id).isApp
            : deployInfo.isApp,
      };
    }),
  });

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (record) => {
    if (findWindowById(JSON.stringify(record))) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({ id: JSON.stringify(record), dataType: 'entryModal' });
  };

  const handleCreateNewWindow = () => {
    createWindow({ id: `${NEW}${Date.now()}`, dataType: 'entryModal' });
  };

  const deploy = () => {
    if (window.confirm('앱에 변경된 사항을 반영하여 배포하시겠습니까?')) {
      patchDeployInitialize();
      patchDeploy();
    }
  };

  const patchDeploy = () => {
    patchDeployMutate();
    setDeployChangeInfo([]);
  };

  const cancelDeploy = () => {
    setDeployChangeInfo([]);
  };

  const isDeployReady = deployChangeInfo?.length > 0;

  const onClickDeploy = () => {
    if (!isDeployReady) {
      alert('배포할 변경사항이 없습니다.');
      return;
    }
    deploy();
  };

  const onClickCancelDeploy = () => {
    if (!isDeployReady) {
      alert('변경사항이 없습니다.');
      return;
    }
    cancelDeploy();
  };

  useEffect(() => {
    if (patchDeployDone) {
      getData();
    }
  }, [patchDeployDone]);

  const onChange = (pagination, filters, sorter, extra) => {
    dispatch(
      paginationCreators.setValue(
        (entryModalPagination.page = pagination?.current),
        (entryModalPagination.filter = filters),
        (entryModalPagination.total = extra?.currentDataSource?.length),
      ),
    );
    setTotal(extra?.currentDataSource?.length);
  };

  const diseaseOption = useMemo(() => {
    if (diseaseTypesInfo) {
      const diseaseIds = [];
      entryModals?.map((modal) => diseaseIds.push(...modal.diseaseTypeIds));

      const result = diseaseIds.filter((element, index) => {
        return diseaseIds.indexOf(element) === index;
      });

      const filters = [
        {
          text: '전체 질환',
          value: [],
        },
        ...result?.map((disease) => {
          return {
            text: diseaseTypesInfo?.find((role) => role.id === disease)?.krName,
            value: disease,
          };
        }),
      ];
      return filters;
    }
  }, [entryModals, diseaseTypesInfo]);

  const columns = [
    {
      title: '생성일',
      width: 90,
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => dayjs(text).format('YY-MM-DD'),
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
    },
    {
      title: '노출 기간',
      dataIndex: 'period',
      key: 'period',
      sorter: {
        compare: (a, b) => a?.period?.localeCompare(b?.period),
      },
      width: 170,
      defaultSortOrder: 'descend',
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypeIds',
      key: 'diseaseTypeIds',
      width: '250px',
      sorter: {
        compare: (a, b) => a?.type?.localeCompare(b?.type),
      },
      filteredValue: entryModalPagination.filter?.diseaseTypeIds || null,
      filters: diseaseOption,
      onFilter: (value, record) =>
        record?.diseaseTypeIds?.length === 0 && value?.length === 0
          ? true
          : record?.diseaseTypeIds?.includes(value),
      render: (value) =>
        value?.length === 0 ? (
          <div
            className="tagWrapper"
            style={{
              color: '#1890ff',
            }}
          >
            전체 질환
          </div>
        ) : (
          value?.map((disease) => {
            const tag = diseaseTypesInfo?.find((role) => role.id === disease)
              ?.krName;
            return (
              <div
                className="tagWrapper"
                style={{
                  color: '#979797',
                }}
              >
                {tag}
              </div>
            );
          })
        ),
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: entryModalPagination.filter?.title || null,
      onFilter: (search, { title, createdAt, diseaseTypeIds }) =>
        [
          title,
          createdAt,
          ...diseaseTypeIds?.map(
            (disease) =>
              diseaseTypesInfo?.find((role) => role.id === disease)?.krName,
          ),
        ].some((value) => value?.includes(search)),
      render: (text, record) =>
        text ? (
          <a onClick={() => handleCreateEditWindow(record)}>{text}</a>
        ) : (
          '-'
        ),
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      width: 160,
      render: (text, record) =>
        text === MODAL_STATUS.PENDING || text === MODAL_STATUS.ACTIVE
          ? `${text} ${dayjs(record.isAppEndAt).diff(dayjs(), 'day')}일 남음`
          : text,
      filteredValue: entryModalPagination.filter?.status || null,
      filters: Object.values(MODAL_STATUS).map((status) => ({
        text: status,
        value: status,
      })),
      onFilter: (value, record) => record?.status?.includes(value),
    },
    {
      title: '앱 노출',
      dataIndex: 'isApp',
      key: 'isApp',
      width: 80,
      filters: [
        {
          text: 'On',
          value: true,
        },
        {
          text: 'Off',
          value: false,
        },
      ],
      filteredValue: entryModalPagination.filter?.isApp || null,
      onFilter: (value, record) => record.isApp === value,
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="entryModal" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="운영"
            subTitle="앱 진입 모달"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <DeployButton
              onClickDeploy={onClickDeploy}
              onClickCancelDeploy={onClickCancelDeploy}
              disabled={!isDeployReady}
            />
            <div className="divider" />
            <Button
              type="primary"
              style={{ width: 100 }}
              onClick={handleCreateNewWindow}
            >
              추가
            </Button>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (entryModalPagination.text = value),
                  ),
                );
              }}
              defaultValue={entryModalPagination.text}
            />
            <div className="searchResult">
              {entryModals && `검색결과 ${total}개`}
            </div>

            <AntList
              deployChangeInfo={deployChangeInfo}
              setDeployChangeInfo={setDeployChangeInfo}
              pageUpdate={pageUpdate}
              setPageUpdate={setPageUpdate}
              columns={columns}
              dataSource={tableData}
              onChange={onChange}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default EntryModal;
