import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 의료진 프로필 데이터를 가져옵니다.
 */
export async function fetchAllDoctorProfiles() {
  const res = await axios.get(`/v4/admin/doctorProfiles`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 의료진 프로필을 가져옵니다.
 * @param {String} doctorProfileId
 */
export async function fetchDoctorProfile(doctorProfileId) {
  const res = await axios.get(`/v4/admin/doctorProfiles/${doctorProfileId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 의료진 프로필을 생성합니다.
 * @param {object} body
 */
export async function postDoctorProfile(body) {
  const res = await axios.post(`/v4/admin/doctorProfiles`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 의료진 프로필을 수정합니다.
 * @param {String} doctorProfileId
 * @param {object} body
 */
export async function patchDoctorProfile(doctorProfileId, body) {
  const res = await axios.patch(
    `/v4/admin/doctorProfiles/${doctorProfileId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 의료진 프로필을 삭제합니다.
 * @param {String} doctorProfileId
 */
export async function deleteDoctorProfile(doctorProfileId) {
  const res = await axios.delete(`/v4/admin/doctorProfiles/${doctorProfileId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
