import React from 'react';
import { Form, Button, ButtonGroup, Row, Col } from 'react-bootstrap';

const DistinctImage = ({ text, imageFileInput, images, deleteImage, name }) => {
  const imageList = images
    ? typeof images !== 'object'
      ? [images]
      : images
    : [];
  return (
    <Form.Group className="formContent" as={Row}>
      <Form.Label column sm="3" md="2" xl="1">
        {text}
      </Form.Label>
      <Col sm="9" md="10" xl="11">
        <Form.Group>
          <input
            type="file"
            name="image"
            accept="image/*"
            onChange={imageFileInput}
          />
          {imageList
            ? imageList.map((imageElement) => (
                <>
                  <ButtonGroup size="sm">
                    <Button
                      variant="secondary"
                      onClick={() => deleteImage(name, imageElement)}
                    >
                      &times;
                    </Button>
                    <Button
                      variant="outline-secondary"
                      disabled
                      style={{
                        cursor: 'default',
                        color: '#000',
                      }}
                    >
                      <img
                        src={imageElement}
                        alt=""
                        style={{ height: '50px' }}
                      />{' '}
                      <br />
                      {name}
                    </Button>
                  </ButtonGroup>
                  &nbsp;&nbsp;
                </>
              ))
            : null}
        </Form.Group>
      </Col>
    </Form.Group>
  );
};

export default DistinctImage;
