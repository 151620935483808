import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout, Table, Input, Button } from 'antd';
import 'antd/dist/antd.css';
import { SettingOutlined } from '@ant-design/icons';
import * as permissions from '../../../util/permission';
import { userCreators } from '../../../store/reducers/user.reducer';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../../window/util/utils';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';

const clientId = process.env.REACT_APP_CLIENT_ID || '';

const Admin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { adminUsers } = useSelector(
    (state) => ({
      adminUsers: state.userReducer.adminUser.data,
    }),
    shallowEqual,
  );

  const getData = useCallback(() => {
    dispatch(userCreators.fetchAllUser.request());
    dispatch(userCreators.fetchAdminUser.request({ clientId, isAdmin: true }));
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setDataSource(adminUsers);
    setFilteredData(adminUsers?.length);
  }, [adminUsers]);

  const permissionMap = {
    [permissions.ACCOUNT_ADMIN]: '계정: 어드민',
    [permissions.ACCOUNT_USER]: '계정: 유저',
    [permissions.WITHDRAWAL_USER]: '계정: 탈퇴',
    [permissions.GENE_RESEARCH]: '데이터: 연구 신청',
    [permissions.DNA_COUNSELING]: '유전자 검사: 상담 신청',
    [permissions.DNA_REPORT]: '유전자 검사: 변이 유전자 보고서',
    [permissions.CONTENT_TREATMENT_NEWS]: '콘텐츠: 소식',
    [permissions.CONTENT_TERMS]: '콘텐츠: 용어사전',
    [permissions.CONTENT_DNA]: '콘텐츠: 유전자',
    [permissions.CONTENT_DISEASE]: '콘텐츠: 질환',
    [permissions.CONTENT_PRODUCT]: '콘텐츠: 치료제',
    [permissions.CONTENT_DISEASE_GROUP]: '콘텐츠: 질환 그룹',
    [permissions.CONTENT_DNA_ANALYSIS_STATS]: '콘텐츠: 유전자리포트',
    [permissions.CONTENT_HEALTH_PROFILE]: '콘텐츠: 설문',
    [permissions.CONTENT_HOSPITAL_EXAMINATION]: '콘텐츠: 병원검사',
    [permissions.CONTENT_REHABILITATION]: '콘텐츠: 재활',
    [permissions.CONTENT_WEB_VIEW]: '콘텐츠: 웹뷰',
    [permissions.MANAGE_NOTICE]: '운영: 공지',
    [permissions.MANAGE_NOTIFICATION]: '운영: 콘텐츠 알림/푸시',
    [permissions.MANAGE_INDIVIDUAL_NOTIFICATION]: '운영: 메세지 알림/푸시',
    [permissions.MANAGE_RECOMMENDATION]: '운영: 추천검색어',
    [permissions.MANAGE_EVENT]: '운영: 이벤트',
    [permissions.ADMIN_LOGIN]: '어드민 로그인',
    [permissions.HP_HISTORY]: 'HP: 포인트 내역',
    [permissions.HP_CONVERSION]: 'HP: 적환 내역',
    [permissions.DASHBOARD]: '대시보드: 대시보드',
    [permissions.DASHBOARD_CONTENTS]: '대시보드: 콘텐츠',
    [permissions.DASHBOARD_POINT]: '대시보드: HP',
    [permissions.SUPPORT_VERSION]: '개발: 강제업데이트',
    [permissions.MANAGE_POPUP]: '개발: 팝업',
    [permissions.CLINICALTRIAL_COUNSELING]: '유전자 검사: 임상상담 신청',
    [permissions.IP_ALLOW]: '개발: 개인정보 IP',
    [permissions.GET_USER_INFO_FOR_SEARCH]: '읽기: 유저 데이터 검색 권한',
  };

  const { Content } = Layout;
  const [dataSource, setDataSource] = useState('');
  const { Search } = Input;
  const [filteredData, setFilteredData] = useState(null);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'save' || command === 'delete' || command === 'cancel') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateNewWindow = () => {
    createWindow({ id: `${NEW}${Date.now()}`, dataType: 'admin' });
  };

  const handleCreateEditWindow = (adminUserInfo) => {
    if (findWindowById(adminUserInfo.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({ id: adminUserInfo.id, dataType: 'admin' });
  };

  const onSearch = (e) => {
    if (e === '') {
      setDataSource(adminUsers);
      setFilteredData(adminUsers?.length);
    } else {
      const filteredDatas = dataSource?.filter((entry) =>
        Object?.values(entry).toString().includes(e),
      );
      setDataSource(filteredDatas);
      setFilteredData(filteredDatas.length);
    }
  };

  const permissionRange = (permissionList) => {
    const permissionMapList = permissionList.map(
      (permission) => permissionMap[permission],
    );
    return permissionMapList?.join(', ');
  };

  const moveToRolePermissionSettingPage = () => {
    history.push('/adminSetting');
  };

  const columns = [
    {
      title: '계정',
      dataIndex: 'email',
      key: 'email',
      width: 400,
      sorter: {
        compare: (a, b) => a?.email?.localeCompare(b?.email),
      },
      render: (text, record) => (
        <a
          style={{ color: '#40a9ff' }}
          onClick={() => handleCreateEditWindow(record)}
        >
          {text}
        </a>
      ),
    },
    {
      title: '접근 권한',
      dataIndex: 'role',
      key: 'role',
      width: 300,
      sorter: {
        compare: (a, b) => a?.role?.localeCompare(b?.role),
      },
    },

    {
      title: '비고',
      dataIndex: 'adminMemo',
      key: 'adminMemo',
      width: 300,
      sorter: {
        compare: (a, b) => a?.adminMemo?.localeCompare(b?.adminMemo),
      },
    },
  ];

  const expandedRowRender = (record) => {
    const expandedColumns = [
      {
        title: '권한 범위',
        width: 900,
        dataIndex: 'permission',
        key: 'permission',
        render: (permission) => <>{permissionRange(permission)}</>,
      },
    ];

    return (
      <Table
        columns={expandedColumns}
        dataSource={[record]}
        pagination={false}
      />
    );
  };

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabAccount" link="admin" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="계정"
            subTitle="어드민"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button
              type="primary"
              style={{ width: 100 }}
              onClick={handleCreateNewWindow}
            >
              추가
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              type="default"
              style={{ width: 100 }}
              icon={
                <SettingOutlined
                  style={{ fontSize: '18px', verticalAlign: 'baseline' }}
                />
              }
              onClick={moveToRolePermissionSettingPage}
            >
              설정
            </Button>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={onSearch}
            />
            <div className="searchResult">검색결과 {filteredData}개</div>
            <Table
              rowKey={(record) => record.id}
              className="components-table-demo-nested"
              columns={columns}
              loading={!dataSource}
              expandable={{ expandedRowRender }}
              dataSource={dataSource}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(_, __, ___, { currentDataSource }) =>
                setFilteredData(currentDataSource.length)
              }
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Admin;
