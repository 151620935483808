import { handleActions } from 'redux-actions';
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  doctorProfile: getAsyncState.initial(null),
};

// action types
export const doctorProfileActions = {
  FETCH_ALL_DOCTORPROFILES: '@doctorProfile/FETCH_ALL_DOCTORPROFILES',
};

// actions
export const doctorProfileCreators = {
  fetchAllDoctorProfiles: createAsyncActions(
    doctorProfileActions,
    'FETCH_ALL_DOCTORPROFILES',
  ),
};

const doctorProfileReducer = handleActions(
  {
    ...handleAsyncActions(
      doctorProfileActions.FETCH_ALL_DOCTORPROFILES,
      'doctorProfile',
    ),
  },
  initialState,
);

export default doctorProfileReducer;
