import React, { useEffect, useState, useRef, useReducer } from 'react';
import {
  Form,
  Input,
  Button,
  Alert,
  Row,
  Col,
  Upload,
  Radio,
  notification,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../public/css/window.css';
import qs from 'query-string';
import { useLocation } from 'react-router';
import WindowHeader from '../../components/windowHeader';
import ElementLoading from '../../components/elementLoading';

import {
  postDoctorProfile,
  patchDoctorProfile,
  deleteDoctorProfile,
} from '../../../services/doctorProfileService';
import { useMutate } from '../../../hooks/useRequest';
import { ALERT_MESSAGES } from '../../../assets/alert';
import { useAntFileUpload } from '../../util/useAntFileImage';

const DoctorProfileWindow = () => {
  const { search } = useLocation();

  const genderOptions = [
    { label: '남', value: 'male' },
    { label: '여', value: 'female' },
  ];

  const profileTypeOptions = [
    { label: '의료진', value: 'doctor' },
    { label: '단체', value: 'group' },
  ];

  const initialState = {
    name: '',
    photo: '',
    hospital: '',
    specialty: '',
    gender: 'male',
    profileType: 'doctor',
    briefHistories: [],
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);
  const [windowId, setWindowId] = useState('');
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [doctorProfileId, setDoctorProfileId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const fileUploadSetting = useRef(false);
  const didCancel = useRef(false);
  const uploadCancel = useRef(false);
  const nextID = useRef(1);
  const [inputItems, setInputItems] = useState([{ id: 0, title: '' }]);

  const {
    publicUrl: distinctPublicUrl,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useAntFileUpload(distinctFileInputEvent);

  const postPatchData = {
    name: state.name,
    photo: state.photo || null,
    hospital: state.hospital,
    specialty: state.specialty,
    gender: state.gender,
    profileType: state.profileType,
    briefHistories: inputItems?.map((item) => item.title).filter(Boolean),
  };

  const { mutate: putDoctorProfile, done: isPosted } = useMutate(
    postDoctorProfile,
    postPatchData,
  );

  const { mutate: changeDoctorProfile, done: isPatched } = useMutate(
    patchDoctorProfile,
    doctorProfileId,
    postPatchData,
  );

  const { mutate: eraseDoctorProfile, done: isDeleted } = useMutate(
    deleteDoctorProfile,
    doctorProfileId,
  );

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? JSON.parse(params.id).id : params.new);
    if (params.id) {
      const parseParams = JSON.parse(params.id);
      setDoctorProfileId(parseParams.id);
      setState({
        name: parseParams.name,
        photo: parseParams.photo,
        gender: parseParams.gender,
        profileType: parseParams.profileType,
        hospital: parseParams.hospital,
        specialty: parseParams.specialty,
        briefHistories: parseParams.briefHistories,
      });
      setInputItems(
        parseParams?.briefHistories?.map((history, index) => ({
          id: index,
          title: history,
        })),
      );
      didCancel.current = true;
      setIsLoading(false);
    } else {
      setDoctorProfileId('');
      didCancel.current = true;
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId]);

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      name: values.name,
      hospital: values.hospital,
      specialty: values.specialty,
    });
    if (doctorProfileId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      eraseDoctorProfile();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeDoctorProfile();
      setIsPatch(false);
    }
    if (isPost) {
      putDoctorProfile();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({
        message: 'JPG/PNG만 업로드 가능합니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      notification.error({
        message: '이미지가 10MB보다 큽니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    return isJpgOrPng && isLt10M;
  };

  const uploadImg = async (e) => {
    if (e !== undefined) {
      setDistinctFileInputEvent(e);
      uploadCancel.current = true;
      setLoading(true);
    }
  };

  const noop = () => {};

  const deleteThumbImg = () => {
    setState({
      photo: '',
    });
    notification.success({
      message: '이미지를 삭제하였습니다.',
      description: '이미지를 다시 업로드해주세요.',
    });
  };

  const uploadProps = {
    customRequest: noop,
    onChange: uploadImg,
    onPreview: noop,
  };

  useEffect(() => {
    if (distinctFileInputEvent && uploadCancel.current) {
      distinctImageUpload();
      uploadCancel.current = false;
      fileUploadSetting.current = true;
    }
  }, [distinctFileInputEvent, distinctImageUpload]);

  useEffect(() => {
    if (distinctImageUploadDone && distinctPublicUrl) {
      notification.success({
        message: '이미지 업로드에 성공하였습니다.',
        description: '의료진 프로필 등록에 성공하였습니다.',
      });
      setState({ photo: distinctPublicUrl });
      distinctUploadInitialize();
      setLoading(false);
    }
  }, [distinctImageUploadDone, distinctPublicUrl, distinctUploadInitialize]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const addInput = () => {
    const input = {
      id: nextID.current,
      title: '',
    };
    setInputItems([...inputItems, input]);
    nextID.current += 1;
  };

  const deleteInput = (index) => {
    setInputItems(inputItems.filter((item) => item.id !== index));
  };

  const handleTitleChange = (e, index) => {
    const inputItemsCopy = JSON.parse(JSON.stringify(inputItems));
    inputItemsCopy[index].title = e.target.value;
    setInputItems(inputItemsCopy);
  };

  const onGenderChange = (e) => {
    setState({
      gender: e.target.value,
    });
  };

  const onProfileTypeChange = (e) => {
    setState({
      profileType: e.target.value,
    });
  };

  if (isLoading) return <ElementLoading type="의료진 프로필" />;
  return (
    <>
      <WindowHeader title="의료진 프로필" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              name: state.name,
              gender: state.gender,
              hospital: state.hospital,
              specialty: state.specialty,
              profileType: state.profileType,
            }}
          >
            <Form.Item
              label="이름"
              name="name"
              rules={[
                {
                  required: true,
                  message: '이름을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="프로필 이미지" name="photo">
              <Upload
                {...uploadProps}
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {state?.photo ? (
                  <img
                    src={state?.photo}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              {state?.photo && (
                <Button
                  type="primary"
                  style={{ width: 104 }}
                  danger
                  onClick={deleteThumbImg}
                >
                  프로필 삭제
                </Button>
              )}
            </Form.Item>

            <Form.Item label="성별" name="gender">
              <Radio.Group
                onChange={onGenderChange}
                value={state.gender}
                optionType="button"
                buttonStyle="solid"
                options={genderOptions}
              />
            </Form.Item>

            <Form.Item label="병원/단체 명" name="hospital">
              <Input />
            </Form.Item>

            <Form.Item label="소속과" name="specialty">
              <Input />
            </Form.Item>

            <Form.Item label="프로필 유형" name="profileType">
              <Radio.Group
                onChange={onProfileTypeChange}
                value={state.profileType}
                optionType="button"
                buttonStyle="solid"
                options={profileTypeOptions}
              />
            </Form.Item>

            <Form.Item label="프로필 추가" name="memo">
              <Button
                style={{ width: 104, marginBottom: 16 }}
                onClick={addInput}
              >
                추가하기
              </Button>
              {inputItems?.map((item, index) => (
                <div>
                  <Form.Item name="specialty">
                    <label>{index + 1}번:&nbsp;</label>
                    <Input
                      type="text"
                      className={`title-${index}`}
                      onChange={(e) => handleTitleChange(e, index)}
                      value={item.title}
                      style={{ width: '80%' }}
                    />
                    {index !== 0 && (
                      <Button danger onClick={() => deleteInput(item.id)}>
                        삭제
                      </Button>
                    )}
                  </Form.Item>
                </div>
              ))}
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {doctorProfileId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default DoctorProfileWindow;
