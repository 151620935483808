import React, { useEffect, useReducer, useState, useCallback } from 'react';
import {
  Layout,
  Row,
  Col,
  Form,
  Input,
  Select,
  Table,
  Button,
  Alert,
  Radio,
  Checkbox,
  DatePicker,
} from 'antd';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { getBytesOfString } from '../../../util';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { notificationCreators } from '../../../store/reducers/notification.reducer';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { useMutate } from '../../../hooks/useRequest';
import {
  postNotification,
  postScheduleNotification,
  deleteScheduleNotification,
} from '../../../services/notificationService';
import { userCreators } from '../../../store/reducers/user.reducer';

const clientId = process.env.REACT_APP_CLIENT_ID || '';
const COMPLETE_FLAG = {
  all: null,
  complete: true,
  pending: false,
  user: null,
};

const Notification = () => {
  // declare dispatch function & variables for fetching data from store
  const dispatch = useDispatch();
  const {
    users,
    notUsers,
    notificationInfo,
    pushListInfo,
    notificationContents,
    newsFeedList,
    diseaseTypesInfo,
  } = useSelector((state) => {
    return {
      users: state.userReducer.adminUser.data,
      notUsers: state.userReducer.notAdminUser.data,
      pushListInfo: state.notificationReducer.pushList.data,
      notificationInfo: state.notificationReducer.notification.data,
      notificationContents: state.notificationReducer.contents.data,
      newsFeedList: state.notificationReducer.newsFeedList.data,
      diseaseTypesInfo: state.diseaseTypeReducer.diseaseTypes?.data
        ? state.diseaseTypeReducer.diseaseTypes.data.map(({ id, krName }) => ({
            id,
            krName,
          }))
        : null,
    };
  }, shallowEqual);

  const initialState = {
    type: null,
    selectedTitle: null,
    selectedDiseaseType: [],
    selectedUser: [],
    title: '',
    body: '',
    memo: '',
    selectedSendAt: null,
  };

  const { Content } = Layout;
  const { Option } = Select;
  const { Search } = Input;
  const [form] = Form.useForm();
  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  // component state
  const [state, setState] = useReducer(stateReducer, initialState);
  const [reload, setReload] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [isPost, setIsPost] = useState(false);

  const [typeValue, setTypeValue] = useState('레어노트 콘텐츠');
  const [togetherTypeValue, setTogetherTypeValue] = useState(
    'RARE_TOGETHER_CONTENT_DETAIL',
  );
  const [completedValue, setCompletedValue] = useState('all');
  const [isPatientGroup, setIsPatientGroup] = useState(true);
  const [checkDiseaseAll, setCheckDiseaseAll] = useState(true);
  const [plainOptions, setPlainOptions] = useState([]);
  const [checkScheduled, setCheckScheduled] = useState(false);
  const [selectId, setSelectId] = useState(null);
  const [confirmErase, setConfirmErase] = useState(false);

  // 푸시/알림 리스트
  const [dataSource, setDataSource] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const [listLoad, setListLoad] = useState(false);

  // 푸시내역 리스트
  const [pushListDataSource, setPushListDataSource] = useState('');
  const [pushListFilteredData, setPushListFilteredData] = useState(null);
  const [pushListLoad, setPushListLoad] = useState(false);

  // dispatch to fetch data
  const getData = useCallback(() => {
    dispatch(notificationCreators.fetchAllNotificationContents.request());
    dispatch(notificationCreators.fetchNewsFeedList.request());
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    dispatch(userCreators.fetchAdminUser.request({ clientId, isAdmin: true }));
    dispatch(
      userCreators.fetchNotAdminUser.request({ clientId, isAdmin: false }),
    );
  }, [dispatch]);

  // data fetching on mount
  useEffect(() => {
    getData();
  }, [getData]);

  const getListData = useCallback(() => {
    dispatch(notificationCreators.fetchAllNotificationHistory.request({}));
    setListLoad(true);
  }, [dispatch]);

  const getPushListData = useCallback(() => {
    dispatch(notificationCreators.fetchPushMessageList.request({}));
    setPushListLoad(true);
  }, [dispatch]);

  useEffect(() => {
    setListLoad(false);
    setDataSource(notificationInfo);
    setFilteredData(notificationInfo?.length);
  }, [notificationInfo]);

  useEffect(() => {
    setPushListLoad(false);
    setPushListDataSource(pushListInfo);
    setPushListFilteredData(pushListInfo?.length);
  }, [pushListInfo]);

  const {
    mutate: putNotification,
    done: isPosted,
    initialize: initializePost,
  } = useMutate(postNotification, {
    type: state.type,
    diseaseTypeIds: state.selectedDiseaseType,
    userIds: state.selectedUser,
    contentsIds: [state.selectedTitle?.toString()],
    body: state.body,
    memo: state.memo,
    title: state.title || null,
    completed: COMPLETE_FLAG[completedValue],
  });

  const {
    mutate: putScheduleNotification,
    done: isShedulePosted,
    error: scheduleError,
    initialize: initializeShedulePost,
  } = useMutate(postScheduleNotification, {
    type: state.type,
    diseaseTypeIds: state.selectedDiseaseType,
    contentsId: state.selectedTitle?.toString(),
    body: state.body,
    memo: state.memo,
    title: state.title || null,
    scheduledAt: dayjs(state.selectedSendAt),
  });

  const {
    mutate: eraseSheduleNotification,
    done: isSheduleDeleted,
    initialize: initializeSheduleDelete,
  } = useMutate(deleteScheduleNotification, selectId);

  // check ready for distribute
  const distribute = () => {
    const contentsByte = getBytesOfString(state.body);
    const titlesByte = getBytesOfString(state.title);
    const maxByte = 250;
    if (titlesByte > maxByte) {
      window.alert(`제목은 최대 50자까지 입력할 수 있습니다.`);
      return;
    }
    if (contentsByte > maxByte) {
      window.alert(`내용은 최대 50자까지 입력할 수 있습니다.`);
      return;
    }
    if (
      (!checkDiseaseAll || completedValue === 'user') &&
      !state.selectedUser.length &&
      !state.selectedDiseaseType?.length
    ) {
      window.alert('발송 대상(유저 또는 질환)을 지정해주세요.');
      return;
    }
    if (checkScheduled) {
      if (window.confirm('알림/푸시를 보내시겠습니까?')) {
        putScheduleNotification();
        setReload(true);
      }
    } else if (window.confirm('알림/푸시를 보내시겠습니까?')) {
      putNotification();
      setReload(true);
    }
  };

  useEffect(() => {
    if (reload && isPosted) {
      form.resetFields();
      setReload(false);
      setCheckScheduled(false);
      initializePost();
      getData();
      window.alert(`알림/푸시 전송 완료하였습니다.`);
    }
  }, [form, getData, initializePost, isPosted, reload]);

  useEffect(() => {
    if (reload && isShedulePosted) {
      form.resetFields();
      setReload(false);
      setCheckScheduled(false);
      initializeShedulePost();
      getData();
      window.alert(`예약 알림/푸시 전송 완료하였습니다.`);
    }
  }, [form, getData, initializeShedulePost, isShedulePosted, reload]);

  useEffect(() => {
    if (reload && scheduleError) {
      form.resetFields();
      setCheckScheduled(false);
      setReload(false);
      initializeShedulePost();
      getData();
    }
  }, [form, getData, initializeShedulePost, scheduleError, reload]);

  useEffect(() => {
    if (isSheduleDeleted) {
      initializeSheduleDelete();
      getListData();
    }
  }, [isSheduleDeleted, initializeSheduleDelete, getListData]);

  useEffect(() => {
    if (confirmErase) {
      eraseSheduleNotification();
      setConfirmErase(false);
    }
  }, [selectId, confirmErase, eraseSheduleNotification]);

  const onTypeChange = (e) => {
    setTypeValue(e.target.value);
    setCheckDiseaseAll(true);
    setCompletedValue('all');
    setIsPatientGroup(true);
    setState({ selectedTitle: null, selectedDiseaseType: [] });
    form.setFieldsValue({
      selectedTitle: null,
      selectedUser: [],
      selectedDiseaseType: [],
    });
  };

  const onCompletedChange = (e) => {
    setCompletedValue(e.target.value);
    if (e.target.value === 'all') form.setFieldsValue({ selectedUser: [] });
  };

  const onPatientGroupChange = (e) => {
    setIsPatientGroup(e.target.value);
  };

  const onTogetherTypeChange = (e) => {
    setTogetherTypeValue(e.target.value);
    if (e.target.value !== 'RARE_TOGETHER_CONTENT_DETAIL') {
      setState({ selectedTitle: null });
      form.setFieldsValue({ selectedTitle: null });
    }
  };

  const onTitleChange = (e) => {
    setState({ selectedTitle: JSON.parse(e).id });
    setPlainOptions(
      JSON.parse(e)
        .diseaseTypeIds?.map((id) => notificationContents?.diseaseTypes[id])
        .filter(Boolean),
    );
  };

  const onDiseaseAllChange = (e) => {
    setCheckDiseaseAll(e.target.checked);
  };

  const onScheduledChange = (e) => {
    setCheckScheduled(e.target.checked);
  };

  const groupOptions = (value, text) => {
    if (value === null) {
      return JSON.stringify({
        id: null,
        text: null,
        diseaseTypeIds: [],
      });
    }
    return JSON.stringify({
      id: value?.id,
      text: value?.[text],
      diseaseTypeIds: value?.diseaseTypeIds,
    });
  };

  const onFinish = (values) => {
    const allDiseases = [];
    plainOptions?.forEach((d1) => {
      diseaseTypesInfo?.forEach((d2) => {
        if (d1 === d2?.krName) {
          allDiseases.push(d2?.id);
        }
      });
    });

    const resultSelectDiseaseType = [];
    values?.selectedDiseaseType?.forEach((d1) => {
      diseaseTypesInfo?.forEach((d2) => {
        if (d1 === d2?.krName) {
          resultSelectDiseaseType.push(d2?.id);
        }
      });
    });
    const resultSelectUser = [];
    values?.selectedUser?.forEach((u1) => {
      users.forEach((u2) => {
        if (u1 === u2?.email) {
          resultSelectUser.push(u2?.id);
        }
      });
      notUsers.forEach((u2) => {
        if (u1 === u2?.email) {
          resultSelectUser.push(u2?.id);
        }
      });
    });

    switch (typeValue) {
      case '레어노트 콘텐츠':
        if (checkDiseaseAll) {
          setState({
            type: 'RARENOTE_CONTENT_EVERYONE',
            selectedDiseaseType: [],
            selectedUser: [],
          });
        } else {
          setState({
            type: 'RARENOTE_CONTENT',
            selectedDiseaseType: resultSelectDiseaseType,
            selectedUser: resultSelectUser,
          });
        }
        break;
      case '공지':
        if (checkDiseaseAll) {
          setState({
            type: 'NOTICE_EVERYONE',
            selectedDiseaseType: [],
            selectedUser: [],
          });
        } else {
          setState({
            type: 'NOTICE',
            selectedDiseaseType: resultSelectDiseaseType,
            selectedUser: resultSelectUser,
          });
        }
        break;
      case '설문':
        switch (completedValue) {
          case 'complete':
            setState({
              type: 'HEALTH_PROFILE_COMPLETE',
              selectedUser: undefined,
              selectedDiseaseType: undefined,
            });
            break;
          case 'pending':
            setState({
              type: 'HEALTH_PROFILE_PENDING',
              selectedUser: undefined,
              selectedDiseaseType: undefined,
            });
            break;
          case 'user':
            setState({
              type: 'HEALTH_PROFILE_TO_TARGET_USER',
              selectedUser: resultSelectUser,
              selectedDiseaseType: undefined,
            });
            break;
          default:
            setState({
              type: 'HEALTH_PROFILE_IN_SERVEY',
              selectedUser: undefined,
              selectedDiseaseType: undefined,
            });
        }
        break;
      case '커뮤니티':
        if (checkDiseaseAll) {
          setState({
            type: `${togetherTypeValue}_EVERYONE`,
            selectedDiseaseType: [],
            selectedUser: [],
          });
        } else {
          setState({
            type: togetherTypeValue,
            selectedDiseaseType: resultSelectDiseaseType,
            selectedUser: resultSelectUser,
          });
        }
        break;
      case '환자 모임':
        if (isPatientGroup) {
          setState({
            type: 'PATIENT_GROUP_TO_MEMBER',
            selectedDiseaseType: [],
            selectedUser: [],
          });
        } else if (checkDiseaseAll) {
          setState({
            type: 'PATIENT_GROUP_EVERYONE',
            selectedDiseaseType: [],
            selectedUser: [],
          });
        } else {
          setState({
            type: 'PATIENT_GROUP',
            selectedDiseaseType: resultSelectDiseaseType,
            selectedUser: resultSelectUser,
          });
        }
        break;
      default:
        setShowAlert(true);
        break;
    }

    setState({
      title: values.title,
      body: values.body,
      memo: values.memo,
    });
    setShowAlert(false);
    setIsPost(true);
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  useEffect(() => {
    if (isPost) {
      distribute();
      setIsPost(false);
    }
  }, [isPost]);

  const onSearch = (e, type) => {
    const {
      typeDataSource,
      originList,
      setTypeDataSource,
      setTypeFilteredData,
    } =
      type === 'notification'
        ? {
            typeDataSource: dataSource,
            originList: notificationInfo,
            setTypeDataSource: setDataSource,
            setTypeFilteredData: setFilteredData,
          }
        : {
            typeDataSource: pushListDataSource,
            originList: pushListInfo,
            setTypeDataSource: setPushListDataSource,
            setTypeFilteredData: setPushListFilteredData,
          };

    if (e === '') {
      setTypeDataSource(originList);
      setTypeFilteredData(originList?.length);
    } else {
      const filteredDatas = typeDataSource?.filter((entry) =>
        Object?.values(entry).toString().includes(e),
      );
      setTypeDataSource(filteredDatas);
      setTypeFilteredData(filteredDatas.length);
    }
  };

  const columns = [
    {
      title: '콘텐츠 제목',
      dataIndex: 'contentsTitle',
      key: 'contentsTitle',
      width: 100,
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
      render: (text, record) =>
        text === null
          ? newsFeedList?.find(
              (newsFeed) =>
                newsFeed?.id?.toString() === record?.contentsId?.toString(),
            )?.contentsTitle
          : text,
    },
    {
      title: '콘텐츠ID',
      dataIndex: 'contentsId',
      key: 'contentsId',
      width: 180,
    },
    {
      title: '[제목] 내용',
      dataIndex: 'message',
      key: 'message',
      width: 140,
      sorter: {
        compare: (a, b) => a?.message?.localeCompare(b?.message),
      },
    },
    {
      title: '발송 시간',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 100,
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
      render: (createdAt, record) => (
        <>
          {createdAt ? (
            <>{createdAt}</>
          ) : (
            <>
              {record?.scheduledAt}{' '}
              <Button
                size="small"
                onClick={() => {
                  setSelectId(record?.id);
                  if (window.confirm('푸시 예약을 취소하시겠습니까?')) {
                    setConfirmErase(true);
                  }
                }}
              >
                취소
              </Button>
            </>
          )}
        </>
      ),
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypeKrName',
      key: 'diseaseTypeKrName',
      width: 160,
      sorter: {
        compare: (a, b) =>
          a?.diseaseTypeKrName?.localeCompare(b?.diseaseTypeKrName),
      },
    },
    {
      title: '전송 수',
      dataIndex: 'sendCount',
      key: 'sendCount',
      width: 50,
    },
    {
      title: '수신 수',
      dataIndex: 'receiveCount',
      key: 'receiveCount',
      width: 50,
    },
    {
      title: '읽은 수',
      dataIndex: 'readCount',
      key: 'readCount',
      width: 50,
    },
    {
      title: '비고',
      dataIndex: 'memo',
      key: 'memo',
      width: 50,
    },
  ];

  const expandedRowRender = (record) => {
    const expandedColumns = [
      {
        title: '질환',
        dataIndex: 'diseaseTypeKrName',
        key: 'diseaseTypeKrName',
        width: 600,
      },
      {
        title: '전송 수',
        dataIndex: 'sendCount',
        key: 'sendCount',
        width: 100,
      },
      {
        title: '수신 수',
        dataIndex: 'receiveCount',
        key: 'receiveCount',
        width: 100,
      },
      {
        title: '읽은 수',
        dataIndex: 'readCount',
        key: 'readCount',
        width: 100,
      },
    ];

    return (
      <Table
        columns={expandedColumns}
        dataSource={record?.diseases}
        pagination={false}
      />
    );
  };

  const pushListColumns = [
    {
      title: '전송일',
      dataIndex: 'sendAt',
      key: 'sendAt',
      width: 50,
      sorter: {
        compare: (a, b) => a?.sendAt?.localeCompare(b?.sendAt),
      },
    },
    {
      title: '카테고리',
      dataIndex: 'category',
      key: 'category',
      width: 50,
      sorter: {
        compare: (a, b) => a?.category?.localeCompare(b?.category),
      },
    },
    {
      title: '문구',
      dataIndex: 'message',
      key: 'message',
      width: 150,
      sorter: {
        compare: (a, b) => a?.message?.localeCompare(b?.message),
      },
    },
    {
      title: '전송 수',
      dataIndex: 'sendCount',
      key: 'sendCount',
      width: 50,
      sorter: (a, b) => a.sendCount - b.sendCount,
    },
    {
      title: '읽은 수',
      dataIndex: 'readCount',
      key: 'readCount',
      width: 50,
      sorter: (a, b) => a.readCount - b.readCount,
    },
    {
      title: '비고',
      dataIndex: 'adminMemo',
      key: 'adminMemo',
      width: 100,
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabOperation" link="notification" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="운영"
            subTitle="콘텐츠 알림/푸시"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Form
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              {/* 종류 */}
              <Form.Item label="종류">
                <Radio.Group onChange={onTypeChange} value={typeValue}>
                  <Radio value="레어노트 콘텐츠">레어노트 콘텐츠</Radio>
                  <Radio value="공지">공지</Radio>
                  <Radio value="설문">설문</Radio>
                  <Radio value="커뮤니티">커뮤니티</Radio>
                  <Radio value="환자 모임">환자 모임</Radio>
                </Radio.Group>
              </Form.Item>

              {/* 제목 */}
              {(typeValue === '레어노트 콘텐츠' ||
                typeValue === '공지' ||
                typeValue === '환자 모임') && (
                <Form.Item
                  name="selectedTitle"
                  label={typeValue}
                  hasFeedback
                  rules={[{ required: true, message: '제목을 선택해주세요' }]}
                  value={state.selectedTitle}
                >
                  <Select
                    showSearch
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="제목을 선택해주세요"
                    onChange={onTitleChange}
                  >
                    {notificationContents?.[typeValue]?.map(
                      (notificationContent) => (
                        <Option
                          key={groupOptions(
                            notificationContent,
                            typeValue === '환자 모임' ? 'name' : 'title',
                          )}
                        >
                          {typeValue === '환자 모임'
                            ? notificationContent?.name
                            : notificationContent?.title}
                        </Option>
                      ),
                    )}
                  </Select>
                </Form.Item>
              )}

              {/* 제목 */}
              {typeValue === '설문' && (
                <Form.Item
                  name="selectedTitle"
                  label={typeValue}
                  hasFeedback
                  rules={[{ required: true, message: '제목을 선택해주세요' }]}
                  value={state.selectedTitle}
                >
                  <Select
                    showSearch
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="제목을 선택해주세요"
                    onChange={onTitleChange}
                  >
                    {notificationContents?.[typeValue]?.map(
                      (notificationContent) =>
                        notificationContent.id !==
                          '9ac2117c-2bf1-4ffe-b633-0d1328862058' &&
                        notificationContent.id !==
                          '6652edde-0a7c-4880-b79a-5e267f049e15' && (
                          <Option
                            key={groupOptions(notificationContent, 'title')}
                          >
                            {notificationContent?.title}
                          </Option>
                        ),
                    )}
                  </Select>
                </Form.Item>
              )}

              {/* 커뮤니티 제목 */}
              {typeValue === '커뮤니티' && (
                <>
                  {togetherTypeValue === 'RARE_TOGETHER_CONTENT_DETAIL' && (
                    <Form.Item
                      label="커뮤니티 ID"
                      name="selectedTitle"
                      onChange={(e) =>
                        setState({ selectedTitle: e.target.value })
                      }
                      rules={[
                        {
                          required: true,
                          message: '커뮤니티 ID를 확인해주세요.',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  <Form.Item label="커뮤니티 종류">
                    <Radio.Group
                      onChange={onTogetherTypeChange}
                      value={togetherTypeValue}
                    >
                      <Radio value="RARE_TOGETHER_CONTENT_DETAIL">
                        커뮤니티 상세(ID)
                      </Radio>
                      <Radio value="RARE_TOGETHER_CONTENT_INSERT">
                        커뮤니티 입력 상세
                      </Radio>
                      <Radio value="RARE_TOGETHER_RECENT_CONTENT_LIST">
                        소통 탭
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </>
              )}

              {/* 설문은 선택질환들을 체크 할 수 있습니다. */}
              {typeValue === '설문' && (
                <>
                  <Form.Item label="종류">
                    <Radio.Group
                      onChange={onCompletedChange}
                      value={completedValue}
                    >
                      <Radio value="all">해당 질환 모두</Radio>
                      <Radio value="complete">참여완료</Radio>
                      <Radio value="pending">미참여</Radio>
                      <Radio value="user">유저 선택</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {completedValue === 'user' && (
                    <Form.Item name="selectedUser" label="유저" hasFeedback>
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="유저를 선택해주세요"
                      >
                        {users?.map((user) => (
                          <Option key={user.email}>{user.email}</Option>
                        ))}
                        {notUsers?.map((user) => (
                          <Option key={user.email}>{user.email}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </>
              )}

              {/* 환자 모임은 대상을 체크 할 수 있습니다. */}
              {typeValue === '환자 모임' && (
                <>
                  <Form.Item label="대상">
                    <Radio.Group
                      onChange={onPatientGroupChange}
                      value={isPatientGroup}
                    >
                      <Radio value={true}>해당 환자 모임 회원</Radio>
                      <Radio value={false}>질환별 선택</Radio>
                    </Radio.Group>
                  </Form.Item>
                </>
              )}

              {/* 레어노트 콘텐츠, 공지, 커뮤니티은 전체선택, 선택질환들을 체크 할 수 있습니다. */}
              {(typeValue === '레어노트 콘텐츠' ||
                typeValue === '공지' ||
                typeValue === '커뮤니티' ||
                (typeValue === '환자 모임' && !isPatientGroup)) && (
                <>
                  <Form.Item label="질환">
                    <Checkbox
                      onChange={onDiseaseAllChange}
                      checked={checkDiseaseAll}
                    >
                      전체
                    </Checkbox>
                  </Form.Item>
                  {!checkDiseaseAll && (
                    <>
                      <Form.Item
                        name="selectedDiseaseType"
                        label="질환"
                        hasFeedback
                      >
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="질환을 선택해주세요"
                        >
                          {diseaseTypesInfo?.map((diseaseType) => (
                            <Option key={diseaseType.krName}>
                              {diseaseType.krName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item name="selectedUser" label="유저" hasFeedback>
                        <Select
                          mode="multiple"
                          allowClear
                          style={{ width: '100%' }}
                          placeholder="유저를 선택해주세요"
                        >
                          {users?.map((user) => (
                            <Option key={user.email}>{user.email}</Option>
                          ))}
                          {notUsers?.map((user) => (
                            <Option key={user.email}>{user.email}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </>
                  )}
                </>
              )}

              <Form.Item
                label="알림/푸시 제목"
                name="title"
                rules={[
                  {
                    required: true,
                    message: '제목을 확인해주세요.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="알림/푸시 내용"
                name="body"
                rules={[
                  {
                    required: true,
                    message: '내용를 확인해주세요.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="비고" name="memo">
                <Input />
              </Form.Item>

              <Form.Item label="예약">
                <Checkbox onChange={onScheduledChange} checked={checkScheduled}>
                  체크박스 선택 시 예약 발송 (미선택 시 즉시 발송)
                </Checkbox>
              </Form.Item>
              {checkScheduled && (
                <Form.Item
                  label="예약 시간"
                  name="sendAt"
                  rules={[
                    {
                      required: true,
                      message: '예약 시간을 확인해주세요.',
                    },
                  ]}
                >
                  <DatePicker
                    showTime
                    showNow={false}
                    disabledDate={(current) => {
                      return (
                        current && current < dayjs().add(-1, 'day').endOf('day')
                      );
                    }}
                    disabledHours={() => [
                      0,
                      1,
                      2,
                      3,
                      4,
                      5,
                      6,
                      7,
                      8,
                      21,
                      22,
                      23,
                    ]}
                    onChange={(date, dateString) => {
                      setState({
                        selectedSendAt: dateString,
                      });
                    }}
                  />
                </Form.Item>
              )}
              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 20,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: 100 }}
                  disabled={reload}
                  loading={reload}
                >
                  보내기
                </Button>
                <Form.Item noStyle>
                  &nbsp;&nbsp;실서비스에 반영할 내용을 등록하고 보내기 버튼을
                  눌러주세요.
                </Form.Item>
              </Form.Item>
              {showAlert && (
                <Row>
                  <Col span={20} offset={4} style={{ marginBottom: 10 }}>
                    <Alert
                      message="에러"
                      description="필수값을 확인해주세요."
                      type="error"
                      showIcon
                    />
                  </Col>
                </Row>
              )}
            </Form>

            <div className="divider" />

            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(e) => onSearch(e, 'notification')}
            />
            <div className="searchResult">검색결과 {filteredData}개</div>
            <Button type="primary" disabled={listLoad} onClick={getListData}>
              기존 리스트 불러오기
            </Button>
            <Table
              rowKey={(record, index) => index}
              columns={columns}
              loading={listLoad}
              expandable={{ expandedRowRender }}
              dataSource={dataSource}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(_, __, ___, { currentDataSource }) =>
                setFilteredData(currentDataSource.length)
              }
              size="small"
              bordered
            />

            <div className="divider" />

            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(e) => onSearch(e, 'pushList')}
            />
            <div className="searchResult">
              검색결과 {pushListFilteredData}개
            </div>
            <Button
              type="primary"
              disabled={pushListLoad}
              onClick={getPushListData}
            >
              푸시내역 리스트 불러오기
            </Button>
            <Table
              rowKey={(record, index) => index}
              columns={pushListColumns}
              loading={pushListLoad}
              dataSource={pushListDataSource}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(_, __, ___, { currentDataSource }) =>
                setFilteredData(currentDataSource.length)
              }
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Notification;
