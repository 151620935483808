import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Layout, Table, Input, Button, Image } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { doctorProfileCreators } from '../../../store/reducers/doctorProfile.reducer';
import { NEW } from '../../../window/util/utils';
import useWindow from '../../../hooks/useWindow';
import * as listMap from '../../../util/listMap';

const DoctorProfile = () => {
  const dispatch = useDispatch();
  const { doctorProfile } = useSelector((state) => {
    return {
      doctorProfile: state?.doctorProfileReducer?.doctorProfile.data,
    };
  }, shallowEqual);

  const getData = useCallback(() => {
    dispatch(doctorProfileCreators.fetchAllDoctorProfiles.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setDataSource(doctorProfile);
    setFilteredData(doctorProfile?.length);
  }, [doctorProfile]);

  const [dataSource, setDataSource] = useState('');
  const { Search } = Input;
  const { Content } = Layout;
  const [filteredData, setFilteredData] = useState(null);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (record) => {
    if (findWindowById(JSON.stringify(record))) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({ id: JSON.stringify(record), dataType: 'doctorProfile' });
  };

  const handleCreateNewWindow = () => {
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: 'doctorProfile',
    });
  };

  const onSearch = (e) => {
    if (e === '') {
      setDataSource(doctorProfile);
      setFilteredData(doctorProfile?.length);
    } else {
      const filteredDatas = dataSource?.filter((entry) =>
        Object?.values(entry).toString().includes(e),
      );
      setDataSource(filteredDatas);
      setFilteredData(filteredDatas.length);
    }
  };

  const columns = [
    {
      title: '프로필',
      width: 37,
      dataIndex: 'profilePhoto',
      key: 'profilePhoto',
      render: (value) => (
        <>
          <Image width={37} src={value} />
        </>
      ),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      width: '15%',
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
      },
      render: (text, record) => (
        <a
          style={{ color: '#40a9ff' }}
          onClick={() => handleCreateEditWindow(record)}
        >
          {text}
        </a>
      ),
    },
    {
      title: '병원명',
      dataIndex: 'hospital',
      key: 'hospital',
      sorter: {
        compare: (a, b) => a.hospital.localeCompare(b.hospital),
      },
    },
    {
      title: '성별',
      dataIndex: 'gender',
      key: 'gender',
      sorter: {
        compare: (a, b) => a.gender.localeCompare(b.gender),
      },
      render: (gender) => <>{listMap.genderMap[gender]}</>,
    },

    {
      title: '소속과',
      dataIndex: 'specialty',
      key: 'specialty',
      sorter: {
        compare: (a, b) => a.specialty.localeCompare(b.specialty),
      },
    },

    {
      title: '프로필 유형',
      dataIndex: 'profileType',
      key: 'profileType',
      sorter: {
        compare: (a, b) => a.profileType.localeCompare(b.profileType),
      },
      render: (profileType) => <>{listMap.doctorProfileTypeMap[profileType]}</>,
    },

    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a, b) => a.createdAt.localeCompare(b.createdAt),
      },
      render: (text, record) => moment(text).format('YYYY-MM-DD'),
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="doctorProfile" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="데이터"
            subTitle="의료진 프로필"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button
              type="primary"
              style={{ width: 100 }}
              onClick={handleCreateNewWindow}
            >
              추가
            </Button>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={onSearch}
            />
            <div className="searchResult">
              {doctorProfile && `검색결과 ${filteredData}개`}
            </div>
            <Table
              rowKey={(item) => {
                return item.id;
              }}
              columns={columns}
              dataSource={dataSource}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              style={{ marginBottom: 60 }}
              size="small"
              onChange={(_, __, ___, { currentDataSource }) =>
                setFilteredData(currentDataSource.length)
              }
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DoctorProfile;
