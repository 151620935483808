import React from 'react';
import { Form, Button, ButtonGroup, Row, Col } from 'react-bootstrap';
import { Dropdown } from 'semantic-ui-react';

const MultiSelectDropdown = ({ text, data, isEssential = false }) => {
  // add element (when select dropdown)
  const addElement = ({ element, selectedArray, setterFunction, keyName }) => {
    const alreadySelected = selectedArray.some(
      (selectedElement) => selectedElement.id === element.id,
    );
    const newSelectedRelatedDnas = alreadySelected
      ? selectedArray
      : [...selectedArray, element];
    setterFunction({
      target: { name: keyName, value: newSelectedRelatedDnas },
    });
  };

  // remove element (when delete selected element)
  const removeElement = ({
    elementId,
    selectedArray,
    setterFunction,
    keyName,
  }) => {
    const newSelectedRelatedDnas = selectedArray.filter(
      (selectedElement) => selectedElement.id !== elementId,
    );
    setterFunction({
      target: { name: keyName, value: newSelectedRelatedDnas },
    });
  };

  return (
    <Form.Group className="formContent" as={Row}>
      <Form.Label column sm="3" md="2" xl="1">
        {text}
        {isEssential && <span className="required-tag">*필수</span>}
      </Form.Label>
      <Col sm="9" md="10" xl="11">
        {data.map((dataElement) => (
          <>
            <Form.Group>
              <Dropdown
                disabled={dataElement.disabled ?? false}
                className="dropdownButton"
                button
                labeled
                options={dataElement.option?.map((element) => ({
                  key: element.id
                    ? element.id
                    : element.krName
                    ? element.krName
                    : element.name,
                  text: element.krName
                    ? element.krName
                    : element.name
                    ? element.name
                    : element.productName,
                  value: element,
                }))}
                search
                text={dataElement.optionName}
                value
                onChange={(e, changeData) => {
                  if (changeData.value !== null) {
                    addElement({
                      element: changeData.value,
                      selectedArray: dataElement.selectedOption,
                      setterFunction: dataElement.setOption,
                      keyName: dataElement.name,
                    });
                  }
                }}
              />
            </Form.Group>
            <Form.Group className="formContent" as={Row}>
              {dataElement.selectedOption?.map((element) => {
                return (
                  <React.Fragment key={element.id}>
                    <ButtonGroup size="sm">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          removeElement({
                            elementId: element.id,
                            selectedArray: dataElement.selectedOption,
                            setterFunction: dataElement.setOption,
                            keyName: dataElement.name,
                          });
                        }}
                      >
                        &times;
                      </Button>
                      <Button
                        className="mr-2"
                        variant="outline-secondary"
                        disabled
                        style={{
                          cursor: 'default',
                          color: '#000',
                        }}
                      >
                        {element.krName
                          ? element.krName
                          : element.name
                          ? element.name
                          : element.productName}
                      </Button>
                    </ButtonGroup>
                    <br />
                  </React.Fragment>
                );
              })}
            </Form.Group>
          </>
        ))}
      </Col>
    </Form.Group>
  );
};

export default MultiSelectDropdown;
