import React, { useEffect, useState, useRef } from 'react';
import { Table, Input, Select, notification, Button, Image, Modal } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import download from 'downloadjs';
import { SmileOutlined, DownloadOutlined } from '@ant-design/icons';
import { useFetch } from '../../../hooks/useRequest';
import useWindow from '../../../hooks/useWindow';
import {
  fetchMutationDnaReportRegisterRequestContent as fetchMutationDnaReportRegisterRequestContentService,
  patchMutationDnaReportRegisterRequest as patchMutationDnaReportRegisterRequestService,
  deleteMutationDnaReportRegisterRequest as deleteMutationDnaReportRegisterRequestService,
} from '../../../services/mutationDnaReportRegisterRequestService';

const DiseaseTypeCertificationRow = (data) => {
  const { findWindowById, createWindow } = useWindow();
  const diseaseTypeRowData = data?.data;
  const [registerRowData, setRegisterRowData] = useState([]);
  const [patchId, setPatchId] = useState('');
  const [patchStatus, setPatchStatus] = useState('');
  const [patchComment, setPatchComment] = useState('');
  const patchDone = useRef(false);
  const deleteDone = useRef(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { TextArea } = Input;

  const registerRequestStatusOptions = [
    { key: '확인 중', text: '확인 중', value: '확인 중' },
    { key: '등록 완료', text: '등록 완료', value: '등록 완료' },
    { key: '등록 실패', text: '등록 실패', value: '등록 실패' },
  ];

  const {
    done: fetchMutationDnaReportRegisterRequestContentDone,
    data: fetchMutationDnaReportRegisterRequestContentData,
    call: fetchMutationDnaReportRegisterRequestContent,
    initialize: initializeFetchMutationDnaReportRegisterRequestContent,
  } = useFetch(
    null,
    fetchMutationDnaReportRegisterRequestContentService,
    diseaseTypeRowData?.userId,
  );

  const {
    done: patchMutationDnaReportRegisterRequestContentDone,
    data: patchMutationDnaReportRegisterRequestContentData,
    call: patchMutationDnaReportRegisterRequest,
    initialize: initializePatchMutationDnaReportRegisterRequestContent,
  } = useFetch(
    null,
    patchMutationDnaReportRegisterRequestService,
    diseaseTypeRowData?.userId,
    {
      type: '질환 인증',
      id: patchId,
      comment: patchComment,
      status: patchStatus,
    },
  );

  const {
    done: deleteMutationDnaReportRegisterRequestContentDone,
    data: deleteMutationDnaReportRegisterRequestContentData,
    call: deleteMutationDnaReportRegisterRequest,
    initialize: initializeDeleteMutationDnaReportRegisterRequestContent,
  } = useFetch(
    null,
    deleteMutationDnaReportRegisterRequestService,
    diseaseTypeRowData?.userId,
    patchId,
  );

  useEffect(() => {
    if (fetchMutationDnaReportRegisterRequestContentDone) {
      notification.success({
        message: '등록 이력을 불러왔습니다.',
        description: '등록 이력 리스트를 확인해 주세요.',
      });
      setRegisterRowData(
        fetchMutationDnaReportRegisterRequestContentData?.filter(
          (info) => info.type === '질환 인증',
        ),
      );
      setPatchId('');
      setPatchStatus('');
      setPatchComment('');
      initializeFetchMutationDnaReportRegisterRequestContent();
    }
  }, [fetchMutationDnaReportRegisterRequestContentDone]);

  useEffect(() => {
    if (patchMutationDnaReportRegisterRequestContentDone) {
      notification.success({
        message: '상태 업데이트에 성공하였습니다.',
        description: '등록 이력 리스트를 확인해 주세요.',
      });
      initializePatchMutationDnaReportRegisterRequestContent();
      fetchMutationDnaReportRegisterRequestContent();
    }
  }, [patchMutationDnaReportRegisterRequestContentDone]);

  useEffect(() => {
    if (deleteMutationDnaReportRegisterRequestContentDone) {
      notification.success({
        message: '등록 이력 삭제에 성공하였습니다.',
        description: '등록 이력 리스트를 확인해 주세요.',
      });
      initializeDeleteMutationDnaReportRegisterRequestContent();
      fetchMutationDnaReportRegisterRequestContent();
    }
  }, [deleteMutationDnaReportRegisterRequestContentDone]);

  const handleFetchData = () => {
    fetchMutationDnaReportRegisterRequestContent();
  };

  const handleCreateEditWindow = (record, rowData) => {
    if (findWindowById(record.id)) {
      notification.open({
        message: '이미 편집 중인 글입니다.',
        description: '다른 탭들을 확인해 주세요. 이미 편집 중인 글입니다.',
        icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      });
      return;
    }
    createWindow({
      id: `${rowData.userId}/${record.id}`,
      dataType: 'userDiseaseTypeCertification',
    });
  };

  const handleCreateNewWindow = (rowData) => {
    createWindow({
      id: rowData.userId,
      dataType: 'userDiseaseTypeCertification',
    });
  };

  const forceDownload = (url) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      const extension = xhr.response.type.split('/').pop();
      let downloadUrl = url.split('/').pop();
      if (!downloadUrl.endsWith(extension)) downloadUrl += `.${extension}`;
      download(xhr.response, downloadUrl, xhr.response.type);
    };
    xhr.onerror = function (e) {
      notification.error({
        message: '이미지 다운로드에 실패하였습니다.',
        description:
          '사진 다운로드 기한이 지났습니다. 등록 이력을 다시 불러와주세요',
      });
    };
    xhr.send();
  };

  const forceDownloadAll = (urls) => {
    urls?.map(({ signedUrl }) => forceDownload(signedUrl));
  };

  const statusOnChange = (value, e, record) => {
    if (window.confirm('상태를 수정하시겠습니까?')) {
      setPatchId(record?.id);
      setPatchStatus(value);
      setPatchComment(record?.comment);
      patchDone.current = true;
    }
  };

  const showModal = (record) => {
    setPatchId(record?.id);
    setPatchStatus(record?.status);
    setPatchComment(record?.comment);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (window.confirm('메모를 수정하시겠습니까?')) {
      patchDone.current = true;
      setIsModalVisible(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (patchDone.current) {
      patchMutationDnaReportRegisterRequest();
      patchDone.current = false;
    }
  }, [patchDone.current]);

  const deleteOnChange = (record) => {
    if (window.confirm('등록이력을 삭제하시겠습니까?')) {
      setPatchId(record?.id);
      deleteDone.current = true;
    }
  };

  useEffect(() => {
    if (deleteDone.current) {
      deleteMutationDnaReportRegisterRequest();
      deleteDone.current = false;
    }
  }, [deleteDone.current]);

  const expandedColumns = [
    {
      title: '등록일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
      render: (text, record) => moment(text).format('YYYY-MM-DD'),
    },
    {
      title: '발급일',
      dataIndex: 'diagnosisDate',
      key: 'diagnosisDate',
      sorter: {
        compare: (a, b) => a?.diagnosisDate?.localeCompare(b?.diagnosisDate),
      },
    },
    {
      title: '병원명',
      dataIndex: 'institution',
      key: 'institution',
    },
    {
      title: '질환명',
      dataIndex: ['diseaseType', 'krName'],
      key: 'diseaseType',
      render: (text, record) => (
        <>
          <a onClick={() => handleCreateEditWindow(record, diseaseTypeRowData)}>
            {text} &nbsp;
          </a>
        </>
      ),
    },
    {
      title: '질병코드',
      dataIndex: 'diseaseTypeCode',
      key: 'diseaseTypeCode',
    },
    {
      title: '인증',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: '서류 종류',
      dataIndex: 'documentType',
      key: 'documentType',
      sorter: {
        compare: (a, b) => a?.documentType?.localeCompare(b?.documentType),
      },
    },
  ];

  const registerColumns = [
    {
      title: '신청일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
    },
    {
      title: '종류',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: '상태',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <>
          <Select
            placeholder="계정을 선택해주세요"
            optionFilterProp="children"
            value={text}
            onChange={(value, e) => statusOnChange(value, e, record)}
          >
            {registerRequestStatusOptions?.map((registerRequestStatus) => {
              return (
                <Select.Option
                  key={registerRequestStatus.key}
                  value={registerRequestStatus.id}
                >
                  {registerRequestStatus.value}
                </Select.Option>
              );
            })}
          </Select>
        </>
      ),
    },
    {
      title: '사진',
      dataIndex: 'photos',
      key: 'photos',
      render: (text, record) => (
        <>
          <Button
            type="primary"
            onClick={() => forceDownloadAll(record?.photos)}
            icon={<DownloadOutlined />}
            style={{ verticalAlign: 'middle', marginLeft: 4 }}
          >
            전체
          </Button>
          <Image.PreviewGroup>
            {record?.photos?.map((photo) => (
              <>
                <Button
                  type="primary"
                  onClick={() => forceDownload(photo?.signedUrl)}
                  icon={<DownloadOutlined />}
                  style={{ verticalAlign: 'middle', marginLeft: 4 }}
                />
                <Image
                  width={80}
                  height={32}
                  src={photo?.signedUrl}
                  preview={{
                    src: photo?.signedUrl,
                  }}
                />
              </>
            ))}
          </Image.PreviewGroup>
        </>
      ),
    },
    {
      title: '메모',
      dataIndex: 'comment',
      key: 'comment',
      render: (text, record) => (
        <>
          {record?.status === '등록 실패' && (
            <>
              {text}{' '}
              <Button
                type="primary"
                size="small"
                style={{ marginBottom: 8 }}
                onClick={() => showModal(record)}
              >
                메모 수정
              </Button>
            </>
          )}
        </>
      ),
    },
    {
      title: '삭제',
      key: 'delete',
      render: (text, record) => (
        <>
          <Button
            type="danger"
            size="small"
            style={{ marginBottom: 8 }}
            onClick={() => deleteOnChange(record)}
          >
            삭제
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="아쉬워요(피드백) 상세"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            취소
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            저장
          </Button>,
        ]}
      >
        <TextArea
          rows={4}
          value={patchComment}
          onChange={(e) => setPatchComment(e.target.value)}
        />
      </Modal>
      <Button
        type="primary"
        size="small"
        style={{ marginBottom: 8 }}
        onClick={() => handleCreateNewWindow(diseaseTypeRowData)}
      >
        질환인증 추가
      </Button>
      <Table
        rowKey={(record) => record.id}
        columns={expandedColumns}
        dataSource={diseaseTypeRowData?.diseaseTypeCertifications}
        pagination={false}
        bordered
      />
      <hr />
      <Button
        type="primary"
        size="small"
        style={{
          marginBottom: 8,
          backgroundColor: 'green',
          borderColor: 'green',
        }}
        onClick={() => handleFetchData()}
      >
        등록이력 불러오기
      </Button>
      {registerRowData?.length > 0 ? (
        <Table
          rowKey={(record) => record.id}
          columns={registerColumns}
          dataSource={registerRowData}
          pagination={false}
          bordered
        />
      ) : (
        <div>등록이력을 불러와주세요</div>
      )}
    </>
  );
};

export default DiseaseTypeCertificationRow;
