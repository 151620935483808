import React from 'react';
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import welcome from '../../../img/welcome.gif';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';

const Main = () => {
  const { Content } = Layout;
  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="" />
        <Layout className="right-layout">
          <TitleBreadcrumb title="홈" subTitle="메인" className="white-bg" />
          <Content className="site-layout-background contentStyle">
            <h1>
              레어노트에 오신 것을 환영합니다!
              <img src={welcome} style={{ height: 100 }} alt="welcome" />
            </h1>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Main;
