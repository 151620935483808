import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Layout } from 'antd';
import 'antd/dist/antd.css';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { noticeCreators } from '../../../store/reducers/notice.reducer';
import NoticeInfo from './noticeInfo';
import { tagCreators } from '../../../store/reducers/tag.reducer';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';

const Notice = () => {
  const dispatch = useDispatch();
  const { notices } = useSelector((state) => {
    return {
      notices: state.noticeReducer.notices.data?.filter(
        (info) => info.type === 'NOTICE',
      ),
    };
  }, shallowEqual);

  const getData = useCallback(() => {
    dispatch(noticeCreators.fetchNotices.request());
    dispatch(tagCreators.fetchTopicTags.request());
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);
  useEffect(getData, [getData]);

  const { Content } = Layout;

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabOperation" link="notice" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="운영"
            subTitle="공지/소식"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <NoticeInfo data={notices} />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Notice;
