import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS, HTTP_NO_CONTENT } from './utils';

/**
 * signed Url을 가져옵니다.
 */
export async function getSignedUrl(body) {
  const res = await axios.get(`/v3/admin/signedUrl`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * multi signed Url을 가져옵니다.
 */
export async function getMultiSignedUrl({
  functionType,
  fileNameList,
  contentType,
}) {
  const res = await axios.get(
    `/v3/admin/multiSignedUrl?functionType=${functionType}&${fileNameList
      ?.map((fileName) => `fileNameList=${fileName}`)
      .join('&')}&contentType=${contentType}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 업로드된 파일을 교체합니다.
 */
export async function replace(uploadSuccessDataHistoryId, body) {
  const res = await axios.put(
    `/v3/admin/fileupload/${uploadSuccessDataHistoryId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
}

/**
 * HEIF 포맷 이미지 파일을 PNG로 변환합니다.
 * @param {string} uploadSuccessDataHistoryId
 */
export async function convertToPng(uploadSuccessDataHistoryId) {
  const res = await axios.post(
    `/v3/admin/fileupload/${uploadSuccessDataHistoryId}/convert/heif`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * acess range를 private에서 public으로 바꿉니다.
 */
export async function changeAccessRangeToPublic(body) {
  const res = await axios.patch(`/v3/admin/makePublic`, body);
  if (res.status !== HTTP_SUCCESS) throw res;
  return res.data.data;
}

/**
 * 단일 이미지를 삭제합니다.
 * @param {obejct} signedUrlData
 */
export async function deleteImageRequest(signedUrlData) {
  const res = await fetch(signedUrlData.signedUrl, {
    method: 'DELETE',
  });
  if (res.status !== HTTP_NO_CONTENT) throw res;
}

/**
 * 다중 이미지를 삭제합니다.
 * @param {obejct} multiSignedUrlData
 */
export async function deleteImagesRequest(multiSignedUrlData) {
  await Promise.all(
    multiSignedUrlData.map(({ signedUrl }) => {
      return () => deleteImageRequest(signedUrl);
    }),
  );
}
