import { all, takeLatest } from 'redux-saga/effects';
import {
  fileUploadCreators,
  fileUploadActions,
} from '../reducers/fileUpload.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  getSignedUrl,
  getMultiSignedUrl,
  changeAccessRangeToPublic,
} from '../../services/fileUploadService';

const handleGetSignedUrl = createAsyncSaga(
  fileUploadCreators.getSignedUrl,
  getSignedUrl,
);

const handleGetMultiSignedUrl = createAsyncSaga(
  fileUploadCreators.getMultiSignedUrl,
  getMultiSignedUrl,
);

const handleChangeAccessRangeToPublic = createAsyncSaga(
  fileUploadCreators.changeAccessRangeToPublic,
  changeAccessRangeToPublic,
);

export function* fileUploadSaga() {
  yield all([
    takeLatest(fileUploadActions.GET_SIGNED_URL, handleGetSignedUrl),
    takeLatest(fileUploadActions.GET_MULTI_SIGNED_URL, handleGetMultiSignedUrl),
    takeLatest(
      fileUploadActions.CHANGE_ACCESS_RANGE_TO_PUBLIC,
      handleChangeAccessRangeToPublic,
    ),
  ]);
}
