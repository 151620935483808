import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Layout, Table, Input, notification } from 'antd';
import 'antd/dist/antd.css';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import { patientGroupCreators } from '../../../store/reducers/patientGroup.reducer';
import { useFetch } from '../../../hooks/useRequest';
import { fetchUnmaskedInfo as fetchUnmaskedInfoService } from '../../../services/userService';
import PatientGroupExcelDown from './patientGroupExcelDown';

import * as listMap from '../../../util/listMap';
import humanscapeIcn from '../../../img/icn/humanscape.png';
import naverIcn from '../../../img/icn/naver.png';
import googleIcn from '../../../img/icn/google.png';
import kakaoIcn from '../../../img/icn/kakao.png';
import appleIcn from '../../../img/icn/apple.png';

const PatientGroupUser = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { patientGroup, patientGroupUserPagination } = useSelector(
    (reducer) => {
      return {
        patientGroup: reducer.patientGroupReducer?.patientGroupUser?.data,
        patientGroupUserPagination:
          reducer?.paginationReducer?.patientGroupUserPagination,
      };
    },
    shallowEqual,
  );

  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const confirmRef = useRef(() => {});

  const getData = useCallback(() => {
    dispatch(
      patientGroupCreators.fetchAllPatientGroupUsers.request(
        state?.patientGroupId,
      ),
    );
  }, [dispatch]);
  useEffect(getData, [getData]);

  useEffect(() => {
    if (patientGroup) {
      if (patientGroupUserPagination.total === null)
        setTotal(patientGroup?.length);
      else setTotal(patientGroupUserPagination.total);
      setPageUpdate(patientGroupUserPagination.page);
      confirmRef.current(patientGroupUserPagination.text);
    }
  }, [patientGroup]);

  const { Search } = Input;
  const { Content } = Layout;

  const [unmaskedType, setUnmaskedType] = useState('');
  const [unmaskedId, setUnmaskedId] = useState('');
  const [getMasked, setGetMasked] = useState('');

  const {
    done: fetchUnmaskedInfoDone,
    data: fetchUnmaskedInfoData,
    call: fetchUnmaskedInfo,
    initialize: initializeDone,
  } = useFetch(null, fetchUnmaskedInfoService, {
    userId: unmaskedId,
    type: unmaskedType,
  });

  const onChangeMasked = (record, type) => {
    if (record === '-') return;
    setUnmaskedType(type);
    setUnmaskedId(record.id);
    setGetMasked(true);
  };

  useEffect(() => {
    if (getMasked) {
      fetchUnmaskedInfo();
      setGetMasked(false);
    }
  }, [getMasked]);

  useEffect(() => {
    if (fetchUnmaskedInfoDone) {
      initializeDone();
      notification.success({
        message: '마스킹을 해제하였습니다.',
        description: '마스킹을 해제하였습니다.',
      });
    }
  }, [fetchUnmaskedInfoDone]);

  const socialRender = (values) => {
    return values?.map((item) => {
      let source;
      let altText;

      switch (item) {
        case 'HUMANSCAPE':
          source = humanscapeIcn;
          altText = 'humanscape';
          break;
        case 'KAKAO':
          source = kakaoIcn;
          altText = 'kakao';
          break;
        case 'NAVER':
          source = naverIcn;
          altText = 'naver';
          break;
        case 'APPLE':
          source = appleIcn;
          altText = 'apple';
          break;
        case 'GOOGLE':
          source = googleIcn;
          altText = 'google';
          break;
        default:
          source = '';
          altText = '';
      }

      return (
        <img
          src={source}
          style={source ? { height: 20, marginRight: 4 } : {}}
          alt={altText}
        />
      );
    });
  };

  const MaskedData = useCallback(
    ({ record, type }) => {
      return (
        <>
          <a onClick={() => onChangeMasked(record, type)}>
            {record?.id === unmaskedId && type === unmaskedType
              ? fetchUnmaskedInfoData?.[type] ?? '-'
              : type === 'birthDate'
              ? '****-**-**'
              : record?.[type] ?? '-'}
          </a>
        </>
      );
    },
    [fetchUnmaskedInfoDone],
  );

  const handleMoveDetailPage = useCallback(
    ({ id }) => () => history.push('/userDetail', { userId: id }),
    [history],
  );

  const columns = [
    {
      title: '계정 유형',
      dataIndex: 'socialAccounts',
      key: 'socialAccounts',
      filters: [
        {
          text: (
            <>
              <span>
                <img
                  src={humanscapeIcn}
                  style={{ height: 20, marginRight: 4 }}
                  alt="humanscape"
                />
                이메일 가입
              </span>
            </>
          ),
          value: 'NULL',
        },
        {
          text: (
            <>
              <span>
                <img
                  src={kakaoIcn}
                  style={{ height: 20, marginRight: 4 }}
                  alt="kakao"
                />
                카카오
              </span>
            </>
          ),
          value: 'KAKAO',
        },
        {
          text: (
            <>
              <span>
                <img
                  src={naverIcn}
                  style={{ height: 20, marginRight: 4 }}
                  alt="naver"
                />
                네이버
              </span>
            </>
          ),
          value: 'NAVER',
        },
        {
          text: (
            <>
              <span>
                <img
                  src={appleIcn}
                  style={{ height: 20, marginRight: 4 }}
                  alt="apple"
                />
                애플
              </span>
            </>
          ),
          value: 'APPLE',
        },
        {
          text: (
            <>
              <span>
                <img
                  src={googleIcn}
                  style={{ height: 20, marginRight: 4 }}
                  alt="google"
                />
                구글
              </span>
            </>
          ),
          value: 'GOOGLE',
        },
      ],
      filteredValue: patientGroupUserPagination.filter?.socialAccounts || null,
      onFilter: (value, record) => record.socialAccounts.includes(value),
      render: (value) =>
        value?.length > 0 ? (
          socialRender(value)
        ) : (
          <img
            src={humanscapeIcn}
            style={{ height: 20, marginRight: 4 }}
            alt="humanscape"
          />
        ),
    },
    {
      title: '계정',
      dataIndex: 'email',
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: patientGroupUserPagination.filter?.email || null,
      onFilter: (
        search,
        { email, socialAccounts, gender, name, phone, relationship },
      ) =>
        [
          email,
          socialAccounts,
          gender,
          name,
          phone,
          relationship,
        ].some((value) => value?.includes(search)),
      render: (text, record) => (
        <>
          {text ? <MaskedData record={record} type="email" /> : <>-</>}
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: 8 }}
            onClick={handleMoveDetailPage(record)}
          >
            이동
          </Button>
        </>
      ),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
      render: (text, record) => <MaskedData record={record} type="name" />,
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        compare: (a, b) => a?.phone?.localeCompare(b?.phone),
      },
      render: (text, record) => <MaskedData record={record} type="phone" />,
    },
    {
      title: '성별',
      dataIndex: 'gender',
      key: 'gender',
      sorter: {
        compare: (a, b) => a?.gender?.localeCompare(b?.gender),
      },
      render: (gender) => <>{listMap.genderMap[gender]}</>,
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypeName',
      key: 'diseaseTypeName',
      sorter: {
        compare: (a, b) =>
          a?.diseaseTypeName?.localeCompare(b?.diseaseTypeName),
      },
    },
    {
      title: '관계',
      dataIndex: 'relationship',
      key: 'relationship',
      sorter: {
        compare: (a, b) => a?.phone?.localeCompare(b?.phone),
      },
    },
    {
      title: '건강 설문',
      dataIndex: 'healthProfileStatus',
      key: 'healthProfileStatus',
      sorter: {
        compare: (a, b) =>
          a?.healthProfileStatus?.localeCompare(b?.healthProfileStatus),
      },
      filters: [
        {
          text: '완료',
          value: '완료',
        },
        {
          text: '진행전',
          value: '진행전',
        },
        {
          text: '진행중',
          value: '진행중',
        },
      ],
      filteredValue:
        patientGroupUserPagination.filter?.healthProfileStatus || null,
      onFilter: (value, record) => record.healthProfileStatus === value,
    },
    {
      title: '테스트 ID',
      dataIndex: 'isTest',
      key: 'isTest',
      filters: [
        { text: 'O', value: true },
        { text: 'X', value: false },
      ],
      filterMultiple: false,
      filteredValue: patientGroupUserPagination.filter?.isTest || null,
      onFilter: (value, record) => record.isTest === value,
      render: (value) => (value ? 'O' : 'X'),
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabAccount" link="patientGroup" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="계정"
            subTitle="환우회 가입자"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button
              type="primary"
              onClick={() => history.push('/patientGroup')}
            >
              환우회 돌아가기
            </Button>
            <PatientGroupExcelDown
              projectName={state?.patientGroupId}
              text={state?.patientGroupName}
            />
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (patientGroupUserPagination.text = value),
                  ),
                );
              }}
              defaultValue={patientGroupUserPagination.text}
            />
            <div className="searchResult">
              {patientGroup && `검색결과 ${total}개`}
            </div>
            <Table
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={patientGroup}
              pagination={{
                onChange: (page) => {
                  setPageUpdate(page);
                },
                current: pageUpdate,
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(pagination, filters, sorter, extra) => {
                dispatch(
                  paginationCreators.setValue(
                    (patientGroupUserPagination.page = pagination?.current),
                    (patientGroupUserPagination.filter = filters),
                    (patientGroupUserPagination.total =
                      extra?.currentDataSource?.length),
                  ),
                );
                setTotal(extra?.currentDataSource?.length);
              }}
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PatientGroupUser;
