import React from 'react';
import { Table, Spinner, Button } from 'react-bootstrap';

const ListView = ({
  loading,
  listData,
  listName,
  listItemView,
  searchFilter,
  searchWord,
  editWindow,
  modalWindow,
  conversionWindow,
  deployChangeInfo,
  setDeployChangeInfo,
  activatedDeploy,
}) => {
  const index = listName.map(({ name }) => name).indexOf(searchFilter);

  const TableHeader = ({ headerList }) => {
    return (
      <tr key="listHeader">
        {headerList.map((headerData) => (
          <th style={{ width: `${headerData.width}` }}>{headerData.name}</th>
        ))}
      </tr>
    );
  };

  const TableContent = ({ contentList }) => {
    const filteredList = contentList?.filter((data) => {
      const listItem = listItemView[index]?.view(data)?.toLowerCase() ?? '';
      return listItem.includes(searchWord);
    });

    return filteredList.map((listItem) => (
      <tr key={listItem.id}>
        {listItemView.map((listItemInfo) =>
          listItemInfo.deployOption ? (
            <td>
              <DeployButton
                item={listItem}
                deployOption={listItemInfo.deployOption}
              />
            </td>
          ) : listItemInfo.type === 'link' ? (
            <td onClick={() => editWindow(listItem)}>
              <div className="toggleWindow">{listItemInfo.view(listItem)}</div>
            </td>
          ) : listItemInfo.type === 'modal' ? (
            <td onClick={() => modalWindow(listItem)}>
              <div className="toggleWindow">{listItemInfo.view(listItem)}</div>
            </td>
          ) : listItemInfo.type === 'conversion' ? (
            <td onClick={() => conversionWindow(listItem)}>
              <div className="toggleWindow">{listItemInfo.view(listItem)}</div>
            </td>
          ) : (
            <td>{listItemInfo.view(listItem)}</td>
          ),
        )}
      </tr>
    ));
  };

  const visualDistribute = ({ targetItem, deployOption }) => {
    const target = deployChangeInfo.filter(
      (changedInfo) => changedInfo.id === targetItem.id,
    );
    const selected =
      target.length > 0 && deployOption in target[0]
        ? Boolean(target[0][deployOption])
        : Boolean(targetItem[deployOption]);
    return selected;
  };

  const update = ({ id, which, toWhat }) => {
    if (activatedDeploy && !activatedDeploy.includes(which)) {
      alert(`${which} column 생성 작업 이후 활성화됩니다!`);
      return;
    }
    let isIdExist = false;
    const newDeployChangeInfo = deployChangeInfo
      .map((changedInfo) => {
        if (changedInfo.id === id) {
          isIdExist = true;
          changedInfo[which] = toWhat;
          const listItem = listData.find((data) => data.id === id);
          if (listItem[which] === toWhat) {
            delete changedInfo[which];
          }
          if (Object.keys(changedInfo).length === 1) {
            return null;
          }
        }
        return changedInfo;
      })
      .filter(Boolean);

    if (!isIdExist) {
      newDeployChangeInfo.push({ id, [which]: toWhat });
    }
    setDeployChangeInfo(newDeployChangeInfo);
  };

  const DeployButton = ({ item, deployOption }) => {
    return (
      <Button
        size="sm"
        className="exposureButton"
        variant={
          visualDistribute({
            targetItem: item,
            deployOption,
          })
            ? 'primary'
            : 'outline-primary'
        }
        onClick={() =>
          update({
            id: item.id,
            which: deployOption,
            toWhat:
              deployChangeInfo.find(
                (changedInfo) => changedInfo.id === item.id,
              )?.[deployOption] !== undefined
                ? !deployChangeInfo.find(
                    (changedInfo) => changedInfo.id === item.id,
                  )[deployOption]
                : !item[deployOption],
          })
        }
      >
        {visualDistribute({
          targetItem: item,
          deployOption,
        })
          ? 'YES'
          : 'NO'}
      </Button>
    );
  };

  return (
    <div>
      {loading ? (
        <div className="loading">
          <Spinner style={{ margin: 'auto' }} animation="border" />
        </div>
      ) : (
        <div className="tableFixHead">
          <Table id="table" striped bordered hover size="sm">
            <thead>
              <TableHeader headerList={listName} />
            </thead>
            <tbody>
              <TableContent contentList={listData} />
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default ListView;
