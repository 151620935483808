import React, { useEffect, useState, useRef, useReducer } from 'react';
import {
  Form,
  Input,
  Button,
  Alert,
  Row,
  Col,
  Upload,
  Select,
  Checkbox,
  notification,
} from 'antd';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import '../../../public/css/window.css';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { healthProfileCreators } from '../../../store/reducers/healthProfile.reducer';
import WindowHeader from '../../components/windowHeader';
import ElementLoading from '../../components/elementLoading';

import {
  fetchHealthProfileQuestion,
  postHealthProfileQuestion,
  patchHealthProfileQuestion,
  deleteHealthProfileQuestion,
} from '../../../services/healthProfileService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import { ALERT_MESSAGES } from '../../../assets/alert';
import { useAntFileUpload } from '../../util/useAntFileImage';
import * as listMap from '../../../util/listMap';

const HealthProfileQuestionWindow = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { healthProfileInfo } = useSelector((state) => {
    return {
      healthProfileInfo: state?.healthProfileReducer?.healthProfile?.data,
    };
  }, shallowEqual);

  const initialState = {
    healthProfileId: null,
    mainQuestion: null,
    mainQuestionType: null,
    photo: null,
    info: null,
    order: null,
    isChangeable: false,
    isUserAnswerEditable: true,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);
  const [windowId, setWindowId] = useState('');
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [healthProfileQuestionId, setHealthProfileQuestionId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const fileUploadSetting = useRef(false);
  const didCancel = useRef(false);
  const uploadCancel = useRef(false);

  const {
    publicUrl: distinctPublicUrl,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useAntFileUpload(distinctFileInputEvent);

  const postPatchData = {
    healthProfileId: state.healthProfileId,
    mainQuestion: state.mainQuestion,
    mainQuestionType: state.mainQuestionType,
    photo: state.photo,
    info: state.info,
    order: state.order,
    isChangeable: state.isChangeable,
    isUserAnswerEditable: state.isUserAnswerEditable,
  };

  const {
    data: fetchHealthProfileQuestionData,
    call: callHealthProfileQuestion,
  } = useFetch(
    null,
    fetchHealthProfileQuestion,
    state.healthProfileId,
    healthProfileQuestionId,
  );

  const { mutate: putHealthProfileQuestion, done: isPosted } = useMutate(
    postHealthProfileQuestion,
    postPatchData,
  );

  const { mutate: changeHealthProfileQuestion, done: isPatched } = useMutate(
    patchHealthProfileQuestion,
    healthProfileQuestionId,
    postPatchData,
  );

  const { mutate: eraseHealthProfileQuestion, done: isDeleted } = useMutate(
    deleteHealthProfileQuestion,
    healthProfileQuestionId,
  );

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(healthProfileCreators.fetchAllHealthProfiles.request());
    if (params.id) {
      setHealthProfileQuestionId(params.id);
    } else {
      setHealthProfileQuestionId('');
    }
    if (params.subId) {
      setState({ healthProfileId: params.subId });
    } else {
      setState({ healthProfileId: '' });
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (state.healthProfileId && healthProfileQuestionId) {
      callHealthProfileQuestion();
    }
  }, [
    state.healthProfileId,
    healthProfileQuestionId,
    callHealthProfileQuestion,
  ]);

  useEffect(() => {
    if (fetchHealthProfileQuestionData && !didCancel.current) {
      setState({
        mainQuestion: fetchHealthProfileQuestionData.mainQuestion,
        mainQuestionType: fetchHealthProfileQuestionData.mainQuestionType,
        photo: fetchHealthProfileQuestionData.photo,
        order: fetchHealthProfileQuestionData.order,
        info: fetchHealthProfileQuestionData.info,
        isChangeable: fetchHealthProfileQuestionData.isChangeable,
        isUserAnswerEditable:
          fetchHealthProfileQuestionData.isUserAnswerEditable,
      });
      didCancel.current = true;
      setIsLoading(false);
    } else if (healthProfileQuestionId === '' && !didCancel.current) {
      didCancel.current = true;
      setIsLoading(false);
    }
  }, [fetchHealthProfileQuestionData, healthProfileQuestionId]);

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      mainQuestion: values.mainQuestion,
      info: values.info,
    });
    if (healthProfileQuestionId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      eraseHealthProfileQuestion();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeHealthProfileQuestion();
      setIsPatch(false);
    }
    if (isPost) {
      putHealthProfileQuestion();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  const beforeUpload = (file) => {
    const isSupportedImageType =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/svg+xml';
    if (!isSupportedImageType) {
      notification.error({
        message: 'JPG/PNG/SVG만 업로드 가능합니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      notification.error({
        message: '이미지가 10MB보다 큽니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    return isSupportedImageType && isLt10M;
  };

  const uploadImg = async (e) => {
    if (e !== undefined) {
      setDistinctFileInputEvent(e);
      uploadCancel.current = true;
      setLoading(true);
    }
  };

  const noop = () => {};

  const deleteThumbImg = () => {
    setState({
      photo: '',
    });
    notification.success({
      message: '이미지를 삭제하였습니다.',
      description: '이미지를 다시 업로드해주세요.',
    });
  };

  const uploadProps = {
    customRequest: noop,
    onChange: uploadImg,
    onPreview: noop,
  };

  useEffect(() => {
    if (distinctFileInputEvent && uploadCancel.current) {
      distinctImageUpload();
      uploadCancel.current = false;
      fileUploadSetting.current = true;
    }
  }, [distinctFileInputEvent, distinctImageUpload]);

  useEffect(() => {
    if (distinctImageUploadDone && distinctPublicUrl) {
      notification.success({
        message: '이미지 업로드에 성공하였습니다.',
        description: '설문 이미지 등록에 성공하였습니다.',
      });
      setState({ photo: distinctPublicUrl });
      distinctUploadInitialize();
      setLoading(false);
    }
  }, [distinctImageUploadDone, distinctPublicUrl, distinctUploadInitialize]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  if (isLoading) return <ElementLoading type="설문 질문" />;
  return (
    <>
      <WindowHeader title="설문 질문" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              healthProfileId: state.healthProfileId,
              mainQuestion: state.mainQuestion,
              mainQuestionType: state.mainQuestionType,
              order: state.order,
              info: state.info,
              photo: state.photo,
              isChangeable: state.isChangeable,
              isUserAnswerEditable: state.isUserAnswerEditable,
            }}
          >
            <Form.Item label="종속 항목">
              <Input
                prefix={
                  healthProfileInfo?.filter(
                    (healthProfile) =>
                      healthProfile.id === state.healthProfileId,
                  )[0]?.type
                }
                disabled
              />
            </Form.Item>

            <Form.Item label="순서" name="order">
              <Input
                placeholder="순서를 생성 후 메인 페이지에서 지정해주세요!"
                disabled
              />
            </Form.Item>

            <Form.Item
              label="질문 내용"
              name="mainQuestion"
              rules={[
                {
                  required: true,
                  message: '질문 내용을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="mainQuestionType"
              label="설문 타입"
              rules={[
                {
                  required: true,
                  message: '설문 타입을 선택해주세요.',
                },
              ]}
            >
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="설문 타입을 선택해주세요."
                name="mainQuestionType"
                onChange={(e) => setState({ mainQuestionType: e })}
              >
                {listMap.healthProfileTypeOption?.map((status) => (
                  <Select.Option
                    key={status.value}
                    id={status.value}
                    value={status.value}
                    disabled={status.disabled ?? false}
                  >
                    {status.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="부가 설명" name="info">
              <Input />
            </Form.Item>

            <Form.Item label="동적 질문 여부" name="isChangeable">
              <Checkbox
                onChange={(e) =>
                  setState({
                    isChangeable: e.target.checked,
                  })
                }
                checked={state?.isChangeable}
              >
                동적 질문
              </Checkbox>
            </Form.Item>

            <Form.Item label="답변 변경" name="isUserAnswerEditable">
              <Checkbox
                onChange={(e) =>
                  setState({
                    isUserAnswerEditable: e.target.checked,
                  })
                }
                checked={state?.isUserAnswerEditable}
              >
                변경 가능 여부(체크 시 설문 답변 변경 가능 / 체크 해제 시 변경
                불가)
              </Checkbox>
            </Form.Item>

            <Form.Item label="설문 그림" name="photo">
              <Upload
                {...uploadProps}
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {state?.photo ? (
                  <img
                    src={state?.photo}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              {state?.photo && (
                <Button
                  type="primary"
                  style={{ width: 104 }}
                  danger
                  onClick={deleteThumbImg}
                >
                  설문 그림 삭제
                </Button>
              )}
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {healthProfileQuestionId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default HealthProfileQuestionWindow;
