import { all, takeLatest } from 'redux-saga/effects';
import { authCreators, authActions } from '../reducers/auth.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  autoLogin,
  accessLogin,
  renewSession,
} from '../../services/authService';
import errMessage from '../../util/loginErrMessage';

const onSuccess = ({
  accessToken,
  refreshToken,
  accessTokenExpiresAt: expiresAt,
  jwtToken,
}) => {
  window.localStorage.setItem('accessToken', accessToken);
  window.localStorage.setItem('refreshToken', refreshToken);
  window.localStorage.setItem('expiresAt', expiresAt);
  window.localStorage.setItem('jwtToken', jwtToken);
};

const handleAutoLogin = createAsyncSaga(authCreators.autoLogin, autoLogin);

const handleAccessLogin = createAsyncSaga(
  authCreators.accessLogin,
  accessLogin,
  {
    onSuccess,
    onFail: (error) => alert(errMessage(error.data.message.SequelizeError)),
    formatData: ({
      accessToken,
      refreshToken,
      accessTokenExpiresAt,
      jwtToken,
      email,
    }) => ({
      accessToken,
      refreshToken,
      expiresAt: new Date(accessTokenExpiresAt),
      jwtToken,
      email,
    }),
  },
);

const handleRenewSession = createAsyncSaga(
  authCreators.renewSession,
  renewSession,
  {
    onSuccess: (data) => {
      onSuccess(data);
      alert('세션이 연장되었습니다.');
    },
    onFail: () => alert('세션 연장에 실패했습니다.'),
    formatData: ({
      accessToken,
      refreshToken,
      accessTokenExpiresAt,
      jwtToken,
    }) => ({
      accessToken,
      refreshToken,
      expiresAt: new Date(accessTokenExpiresAt),
      jwtToken,
    }),
  },
);

export function* authSaga() {
  yield all([
    takeLatest(authActions.AUTO_LOGIN, handleAutoLogin),
    takeLatest(authActions.ACCESS_LOGIN, handleAccessLogin),
    takeLatest(authActions.RENEW_SESSION, handleRenewSession),
  ]);
}
