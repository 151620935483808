import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 강제업데이트를 가져옵니다.
 */
export async function fetchAllSupportVersion() {
  const res = await axios.get(`/v3/admin/supportVersions`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 강제업데이트를 생성합니다.
 * @param {object} body
 */
export async function postSupportVersion(body) {
  const res = await axios.post('/v3/admin/supportVersions', body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 강제업데이트를 수정합니다.
 * @param {String} supportVersionId
 * @param {object} body
 */
export async function patchSupportVersion(supportVersionId, body) {
  const res = await axios.patch(
    `/v3/admin/supportVersions/${supportVersionId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 강제업데이트를 삭제합니다.
 * @param {String} supportVersionId
 */
export async function deleteSupportVersion(supportVersionId) {
  const res = await axios.delete(
    `/v3/admin/supportVersions/${supportVersionId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
