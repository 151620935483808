import { all, takeLatest } from 'redux-saga/effects';
import {
  treatmentNewsCreators,
  treatmentNewsActions,
} from '../reducers/treatmentNews.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchAllTreatmentNews } from '../../services/treatmentNewsService';

const handleFetchAllTreatmentNews = createAsyncSaga(
  treatmentNewsCreators.fetchAllTreatmentNews,
  fetchAllTreatmentNews,
);

export function* treatmentNewsSaga() {
  yield all([
    takeLatest(
      treatmentNewsActions.FETCH_ALL_TREATMENT_NEWS,
      handleFetchAllTreatmentNews,
    ),
  ]);
}
