import React, { useState } from 'react';
import { Form, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import { Editor } from '@tinymce/tinymce-react';
import { tinymceInit } from '../../util/tinymceInit';
import {
  getSignedUrl,
  changeAccessRangeToPublic,
} from '../../services/fileUploadService';

const tinymceApiKey = process.env.REACT_APP_TINYMCE_API_KEY || '';
const TinymceInput = ({
  content,
  setContent,
  setUploadUrl,
  onBlur,
  setImageLoading,
  contentLinkData,
  windowId,
  name,
}) => {
  const [editorContents, setEditorContents] = useState(content);
  const images_upload_handler = async (blobInfo, success, failure) => {
    setImageLoading(true);
    const newSignedUrlUploadData = await getSignedUrl({
      params: {
        functionType: 'PUT',
        fileName: blobInfo ? blobInfo.filename() : null,
        contentType: blobInfo ? blobInfo.blob().type : null,
      },
    });
    const data = newSignedUrlUploadData;
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', data.signedUrl);
    xhr.onload = async () => {
      await changeAccessRangeToPublic({
        filePath: newSignedUrlUploadData?.filePath,
      });
      success(newSignedUrlUploadData.publicUrl);
      if (setUploadUrl) setUploadUrl(newSignedUrlUploadData.publicUrl);
      setImageLoading(false);
    };
    xhr.onerror = () => {
      alert('이미지 업로드에 실패했습니다!');
      failure(newSignedUrlUploadData.publicUrl);
      setImageLoading(false);
    };
    xhr.setRequestHeader('Content-Type', blobInfo.blob().type);
    xhr.send(blobInfo.blob());
  };

  return (
    <>
      <Form.Item label="내용">
        <Editor
          value={content}
          apiKey={tinymceApiKey}
          init={{
            ...tinymceInit,
            images_upload_handler,
            setup(editor) {
              if (onBlur) {
                editor.on('blur', () => {
                  onBlur(editor.getDoc());
                });
              }
              editor.on('NodeChange', () => {
                const imageList = editor.getDoc().getElementsByTagName('img');
                for (let i = 0; i < imageList.length; i += 1) {
                  editor.dom.setAttribs(imageList[i], {
                    width: '100%',
                    height: null,
                    alt: null,
                  });
                }
              });

              editor.addShortcut(
                'meta+m',
                'addShortcut subscript.',
                function () {
                  editor.execCommand('subscript');
                },
              );
            },
          }}
          onEditorChange={(newContent) => {
            setEditorContents(newContent);
            setContent({ target: { name, value: newContent } });
          }}
        />
      </Form.Item>
    </>
  );
};
export default TinymceInput;
