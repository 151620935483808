import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  treatmentNews: getAsyncState.initial(null),
};

// action types
export const treatmentNewsActions = {
  FETCH_ALL_TREATMENT_NEWS: '@treatmentNews/FETCH_ALL_TREATMENT_NEWS',
};

// actions
export const treatmentNewsCreators = {
  fetchAllTreatmentNews: createAsyncActions(
    treatmentNewsActions,
    'FETCH_ALL_TREATMENT_NEWS',
  ),
};

const treatmentNewsReducer = handleActions(
  {
    ...handleAsyncActions(
      treatmentNewsActions.FETCH_ALL_TREATMENT_NEWS,
      'treatmentNews',
    ),
  },
  initialState,
);

export default treatmentNewsReducer;
