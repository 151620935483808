import { all, takeLatest } from 'redux-saga/effects';
import {
  mutationDnaReportActions,
  mutationDnaReportCreators,
} from '../reducers/mutationDnaReport.reducer';
import { createAsyncSaga } from '../reducer.util';
import { fetchAllMutationDnaReports } from '../../services/mutationDnaReportService';

const handleFetchAllMutationDnaReport = createAsyncSaga(
  mutationDnaReportCreators.fetchAllMutationDnaReport,
  fetchAllMutationDnaReports,
);

export function* mutationDnaReportSaga() {
  yield all([
    takeLatest(
      mutationDnaReportActions.FETCH_ALL_MUTATION_DNA_REPORT,
      handleFetchAllMutationDnaReport,
    ),
  ]);
}
