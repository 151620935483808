import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Table, Input, Button } from 'antd';
import 'antd/dist/antd.css';
import { NEW } from '../../../window/util/utils';
import { descending } from '../../../util/sort';
import useWindow from '../../../hooks/useWindow';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { remoteConfigCreators } from '../../../store/reducers/remoteConfig.reducer';
import { notificationCreators } from '../../../store/reducers/notification.reducer';

const RemoteConfig = () => {
  const dispatch = useDispatch();
  const { remoteConfig } = useSelector((state) => ({
    remoteConfig: state.remoteConfigReducer?.remoteConfig?.data?.sort(
      descending('updatedAt'),
    ),
  }));

  const getData = useCallback(() => {
    dispatch(remoteConfigCreators.fetchAllRemoteConfigs.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setDataSource(remoteConfig);
    setFilteredData(remoteConfig?.length);
  }, [remoteConfig]);

  const { Content } = Layout;
  const [dataSource, setDataSource] = useState('');
  const { Search } = Input;
  const [filteredData, setFilteredData] = useState(null);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (record) => {
    if (findWindowById(record.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }

    createWindow({
      id: record.id,
      dataType: 'remoteConfig',
      subId: record.key,
    });
  };

  const handleCreateNewWindow = () => {
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: 'remoteConfig',
    });
  };

  const onSearch = (e) => {
    if (e === '') {
      setDataSource(remoteConfig);
      setFilteredData(remoteConfig?.length);
    } else {
      const filteredDatas = dataSource?.filter((entry) =>
        Object?.values(entry).toString().includes(e),
      );
      setDataSource(filteredDatas);
      setFilteredData(filteredDatas.length);
    }
  };

  const columns = [
    {
      title: '키',
      dataIndex: 'key',
      key: 'key',
      width: 200,
      sorter: {
        compare: (a, b) => a?.key?.localeCompare(b?.key),
      },
      render: (text, record) => (
        <a
          style={{ color: '#40a9ff' }}
          onClick={() => handleCreateEditWindow(record)}
        >
          {text}
        </a>
      ),
    },
    {
      title: '데이터 타입',
      dataIndex: 'dataType',
      key: 'dataType',
      width: 100,
      sorter: {
        compare: (a, b) => a?.dataType?.localeCompare(b?.dataType),
      },
    },
    {
      title: '설명',
      dataIndex: 'description',
      key: 'description',
      width: 200,
    },
    {
      title: '값',
      dataIndex: 'value',
      key: 'value',
      width: 200,
      ellipsis: true,
      style: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabDevelop" link="remoteConfig" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="개발"
            subTitle="리모트 컨피그"
            className="white-bg"
            extra={
              <Button
                type="secondary"
                style={{ width: 100 }}
                onClick={() => {
                  window.open(
                    'https://www.notion.so/humanscape/2-0-da35356c33814d3c89b3790ec308928e',
                  );
                }}
              >
                매뉴얼
              </Button>
            }
          />
          <Content className="site-layout-background contentStyle">
            <Button
              type="primary"
              style={{ width: 100 }}
              onClick={handleCreateNewWindow}
            >
              추가
            </Button>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={onSearch}
            />
            <div className="searchResult">검색결과 {filteredData}개</div>

            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(_, __, ___, { currentDataSource }) =>
                setFilteredData(currentDataSource.length)
              }
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default RemoteConfig;
