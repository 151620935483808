import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  mutationDnaReportRegisterRequest: getAsyncState.initial(null),
};

// action types
export const mutationDnaReportRegisterRequestActions = {
  FETCH_ALL_MUTATION_DNA_REPORT_REGISTER_REQUEST:
    '@mutationDnaReportRegisterRequest/FETCH_ALL_MUTATION_DNA_REPORT_REGISTER_REQUEST',
};

// actions
export const mutationDnaReportRegisterRequestCreators = {
  fetchAllMutationDnaReportRegisterRequest: createAsyncActions(
    mutationDnaReportRegisterRequestActions,
    'FETCH_ALL_MUTATION_DNA_REPORT_REGISTER_REQUEST',
  ),
};

const mutationDnaReportRegisterRequestReducer = handleActions(
  {
    ...handleAsyncActions(
      mutationDnaReportRegisterRequestActions.FETCH_ALL_MUTATION_DNA_REPORT_REGISTER_REQUEST,
      'mutationDnaReportRegisterRequest',
    ),
  },
  initialState,
);

export default mutationDnaReportRegisterRequestReducer;
