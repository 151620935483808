import {
  Button,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Layout,
  Menu,
  notification,
  Space,
  Table,
  Typography,
} from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Content } from 'antd/lib/layout/layout';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import {
  DownloadOutlined,
  DownOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import PAGE_URL from '../../../assets/pageUrl';
import {
  downloadPapDocuments,
  downloadPapProjectFinanceExcel,
  downloadPapProjectReportExcel,
  fetchPapProject,
  fetchPapProjectRequests,
  updatePapUserRequest,
} from '../../../services/papService';
import { PAP_REQUEST_STATUS } from '../../../util/papConstants';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';

const PapAuditList = () => {
  const [papProject, setPapProject] = useState(null);
  const [papRequests, setPapRequests] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const confirmRef = useRef(() => {});

  const { papRequestPagination } = useSelector((pagingState) => {
    return {
      papRequestPagination: pagingState.paginationReducer.papProjectPagination,
    };
  });

  const fetchList = useCallback(async () => {
    setLoading(true);
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    const result = await fetchPapProjectRequests(params.id);
    dispatch(
      paginationCreators.setValue((papRequestPagination.total = result.count)),
    );

    const project = await fetchPapProject(params.id);
    setPapProject(project);

    setPapRequests(result.data.filter((r) => r.userId));
    setLoading(false);
  }, [dispatch, papRequestPagination, search]);

  useEffect(() => {
    fetchList();
  }, [fetchList]);

  const completeSelected = async () => {
    if (selectedUser.length === 0) {
      notification.warning({
        message: '선택된 유저가 없습니다.',
        key: 'completeSelectedFail',
      });
      return;
    }
    const result = await Promise.allSettled(
      selectedUser.map((userId) =>
        updatePapUserRequest(userId, { status: 'refund_complete' }, true),
      ),
    );
    if (result.some((res) => res.status === 'rejected')) {
      const count = result.filter((res) => res.status === 'rejected').length;
      notification.error({
        message: `유저 ${result.length}명 중 ${count}명의 지원금 입금 완료 처리에 실패했습니다.`,
        key: 'completeSelectedFail',
      });
    } else {
      notification.success({
        message: '선택한 유저의 지원금 입금 완료 처리가 완료되었습니다.',
        key: 'completeSelectedSuccess',
      });
    }
    fetchList();
  };

  const handleMoveDetailPage = (record) => {
    history.push(`/${PAGE_URL.PAP_AUDIT_DETAIL}`, {
      userId: record?.userId ?? '',
      requestId: record?.id,
      type: 'pap',
    });
  };

  const onDownloadUserDocument = async (id) => {
    const row = papRequests.find((r) => r.id === id);
    const result = await downloadPapDocuments(id);
    const fileURL = window.URL.createObjectURL(
      new Blob([result], { type: 'application/zip' }),
    );

    const tempLink = document.createElement('a');
    tempLink.href = fileURL;
    tempLink.setAttribute(
      'download',
      `${papProject?.title}_${row.name}_${row.version}차.zip`,
    );
    tempLink.click();
    fetchList();
  };

  const exportFinancialReport = async () => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    const result = await downloadPapProjectFinanceExcel(params.id);
    const csvURL = window.URL.createObjectURL(
      new Blob([result], { type: 'text/csv' }),
    );
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute(
      'download',
      `${papProject.title}_대량_이체_내역_${moment().format('YY_MM_DD')}.csv`,
    );
    tempLink.click();
  };

  const exportPatientsReport = async () => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    const result = await downloadPapProjectReportExcel(params.id);
    const csvURL = window.URL.createObjectURL(
      new Blob([result], { type: 'text/csv' }),
    );
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute(
      'download',
      `${papProject.title}_제약사_보고용_${moment().format('YY_MM_DD')}.csv`,
    );
    tempLink.click();
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    dispatch(
      paginationCreators.setValue(
        (papRequestPagination.filter = filters),
        (papRequestPagination.sorter = sorter),
        (papRequestPagination.page = pagination.size),
        (papRequestPagination.total = extra?.currentDataSource?.length),
      ),
    );
  };

  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <DatePicker.RangePicker
            defaultValue={[
              selectedKeys?.[0]
                ? moment(selectedKeys?.[0], 'YYYY-MM-DD')
                : null,
              selectedKeys?.[1]
                ? moment(selectedKeys?.[1], 'YYYY-MM-DD')
                : null,
            ]}
            onChange={(e) => {
              if (e !== null) {
                if (
                  e?.[0].format('YYYY-MM-DD') === e?.[1].format('YYYY-MM-DD')
                ) {
                  setSelectedKeys([
                    e?.[0].format('YYYY-MM-DD'),
                    moment(e?.[1]).add(1, 'days').format('YYYY-MM-DD'),
                  ]);
                } else {
                  setSelectedKeys([
                    e?.[0].format('YYYY-MM-DD'),
                    e?.[1].format('YYYY-MM-DD'),
                  ]);
                }
              } else {
                setSelectedKeys([]);
              }
            }}
            format="YYYY-MM-DD"
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
          </Space>
        </div>
      );
    },
    onFilter: (_, record) => {
      if (
        !papRequestPagination.filter[dataIndex] ||
        papRequestPagination.filter[dataIndex].length !== 2
      )
        return true;
      const [startStr, endStr] = papRequestPagination.filter[dataIndex];
      const value = moment(record[dataIndex]);
      const start = moment(startStr);
      const end = moment(endStr).add(1, 'days');
      return value.isBetween(start, end);
    },
  });

  const columns = [
    {
      title: '계정',
      dataIndex: 'email',
      key: 'email',
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => {
            handleMoveDetailPage(record);
          }}
        >
          {text}
        </Button>
      ),
      sorter: (a, b) => a.email.localeCompare(b.email),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      onFilter: (keyword, { email, name }) =>
        [email, name].some((value) => value?.includes(keyword)),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text) => <Typography.Text ellipsis>{text}</Typography.Text>,
    },
    {
      title: '지원 차수',
      key: 'version',
      dataIndex: 'version',
      render: (text, record) => (
        <div
          className="tagWrapper"
          key={record.id}
          style={{
            color: '#333',
            backgroundColor: '#fff',
          }}
        >
          {text}차
        </div>
      ),
      sorter: (a, b) => a.version - b.version,
      filters: papRequests.map((r) => ({
        text: `${r.version}차`,
        value: r.version,
      })),
      onFilter: (value, record) => record.version === value,
    },
    {
      title: '신청일시',
      key: 'requestDate',
      dataIndex: 'requestDate',
      render: (value) =>
        value ? <div>{moment(value).format('YYYY.MM.DD HH:mm')}</div> : '-',
      sorter: (a, b) => a.requestDate.localeCompare(b.requestDate),
      ...getColumnSearchDateProps('requestDate'),
    },
    {
      title: '유저 상태',
      key: 'status',
      dataIndex: 'status',
      render: (text, record) => (
        <div
          className="tagWrapper"
          key={record.id}
          style={{
            color: '#333',
            backgroundColor: '#fff',
          }}
        >
          {PAP_REQUEST_STATUS[text]}
        </div>
      ),
      sorter: (a, b) => a.status - b.status,
      filters: Object.keys(PAP_REQUEST_STATUS).map((key) => ({
        text: PAP_REQUEST_STATUS[key],
        value: key,
      })),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: '처방 일자',
      key: 'prescriptionDate',
      dataIndex: 'prescriptionDate',
      render: (value) =>
        value ? <div>{moment(value).format('YYYY.MM.DD')}</div> : '-',
      sorter: (a, b) => a.prescriptionDate.localeCompare(b.prescriptionDate),
      ...getColumnSearchDateProps('prescriptionDate'),
    },
    {
      title: '지원금',
      key: 'supportAmount',
      dataIndex: 'supportAmount',
      render: (value) => <div>{(value ?? '-')?.toLocaleString('ko-KR')}</div>,
      sorter: (a, b) => a.supportAmount - b.supportAmount,
    },
    {
      title: '사진 일괄 다운로드',
      key: 'download',
      render: (text, record) => (
        <Button
          onClick={() => {
            onDownloadUserDocument(record.id);
          }}
          disabled={record.documents.length === 0}
        >
          <DownloadOutlined style={{ fontSize: 16 }} />
        </Button>
      ),
    },
    {
      title: '최근 서류 등록일시',
      key: 'recentDocumentUpdatedAt',
      dataIndex: 'recentDocumentUpdatedAt',
      render: (value, record) => {
        if (value) return <div>{moment(value).format('YYYY.MM.DD HH:mm')}</div>;
        if (record.documents.length > 0) {
          const max = moment.max(
            record.documents.map((doc) => moment(doc.createdAt ?? 0)),
          );
          if (max.valueOf() > 0)
            return <div>{moment(max).format('YYYY.MM.DD HH:mm')}</div>;
        }
        return '-';
      },
      sorter: (a, b) =>
        a.recentDocumentUpdatedAt.localeCompare(b.recentDocumentUpdatedAt),
    },
  ];

  const { Search } = Input;
  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabData" link={PAGE_URL.PAP} />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="운영"
            subTitle="PAP 심사 관리"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button
              type="link"
              href={`/${PAGE_URL.PAP}`}
              onClick={(e) => {
                e.preventDefault();
                history.push(`/${PAGE_URL.PAP}`);
              }}
            >
              PAP 리스트로 돌아가기
            </Button>
            <Divider />
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item key="1" onClick={completeSelected}>
                    지원금 입금 완료로 변경 및 푸시 발송
                  </Menu.Item>
                </Menu>
              }
              disabled={selectedUser.length === 0}
            >
              <Button>
                선택한 유저 <DownOutlined />
              </Button>
            </Dropdown>
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item key="1" onClick={exportFinancialReport}>
                    대량 이체 엑셀 추출
                  </Menu.Item>
                  <Menu.Item key="2" onClick={exportPatientsReport}>
                    제약사 보고서 엑셀 추출
                  </Menu.Item>
                </Menu>
              }
            >
              <Button style={{ marginLeft: '8px' }}>
                전체 유저 <DownOutlined />
              </Button>
            </Dropdown>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (papRequestPagination.filter.keyword =
                      value.length > 0 ? value : null),
                  ),
                );
              }}
              defaultValue={papRequestPagination.filter.keyword}
            />
            <div className="searchResult">{`검색결과 ${papRequestPagination.total}개`}</div>
            <Table
              loading={loading}
              pageUpdate={page}
              setPageUpdate={setPage}
              columns={columns}
              dataSource={papRequests}
              size="small"
              bordered
              rowSelection={{
                type: 'checkbox',
                onChange: (selectedRowKeys) => {
                  setSelectedUser(selectedRowKeys);
                },
              }}
              onChange={onTableChange}
              rowKey={(record) => record.id}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PapAuditList;
