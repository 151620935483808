import React, { useState, useEffect, useCallback, useReducer } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { Layout, Tag, Input, Tooltip, Card, Switch, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { useHistory } from 'react-router-dom';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { dnaCounselingRequestCreators } from '../../../store/reducers/dnaCounselingRequest.reducer';
import { useMutate } from '../../../hooks/useRequest';
import { ALERT_MESSAGES } from '../../../assets/alert';
import { patchSetting } from '../../../services/dnaCounselingRequestService';

const DnaCounselingSetting = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { dnaCounselingRequestInfo } = useSelector(
    (state) => ({
      dnaCounselingRequestInfo:
        state?.dnaCounselingRequestReducer?.dnaCounselingSetting.data,
    }),
    shallowEqual,
  );

  const getData = useCallback(() => {
    dispatch(
      dnaCounselingRequestCreators.fetchAllDnaCounselingSetting.request(),
    );
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const MIN = 10;
  const MAX = 19;
  const counselingTime = [...Array(MAX - MIN)].map((_, index) => ({
    value: index + MIN,
    selected: false,
  }));

  const initialState = {
    tags: ['주제1 입니다.', '주제2 입니다.', '주제3 입니다.'],
    inputVisible: false,
    inputValue: '',
    editInputIndex: -1,
    editInputValue: '',
    monTime: counselingTime,
    tueTime: counselingTime,
    wedTime: counselingTime,
    thuTime: counselingTime,
    friTime: counselingTime,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const { Content } = Layout;
  const [state, setState] = useReducer(stateReducer, initialState);
  const [settingId, setSettingId] = useState(null);
  const [settingData, setSettingData] = useState(null);
  const [isPatch, setIsPatch] = useState(false);

  const postPatchData = {
    availableHours: settingData,
  };

  const {
    mutate: changeSetting,
    done: isPatched,
    initialize: initializePatch,
  } = useMutate(patchSetting, settingId, postPatchData);

  const handleClose = (removedTag) => {
    const tags = state.tags.filter((tag) => tag !== removedTag);
    setState({ tags });
  };

  const showInput = () => {
    setState({ inputVisible: true }, () => input.focus());
  };

  const handleInputChange = (e) => {
    setState({ inputValue: e.target.value });
  };

  const handleInputConfirm = () => {
    const { inputValue } = state;
    let { tags } = state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    setState({
      tags,
      inputVisible: false,
      inputValue: '',
    });
  };

  const handleEditInputChange = (e) => {
    setState({ editInputValue: e.target.value });
  };

  const handleEditInputConfirm = () => {
    const newTags = [...state.tags];
    newTags[state.editInputIndex] = state.editInputValue;
    setState({ tags: newTags, editInputIndex: -1, editInputValue: '' });
  };

  const saveInputRef = (input) => {
    state.input = input;
  };

  const saveEditInputRef = (input) => {
    state.editInput = input;
  };

  const moveToPage = () => {
    history.push('/dnaCounselingRequest');
  };

  useEffect(() => {
    const message = ALERT_MESSAGES.UPDATE;
    if (isPatched) {
      alert(message);
      initializePatch();
    }
  }, [isPatched]);

  const onTime = (values, label) => {
    setSettingId(
      dnaCounselingRequestInfo?.filter((day) => day.day === label)?.[0]?.id,
    );
    setSettingData(
      values.filter((word) => word.selected)?.map((value) => value.value),
    );

    if (window.confirm('수정하시겠습니까?')) {
      setIsPatch(true);
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeSetting();
      setIsPatch(false);
    }
  }, [isPatch]);

  const setDayTime = (hour, label) => {
    const funcs = dnaCounselingRequestInfo
      ?.filter((day) => day.day === label)?.[0]
      ?.availableHours.includes(hour.value)
      ? { ...hour, selected: true }
      : hour;
    return funcs;
  };

  useEffect(() => {
    if (dnaCounselingRequestInfo) {
      setState({
        monTime: state.monTime?.map((hour) => setDayTime(hour, '월')),
        tueTime: state.tueTime?.map((hour) => setDayTime(hour, '화')),
        wedTime: state.wedTime?.map((hour) => setDayTime(hour, '수')),
        thuTime: state.thuTime?.map((hour) => setDayTime(hour, '목')),
        friTime: state.friTime?.map((hour) => setDayTime(hour, '금')),
      });
    }
  }, [dnaCounselingRequestInfo]);

  const SwitchTime = ({ timeList, label }) => {
    return (
      <>
        {timeList.map((time) => (
          <div>
            <Switch
              defaultChecked={time.selected}
              onChange={(value) => onChangeSwitch(value, time.value, label)}
            />
          </div>
        ))}
      </>
    );
  };

  const onChangeSwitch = (checked, index, label) => {
    setState({
      [label]: state[label].map((hour) =>
        hour.value === index
          ? {
              ...hour,
              selected: checked,
            }
          : hour,
      ),
    });
  };

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabData" link="dnaCounselingRequest" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="데이터"
            subTitle="유전자검사 신청 설정"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button type="primary" onClick={() => moveToPage()}>
              목록 돌아가기
            </Button>
            <p />
            <div className="divider" />
            <div>
              <div>
                <Row gutter={16}>
                  <Col span={16} style={{ minWidth: 660 }}>
                    <Card
                      title="상담 시간 설정"
                      bordered={true}
                      className="dnaCounselingTime"
                    >
                      <Row>
                        <Col span={3}>
                          <div>&nbsp;</div>
                          <div>10~11</div>
                          <div>11~12</div>
                          <div>12~13</div>
                          <div>13~14</div>
                          <div>14~15</div>
                          <div>15~16</div>
                          <div>16~17</div>
                          <div>17~18</div>
                          <div>18~19</div>
                        </Col>
                        <Col span={3}>
                          <div>월</div>
                          <SwitchTime
                            timeList={state.monTime}
                            label="monTime"
                          />
                          <Button
                            type="primary"
                            onClick={() => onTime(state.monTime, '월')}
                          >
                            저장
                          </Button>
                        </Col>
                        <Col span={3}>
                          <div>화</div>
                          <SwitchTime
                            timeList={state.tueTime}
                            label="tueTime"
                          />
                          <Button
                            type="primary"
                            onClick={() => onTime(state.tueTime, '화')}
                          >
                            저장
                          </Button>
                        </Col>
                        <Col span={3}>
                          <div>수</div>
                          <SwitchTime
                            timeList={state.wedTime}
                            label="wedTime"
                          />
                          <Button
                            type="primary"
                            onClick={() => onTime(state.wedTime, '수')}
                          >
                            저장
                          </Button>
                        </Col>
                        <Col span={3}>
                          <div>목</div>
                          <SwitchTime
                            timeList={state.thuTime}
                            label="thuTime"
                          />
                          <Button
                            type="primary"
                            onClick={() => onTime(state.thuTime, '목')}
                          >
                            저장
                          </Button>
                        </Col>
                        <Col span={3}>
                          <div>금</div>
                          <SwitchTime
                            timeList={state.friTime}
                            label="friTime"
                          />
                          <Button
                            type="primary"
                            onClick={() => onTime(state.friTime, '금')}
                          >
                            저장
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  <Col span={8}>
                    <Card
                      title="상담 목적 설정"
                      bordered={true}
                      className="dnaCounselingTime"
                      extra={
                        <Button type="primary" disabled>
                          저장
                        </Button>
                      }
                    >
                      {state.tags.map((tag, index) => {
                        if (state.editInputIndex === index) {
                          return (
                            <Input
                              ref={saveEditInputRef}
                              key={tag}
                              size="small"
                              className="tag-input"
                              value={state.editInputValue}
                              onChange={handleEditInputChange}
                              onBlur={handleEditInputConfirm}
                              onPressEnter={handleEditInputConfirm}
                            />
                          );
                        }

                        const isLongTag = tag.length > 20;

                        const tagElem = (
                          <Tag
                            className="edit-tag"
                            key={tag}
                            closable={index !== 0}
                            onClose={() => handleClose(tag)}
                          >
                            <span
                              onDoubleClick={(e) => {
                                if (index !== 0) {
                                  setState(
                                    {
                                      editInputIndex: index,
                                      editInputValue: tag,
                                    },
                                    () => {
                                      editInput.focus();
                                    },
                                  );
                                  e.preventDefault();
                                }
                              }}
                            >
                              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                            </span>
                          </Tag>
                        );
                        return isLongTag ? (
                          <Tooltip title={tag} key={tag}>
                            {tagElem}
                          </Tooltip>
                        ) : (
                          tagElem
                        );
                      })}
                      {state.inputVisible && (
                        <Input
                          ref={saveInputRef}
                          type="text"
                          size="small"
                          className="tag-input"
                          value={state.inputValue}
                          onChange={handleInputChange}
                          onBlur={handleInputConfirm}
                          onPressEnter={handleInputConfirm}
                        />
                      )}
                      {!state.inputVisible && (
                        <Tag className="site-tag-plus" onClick={showInput}>
                          <PlusOutlined /> 새로운 상담 목적
                        </Tag>
                      )}
                    </Card>
                  </Col>
                </Row>
              </div>
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DnaCounselingSetting;
