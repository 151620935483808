export const SEQUENCING_COMPANY = [
  {
    companyName: '원오믹스',
    validColumn: [
      'geneName',
      'inheritance',
      'zygosity',
      'annoVar',
      'nucleotide',
      'aminoAcid',
      'sift',
      'polyPhen',
      'clinVarSignificance',
      'alleleFreq',
      'exAcFreqEas',
      'krgdb',
    ],
  },
  {
    companyName: '세브란스 병원',
    validColumn: [
      'geneName',
      'inheritance',
      'zygosity',
      'vep',
      'nucleotide',
      'aminoAcid',
      'clinVarSignificance',
      'acmgClassification',
      'alleleFreq',
      'exAcFreqEas',
      'krgdb',
    ],
  },
  {
    companyName: '3billion',
    validColumn: [
      'geneName',
      'inheritance',
      'zygosity',
      'vep',
      'nucleotide',
      'aminoAcid',
      'acmgClassification',
      'genomADExomeEas',
    ],
  },
];

export const INHERITANCE = [
  'AD',
  'AR',
  'AD or AR',
  'X',
  'XD',
  'XR',
  'Y',
  'MT',
  'Sporadic',
];

export const ZYGOSITY = [
  'Homozygous',
  'Heterozygous',
  'Hemizygous',
  'Compound Heterozygous',
  '기타',
];

export const ANNOVAR = [
  'Frameshift Insertion',
  'Frameshift Deletion',
  'Frameshift Block Substitution',
  'Stopgain',
  'Stoploss',
  'Nonframeshift Insertion',
  'Nonframeshift Deletion',
  'Nonframeshift Block Substitution',
  'Nonsynonymous SNV',
  'Synonymous SNV',
  'Unknown',
];

export const VEP = [
  'Frameshift Variant',
  'Stop Gained',
  'Stop Lost',
  'Inframe Insertion',
  'Inframe Deletion',
  'Missense Variant',
  'Synonymous Variant',
  'Intron Variant',
  'Splice Region Variant',
  'Splice Acceptor Variant',
  'Splice Donor Variant',
  'Transcript Ablation',
  'Start Lost',
  'Protein Altering Variant',
  'Incomplete Terminal Codon Variant',
  'Transcript Amplification',
];

export const SIFT = ['Deleterious', 'Tolerated', 'Unknown'];

export const POLYPHEN = [
  'Probably Damaging',
  'Possibly Damaging',
  'Benign',
  'Unknown',
];

export const MUTATIONTASTER = [
  'D: disease causing',
  'A: disease causing automatic',
  'N: polymorphism',
  'P: polymorphism automatic',
];

export const CLINVAR = [
  'Pathogenic',
  'Likely Pathogenic',
  'Likely Benign',
  'Benign',
  'Uncertain Significance',
  'Drug Response',
  'Association',
  'Risk Factor',
  'Protective',
  'Affects',
  'Conflicting Data From Submitters',
  'Not Provided',
  'Pathogenic/ Likely Pathogenic',
  'Conflicting Interpretations Of Pathogenicity',
  'Benign/ Likely Benign',
];

export const ACMG = [
  'Pathogenic',
  'Likely Pathogenic',
  'VOUS/VUS',
  'Likely Benign',
  'Benign',
];

export const CADD = ['Likely Deleterious', 'Likely Benign'];

export const GERP = ['Constrained', 'Unconstrained'];

export const POLYPHEN2 = ['Damaging', 'Possibly Damaging', 'Benign', 'Unknown'];

export const VARIANT_ANNOVAR = [
  'Frameshift Insertion',
  'Frameshift Deletion',
  'Frameshift Block Substitution',
  'Stopgain',
  'Stoploss',
  'Nonframeshift Insertion',
  'Nonframeshift Deletion',
  'Nonframeshift Block Substitution',
  'Nonsynonymous SNV',
  'Synonymous SNV',
  'Unknown',
  'Frameshift Variant',
  'Stop Gained',
  'Stop Lost',
  'Inframe Insertion',
  'Inframe Deletion',
  'Missense Variant',
  'Synonymous Variant',
  'Intron Variant',
  'Splice Region Variant',
  'Splice Acceptor Variant',
  'Splice Donor Variant',
  'Transcript Ablation',
  'Start Lost',
  'Protein Altering Variant',
  'Incomplete Terminal Codon Variant',
  'Transcript Amplification',
];

export const VARIANT_CLASSIFICATION = [
  'Pathogenic',
  'Pathogenic/Likely Pathogenic',
  'Likely Pathogenic',
  'Likely Benign',
  'Benign/Likely Benign',
  'Benign',
  'Uncertain Significance',
  'VOUS/VUS',
];

export const INSILICO_PREDICTION = [
  'Deleterious',
  'Damaging',
  'Probably Damaging',
  'Possibly Damaging',
  'Tolerated',
  'Benign',
  'Unknown',
];

export const FATHMM = ['Damaging', 'Tolerated'];

export const MUTATIONASSESSOR = ['High', 'Medium', 'Low', 'Neutral'];
