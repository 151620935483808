import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form, Input, Button, Alert } from 'antd';
import 'antd/dist/antd.css';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Header from '../../../component/header';
import Footer from '../../../component/footer';
import { authCreators } from '../../../store/reducers/auth.reducer';

const clientId = process.env.REACT_APP_CLIENT_ID || '';
const clientSecret = process.env.REACT_APP_CLIENT_SECRET || '';

const Login = () => {
  // declare dispatch function & variables for fetching data from store
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.authReducer.auth?.data?.email);

  // component state
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken && !loggedIn)
      dispatch(authCreators.autoLogin.request(accessToken));
  }, [dispatch, loggedIn]);

  if (loggedIn) return <Redirect to="/main" />;

  // email box writting functionality
  const updateEmail = (event) => {
    setEmail(event.target.value);
  };

  // password box writting functionality
  const updatePassword = (event) => {
    setPassword(event.target.value);
  };

  // login command
  const adminLogin = () => {
    dispatch(
      authCreators.accessLogin.request({
        email,
        password,
        clientId,
        clientSecret,
      }),
    );
  };

  const onFinish = () => {
    setShowAlert(false);
    adminLogin();
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  return (
    <div className="d-flex flex-column outer">
      <Header isLoginPage={true} />
      <div className="d-flex flex-grow-1 container-fluid">
        <Row className="flex-fill">
          <Col className="loginContent">
            <div className="login-form">
              <Form
                name="basic"
                labelCol={{
                  span: 24,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="이메일"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: '이메일을 확인해주세요.',
                    },
                  ]}
                  onChange={updateEmail}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="비밀번호"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: '비밀번호를 확인해주세요.',
                    },
                  ]}
                  onChange={updatePassword}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: 24,
                  }}
                >
                  <Button type="primary" htmlType="submit" block>
                    로그인
                  </Button>
                </Form.Item>
                {showAlert && (
                  <Alert
                    message="에러"
                    description="이메일과 비밀번호를 확인해주세요."
                    type="error"
                    showIcon
                  />
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
