import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 용어를 가져옵니다.
 */
export async function fetchAllDictionary() {
  const res = await axios.get('/v3/admin/dictionary');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 용어를 가져옵니다.
 * @param {String} dictionaryId
 */
export async function fetchDictionary(dictionaryId) {
  const res = await axios.get(`/v3/admin/dictionary/${dictionaryId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 용어를 생성합니다.
 * @param {object} body
 */
export async function postDictionary(body) {
  const res = await axios.post('/v3/admin/dictionary', body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 용어를 수정합니다.
 * @param {String} dictionaryId
 * @param {object} body
 */
export async function patchDictionary(dictionaryId, body) {
  const res = await axios.patch(`/v3/admin/dictionary/${dictionaryId}`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 용어를 삭제합니다.
 * @param {String} dictionaryId
 */
export async function deleteDictionary(dictionaryId) {
  const res = await axios.delete(`/v3/admin/dictionary/${dictionaryId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
