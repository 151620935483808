import React, { useEffect, useCallback } from 'react';
import { Layout, Table, Button } from 'antd';
import 'antd/dist/antd.css';
import { useHistory, useLocation } from 'react-router';
import dayjs from 'dayjs';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import useWindow from '../../../hooks/useWindow';
import { usePagination } from '../../../hooks/usePagination';
import { fetchAllDiseaseTypeClinicalTrial } from '../../../services/diseaseTypeService';
import { makeLink } from './diseaseType';

const formatDate = (text) => dayjs(text).format('YYYY-MM-DD');

const DiseaseTypeClinicalTrial = () => {
  const { Content } = Layout;
  const history = useHistory();
  const { findWindowById, createWindow, destroyWindowById } = useWindow();
  const { state } = useLocation();
  const {
    apiParams: [diseaseTypeId, isApp],
    setApiParams,
    initialize,
    data,
    loading,
    pageState,
    pagination,
  } = usePagination({
    call: fetchAllDiseaseTypeClinicalTrial,
    initialApiParams: [state?.diseaseTypeId, true],
    initialPageState: {
      current: 1,
      pageSize: 10000,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: [10, 20, 50, 100, 1000, 10000, 100000],
    },
  });

  useEffect(() => {
    if (!state?.diseaseTypeId) history.goBack();
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initialize, []);

  const handleCreateEditWindow = (record) => {
    if (findWindowById(record.ctkId.toString())) {
      alert('이미 편집중인 글입니다.');
      return;
    }

    createWindow({
      id: record.ctkId,
      dataType: 'clinicalTrial',
      subId: state.diseaseTypeId,
    });
  };

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      setApiParams([state.diseaseTypeId, true], pageState);
    },
    [destroyWindowById, setApiParams, state?.diseaseTypeId, pageState],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const columns = [
    {
      title: '업데이트일',
      dataIndex: 'updatedAt',
      width: '9%',
      sorter: {
        compare: (a, b) => a?.updatedAt?.localeCompare(b?.updatedAt),
      },
      render: formatDate,
    },
    {
      title: '한임정 ID',
      dataIndex: 'ctkId',
      width: '6%',
      sorter: {
        compare: (a, b) => a?.ctkId?.localeCompare(b?.ctkId),
      },
    },
    {
      title: 'NCT ID',
      dataIndex: 'nctId',
      width: '11%',
      sorter: {
        compare: (a, b) => a?.nctId?.localeCompare(b?.nctId),
      },
      render: (text, { link }) =>
        link ? (
          <a
            style={{ color: '#40a9ff' }}
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            {text}
          </a>
        ) : (
          text
        ),
    },
    {
      title: '단계',
      dataIndex: 'clientPhase',
      width: '5%',
      sorter: {
        compare: (a, b) => a?.clientPhase?.localeCompare(b?.clientPhase),
      },
    },
    {
      title: '상태',
      dataIndex: 'clientOverallStatus',
      width: '10%',
      sorter: {
        compare: (a, b) =>
          a?.clientOverallStatus?.localeCompare(b?.clientOverallStatus),
      },
    },
    {
      title: '상태 업데이트',
      dataIndex: 'statusVerifiedDate',
      width: '9%',
      sorter: {
        compare: (a, b) =>
          a?.statusVerifiedDate?.localeCompare(b?.statusVerifiedDate),
      },
    },
    {
      title: '결과 등록',
      dataIndex: 'resultFirstPostDate',
      width: '9%',
      sorter: {
        compare: (a, b) =>
          a?.resultFirstPostDate?.localeCompare(b?.resultFirstPostDate),
      },
      render: (text, { nctId }) =>
        text && (
          <a
            style={{ color: '#40a9ff' }}
            href={`https://clinicaltrials.gov/ct2/show/results/${nctId}`}
            target="_blank"
            rel="noreferrer"
          >
            {text}
          </a>
        ),
    },
    {
      title: '스폰서',
      dataIndex: 'leadSponsorName',
      width: '11%',
      ellipsis: true,
      sorter: {
        compare: (a, b) =>
          a?.leadSponsorName?.localeCompare(b?.leadSponsorName),
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      width: '24%',
      render: makeLink(handleCreateEditWindow),
      sorter: {
        compare: (a, b) => a?.title?.localeCompare(b?.title),
      },
    },
    {
      title: '앱 노출',
      dataIndex: 'isApp',
      filters: [
        { text: 'O', value: true },
        { text: 'X', value: false },
      ],
      width: '6%',
      render: (text) => text && 'O',
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="diseaseType" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="정보"
            subTitle={`${state?.name ? `${state.name} ` : ''}관련 임상시험`}
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button type="primary" onClick={() => history.goBack()}>
              목록 돌아가기
            </Button>
            <div>
              <div className="searchResult">
                검색결과 {pagination.total ?? 0}개
              </div>

              <Table
                columns={columns}
                rowKey={(record) => record.id}
                dataSource={data}
                pagination={pagination}
                loading={loading}
                size="small"
                onChange={(_, filters, __, { action }) => {
                  if (action !== 'filter') return;
                  const isAppFilter = filters.isApp;
                  const newIsApp =
                    isAppFilter?.length === 1 ? isAppFilter[0] : null;
                  if (isApp !== newIsApp)
                    setApiParams([diseaseTypeId, newIsApp]);
                }}
                bordered
              />
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default DiseaseTypeClinicalTrial;
