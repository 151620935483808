import { all, takeLatest } from 'redux-saga/effects';
import { tagCreators, tagActions } from '../reducers/tag.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchTopicTags } from '../../services/tagService';

const handleFetchAllTags = createAsyncSaga(
  tagCreators.fetchTopicTags,
  fetchTopicTags,
);

export function* topicTagSaga() {
  yield all([takeLatest(tagActions.FETCH_TOPIC_TAGS, handleFetchAllTags)]);
}
