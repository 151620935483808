import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 역할-권한 설정을 추가/수정합니다.
 * @param {String} roleId
 * @param {array} permissionIds
 */
export async function postRolePermission(roleId, permissionIds) {
  const res = await axios.post('/v3/rolePermissions', {
    roleId,
    permissionIds,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
