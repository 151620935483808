import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  treatmentStudy: getAsyncState.initial(null),
};

// action types
export const treatmentStudyActions = {
  FETCH_ALL_TREATMENT_STUDIES: '@treatmentStudy/FETCH_ALL_TREATMENT_STUDIES',
  FETCH_ALL_TREATMENT_STUDIES_BY_DISEASE_GROUP:
    '@treatmentStudy/FETCH_ALL_TREATMENT_STUDIES_BY_DISEASE_GROUP',
};

// actions
export const treatmentStudyCreators = {
  fetchAllTreatmentStudies: createAsyncActions(
    treatmentStudyActions,
    'FETCH_ALL_TREATMENT_STUDIES',
  ),
  fetchAllTreatmentStudiesByDiseaseGroup: createAsyncActions(
    treatmentStudyActions,
    'FETCH_ALL_TREATMENT_STUDIES_BY_DISEASE_GROUP',
  ),
};

const treatmentStudyReducer = handleActions(
  {
    ...handleAsyncActions(
      treatmentStudyActions.FETCH_ALL_TREATMENT_STUDIES,
      'treatmentStudy',
    ),
    ...handleAsyncActions(
      treatmentStudyActions.FETCH_ALL_TREATMENT_STUDIES_BY_DISEASE_GROUP,
      'treatmentStudy',
    ),
  },
  initialState,
);

export default treatmentStudyReducer;
