import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 임상상담신청을 가져옵니다.
 */
export async function fetchAllClinicalTrialCounselingRequest() {
  const res = await axios.get(`/v4/admin/clinicalTrialCounselingRequests`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 임상상담신청 정보를 가져옵니다.
 * @param {String} clinicalTrialCounselingRequestId
 */
export async function fetchClinicalTrialCounseling(
  clinicalTrialCounselingRequestId,
) {
  const res = await axios.get(
    `/v4/admin/clinicalTrialCounselingRequests/${clinicalTrialCounselingRequestId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 임상상담신청을 수정합니다.
 * @param {String} clinicalTrialCounselingRequestId
 * @param {object} body
 */
export async function patchClinicalTrialCounseling(
  clinicalTrialCounselingRequestId,
  body,
) {
  const res = await axios.patch(
    `/v4/admin/clinicalTrialCounselingRequests/${clinicalTrialCounselingRequestId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 임상상담신청을 삭제합니다.
 * @param {String} clinicalTrialCounselingRequestId
 * @param {object} body
 */
export async function deleteClinicalTrialCounseling(
  clinicalTrialCounselingRequestId,
) {
  const res = await axios.delete(
    `v4/admin/clinicalTrialCounselingRequests/${clinicalTrialCounselingRequestId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 임상 검사 상담 내역을 가져옵니다.
 * @param {String} userId
 */
export async function fetchClinicalTrialUserCounseling(userId) {
  const res = await axios.get(
    `/v4/admin/users/${userId}/clinicalTrialCounselingRequests`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 모든 임상상담 신청 설정을 가져옵니다.
 */
export async function fetchAllClinicalTrialCounselingSetting() {
  const res = await axios.get('/v4/admin/clinicalTrialCounselingSchedules');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 임상상담 신청 (가능) 스케쥴을 수정합니다.
 * @param {String} settingId
 * @param {object} body
 */
export async function patchClinicalTrialCounselingSetting(settingId, body) {
  const res = await axios.patch(
    `/v4/admin/clinicalTrialCounselingSchedules/${settingId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
