import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout, Menu, Dropdown } from 'antd';
import { authCreators } from '../store/reducers/auth.reducer';
import { userCreators } from '../store/reducers/user.reducer';
import SessionTimer from './sessionTimer';
import logoImg from '../img/logo.svg';
import 'antd/dist/antd.css';

import logged from '../img/img_logged_in.png';

const Header = ({ isLoginPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const email = useSelector((state) => state.authReducer.auth.data?.email);

  const { Header } = Layout;

  const headerOption =
    process.env.REACT_APP_DEPLOY === 'production'
      ? '#343a40'
      : process.env.REACT_APP_DEPLOY === 'staging'
      ? '#009b5a'
      : '#22549e';

  const logoutResetData = useCallback(() => {
    dispatch(authCreators.logout());
    dispatch(userCreators.permissionLogout());
  }, [dispatch]);

  const menu = (
    <Menu>
      <Menu.ItemGroup title="세션타이머">
        <Menu.Item>
          <SessionTimer />
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup title="계정">
        <Menu.Item
          onClick={() => {
            if (window.confirm('로그아웃 하시겠습니까?')) {
              logoutResetData();
              window.localStorage.clear();
            }
          }}
        >
          로그아웃
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <Header
      style={{ backgroundColor: headerOption }}
      className="header headerStyle"
    >
      <div className="logo headerLogo" onClick={() => history.push('/main')}>
        <img alt="logo" src={logoImg} />
        <div className="headerText">
          {process.env.REACT_APP_DEPLOY === 'production'
            ? '운영 어드민'
            : process.env.REACT_APP_DEPLOY === 'staging'
            ? '스테이지 어드민'
            : '개발 어드민'}
        </div>
      </div>
      {!isLoginPage && (
        <div style={{ float: 'right' }}>
          <Dropdown overlay={menu} trigger={['click']}>
            <a className="headerDrop">
              <img src={logged} alt="logged" />
              {email}
            </a>
          </Dropdown>
        </div>
      )}
    </Header>
  );
};
export default Header;
