import React, { useEffect, useState, useReducer } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Alert, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import WindowHeader from '../../components/windowHeader';
import {
  postRehabilitation,
  patchRehabilitation,
  deleteRehabilitation,
} from '../../../services/rehabilitationService';
import { useMutate } from '../../../hooks/useRequest';
import ElementLoading from '../../components/elementLoading';
import { ALERT_MESSAGES } from '../../../assets/alert';

const RehabilitationWindow = () => {
  const dispatch = useDispatch();

  const { TextArea } = Input;
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [rehabilitationId, setRehabilitationId] = useState(null);
  const [diseaseTypeId, setDiseaseTypeId] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);

  const initialState = {
    title: '',
    subTitle: '',
    description: '',
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(stateReducer, initialState);

  const postPatchData = {
    title: state.title,
    subTitle: state.subTitle,
    description: state.description,
    diseaseTypeId,
  };

  const { mutate: putRehabilitation, done: isPosted } = useMutate(
    postRehabilitation,
    postPatchData,
  );
  const { mutate: changeRehabilitation, done: isPatched } = useMutate(
    patchRehabilitation,
    rehabilitationId,
    postPatchData,
  );
  const { mutate: eraseRehabilitation, done: isDeleted } = useMutate(
    deleteRehabilitation,
    rehabilitationId,
  );

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setRehabilitationId(params.id);
      const paramData = JSON.parse(params.subId);
      setDiseaseTypeId(params.diseaseTypeId);
      setState({
        title: paramData.title,
        subTitle: paramData.subTitle,
        description: paramData.description,
      });
      setIsLoading(false);
    } else {
      const subParamData = JSON.parse(params.subId);
      setDiseaseTypeId(subParamData.diseaseTypeId);
      setRehabilitationId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (rehabilitationId) {
      setIsLoading(false);
    }
  }, [rehabilitationId]);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      title: values.title,
      subTitle: values.subTitle,
      description: values.description,
    });
    if (rehabilitationId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      eraseRehabilitation();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeRehabilitation();
      setIsPatch(false);
    }
    if (isPost) {
      putRehabilitation();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  if (isLoading) return <ElementLoading type="재활" />;
  return (
    <>
      <WindowHeader title="재활" />

      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              title: state.title,
              subTitle: state.subTitle,
              description: state.description,
            }}
          >
            <Form.Item
              label="제목"
              name="title"
              rules={[
                {
                  required: true,
                  message: '제목을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="subTitle"
              name="subTitle"
              rules={[
                {
                  required: true,
                  message: '부제목을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="설명글"
              name="description"
              rules={[
                {
                  required: true,
                  message: '설명글을 확인해주세요.',
                },
              ]}
            >
              <TextArea />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {rehabilitationId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default RehabilitationWindow;
