const { REACT_APP_RARENOTE_URL, NODE_ENV } = process.env;
const urlSuffix = '/api';

/**
 * 환경변수 및 실행 환경에 따라 Base URL을 반환하는 팩토리 함수
 */
const getBaseURL = () => {
  let baseURL;

  if (!REACT_APP_RARENOTE_URL || NODE_ENV === 'development') {
    baseURL = 'https://sso-dev.rarenote.io/rarenote';
  } else {
    baseURL = REACT_APP_RARENOTE_URL;
  }

  return baseURL + urlSuffix;
};

// TODO: 팩토리 함수의 return 값이 아닌 함수를 export해야 함. 이후 일부 코드 변경해야 함.
// export default getBaseURL;
export default getBaseURL();
