import React, { useMemo, useEffect, useState, useRef } from 'react';
import qs from 'query-string';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Input, Button, Row, Col, Select, Spin, Checkbox } from 'antd';
import WindowHeader from '../../components/windowHeader';
import 'antd/dist/antd.css';
import { dnaCreators } from '../../../store/reducers/dna.reducer';
import { dictionaryCreators } from '../../../store/reducers/dictionary.reducer';
import {
  postDiseaseType,
  patchDiseaseType,
  deleteDiseaseType,
  fetchDiseaseType,
} from '../../../services/diseaseTypeService';
import { useLazyRequest } from '../../../hooks/useRequest';
import 'bootstrap';
import { ALERT_MESSAGES } from '../../../assets/alert';
import ElementLoading from '../../components/elementLoading';
import {
  fetchAllChapters,
  fetchByCode,
} from '../../../services/standardDiseaseService';
import TinymceInput from '../../components/tinymceInput';

const setupBeforeUnloadListener = (data) => {
  window.addEventListener('beforeunload', (event) => {
    event.preventDefault();
    return window?.opener?.postMessage(data, '/');
  });
};

const onCancel = () => window.confirm('취소하시겠습니까?') && window.close();
const isDone = ({ data, loading }) => data && !loading;

const kcdCodeRegex = /^[A-Z]\d{2}(-[A-Z]\d{2}|(\.\d(\d)?)?)$/;
const toStandardDiseaseOption = ({ code, id, krName }) => ({
  label: `[${code}] ${krName}`,
  value: id,
});
const extractId = ({ id }) => id;
const filterOption = (input, { label }) =>
  label.toLowerCase().includes(input.toLowerCase());

const flagOptions = [
  { label: '국내 희귀질환', name: 'isDomestic', disabled: true },
  { label: '유전자 상담 신청', name: 'isCounsellable', disabled: true },
  { label: '유전자 탭 노출', name: 'isHereditary', disabled: true },
  { label: '정식 질환', name: 'isRegular' },
  { label: '자주 선택된 질환', name: 'isFrequentlySelected' },
  { label: '앱 노출', name: 'isApp' },
  { label: '유전자 리포트', name: 'isDnaReportable' },
];
const nullIfMissingFields = [
  'krSynonyms',
  'enSynonyms',
  'content',
  'feature',
  'mainSymptom',
  'exemptionCode',
  'helplineLink',
];

const DiseaseTypeWindow = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { dnaInfo, dnas, dictionaryInfo, dictionaries } = useSelector(
    (state) => {
      const dnaData = state.dnaReducer.dna?.data;
      const dictionaryData = state.dictionaryReducer.dictionary?.data;
      return {
        dnaInfo: dnaData,
        dnas: dnaData?.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
        dictionaryInfo: dictionaryData,
        dictionaries: state.dictionaryReducer.dictionary?.data?.map(
          ({ id, krName }) => ({ label: krName, value: id }),
        ),
      };
    },
    shallowEqual,
  );
  const [windowId, setWindowId] = useState(null);
  const [standardDiseaseOptions, setStandardDiseaseOptions] = useState([]);
  const [form] = Form.useForm();
  const standardDiseaseSelectRef = useRef(null);
  const { fetchDetail, fetchStandardDiseases } = useMemo(
    () => ({
      fetchDetail: (diseaseTypeId) =>
        fetchDiseaseType(diseaseTypeId).then((data) => {
          let standardDiseaseIds;
          if (data.standardDiseases) {
            setStandardDiseaseOptions(
              data.standardDiseases.map(toStandardDiseaseOption),
            );
            standardDiseaseIds = data.standardDiseases.map(extractId);
          }
          return {
            ...data,
            chapterId: data.chapterId && +data.chapterId,
            krSynonyms: data.krSynonyms ?? [],
            enSynonyms: data.enSynonyms ?? [],
            dnaIds: data.dnas?.map(extractId),
            dictionaryIds: data?.dictionaries?.map(extractId),
            standardDiseaseIds,
          };
        }),
      fetchStandardDiseases: (codePrefix, chapterId) =>
        kcdCodeRegex.test(codePrefix) &&
        fetchByCode({ codePrefix, chapterId }).then((list) => {
          const options = list.map(toStandardDiseaseOption);
          setStandardDiseaseOptions(options);
          standardDiseaseSelectRef.current.focus();
          return options;
        }),
    }),
    [setStandardDiseaseOptions, standardDiseaseSelectRef],
  );
  const { data: diseaseType, loading, call } = useLazyRequest(
    fetchDetail,
    null,
    false,
  );
  const createRequest = useLazyRequest(postDiseaseType);
  const updateRequest = useLazyRequest(patchDiseaseType);
  const deleteRequest = useLazyRequest(deleteDiseaseType);
  const chapterRequest = useLazyRequest(
    () => fetchAllChapters().then((list) => list.map(toStandardDiseaseOption)),
    null,
    false,
  );

  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    switch (true) {
      case isDone(updateRequest):
        alert(ALERT_MESSAGES.UPDATE);
        break;
      case isDone(deleteRequest):
        alert(ALERT_MESSAGES.DELETE);
        break;
      case isDone(createRequest):
        alert(ALERT_MESSAGES.CREATE);
        break;
      default:
        return;
    }
    window.close();
  }, [updateRequest, deleteRequest, createRequest]);

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    dispatch(dnaCreators.fetchAllDnas.request());
    dispatch(dictionaryCreators.fetchAllDictionary.request());
    const id = params.id ?? params.new;
    if (params.id) call(params.id);
    setWindowId(id);
    setupBeforeUnloadListener(`close ${id}`);
  }, [search, dispatch, call]);

  useEffect(() => {
    if (!chapterRequest.data && !chapterRequest.loading) chapterRequest.call();
  }, [chapterRequest]);
  useEffect(() => {
    if (diseaseType) form.setFieldsValue(diseaseType);
  }, [form, diseaseType]);

  if (!windowId || loading) return <ElementLoading type="질환" />;

  return (
    <>
      <WindowHeader title="질환" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={(values) => {
              nullIfMissingFields.forEach((field) => {
                if (!values[field]) values[field] = null;
              });
              return diseaseType
                ? updateRequest.call(diseaseType.id, values)
                : createRequest.call(values);
            }}
          >
            <Form.Item
              label="질환명(한글)"
              name="krName"
              rules={[
                { required: true, message: '질환명(한글)을 확인해주세요.' },
              ]}
            >
              <Input.TextArea autoSize />
            </Form.Item>
            <Form.Item
              label="질환명(영어)"
              name="enName"
              rules={[
                { required: true, message: '질환명(영어)을 확인해주세요.' },
              ]}
            >
              <Input.TextArea autoSize />
            </Form.Item>
            <Form.Item label="동의어(한글)" name="krSynonyms">
              <Select
                allowClear
                mode="tags"
                style={{ width: '100%' }}
                notFoundContent={null}
              />
            </Form.Item>
            <Form.Item label="동의어(영어)" name="enSynonyms">
              <Select
                allowClear
                mode="tags"
                style={{ width: '100%' }}
                notFoundContent={null}
              />
            </Form.Item>
            <Form.Item label="내용" name="content">
              <LoadingOverlay
                active={imageLoading}
                spinner={<Spin />}
                text={<p>이미지를 업로드 하는 중</p>}
              >
                <TinymceInput
                  content={diseaseType?.content}
                  setContent={({ target: { value } }) =>
                    form.setFieldsValue({ content: value })
                  }
                  windowId={windowId}
                  setImageLoading={setImageLoading}
                  contentLinkData={{
                    dictionaryInfo,
                    dnaInfo,
                    diseaseTypeInfo: [],
                    setState: ({ content }) => form.setFieldsValue({ content }),
                  }}
                  name="content"
                  showLabel={false}
                  showApplyContent={false}
                />
              </LoadingOverlay>
            </Form.Item>
            <Form.Item label="주요 증상" name="mainSymptom">
              <Input.TextArea autoSize />
            </Form.Item>
            <Form.Item label="특징" name="feature">
              <Input.TextArea autoSize />
            </Form.Item>
            <Form.Item label="산정특례 코드" name="exemptionCode">
              <Input />
            </Form.Item>
            <Form.Item
              label="헬프라인 링크"
              name="helplineLink"
              rules={[{ type: 'url', message: 'URL을 입력해주세요.' }]}
              validateTrigger="onBlur"
            >
              <Input />
            </Form.Item>
            <Form.Item label="속성">
              {flagOptions.map(({ label, name, disabled }) => (
                <Form.Item
                  key={name}
                  name={name}
                  valuePropName="checked"
                  noStyle
                >
                  <Checkbox disabled={disabled}>{label}</Checkbox>
                </Form.Item>
              ))}
            </Form.Item>
            <Form.Item label="관련 유전자" name="dnaIds">
              <Select
                showSearch
                allowClear
                mode="multiple"
                options={dnas}
                filterOption={filterOption}
                style={{ width: '100%' }}
                placeholder="관련 유전자를 선택하세요"
              />
            </Form.Item>
            <Form.Item name="dictionaryIds" label="관련 용어">
              <Select
                showSearch
                allowClear
                mode="multiple"
                options={dictionaries}
                filterOption={filterOption}
                style={{ width: '100%' }}
                placeholder="관련 용어를 선택하세요"
              />
            </Form.Item>
            <Form.Item
              label="관련 표준 질환 (KCD-7)"
              shouldUpdate={(prev, curr) => prev.chapterId !== curr.chapterId}
            >
              {() => {
                const chapterId = form.getFieldValue('chapterId');
                return (
                  <>
                    <Input.Group compact>
                      <Form.Item name="chapterId" noStyle>
                        <Select
                          showSearch
                          allowClear
                          options={chapterRequest.data}
                          onChange={() => {
                            form.setFieldsValue({ standardDiseaseIds: [] });
                            setStandardDiseaseOptions([]);
                          }}
                          filterOption={filterOption}
                          style={{ width: '69%', marginRight: '2%' }}
                          placeholder="질환 대분류를 선택하세요"
                        />
                      </Form.Item>
                      <Form.Item style={{ width: '29%' }}>
                        <Form.Item
                          name="code"
                          rules={[
                            {
                              pattern: kcdCodeRegex,
                              message: 'KCD-7 코드 형식이 아닙니다',
                            },
                          ]}
                          validateTrigger="onSearch"
                          noStyle
                        >
                          <Input.Search
                            {...(chapterId
                              ? { placeholder: '질환 코드를 입력하세요' }
                              : { disabled: true })}
                            onSearch={(codePrefix, e) => {
                              e.preventDefault(); // no submit
                              fetchStandardDiseases(codePrefix, chapterId);
                            }}
                          />
                        </Form.Item>
                      </Form.Item>
                    </Input.Group>
                    {chapterId && (
                      <Form.Item name="standardDiseaseIds">
                        <Select
                          allowClear
                          ref={standardDiseaseSelectRef}
                          mode="multiple"
                          options={standardDiseaseOptions}
                          showAction={['focus']}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    )}
                  </>
                );
              }}
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onCancel}
              >
                취소
              </Button>
              {diseaseType && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={() =>
                    window.confirm('삭제하시겠습니까?') &&
                    deleteRequest.call(diseaseType.id)
                  }
                >
                  삭제
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default DiseaseTypeWindow;
