import React, { useState, useRef, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout, Table, Input } from 'antd';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import { dashboardCreators } from '../../../store/reducers/dashboard.reducer';
import 'antd/dist/antd.css';

const renderPlaceholder = (value) => value ?? '-';

const getSortableCol = (
  title,
  dataIndex,
  width,
  { sorter, render } = {
    sorter: (a, b) => a?.[dataIndex]?.localeCompare(b?.[dataIndex]),
    render: renderPlaceholder,
  },
) => ({ title, dataIndex, width, sorter, render });

const UserData = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { dashboardUserData, dashboardDataPagination } = useSelector(
    (state) => {
      return {
        dashboardUserData: state?.dashboardReducer?.dashboardUserData?.data,
        dashboardDataPagination:
          state?.paginationReducer?.dashboardDataPagination,
      };
    },
    shallowEqual,
  );

  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const confirmRef = useRef(() => {});

  const handleMoveDetailPage = useCallback(
    ({ userId }) => () => history.push('/userDetail', { userId }),
    [history],
  );

  const getData = useCallback(() => {
    dispatch(dashboardCreators.fetchDashboardUserData.request());
  }, [dispatch]);
  useEffect(getData, [getData]);

  const { Content } = Layout;
  const { Search } = Input;

  useEffect(() => {
    if (dashboardUserData) {
      if (dashboardDataPagination.total === null)
        setTotal(dashboardUserData?.length);
      else setTotal(dashboardDataPagination.total);
      setPageUpdate(dashboardDataPagination.page);
      confirmRef.current(dashboardDataPagination.text);
    }
  }, [dashboardUserData]);

  const columns = [
    {
      title: '계정',
      dataIndex: 'email',
      width: 180,
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: dashboardDataPagination.filter?.email || null,
      onFilter: (
        search,
        {
          email,
          name,
          phone,
          institution,
          diseaseTypeName,
          type,
          certifiedDiseaseType,
          mutationDnas,
          createdAt,
        },
      ) =>
        [
          email,
          name,
          phone,
          institution,
          diseaseTypeName,
          type,
          certifiedDiseaseType?.krName,
          ...(mutationDnas ?? []),
          createdAt,
        ].some((value) => value?.includes(search)),
      render: (text, record) =>
        text ? <a onClick={handleMoveDetailPage(record)}>{text}</a> : '-',
    },
    getSortableCol('이름', 'name', 80),
    getSortableCol('연락처', 'phone', 120),
    { ...getSortableCol('질환', 'diseaseTypeName', 140) },
    {
      title: '종류',
      dataIndex: 'type',
      width: 100,
      filters: [
        { text: '유전자 검사', value: '유전자 검사' },
        { text: '병원 검사', value: '병원 검사' },
        { text: '질환 인증', value: '질환 인증' },
      ],
      filteredValue: dashboardDataPagination.filter?.type || null,
      onFilter: (value, { type }) => value === type,
    },
    getSortableCol('병원', 'institution', 120),
    {
      title: '인증 질환',
      dataIndex: ['certifiedDiseaseType', 'krName'],
      width: 100,
      render: renderPlaceholder,
    },
    {
      title: '유전자',
      dataIndex: 'mutationDnas',
      width: 80,
      render: (value) => (
        <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
          {value?.join(', ')}
        </div>
      ),
    },
    {
      ...getSortableCol('등록일', 'createdAt', 120),
      render: (value) => dayjs(value).format('YYYY-MM-DD'),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: '테스트 ID',
      width: 80,
      dataIndex: 'isTest',
      render: (value) => (value ? 'O' : 'X'),
      filters: [
        { text: 'O', value: true },
        { text: 'X', value: false },
      ],
      filteredValue: dashboardDataPagination.filter?.isTest || null,
      onFilter: (value, { isTest }) => value !== !isTest, // handle isTest null as false
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabHP" link="dashboardData" />
        <Layout className="right-layout">
          <TitleBreadcrumb title="데이터 내역" subTitle="데이터 등록" />
          <Content className="site-layout-background contentStyle">
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (dashboardDataPagination.text = value),
                  ),
                );
              }}
              defaultValue={dashboardDataPagination.text}
            />
            <div className="searchResult">검색결과 {total}명</div>

            <Table
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={dashboardUserData}
              pagination={{
                onChange: (page) => {
                  setPageUpdate(page);
                },
                current: pageUpdate,
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(pagination, filters, sorter, extra) => {
                dispatch(
                  paginationCreators.setValue(
                    (dashboardDataPagination.page = pagination?.current),
                    (dashboardDataPagination.filter = filters),
                    (dashboardDataPagination.total =
                      extra?.currentDataSource?.length),
                  ),
                );
                setTotal(extra?.currentDataSource?.length);
              }}
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default UserData;
