import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Layout, Table, Input, Tooltip, Button } from 'antd';
import 'antd/dist/antd.css';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import useWindow from '../../../hooks/useWindow';
import { mutationDnaReportCreators } from '../../../store/reducers/mutationDnaReport.reducer';
import { NEW } from '../../../window/util/utils';

const MutationDnaReport = () => {
  const dispatch = useDispatch();
  const { mutationDnaReports } = useSelector(
    (state) => ({
      mutationDnaReports: state.mutationDnaReportReducer.mutationDnaReport.data,
    }),
    shallowEqual,
  );

  const getData = useCallback(() => {
    dispatch(mutationDnaReportCreators.fetchAllMutationDnaReport.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setDataSource(mutationDnaReports);
    setFilteredData(mutationDnaReports?.length);
  }, [mutationDnaReports]);

  const [dataSource, setDataSource] = useState('');
  const { Search } = Input;
  const { Content } = Layout;
  const [filteredData, setFilteredData] = useState(null);
  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (mutationDnaReport) => {
    if (findWindowById(mutationDnaReport.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({ id: mutationDnaReport.id, dataType: 'mutationDnaReport' });
  };

  const handleCreateNewWindow = () => {
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: 'mutationDnaReport',
      subId: 'appImageRegister',
    });
  };

  const handleSubCreateNewWindow = () => {
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: 'mutationDnaReport',
      subId: 'humanProtocolRegister',
    });
  };

  const onSearch = (e) => {
    if (e === '') {
      setDataSource(mutationDnaReports);
      setFilteredData(mutationDnaReports?.length);
    } else {
      const filteredDatas = dataSource?.filter((entry) =>
        Object?.values(entry).toString().includes(e),
      );
      setDataSource(filteredDatas);
      setFilteredData(filteredDatas.length);
    }
  };

  const columns = [
    {
      title: '계정',
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a?.email?.localeCompare(b?.email),
      },
      render: (text, record) =>
        text ? (
          <a onClick={() => handleCreateEditWindow(record)}>{text}</a>
        ) : (
          '-'
        ),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
      render: (text, record) =>
        text ? (
          <a onClick={() => handleCreateEditWindow(record)}>{text}</a>
        ) : (
          '-'
        ),
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      width: 120,
      sorter: {
        compare: (a, b) => a?.phone?.localeCompare(b?.phone),
      },
      render: (text) => text || '-',
    },
    {
      title: '질환',
      dataIndex: 'userDiseaseName',
      key: 'userDiseaseName',
      width: 120,
    },
    {
      title: '질환인증',
      dataIndex: 'certifiedDiseaseNames',
      key: 'certifiedDiseaseNames',
      render: (mutationDnas) => <>{mutationDnas?.join(', ')}</>,
    },
    {
      title: '등록일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
    },
    {
      title: '검사병원',
      dataIndex: 'institution',
      key: 'institution',
      width: 120,
      sorter: {
        compare: (a, b) => a?.institution?.localeCompare(b?.institution),
      },
    },
    {
      title: '변이 유전자',
      dataIndex: 'mutationDnas',
      key: 'mutationDnas',
      width: 120,
      render: (mutationDnas) => <>{mutationDnas?.join(', ')}</>,
    },
    {
      title: '계정-데이터 연결',
      dataIndex: 'userId',
      key: 'userId',
      width: 140,
      render: (userId) => <>{userId ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      onFilter: (value, record) => !record.userId !== value,
    },
    {
      title: '테스트ID',
      dataIndex: 'isTest',
      key: 'isTest',
      width: 100,
      render: (isTest) => <>{isTest ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      onFilter: (value, record) => record.isTest === value,
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabData" link="mutationDnaReport" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="데이터"
            subTitle="유전자 검사 리포트"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button
              type="primary"
              style={{ width: 120 }}
              onClick={handleCreateNewWindow}
            >
              앱 사진 등록
            </Button>
            &nbsp;&nbsp;
            <Button
              type="primary"
              style={{ width: 150 }}
              onClick={handleSubCreateNewWindow}
            >
              휴먼 프로토콜 등록
            </Button>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={onSearch}
            />
            <div className="searchResult">검색결과 {filteredData}개</div>
            <Table
              columns={columns}
              loading={!dataSource}
              dataSource={dataSource}
              pagination={{
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(_, __, ___, { currentDataSource }) =>
                setFilteredData(currentDataSource.length)
              }
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MutationDnaReport;
