import { all, takeLatest } from 'redux-saga/effects';
import {
  geneResearchCreators,
  geneResearchActions,
} from '../reducers/geneResearch.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  fetchAllGeneResearch,
  fetchAllResearch,
} from '../../services/geneResearchService';

const handleFetchAllGeneResearchs = createAsyncSaga(
  geneResearchCreators.fetchAllGeneResearchs,
  fetchAllGeneResearch,
);

const handleFetchAllResearchs = createAsyncSaga(
  geneResearchCreators.fetchAllResearchs,
  fetchAllResearch,
);

export function* geneResearchSaga() {
  yield all([
    takeLatest(
      geneResearchActions.FETCH_ALL_GENERESEARCHES,
      handleFetchAllGeneResearchs,
    ),
    takeLatest(
      geneResearchActions.FETCH_ALL_RESEARCHES,
      handleFetchAllResearchs,
    ),
  ]);
}
