import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const SingleSwitchInput = ({
  text,
  value,
  onChange,
  name,
  isEssential = false,
}) => {
  return (
    <Form.Group className="formContent" as={Row}>
      <Form.Label column sm="3" md="2" xl="1">
        {text} {isEssential && <span className="required-tag">*필수</span>}
      </Form.Label>
      <Col sm="9" md="10" xl="11">
        <Form.Group controlId={name}>
          <Form.Check
            name={name}
            checked={value}
            onChange={onChange}
            type="switch"
          />
        </Form.Group>
      </Col>
    </Form.Group>
  );
};

export default SingleSwitchInput;
