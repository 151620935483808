import { all, takeLatest } from 'redux-saga/effects';
import {
  treatmentTypeActions,
  treatmentTypeCreators,
} from '../reducers/treatmentType.reducer';
import { createAsyncSaga } from '../reducer.util';
import { fetchAllTreatmentTypes } from '../../services/treatmentTypeService';

const handleFetchAllTreatmentType = createAsyncSaga(
  treatmentTypeCreators.fetchAllTreatmentType,
  fetchAllTreatmentTypes,
);

export function* treatmentTypeSaga() {
  yield all([
    takeLatest(
      treatmentTypeActions.FETCH_ALL_TREATMENT_TYPE,
      handleFetchAllTreatmentType,
    ),
  ]);
}
