import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  treatmentStudyTimeline: getAsyncState.initial(null),
};

// action types
export const treatmentStudyTimelineActions = {
  FETCH_ALL_TREATMENT_STUDY_TIMELINE:
    '@treatmentStudyTimeline/FETCH_ALL_TREATMENT_STUDY_TIMELINE',
};

// actions
export const treatmentStudyTimelineCreators = {
  fetchAllTreatmentStudyTimeline: createAsyncActions(
    treatmentStudyTimelineActions,
    'FETCH_ALL_TREATMENT_STUDY_TIMELINE',
  ),
};

const treatmentStudyTimelineReducer = handleActions(
  {
    ...handleAsyncActions(
      treatmentStudyTimelineActions.FETCH_ALL_TREATMENT_STUDY_TIMELINE,
      'treatmentStudyTimeline',
    ),
  },
  initialState,
);

export default treatmentStudyTimelineReducer;
