import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

const initialState = {
  pointHistories: getAsyncState.initial(null),
  pointConversions: getAsyncState.initial([]),
};

export const hpActions = {
  FETCH_POINT_HISTORY: '@hp/FETCH_POINT_HISTORY',
  FETCH_POINT_CONVERSION: '@hp/FETCH_POINT_CONVERSION',
};

export const hpCreators = {
  fetchPointHistories: createAsyncActions(hpActions, 'FETCH_POINT_HISTORY'),
  fetchPointConversions: createAsyncActions(
    hpActions,
    'FETCH_POINT_CONVERSION',
  ),
};

const hpReducer = handleActions(
  {
    ...handleAsyncActions(hpActions.FETCH_POINT_HISTORY, 'pointHistories'),
    ...handleAsyncActions(hpActions.FETCH_POINT_CONVERSION, 'pointConversions'),
  },
  initialState,
);

export default hpReducer;
