import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 포인트 히스토리를 가져옵니다.
 */
export async function fetchPointHistories() {
  const res = await axios.get('/v3/admin/userPointHistories');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 전환 내역을 가져옵니다.
 */
export async function fetchPointConversions() {
  const res = await axios.get('/v3/admin/userWithdrawalHistories');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 전환 내역을 수정합니다.
 * @param {String} userPointWithdrawalHistoryId
 * @param {object} body
 */
export async function patchHp(userPointWithdrawalHistoryId, body) {
  const res = await axios.patch(
    `/v3/admin/userPointWithdrawalHistories/${userPointWithdrawalHistoryId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
