export const ALERT_MESSAGES = {
  CREATE: '생성되었습니다!',
  UPDATE: '수정되었습니다!',
  DELETE: '삭제되었습니다!',
};

export const CONFIRM_MESSAGES = {
  CREATE: '생성하시겠습니까?',
  UPDATE: '수정하시겠습니까?',
  DELETE: '삭제하시겠습니까?',
};
