import React, { useEffect, useCallback, useReducer } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Layout, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { NEW } from '../../../window/util/utils';
import { ascending, descending } from '../../../util/sort';
import useWindow from '../../../hooks/useWindow';
import RehabilitationStep from './rehabilitationStep';
import RehabilitationList from './rehabilitationList';
import RehabilitationContents from './rehabilitationContents';
import { rehabilitationCreators } from '../../../store/reducers/rehabilitation.reducer';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';

const Rehabilitation = () => {
  const dispatch = useDispatch();
  const {
    diseaseTypesInfo,
    rehabilitation,
    rehabilitationDetail,
  } = useSelector(
    (state) => ({
      diseaseTypesInfo: state.diseaseTypeReducer.diseaseTypes?.data?.find(
        (element) => element.krName === '두셴 근디스트로피',
      ).id,
      rehabilitation: state.rehabilitationReducer?.rehabilitation?.data?.sort(
        ascending('createdAt'),
      ),
      rehabilitationDetail: state.rehabilitationReducer?.rehabilitationDetail?.data?.sort(
        descending('order'),
      ),
    }),
    shallowEqual,
  );

  const initialState = {
    diseaseTypeId: '',
    stepValue: '',
    selectContents: '',
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);

  const getData = useCallback(() => {
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getRehabilitationData = useCallback(() => {
    dispatch(
      rehabilitationCreators.fetchAllRehabilitations.request(diseaseTypesInfo),
    );
  }, [dispatch, diseaseTypesInfo]);

  useEffect(() => {
    getRehabilitationData();
    setState({ diseaseTypeId: diseaseTypesInfo });
  }, [getRehabilitationData, diseaseTypesInfo]);

  const getDetailData = useCallback(
    (stepValue) => {
      dispatch(
        rehabilitationCreators.fetchAllRehabilitationDetails.request({
          diseaseTypeId: state.diseaseTypeId,
          rehabilitationId: stepValue,
        }),
      );
    },
    [dispatch, state],
  );

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getRehabilitationData();
      getDetailData(state.stepValue);
    },
    [getRehabilitationData, getDetailData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (record, type) => {
    if (findWindowById(record.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }

    createWindow({
      id: record.id,
      dataType: type,
      subId: JSON.stringify(record),
    });
  };

  const handleCreateNewWindow = (record) => {
    createWindow({
      id: `${NEW}${Date.now()}`,
      dataType: record,
      subId: JSON.stringify(state),
    });
  };

  const { Content } = Layout;
  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout" style={{ minWidth: 1200 }}>
        <SideBar tab="tabOperation" link="rehabilitation" />
        <Layout className="right-layout">
          <TitleBreadcrumb title="운영" subTitle="재활" className="white-bg" />
          <Content className="site-layout-background userContentStyle">
            <Row span={24}>
              <Col span={6} style={{ paddingRight: 8 }}>
                <Content>
                  <RehabilitationStep
                    data={rehabilitation}
                    state={state}
                    setState={setState}
                    handleCreateNewWindow={handleCreateNewWindow}
                    handleCreateEditWindow={handleCreateEditWindow}
                    getDetailData={getDetailData}
                  />
                </Content>
              </Col>
              <Col span={9} style={{ paddingRight: 8 }}>
                <Content>
                  <RehabilitationList
                    data={rehabilitation}
                    detailData={rehabilitationDetail}
                    state={state}
                    setState={setState}
                    handleCreateNewWindow={handleCreateNewWindow}
                    handleCreateEditWindow={handleCreateEditWindow}
                  />
                </Content>
              </Col>
              <Col span={9}>
                <Content>
                  <RehabilitationContents state={state} />
                </Content>
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Rehabilitation;
