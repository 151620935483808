import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './reducers';
import rootSaga from './saga';

const sagaMiddleWare = createSagaMiddleware();

const enhancedCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default createStore(
  rootReducer,
  enhancedCompose(applyMiddleware(sagaMiddleWare)),
);

sagaMiddleWare.run(rootSaga);
