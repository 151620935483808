import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Input, Button } from 'antd';
import 'antd/dist/antd.css';
import dayjs from 'dayjs';
import AntList from '../../components/antList';
import DeployButton from '../../components/deployButton';
import { NEW } from '../../../window/util/utils';
import { useMutate } from '../../../hooks/useRequest';
import { patchDeployTreatmentNews } from '../../../services/treatmentNewsService';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import useWindow from '../../../hooks/useWindow';

const NEW_MAINTAIN_DAY = 7;

const TreatmentNews = ({ treatmentNewsOld, getData }) => {
  const dispatch = useDispatch();
  const { treatmentNewsOldPagination, topicTags } = useSelector((state) => {
    return {
      treatmentNewsOldPagination:
        state?.paginationReducer?.treatmentNewsPagination,
      topicTags: state.tagReducer?.topicTags?.data,
    };
  }, shallowEqual);

  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const confirmRef = useRef(() => {});

  useEffect(() => {
    if (treatmentNewsOld) {
      if (treatmentNewsOldPagination.total === null)
        setTotal(treatmentNewsOld?.length);
      else setTotal(treatmentNewsOldPagination.total);
      setPageUpdate(treatmentNewsOldPagination.page);
      confirmRef.current(treatmentNewsOldPagination.text);
    }
  }, [treatmentNewsOld]);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();
  const { Search } = Input;
  const [deployChangeInfo, setDeployChangeInfo] = useState([]);

  const {
    done: patchDeployDone,
    mutate: patchDeployMutate,
    initialize: patchDeployInitialize,
  } = useMutate(patchDeployTreatmentNews, {
    ids: deployChangeInfo.map((deployInfo) => deployInfo.id),
    changes: deployChangeInfo.map((deployInfo) => {
      return {
        isApp:
          deployInfo.isApp === undefined
            ? treatmentNewsOld.find(({ id }) => id === deployInfo.id).isApp
            : deployInfo.isApp,
        newExpiresAt:
          deployInfo.isNew === undefined
            ? treatmentNewsOld.find(({ id }) => id === deployInfo.id)
                .newExpiresAt
            : deployInfo.isNew
            ? dayjs()
                .add(NEW_MAINTAIN_DAY + 1, 'day')
                .format('YYYY-MM-DD')
            : null,
      };
    }),
  });

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (treatmentNewsInfo) => {
    if (findWindowById(treatmentNewsInfo.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({ id: treatmentNewsInfo.id, dataType: 'treatmentNews' });
  };

  const handleCreateNewWindow = () => {
    createWindow({ id: `${NEW}${Date.now()}`, dataType: 'treatmentNews' });
  };

  const deploy = () => {
    if (window.confirm('앱에 변경된 사항을 반영하여 배포하시겠습니까?')) {
      patchDeployInitialize();
      patchDeploy();
    }
  };

  const patchDeploy = () => {
    patchDeployMutate();
    setDeployChangeInfo([]);
  };

  const cancelDeploy = () => {
    setDeployChangeInfo([]);
  };

  const isDeployReady = deployChangeInfo?.length > 0;

  const onClickDeploy = () => {
    if (!isDeployReady) {
      alert('배포할 변경사항이 없습니다.');
      return;
    }
    deploy();
  };

  const onClickCancelDeploy = () => {
    if (!isDeployReady) {
      alert('변경사항이 없습니다.');
      return;
    }
    cancelDeploy();
  };

  useEffect(() => {
    if (patchDeployDone) {
      getData();
    }
  }, [patchDeployDone]);

  const onChange = (pagination, filters, sorter, extra) => {
    dispatch(
      paginationCreators.setValue(
        (treatmentNewsOldPagination.page = pagination?.current),
        (treatmentNewsOldPagination.filter = filters),
        (treatmentNewsOldPagination.total = extra?.currentDataSource?.length),
      ),
    );
    setTotal(extra?.currentDataSource?.length);
  };

  const tagWrapper = (tags) => {
    const tag = tags?.split(',');

    return tag?.map((diseaseType) => (
      <div className="tagWrapper" key={diseaseType}>
        {diseaseType}
      </div>
    ));
  };

  const topicTag =
    topicTags?.map((t) => ({ text: t.name, value: t.name })) ?? [];

  const columns = [
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '120px',
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: treatmentNewsOldPagination.filter?.title || null,
      onFilter: (search, { title, createdAt, diseaseTypes, topics }) =>
        [title, createdAt, diseaseTypes, topics].some((value) =>
          value?.includes(search),
        ),
      render: (text, record) =>
        text ? (
          <a onClick={() => handleCreateEditWindow(record)}>{text}</a>
        ) : (
          '-'
        ),
    },
    {
      title: '앱 노출',
      dataIndex: 'isApp',
      key: 'isApp',
      width: '100px',
      filters: [
        {
          text: 'On',
          value: true,
        },
        {
          text: 'Off',
          value: false,
        },
      ],
      filteredValue: treatmentNewsOldPagination.filter?.isApp || null,
      onFilter: (value, record) => record.isApp === value,
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypes',
      key: 'diseaseTypes',
      width: '250px',
      render: (text) => (text ? tagWrapper(text) : '-'),
    },
    {
      title: '주제별 태그',
      dataIndex: 'topics',
      key: 'topics',
      sorter: {
        compare: (a, b) => a?.topics?.localeCompare(b?.topics),
      },
      filters: topicTag,
      filteredValue: treatmentNewsOldPagination.filter?.topics || null,
      onFilter: (value, record) => record.topics?.includes(value),
      render: (text) => (text ? tagWrapper(text) : '-'),
    },
    {
      title: '조회수',
      dataIndex: 'readCount',
      key: 'readCount',
      width: '90px',
      sorter: (a, b) => a.readCount - b.readCount,
    },
    {
      title: '공유하기',
      dataIndex: 'shareCount',
      key: 'shareCount',
      width: '90px',
      sorter: (a, b) => a.shareCount - b.shareCount,
    },
    {
      title: '북마크',
      dataIndex: 'bookmarkCount',
      key: 'bookmarkCount',
      width: '90px',
      sorter: (a, b) => a.bookmarkCount - b.bookmarkCount,
    },
    {
      title: '유익해요',
      dataIndex: 'isHelpfulCount',
      key: 'isHelpfulCount',
      width: '90px',
      sorter: (a, b) => a.isHelpfulCount - b.isHelpfulCount,
    },
  ];

  return (
    <>
      <DeployButton
        onClickDeploy={onClickDeploy}
        onClickCancelDeploy={onClickCancelDeploy}
        disabled={!isDeployReady}
      />
      <div className="divider" />
      <Button
        type="primary"
        style={{ width: 100 }}
        onClick={handleCreateNewWindow}
      >
        추가
      </Button>
      <Search
        placeholder="검색어를 입력해주세요."
        allowClear
        className="searchStyle"
        onSearch={(value) => {
          confirmRef.current(value);
          dispatch(
            paginationCreators.setValue(
              (treatmentNewsOldPagination.text = value),
            ),
          );
        }}
        defaultValue={treatmentNewsOldPagination.text}
      />
      <div className="searchResult">
        {treatmentNewsOld && `검색결과 ${total}개`}
      </div>

      <AntList
        deployChangeInfo={deployChangeInfo}
        setDeployChangeInfo={setDeployChangeInfo}
        pageUpdate={pageUpdate}
        setPageUpdate={setPageUpdate}
        columns={columns}
        dataSource={treatmentNewsOld}
        onChange={onChange}
      />
    </>
  );
};

export default TreatmentNews;
