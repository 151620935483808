import { all, takeLatest } from 'redux-saga/effects';
import {
  patientGroupCreators,
  patientGroupActions,
} from '../reducers/patientGroup.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  fetchAllPatientGroup,
  fetchAllPatientGroupUser,
} from '../../services/patientGroupService';

const handleFetchAllPatientGroups = createAsyncSaga(
  patientGroupCreators.fetchAllPatientGroups,
  fetchAllPatientGroup,
);

const handleFetchAllPatientGroupUser = createAsyncSaga(
  patientGroupCreators.fetchAllPatientGroupUsers,
  fetchAllPatientGroupUser,
);

export function* patientGroupSaga() {
  yield all([
    takeLatest(
      patientGroupActions.FETCH_ALL_PATIENT_GROUPS,
      handleFetchAllPatientGroups,
    ),
    takeLatest(
      patientGroupActions.FETCH_ALL_PATIENT_GROUP_USERS,
      handleFetchAllPatientGroupUser,
    ),
  ]);
}
