import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import * as Sentry from '@sentry/react';
import App from './App';

import store from './store';

import { setInterceptors } from './util/axiosUtils';

import './index.css';
import './public/css/bootstrapcustom.css';

// setBaseURL의 주석 참조
// setBaseURL(BaseURL);
setInterceptors();

const sentryDSN = process.env.REACT_APP_SENTRY_DSN;

if (process.env.REACT_APP_DEPLOY !== 'local') {
  Sentry.init({
    dsn: sentryDSN,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
