import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 치료 기술을 가져옵니다.
 */
export async function fetchAllTreatmentTypes() {
  const res = await axios.get('/v3/admin/treatmentType');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
