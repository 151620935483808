import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 어드민 역할을 가져옵니다.
 */
export async function fetchAllRoles() {
  const res = await axios.get('/v3/admin/roles');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 어드민 역할을 추가합니다.
 * @param {object} body
 */
export async function postRole(body) {
  const res = await axios.post(`/v3/admin/roles`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 어드민 역할을 수정합니다.
 * @param {String} roleId
 * @param {object} body
 */
export async function patchRole(roleId, body) {
  const res = await axios.patch(`/v3/admin/roles/${roleId}`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 어드민 역할을 삭제합니다.
 * @param {String} roleId
 */
export async function deleteRole(roleId) {
  const res = await axios.delete(`/v3/admin/roles/${roleId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
