import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const TextInput = ({
  text,
  as,
  rows = 1,
  value,
  onChange,
  readOnly = false,
  isEssential = false,
  subContent = false,
  subText = null,
  name,
  placeholder = '',
}) => {
  if (subContent)
    return (
      <Form.Group className="formContent" as={Row}>
        <Form.Label column sm="3" md="2" xl="1">
          {subText ? text : ''}
          {isEssential && <span className="required-tag">*필수</span>}
        </Form.Label>
        <Form.Label column sm="3" md="2" xl="2">
          {subText ?? text}
        </Form.Label>
        <Col sm="7" md="8" xl="9">
          <Form.Group>
            <Form.Control
              name={name}
              as={as}
              value={value}
              onChange={onChange}
              rows={rows}
            />
          </Form.Group>
        </Col>
      </Form.Group>
    );
  return (
    <Form.Group className="formContent" as={Row}>
      <Form.Label column sm="3" md="2" xl="1">
        {text} {isEssential && <span className="required-tag">*필수</span>}
      </Form.Label>
      <Col sm="9" md="10" xl="11">
        <Form.Group>
          <Form.Control
            name={name}
            as={as}
            value={value}
            onChange={onChange}
            readOnly={readOnly}
            rows={rows}
            placeholder={placeholder}
          />
        </Form.Group>
      </Col>
    </Form.Group>
  );
};

export default TextInput;
