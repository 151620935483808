import React, { useState } from 'react';
import { Layout, Tabs } from 'antd';
import 'antd/dist/antd.css';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import ClinicalTrialInfo from './clinicalTrialInfo';
import ClinicalTrialUpdateHistory from './clinicalTrialUpdateHistory';

const ClinicalTrial = () => {
  const [key, setKey] = useState('history');
  const { TabPane } = Tabs;
  const { Content } = Layout;

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="clinicalTrial" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="콘텐츠"
            subTitle="임상시험"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Tabs defaultActiveKey={key} onChange={setKey}>
              <TabPane tab="임상시험 업데이트" key="history">
                <ClinicalTrialUpdateHistory />
              </TabPane>
              <TabPane tab="임상시험" key="content">
                <ClinicalTrialInfo />
              </TabPane>
            </Tabs>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ClinicalTrial;
