import React, { useState, useRef, useEffect } from 'react';
import { Input } from 'antd';

const getComponent = (componentType) => {
  switch (componentType) {
    case 'textarea':
      return {
        Component: Input.TextArea,
        getValue: (ref) => ref.current.resizableTextArea.props,
      };
    default:
      return { Component: Input, getValue: (ref) => ref.current.state };
  }
};

export default ({ text, id, onSave, componentType, inputProps }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  useEffect(() => {
    if (editing) inputRef.current.focus();
  }, [editing]);

  const toggleEdit = () => setEditing((prev) => !prev);
  const { Component, getValue } = getComponent(componentType);

  return editing ? (
    <Component
      className="editableCellValueInput"
      ref={inputRef}
      defaultValue={text}
      {...inputProps}
      onPressEnter={() => inputRef.current.blur()}
      onBlur={async () => {
        const { value } = getValue(inputRef);
        if (
          text === value ||
          (await Promise.resolve({ id, value }).then(onSave))
        )
          toggleEdit(); // avoid warning handler took N ms
      }}
    />
  ) : (
    <div className="editableCellValueWrap" onClick={toggleEdit}>
      {text}
    </div>
  );
};
