import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 이벤트를 가져옵니다.
 */
export async function fetchAllEvent() {
  const res = await axios.get(`/v3/admin/events`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 이벤트 정보를 가져옵니다.
 * @param {String} eventId
 */
export async function fetchEvent(eventId) {
  const res = await axios.get(`/v3/admin/events/${eventId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 이벤트를 생성합니다.
 * @param {object} body
 */
export async function postEvent(body) {
  const res = await axios.post(`/v3/admin/events`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 이벤트를 수정합니다.
 * @param {String} eventId
 * @param {object} body
 */
export async function patchEvent(eventId, body) {
  const res = await axios.put(`/v3/admin/events/${eventId}`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 이벤트의 배포 정보(isApp)를 수정합니다.
 * @param {array} ids
 * @param {array} changes
 */
export async function patchDistributeEvent({ ids, changes }) {
  const res = await axios.patch(`/v3/admin/events`, {
    ids,
    changes,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 이벤트를 삭제합니다.
 * @param {String} eventId
 * @param {object} body
 */
export async function deleteEvent(eventId, body) {
  const res = await axios.delete(`/v3/admin/events/${eventId}`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 이벤트 참여자 정보를 가져옵니다.
 * @param {String} eventId
 */
export async function fetchEventParticipants(eventId) {
  const res = await axios.get(`/v3/admin/events/${eventId}/participants`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
