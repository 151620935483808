import React, { useMemo, useCallback } from 'react';
import { Form, Row, Col } from 'react-bootstrap';

const CheckBoxSelect = ({
  text,
  type = 'checkbox',
  checkBoxList,
  setCheckBoxList,
  isEssential,
  name,
}) => {
  const selectedCount = useMemo(
    () =>
      checkBoxList?.reduce(
        (count, { selected }) => count + Number(selected),
        0,
      ),
    [checkBoxList],
  );

  const allSelected = selectedCount === checkBoxList?.length;

  const isValid = Boolean(selectedCount);

  const onToggleAllSelect = useCallback(
    (e) => {
      const value = checkBoxList.map((checkBox) => ({
        ...checkBox,
        selected: !allSelected,
      }));
      setCheckBoxList({ target: { name: e.target.name, value } });
    },
    [allSelected, checkBoxList, setCheckBoxList],
  );

  const onToggleSelect = useCallback(
    ({ target }) => {
      const value = checkBoxList.map(
        type === 'radio'
          ? (item) =>
              (target.id === item.id) === item.selected
                ? item
                : { ...item, selected: !item.selected }
          : (item) =>
              target.id === item.id
                ? { ...item, selected: !item.selected }
                : item,
      );
      setCheckBoxList({ target: { name: target.name, value } });
    },
    [checkBoxList, setCheckBoxList, type],
  );

  return (
    <Form.Group className="formContent" as={Row}>
      <Form.Label column sm="3" md="2" xl="1">
        {text}
        {isEssential && <span className="required-tag">*필수</span>}
      </Form.Label>
      <Col sm="9" md="10" xl="11">
        <Form.Group className="formContent checkBoxContainer" as={Row}>
          {type !== 'radio' && checkBoxList?.length > 1 && (
            <Form.Check
              name={name}
              type={type}
              id="selectAll"
              onChange={onToggleAllSelect}
              checked={allSelected}
              label="전체"
              isInvalid={!isValid && isEssential}
              isValid={isValid && isEssential}
            />
          )}
          &nbsp;&nbsp;
          {checkBoxList?.map((checkBoxItem) => (
            <React.Fragment key={checkBoxItem.id}>
              <Form.Check
                name={name}
                type={type}
                id={checkBoxItem?.id}
                onChange={onToggleSelect}
                checked={checkBoxItem.selected}
                label={checkBoxItem.krName ?? checkBoxItem.name}
                isInvalid={!isValid && isEssential}
                isValid={isValid && isEssential}
              />
              &nbsp;&nbsp;
            </React.Fragment>
          ))}
        </Form.Group>
      </Col>
    </Form.Group>
  );
};

export default CheckBoxSelect;
