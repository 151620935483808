import { all, takeLatest } from 'redux-saga/effects';
import {
  hospitalExaminationCreators,
  hospitalExaminationActions,
} from '../reducers/hospitalExamination.reducer';

import { createAsyncSaga } from '../reducer.util';
import {
  fetchAllHospitalExamination,
  fetchAllUserHospitalExamination,
  fetchAllUserDiseaseTypeCertification,
} from '../../services/hospitalExaminationService';

const handleFetchAllHospitalExaminations = createAsyncSaga(
  hospitalExaminationCreators.fetchAllHospitalExaminations,
  fetchAllHospitalExamination,
);

const handleFetchAllUserHospitalExaminations = createAsyncSaga(
  hospitalExaminationCreators.fetchAllUserHospitalExaminations,
  fetchAllUserHospitalExamination,
);

const handleFetchAllUserDiseaseTypeCertifications = createAsyncSaga(
  hospitalExaminationCreators.fetchAllUserDiseaseTypeCertifications,
  fetchAllUserDiseaseTypeCertification,
);

export function* hospitalExaminationSaga() {
  yield all([
    takeLatest(
      hospitalExaminationActions.FETCH_ALL_HOSPITAL_EXAMINATIONS,
      handleFetchAllHospitalExaminations,
    ),
    takeLatest(
      hospitalExaminationActions.FETCH_ALL_USER_HOSPITAL_EXAMINATIONS,
      handleFetchAllUserHospitalExaminations,
    ),
    takeLatest(
      hospitalExaminationActions.FETCH_ALL_USER_DISEASE_TYPE_CERTIFICATIONS,
      handleFetchAllUserDiseaseTypeCertifications,
    ),
  ]);
}
