import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout, Table, Input, notification, Button } from 'antd';
import 'antd/dist/antd.css';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import { fetchUnmaskedInfo as fetchUnmaskedInfoService } from '../../../services/userService';
import { fetchAllMutationDnaReportRegisterRequest } from '../../../services/mutationDnaReportRegisterRequestService';
import { useFetch } from '../../../hooks/useRequest';
import { usePagination } from '../../../hooks/usePagination';
import useWindow from '../../../hooks/useWindow';
import * as listMap from '../../../util/listMap';
import { MutationDnaReportRow } from '.';

const MutationDnaReportRegisterRequest = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    apiParams: [{ type }],
    setApiParams,
    initialize,
    data,
    loading,
    pageState,
  } = usePagination({
    call: fetchAllMutationDnaReportRegisterRequest,
    initialApiParams: [{ type: '유전자 검사' }],
    initialPageState: {
      current: 1,
      pageSize: 10000,
      showSizeChanger: true,
      total: 0,
      pageSizeOptions: [10, 20, 50, 100, 1000, 10000, 100000],
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(initialize, []);

  const { mutationDnaResearchPagination } = useSelector((state) => {
    return {
      mutationDnaResearchPagination:
        state?.paginationReducer?.mutationDnaResearchPagination,
    };
  });

  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const confirmRef = useRef(() => {});

  useEffect(() => {
    if (data) {
      if (mutationDnaResearchPagination.total === null) setTotal(data?.length);
      else setTotal(mutationDnaResearchPagination.total);
      setPageUpdate(mutationDnaResearchPagination.page);
      confirmRef.current(mutationDnaResearchPagination.text);
    }
  }, [data]);

  const { destroyWindowById } = useWindow();

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      setApiParams([{ type: '유전자 검사' }], pageState);
    },
    [setApiParams, pageState, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const { Search } = Input;
  const { Content } = Layout;
  const [unmaskedType, setUnmaskedType] = useState('');
  const [unmaskedId, setUnmaskedId] = useState('');
  const [getMasked, setGetMasked] = useState('');

  const {
    done: fetchUnmaskedInfoDone,
    data: fetchUnmaskedInfoData,
    call: fetchUnmaskedInfo,
    initialize: initializeDone,
  } = useFetch(null, fetchUnmaskedInfoService, {
    userId: unmaskedId,
    type: unmaskedType,
  });

  const onChangeMasked = (record, type) => {
    if (record === '-') return;
    setUnmaskedType(type);
    setUnmaskedId(record.userId);
    setGetMasked(true);
  };

  useEffect(() => {
    if (getMasked) {
      fetchUnmaskedInfo();
      setGetMasked(false);
    }
  }, [getMasked]);

  useEffect(() => {
    if (fetchUnmaskedInfoDone) {
      initializeDone();
      notification.success({
        message: '마스킹을 해제하였습니다.',
        description: '마스킹을 해제하였습니다.',
      });
    }
  }, [fetchUnmaskedInfoDone]);

  const MaskedData = useCallback(
    ({ record, type }) => {
      return (
        <>
          <a onClick={() => onChangeMasked(record, type)}>
            {record?.userId === unmaskedId && type === unmaskedType
              ? fetchUnmaskedInfoData?.[type] ?? '-'
              : record?.[type] ?? '-'}
          </a>
        </>
      );
    },
    [fetchUnmaskedInfoDone],
  );

  const handleMoveDetailPage = (record) => {
    history.push('/mutationDnaReportRegisterRequestDetail', {
      userId: record?.userId,
      type: 'mutationDnaReportRegisterRequest',
    });
  };

  const columns = [
    {
      title: '계정',
      dataIndex: 'email',
      width: 180,
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: mutationDnaResearchPagination.filter?.email || null,
      onFilter: (
        search,
        { email, name, phone, relationship, createdAt, status, diseaseType },
      ) =>
        [
          email,
          name,
          phone,
          relationship,
          createdAt,
          status,
          diseaseType?.krName,
        ].some((value) => value?.includes(search)),
      render: (text, record) => (
        <>
          <MaskedData record={record} type="email" />
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => handleMoveDetailPage(record)}
          >
            이동
          </Button>
        </>
      ),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      width: 65,
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
      render: (text, record) => <MaskedData record={record} type="name" />,
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      width: 110,
      sorter: {
        compare: (a, b) => a?.phone?.localeCompare(b?.phone),
      },
      render: (text, record) => <MaskedData record={record} type="phone" />,
    },
    {
      title: '질환',
      dataIndex: 'diseaseType',
      key: 'diseaseType',
      width: 110,
      sorter: {
        compare: (a, b) =>
          a?.diseaseType?.krName?.localeCompare(b?.diseaseType?.krName),
      },
      render: (diseaseType) => <>{diseaseType?.krName}</>,
    },
    {
      title: '계정 유형',
      dataIndex: 'relationship',
      key: 'relationship',
      width: 105,
      sorter: {
        compare: (a, b) => a?.relationship?.localeCompare(b?.relationship),
      },
      filters: listMap.relationshipFilter,
      filteredValue: mutationDnaResearchPagination.filter?.relationship || null,
      onFilter: (value, record) => record.relationship.includes(value),
    },
    {
      title: '등록일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 80,
      sorter: {
        compare: (a, b) => a?.createdAt?.localeCompare(b?.createdAt),
      },
    },
    {
      title: '등록 상태',
      dataIndex: 'status',
      key: 'status',
      width: 105,
      sorter: {
        compare: (a, b) => a?.status?.localeCompare(b?.status),
      },
      filters: [
        {
          text: '확인 중',
          value: '확인 중',
        },
        {
          text: '등록 완료',
          value: '등록 완료',
        },
        {
          text: '등록 실패',
          value: '등록 실패',
        },
      ],
      filteredValue: mutationDnaResearchPagination.filter?.status || null,
      onFilter: (value, record) => record.status.includes(value),
    },
    {
      title: '테스트ID',
      dataIndex: 'isTest',
      key: 'isTest',
      width: 90,
      render: (isTest) => <>{isTest ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      filteredValue: mutationDnaResearchPagination.filter?.isTest || null,
      onFilter: (value, record) => record.isTest === value,
    },
  ];

  const expandedRowRender = (record) => {
    return <MutationDnaReportRow data={record} />;
  };

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabData" link="mutationDnaReportRegisterRequest" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="데이터"
            subTitle="등록_유전자 검사"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (mutationDnaResearchPagination.text = value),
                  ),
                );
              }}
              defaultValue={mutationDnaResearchPagination.text}
            />

            <div className="searchResult">{data && `검색결과 ${total}개`}</div>

            <Table
              rowKey={(record) => record.id}
              columns={columns}
              dataSource={data}
              loading={loading}
              expandable={{ expandedRowRender }}
              pagination={{
                onChange: (page) => {
                  setPageUpdate(page);
                },
                current: pageUpdate,
                defaultPageSize: 20,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(pagination, filters, sorter, extra) => {
                dispatch(
                  paginationCreators.setValue(
                    (mutationDnaResearchPagination.page = pagination?.current),
                    (mutationDnaResearchPagination.filter = filters),
                    (mutationDnaResearchPagination.total =
                      extra?.currentDataSource?.length),
                  ),
                );
                setTotal(extra?.currentDataSource?.length);
              }}
              size="small"
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MutationDnaReportRegisterRequest;
