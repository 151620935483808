import React from 'react';
import { makeLink } from './diseaseType';

const DiseaseTypeCell = ({ diseaseTypes, toClinicalTrialPage }) => {
  const components = [];
  diseaseTypes?.forEach((diseaseType, index) => {
    if (index) components.push(', ');
    components.push(
      makeLink(toClinicalTrialPage)(diseaseType.krName, diseaseType),
    );
  });
  return <>{components}</>;
};

export default DiseaseTypeCell;
