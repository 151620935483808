import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

const DatePickerInput = ({
  text,
  value,
  setDate,
  readOnly = false,
  isEssential = false,
  name,
}) => {
  const changeDate = (date) => {
    setDate({ target: { name, value: date } });
  };
  return (
    <Form.Group className="formContent" as={Row}>
      <Form.Label column sm="3" md="2" xl="1">
        {text}
        {isEssential && <span className="required-tag">*필수</span>}
      </Form.Label>
      <Col sm="9" md="10" xl="11">
        <Form.Group>
          <DatePicker
            name={name}
            className="form-control react-datepicker-popper"
            placeholderText="YYYY/MM/DD"
            selected={value}
            onChange={changeDate}
            dateFormat="yyyy/MM/dd"
            readOnly={readOnly}
          />
        </Form.Group>
      </Col>
    </Form.Group>
  );
};

export default DatePickerInput;
