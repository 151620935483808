// set file input event (image upload)
import { useState, useEffect, useCallback } from 'react';
import qs from 'query-string';
import {
  getSignedUrl,
  getMultiSignedUrl,
  deleteImageRequest,
  deleteImagesRequest,
} from '../../services/fileUploadService';
import { useFetch } from '../../hooks/useRequest';

export function useDistinctFileUpload(fileList) {
  const [done, setDone] = useState(false);
  const [privateUrl, setPrivateUrl] = useState(null);
  const [filePath, setFilePath] = useState(null);
  const [changeDone, setChangeDone] = useState(null);
  const fileNameList = fileList?.map((file) => file?.name);
  const {
    data: signedUrlData,
    call: fetchSignedUrl,
    initializeData: initializeSignedUrl,
  } = useFetch(null, getMultiSignedUrl, {
    functionType: 'PUT',
    fileNameList,
    contentType: 'image%2Fjpeg',
  });

  const onUploadFinish = (newSignedUrlUploadData) => {
    setFilePath(newSignedUrlUploadData?.filePath);
  };

  useEffect(() => {
    if (filePath) {
      setFilePath(null);
      setChangeDone(true);
    }
  }, [filePath]);

  useEffect(() => {
    if (changeDone) {
      setDone(true);
      initializeSignedUrl();
    }
  }, [changeDone, initializeSignedUrl]);

  const onUploadError = () => {
    alert('이미지 업로드에 실패했습니다!');
  };

  const imageUpload = () => {
    fetchSignedUrl();
  };
  useEffect(() => {
    if (signedUrlData) {
      for (let i = 0; i < signedUrlData.length; i += 1) {
        const data = signedUrlData[i];
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', data.signedUrl);
        xhr.onload = () => {
          onUploadFinish(data);
        };
        xhr.onerror = onUploadError;
        xhr.setRequestHeader('Content-Type', fileList[i].type);
        xhr.send(fileList[i].originFileObj);
      }
      setPrivateUrl(signedUrlData);
    }
  }, [signedUrlData, fileList]);

  const initialize = () => {
    setPrivateUrl(null);
    setDone(false);
  };
  return { privateUrl, done, imageUpload, initialize };
}

export function useDistinctFileDelete(distinctImageToDelete) {
  const [done, setDone] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileNameList, setFileNameList] = useState(null);
  const [deleteDone, setDeleteDone] = useState(false);

  const isArrayData = typeof distinctImageToDelete === 'object';

  const isSingleImageDelete =
    !isArrayData || distinctImageToDelete?.length === 1;

  const {
    data: signedUrlData,
    call: fetchSignedUrl,
    initialize: initializeSignedUrlDone,
  } = useFetch(null, getSignedUrl, {
    params: {
      functionType: 'DELETE',
      fileName,
      contentType: '',
    },
  });

  const {
    data: multiSignedUrlData,
    call: fetchMultiSignedUrl,
    initialize: initializeMultiSignedUrlDone,
  } = useFetch(null, getMultiSignedUrl, {
    functionType: 'DELETE',
    fileNameList,
    contentType: '',
  });

  useEffect(() => {
    if (fileName) {
      fetchSignedUrl();
      setFileName(null);
    }
  }, [fileName, fetchSignedUrl]);

  const deleteImage = useCallback(async () => {
    await deleteImageRequest(signedUrlData);
    setDone(true);
    initializeSignedUrlDone();
  }, [signedUrlData, initializeSignedUrlDone]);

  useEffect(() => {
    if (signedUrlData && !deleteDone) {
      setDeleteDone(true);
      deleteImage();
    }
  }, [signedUrlData, deleteImage, deleteDone]);

  useEffect(() => {
    if (fileNameList) {
      fetchMultiSignedUrl();
      setFileNameList(null);
    }
  }, [fileNameList, fetchMultiSignedUrl]);

  const deleteImages = useCallback(async () => {
    await deleteImagesRequest(multiSignedUrlData);
    setDone(true);
    initializeMultiSignedUrlDone();
  }, [multiSignedUrlData, initializeMultiSignedUrlDone]);

  useEffect(() => {
    if (multiSignedUrlData && !deleteDone) {
      setDeleteDone(true);
      deleteImages();
    }
  }, [multiSignedUrlData, deleteImages, deleteDone]);

  const imageDelete = async () => {
    const newFileName = isArrayData
      ? distinctImageToDelete[0]
      : distinctImageToDelete;
    if (isSingleImageDelete) {
      setFileName(newFileName);
    } else {
      setFileNameList(distinctImageToDelete);
    }
  };

  const initialize = () => {
    setDone(false);
  };
  return { done, imageDelete, initialize };
}
