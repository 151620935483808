import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 소식을 가져옵니다.
 * @param {String} params
 */
export async function fetchAllTreatmentNews({ params }) {
  const res = await axios.get(`/v1/admin/contents/original?${params}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 소식 배포 여부를 변경합니다.
 * @param {object} body
 */
export async function patchDeployTreatmentNews(body) {
  const res = await axios.patch('/v1/admin/contents/original', body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 소식을 가져옵니다.
 * @param {String} treatmentNewsId
 */
export async function fetchTreatmentNews(treatmentNewsId) {
  const res = await axios.get(`/v3/admin/treatmentNews/${treatmentNewsId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 소식을 생성합니다.
 * @param {object} body
 */
export async function postTreatmentNews(body) {
  const res = await axios.post(`/v1/admin/contents/original`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 소식을 수정합니다.
 * @param {String} treatmentNewsId
 * @param {object} body
 */
export async function patchTreatmentNews(treatmentNewsId, body) {
  const res = await axios.patch(
    `/v1/admin/contents/original/${treatmentNewsId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 소식을 삭제합니다.
 * @param {String} treatmentNewsId
 */
export async function deleteTreatmentNews(treatmentNewsId) {
  const res = await axios.delete(
    `/v1/admin/contents/original/${treatmentNewsId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
