import { handleActions } from 'redux-actions';
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  research: getAsyncState.initial(null),
  geneResearch: getAsyncState.initial(null),
};

// action types
export const geneResearchActions = {
  FETCH_ALL_GENERESEARCHES: '@geneResearch/FETCH_ALL_GENERESEARCHES',
  FETCH_ALL_RESEARCHES: '@geneResearch/FETCH_ALL_RESEARCHES',
};

// actions
export const geneResearchCreators = {
  fetchAllGeneResearchs: createAsyncActions(
    geneResearchActions,
    'FETCH_ALL_GENERESEARCHES',
  ),
  fetchAllResearchs: createAsyncActions(
    geneResearchActions,
    'FETCH_ALL_RESEARCHES',
  ),
};

const geneResearchReducer = handleActions(
  {
    ...handleAsyncActions(geneResearchActions.FETCH_ALL_RESEARCHES, 'research'),
    ...handleAsyncActions(
      geneResearchActions.FETCH_ALL_GENERESEARCHES,
      'geneResearch',
    ),
  },
  initialState,
);

export default geneResearchReducer;
