import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  treatmentType: getAsyncState.initial(null),
};

// action types
export const treatmentTypeActions = {
  FETCH_ALL_TREATMENT_TYPE: '@treatmentType/FETCH_ALL_TREATMENT_TYPE',
};

// actions
export const treatmentTypeCreators = {
  fetchAllTreatmentType: createAsyncActions(
    treatmentTypeActions,
    'FETCH_ALL_TREATMENT_TYPE',
  ),
};

const treatmentTypeReducer = handleActions(
  {
    ...handleAsyncActions(
      treatmentTypeActions.FETCH_ALL_TREATMENT_TYPE,
      'treatmentType',
    ),
  },
  initialState,
);

export default treatmentTypeReducer;
