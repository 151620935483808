import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * localStorage에 유효한 토큰이 남아 있는 경우 자동으로 로그인하여 이메일을 가져옵니다.
 */
export async function autoLogin() {
  const res = await axios.get(`/v3/users`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS)
    return {
      accessToken: null,
      refreshToken: null,
      expiresAt: undefined,
      email: null,
    }; // 로그인 풀기
  return { email: res.data.data.email };
}

/**
 * 어드민 로그인을 합니다.
 * @param {String} email
 * @param {String} password
 * @param {uuid} clientId
 * @param {uuid} clientSecret
 */
export async function accessLogin({ email, password, clientId, clientSecret }) {
  const res = await axios.post(`/v3/humanscape/admin/login`, {
    email,
    password,
    clientId,
    clientSecret,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  res.data.data.email = email;
  return res.data.data;
}

/**
 * 어드민 로그인을 합니다.
 * @param {String} refreshToken
 * @param {uuid} clientId
 * @param {uuid} clientSecret
 */
export async function renewSession({ refreshToken, clientId, clientSecret }) {
  const res = await axios.post(`/v3/oauth2/token`, {
    grantType: 'refreshToken',
    refreshToken,
    clientId,
    clientSecret,
  });
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
