import { all, takeLatest } from 'redux-saga/effects';
import { rssFeedCreators, rssFeedActions } from '../reducers/rssFeed.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchAllRssFeed } from '../../services/rssFeedService';

const handleFetchAllRssFeeds = createAsyncSaga(
  rssFeedCreators.fetchAllRssFeeds,
  fetchAllRssFeed,
);

export function* rssFeedSaga() {
  yield all([
    takeLatest(rssFeedActions.FETCH_ALL_RSS_FEEDS, handleFetchAllRssFeeds),
  ]);
}
