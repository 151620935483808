import getBaseURL from '../api/BaseURL';

export default function errMessage(error) {
  switch (error) {
    case 'email or password is incorrect': // TODO: 하드코딩 제거...
      return '이메일 또는 비밀번호가 틀립니다.';
    case 'change password to log in':
      if (
        window.confirm(
          '로그인에 5회 실패하여 비밀번호 변경이 필요합니다. 비밀번호 변경 페이지로 이동하시겠습니까?',
        )
      )
        window.location.href = `${getBaseURL}/v2/views/password/find`;
      return;

    case 'Unauthorized':
      return '어드민 사용 권한이 없습니다.';
    default:
      return '알 수 없는 이유로 로그인에 실패했습니다.';
  }
}
