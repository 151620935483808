import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 병원 검사를 가져옵니다.
 */
export async function fetchAllHospitalExamination() {
  const res = await axios.get(`/v3/admin/hospitalExaminations`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 병원 검사 정보를 가져옵니다.
 * @param {String} hospitalId
 */
export async function fetchHospitalExamination(hospitalId) {
  const res = await axios.get(`/v3/admin/hospitalExaminations/${hospitalId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 병원 검사를 생성합니다.
 * @param {String} hospitalId
 * @param {object} body
 */
export async function postHospitalExamination(body) {
  const res = await axios.post(`/v3/admin/hospitalExaminations`, body);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 병원 검사를 수정합니다.
 * @param {String} hospitalId
 * @param {object} body
 */
export async function patchHospitalExamination(hospitalId, body) {
  const res = await axios.patch(
    `/v3/admin/hospitalExaminations/${hospitalId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 병원 검사를 삭제합니다.
 * @param {String} hospitalId
 * @param {object} body
 */
export async function deleteHospitalExamination(hospitalId, body) {
  const res = await axios.delete(
    `/v3/admin/hospitalExaminations/${hospitalId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 모든 병원 검사를 가져옵니다.
 * @param {String} userId
 */
export async function fetchAllUserHospitalExamination(userId) {
  const res = await axios.get(
    `/v3/admin/users/${userId}/hospitalExaminationRecords`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 특정 병원 검사 정보를 가져옵니다.
 * @param {String} userId
 * @param {String} hospitalId
 */
export async function fetchUserHospitalExamination(userId, hospitalId) {
  const res = await axios.get(
    `/v3/admin/users/${userId}/hospitalExaminationRecords/${hospitalId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 병원 검사를 생성합니다.
 * @param {String} userId
 * @param {object} body
 */
export async function postUserHospitalExamination(userId, body) {
  const res = await axios.post(
    `/v3/admin/users/${userId}/hospitalExaminationRecords`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 병원 검사를 수정합니다.
 * @param {String} userId
 * @param {String} hospitalId
 * @param {object} body
 */
export async function patchUserHospitalExamination(userId, hospitalId, body) {
  const res = await axios.patch(
    `/v3/admin/users/${userId}/hospitalExaminationRecords/${hospitalId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 병원 검사를 삭제합니다.
 * @param {String} userId
 * @param {String} hospitalId
 */
export async function deleteUserHospitalExamination(userId, hospitalId) {
  const res = await axios.delete(
    `/v3/admin/users/${userId}/hospitalExaminationRecords/${hospitalId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 모든 질환인증을 가져옵니다.
 * @param {String} userId
 */
export async function fetchAllUserDiseaseTypeCertification(userId) {
  const res = await axios.get(
    `/v3/admin/users/${userId}/diseaseTypeCertifications`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 특정 질환인증을 가져옵니다.
 * @param {String} userId
 * @param {String} certificationId
 */
export async function fetchUserDiseaseTypeCertification(
  userId,
  certificationId,
) {
  const res = await axios.get(
    `/v3/admin/users/${userId}/diseaseTypeCertifications/${certificationId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 질환인증을 생성합니다.
 * @param {String} userId
 * @param {object} body
 */
export async function postUserDiseaseTypeCertification(userId, body) {
  const res = await axios.post(
    `/v3/admin/users/${userId}/diseaseTypeCertifications`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 질환인증을 수정합니다.
 * @param {String} userId
 * @param {String} certificationId
 * @param {object} body
 */
export async function patchUserDiseaseTypeCertification(
  userId,
  certificationId,
  body,
) {
  const res = await axios.patch(
    `/v3/admin/users/${userId}/diseaseTypeCertifications/${certificationId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 유저의 질환인증을 삭제합니다.
 * @param {String} userId
 * @param {String} certificationId
 */
export async function deleteUserDiseaseTypeCertification(
  userId,
  certificationId,
) {
  const res = await axios.delete(
    `/v3/admin/users/${userId}/diseaseTypeCertifications/${certificationId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
