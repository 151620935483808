import React from 'react';

import UserWithHealthProfile from '../../containers/userWithHealthProfile';

const DnaCounselingDetail = () => {
  return (
    <UserWithHealthProfile type="dnaCounselingRequest" tabLink="tabData" />
  );
};

export default DnaCounselingDetail;
