import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useReducer,
} from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { useSelector, useDispatch, batch, shallowEqual } from 'react-redux';
import { Form, Spinner, Row, Col } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import WindowHeader from '../../components/windowHeader';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { treatmentStudyCreators } from '../../../store/reducers/treatmentStudy.reducer';
import TextInput from '../../components/textInput';
import MutateButton from '../../components/mutateButton';
import {
  fetchDna,
  postDna,
  patchDna,
  deleteDna,
} from '../../../services/dnaService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import MultiSelectDropdown from '../../components/multiSelectDropdown';
import { dictionaryCreators } from '../../../store/reducers/dictionary.reducer';
import { useEditorImageDelete } from '../../util/useEditorImage';
import MultiWriteTextInput from '../../components/multiWriteTextInput';
import ElementLoading from '../../components/elementLoading';
import TinymceInput from '../../components/tinymceInput';
import AntTinymceInput from '../../components/antTinymceInput';
import { ALERT_MESSAGES } from '../../../assets/alert';

const DnaWindow = () => {
  // declare dispatch function & variables for fetching data from store
  const dispatch = useDispatch();
  const { dictionaryInfo, diseaseTypesInfo, treatmentStudyInfo } = useSelector(
    (state) => ({
      dictionaryInfo: state.dictionaryReducer.dictionary.data
        ? state.dictionaryReducer.dictionary.data.map(({ id, krName }) => ({
            id,
            krName,
          }))
        : null,
      diseaseTypesInfo: state.diseaseTypeReducer.diseaseTypes?.data
        ? state.diseaseTypeReducer.diseaseTypes.data.map(({ id, krName }) => ({
            id,
            krName,
          }))
        : null,
      treatmentStudyInfo: state.treatmentStudyReducer.treatmentStudy?.data
        ? state.treatmentStudyReducer.treatmentStudy.data
        : null,
    }),
    shallowEqual,
  );

  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [arrayToDelete, setArrayToDelete] = useState([]);
  const [addedPhotos, setAddedPhotos] = useState([]);
  const [initialPhotos, setInitialPhotos] = useState([]);
  const [uploadUrl, setUploadUrl] = useState(null);
  const [editorPhotos, setEditorPhotos] = useState([]);
  const [windowClose, setWindowClose] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  const [dnaId, setDnaId] = useState(null);
  const [isCanceled, setIsCanceled] = useState(false);

  const initialState = {
    name: '',
    location: '',
    chromosome: '',
    pq: '',
    detailLocation: '',
    selectedTargetDiseaseTypes: [],
    selectedTargetDnaTypes: [],
    subName: '',
    proteinType: '',
    content: '',
    selectedDictionaries: [],
    features: [],
    sources: [],
    selectedTreatmentStudies: [],
    featureContent: '',
    sourceContent: '',
    shareLink: '',
    styleVersion: 1,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const dnaTypeOptions = [
    {
      id: 1,
      krName: '상염색체 우성',
      enName: 'AD_Autosomal Dominant',
    },
    {
      id: 2,
      krName: '상염색체 열성',
      enName: 'AR_Autosomal Recessive',
    },
    {
      id: 3,
      krName: 'X염색체 우성',
      enName: 'XD_X-linked Dominant',
    },
    {
      id: 4,
      krName: 'X염색체 열성',
      enName: 'XR_X-linked Recessive',
    },
  ];
  const [state, setState] = useReducer(stateReducer, initialState);
  const {
    done: editorImageDeleteDone,
    imageDelete: editorImageDelete,
  } = useEditorImageDelete(arrayToDelete);

  const didCancel = useRef(false);
  const isDeleteImageExist = useRef(false);
  const deleteDone = useRef(false);

  const { data: fetchDnaData, call: callDna } = useFetch(null, fetchDna, dnaId);
  const { mutate: putDna, done: isPosted } = useMutate(postDna, {
    name: state.name,
    nameSearchWord: [state.subName],
    location: state.location,
    chromosome: state.chromosome,
    pq: state.pq,
    detailLocation: state.detailLocation,
    proteinType: state.proteinType,
    description: state.content,
    krDnaTypes: state.selectedTargetDnaTypes.map((dnaType) => dnaType.krName),
    enDnaTypes: state.selectedTargetDnaTypes.map((dnaType) => dnaType.enName),
    sources: state.sources,
    features: state.features,
    diseaseTypeIds: state.selectedTargetDiseaseTypes.map(
      (diseaseType) => diseaseType.id,
    ),
    treatmentStudyIds: state.selectedTreatmentStudies.map(
      (treatmentStudy) => treatmentStudy.id,
    ),
    dictionaryIds:
      state.styleVersion === 1
        ? state.selectedDictionaries.map((dictionary) => dictionary.id)
        : state.selectedDictionaries
            ?.map((dictionary) => {
              return dictionaryInfo?.find((info) => info.krName === dictionary)
                ?.id;
            })
            ?.filter(Boolean),
    shareLink: state.shareLink,
    styleVersion: state.styleVersion,
  });
  const { mutate: changeDna, done: isPatched } = useMutate(patchDna, dnaId, {
    name: state.name,
    nameSearchWord: [state.subName],
    location: state.location,
    chromosome: state.chromosome,
    pq: state.pq,
    detailLocation: state.detailLocation,
    proteinType: state.proteinType,
    description: state.content,
    krDnaTypes: state.selectedTargetDnaTypes.map((dnaType) => dnaType.krName),
    enDnaTypes: state.selectedTargetDnaTypes.map((dnaType) => dnaType.enName),
    sources: state.sources,
    features: state.features,
    diseaseTypeIds: state.selectedTargetDiseaseTypes.map(
      (diseaseType) => diseaseType.id,
    ),
    treatmentStudyIds: state.selectedTreatmentStudies.map(
      (treatmentStudy) => treatmentStudy.id,
    ),
    dictionaryIds:
      state.styleVersion === 1
        ? state.selectedDictionaries.map((dictionary) => dictionary.id)
        : state.selectedDictionaries
            ?.map((dictionary) => {
              return dictionaryInfo?.find((info) => info.krName === dictionary)
                ?.id;
            })
            ?.filter(Boolean),
    shareLink: state.shareLink,
    styleVersion: state.styleVersion,
  });
  const { mutate: eraseDna, done: isDeleted } = useMutate(deleteDna, dnaId);

  const message = isPosted
    ? ALERT_MESSAGES.CREATE
    : isPatched
    ? ALERT_MESSAGES.UPDATE
    : isDeleted
    ? ALERT_MESSAGES.DELETE
    : '';

  const isMutated = isPosted || isPatched || isDeleted;
  const needToDeleteEditorImage = Boolean(arrayToDelete.length > 0);

  const editorImageDeleteOnWindowCloseCondition =
    needToDeleteEditorImage && editorImageDeleteDone;

  const windowCloseCondition =
    windowClose && editorImageDeleteOnWindowCloseCondition;

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(dictionaryCreators.fetchAllDictionary.request());
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    dispatch(treatmentStudyCreators.fetchAllTreatmentStudies.request());
    if (params.id) {
      setDnaId(params.id);
    } else {
      setDnaId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (dnaId) {
      callDna();
      setIsLoading(false);
    }
  }, [dnaId, callDna]);

  const imagesOnPage = useCallback(() => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(
      fetchDnaData.description,
      'text/html',
    );
    const imageList = htmlDoc.getElementsByTagName('img');
    const existImage = [];
    for (let i = 0; i < imageList.length; i += 1) {
      const indexSrc = imageList[i].outerHTML.indexOf('src');
      const indexStart = imageList[i].outerHTML
        .substring(indexSrc)
        .indexOf('"');
      const indexEnd = imageList[i].outerHTML
        .substring(indexSrc + indexStart + 1)
        .indexOf('"');
      const imageUrl = imageList[i].outerHTML.substring(
        indexSrc + indexStart + 1,
        indexSrc + indexStart + indexEnd + 1,
      );
      existImage.push(imageUrl);
    }
    return existImage;
  }, [fetchDnaData]);

  useEffect(() => {
    if (fetchDnaData && !didCancel.current) {
      setInitialPhotos(imagesOnPage);
      setEditorPhotos(imagesOnPage);
      setState({
        name: fetchDnaData.name,
        location: fetchDnaData.location,
        chromosome: fetchDnaData.chromosome,
        pq: fetchDnaData.pq,
        detailLocation: fetchDnaData.detailLocation,
        selectedTargetDiseaseTypes: [...fetchDnaData.diseaseTypes],
        selectedTargetDnaTypes: fetchDnaData.krDnaTypes.map((krDnaType) =>
          dnaTypeOptions.find((option) => option.krName === krDnaType),
        ),
        subName: fetchDnaData.nameSearchWord[0] ?? '',
        proteinType: fetchDnaData.proteinType,
        content: fetchDnaData.description,
        selectedDictionaries:
          fetchDnaData.styleVersion === 1
            ? [...fetchDnaData.dictionaries]
            : fetchDnaData.dictionaries?.map((dictionary) => {
                return dictionary?.krName;
              }),
        features: fetchDnaData.features ? [...fetchDnaData.features] : [],
        sources: fetchDnaData.sources ? [...fetchDnaData.sources] : [],
        selectedTreatmentStudies: [...fetchDnaData.treatmentStudies],
        shareLink: fetchDnaData.shareLink ?? '',
        styleVersion: fetchDnaData.styleVersion,
      });
      didCancel.current = true;
    }
  }, [fetchDnaData, dnaTypeOptions, imagesOnPage]);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    if (arrayToDelete.length > 0 && !deleteDone.current) {
      editorImageDelete();
      setWindowClose(true);
      deleteDone.current = true;
    }
  }, [arrayToDelete, editorImageDelete]);

  const closeWindowAfterAlert = useCallback(() => {
    if (isMutated || isCanceled) {
      setWindowClose(false);
      setArrayToDelete([]);
      if (isMutated) alert(message);
      window.close();
    }
  }, [isMutated, isCanceled, message]);

  useEffect(() => {
    if (!isDeleteImageExist.current || windowCloseCondition) {
      closeWindowAfterAlert();
    }
  }, [windowCloseCondition, closeWindowAfterAlert]);

  useEffect(() => {
    if (uploadUrl && !editorPhotos.includes(uploadUrl)) {
      batch(() => {
        setEditorPhotos((prev) => [...prev, uploadUrl]);
        setAddedPhotos((prev) => [...prev, uploadUrl]);
        setUploadUrl(null);
      });
    }
  }, [uploadUrl, editorPhotos]);
  const changeState = useCallback((e) => {
    setState({ [e.target.name]: e.target.value });
  }, []);

  if (isLoading) return <ElementLoading type="유전자" />;
  return (
    <LoadingOverlay
      active={imageLoading}
      spinner={<Spinner animation="border" variant="info" />}
      text={<p>이미지를 업로드 하는 중</p>}
    >
      <WindowHeader title="유전자" />
      <Form className="windowForm">
        <TextInput
          text="이름"
          value={state.name}
          onChange={changeState}
          isEssential={true}
          name="name"
        />
        <TextInput
          text="위치"
          value={state.location}
          onChange={changeState}
          isEssential={true}
          name="location"
        />
        <TextInput
          text="염색체"
          value={state.chromosome}
          onChange={changeState}
          subContent={true}
          name="chromosome"
        />
        <TextInput
          text="p/q"
          value={state.pq}
          onChange={changeState}
          subContent={true}
          name="pq"
        />
        <TextInput
          text="상세 위치"
          value={state.detailLocation}
          onChange={changeState}
          subContent={true}
          name="detailLocation"
        />
        <MultiSelectDropdown
          text="관련 질환"
          data={[
            {
              option: diseaseTypesInfo,
              selectedOption: state.selectedTargetDiseaseTypes,
              setOption: changeState,
              optionName: '대상 질환(복수 선택 가능)을 선택하세요',
              name: 'selectedTargetDiseaseTypes',
            },
          ]}
        />
        <MultiSelectDropdown
          text="유전 형태"
          data={[
            {
              option: dnaTypeOptions,
              selectedOption: state.selectedTargetDnaTypes,
              setOption: changeState,
              optionName: '유전 형태(복수 선택 가능)을 선택하세요',
              name: 'selectedTargetDnaTypes',
            },
          ]}
        />
        <TextInput
          text="다른 이름"
          value={state.subName}
          onChange={changeState}
          name="subName"
        />
        <TextInput
          text="생성 단백질"
          value={state.proteinType}
          onChange={changeState}
          name="proteinType"
        />

        <Form.Group className="formContent" as={Row}>
          <Form.Label column sm="3" md="2" xl="1">
            스타일 버전
          </Form.Label>
          <Col sm="9" md="10" xl="11">
            <Form.Group className="formContent checkBoxContainer" as={Row}>
              <Form.Check
                inline
                label="1"
                name="styleVersion"
                type="radio"
                id="radio-1"
                onChange={() => {
                  setState({
                    styleVersion: 1,
                    selectedDictionaries: state.selectedDictionaries
                      ?.map((dictionary) => {
                        return dictionaryInfo?.find(
                          (info) => info.krName === dictionary,
                        );
                      })
                      ?.filter(Boolean),
                  });
                }}
                checked={state.styleVersion === 1}
              />
              <Form.Check
                inline
                label="2"
                name="styleVersion"
                type="radio"
                id="radio-2"
                onChange={() => {
                  setState({
                    styleVersion: 2,
                    selectedDictionaries: state.selectedDictionaries?.map(
                      (dictionary) => {
                        return dictionary?.krName;
                      },
                    ),
                  });
                }}
                checked={state.styleVersion === 2}
              />
            </Form.Group>
          </Col>
        </Form.Group>

        {state.styleVersion === 1 && (
          <>
            <TinymceInput
              content={state.content}
              setContent={changeState}
              windowId={dnaId}
              setUploadUrl={setUploadUrl}
              setImageLoading={setImageLoading}
              contentLinkData={{
                dictionaryInfo,
                diseaseTypeInfo: [],
                dnaInfo: [],
                setState,
              }}
              name="content"
            />
            <MultiSelectDropdown
              text="본문 링크"
              data={[
                {
                  option: dictionaryInfo,
                  selectedOption: state.selectedDictionaries,
                  setOption: changeState,
                  optionName: '용어',
                  name: 'selectedDictionaries',
                },
              ]}
            />
          </>
        )}
        {state.styleVersion === 2 && (
          <>
            <Form.Group className="formContent" as={Row}>
              <Form.Label column sm="3" md="2" xl="1">
                내용 <span className="required-tag">*필수</span>
              </Form.Label>
              <Col sm="9" md="10" xl="11">
                <AntTinymceInput
                  content={state.content}
                  setContent={(e) => {
                    setState({ content: e });
                  }}
                  setImageLoading={setImageLoading}
                  onBlur={(e) => {}}
                  contentLinkData={{ dictionaryInfo, setState }}
                />
              </Col>
            </Form.Group>
            <TextInput
              text="본문 링크"
              value={state.selectedDictionaries}
              placeholder="용어 본문 링크입니다."
              name="selectedDictionaries"
            />
          </>
        )}

        <MultiWriteTextInput
          text="유전자 특징"
          value={state.featureContent}
          setStateFunction={changeState}
          selectedTextArray={state.features}
          listName="features"
          inputName="featureContent"
          type="textarea"
        />
        <MultiWriteTextInput
          text="유전자 출처"
          value={state.sourceContent}
          setStateFunction={changeState}
          selectedTextArray={state.sources}
          listName="sources"
          inputName="sourceContent"
        />
        <MultiSelectDropdown
          text="관련 치료제"
          data={[
            {
              option: treatmentStudyInfo,
              selectedOption: state.selectedTreatmentStudies,
              setOption: changeState,
              optionName: '관련 치료제(복수 선택 가능)를 선택하세요',
              name: 'selectedTreatmentStudies',
            },
          ]}
        />
        <TextInput
          text="공유 링크"
          value={state.shareLink}
          placeholder="미압력 시 자동으로 생성됩니다"
          onChange={changeState}
          name="shareLink"
        />
        <MutateButton
          content={state.content}
          elementId={dnaId}
          create={putDna}
          update={changeDna}
          remove={eraseDna}
          setIsCanceled={setIsCanceled}
          initialPhotos={initialPhotos}
          addedPhotos={addedPhotos}
          setEditorRemoveArray={setArrayToDelete}
          isDeleteImageExist={isDeleteImageExist}
        />
      </Form>
    </LoadingOverlay>
  );
};

export default DnaWindow;
