import React from 'react';
import { Form, Button, ButtonGroup, Row, Col } from 'react-bootstrap';

const MultiWriteTextInput = ({
  type = 'input',
  text,
  value,
  setStateFunction,
  selectedTextArray,
  isEssential,
  listName,
  inputName,
}) => {
  // add specific text
  const addElement = () => {
    const newList = [...selectedTextArray, value];
    setStateFunction({ target: { name: listName, value: newList } });
    setStateFunction({ target: { name: inputName, value: '' } });
  };

  // remove specific text
  const removeElement = (removeIndex) => {
    const newList = selectedTextArray.filter(
      (_, index) => index !== removeIndex,
    );
    setStateFunction({ target: { name: listName, value: newList } });
  };
  return (
    <>
      <Form.Group className="formContent" as={Row}>
        <Form.Label column sm="3" md="2" xl="1">
          {text} {isEssential && <span className="required-tag">*필수</span>}
        </Form.Label>
        <Col sm="9" md="10" xl="11">
          <Form.Group>
            <Form.Control
              as={type}
              name={inputName}
              value={value}
              onChange={setStateFunction}
            />
          </Form.Group>
        </Col>
      </Form.Group>
      <Form.Group className="formContent" as={Row}>
        <Form.Label column sm="3" md="2" xl="1">
          <span className="required-tag">
            <Button
              className="ml-2"
              variant="outline-primary"
              style={{
                cursor: 'default',
                color: '#000',
              }}
              onClick={() => addElement()}
            >
              추가하기
            </Button>
          </span>
        </Form.Label>
        <Col sm="9" md="10" xl="11">
          <Form.Group className="formContent" as={Row}>
            {selectedTextArray !== null
              ? selectedTextArray.map((sourceItem, index) => (
                  <>
                    <ButtonGroup size="sm">
                      <Button
                        variant="secondary"
                        onClick={() => removeElement(index)}
                      >
                        &times;
                      </Button>
                      <Button
                        className="mr-2"
                        variant="outline-secondary"
                        disabled
                        style={{
                          cursor: 'default',
                          color: '#000',
                        }}
                      >
                        {sourceItem}
                      </Button>
                    </ButtonGroup>
                    <br />
                  </>
                ))
              : null}
          </Form.Group>
        </Col>
      </Form.Group>
    </>
  );
};

export default MultiWriteTextInput;
