import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useReducer,
} from 'react';
import { Form, Input, Radio, Button, Alert, Row, Col, Select, Tag } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';

import qs from 'query-string';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import WindowHeader from '../../components/windowHeader';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import ElementLoading from '../../components/elementLoading';

import {
  fetchUserDiseaseTypeCertification,
  postUserDiseaseTypeCertification,
  patchUserDiseaseTypeCertification,
  deleteUserDiseaseTypeCertification,
} from '../../../services/hospitalExaminationService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import { ALERT_MESSAGES } from '../../../assets/alert';
import * as listMap from '../../../util/listMap';

const certificationOptions = [
  {
    id: '0',
    enName: null,
    krName: '선택 없음',
  },
  {
    id: 'confirmation',
    enName: 'confirmation',
    krName: '확진',
  },
  {
    id: 'suspicion',
    enName: 'suspicion',
    krName: '의심',
  },
];

const tagsData = [
  '질환명',
  '질환코드',
  '산정특례코드',
  '원인유전자',
  '임상연구참여',
  '레노닥',
  '기타',
];

const UserdiseaseTypeCertificationWindow = (props) => {
  const dispatch = useDispatch();
  const { diseaseTypeInfo } = useSelector((state) => {
    return {
      diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data
        ? state.diseaseTypeReducer.diseaseTypes.data.map(({ id, krName }) => ({
            id,
            krName,
          }))
        : null,
    };
  }, shallowEqual);

  const { search } = props.location;
  const { CheckableTag } = Tag;
  const [windowId, setWindowId] = useState('');
  const [userId, setUserId] = useState('');
  const [diseaseTypeCertificationId, setDiseaseTypeCertificationId] = useState(
    null,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [windowClose, setWindowClose] = useState(false);
  const [btnLoadings, setBtnLoadings] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const dateFormat = 'YYYY/MM/DD';

  const didCancel = useRef(false);
  const isDeleteImageExist = useRef(null);

  const initialState = {
    dateAt: null,
    hospitalName: null,
    diseaseCode: null,
    certification: null,
    diseaseType: null,
    notSupportDiseaseTypeKrName: null,
    documentType: '',
    verifiedItems: [],
    verifiedDetail: null,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);

  const postPatchData = {
    institution: state.hospitalName,
    diseaseTypeId: state.diseaseType,
    notSupportDiseaseTypeKrName: null,
    diseaseTypeCode: state.diseaseCode,
    diagnosisDate: state.dateAt,
    type: state.certification ?? null,
    documentType: state.documentType,
    verifiedItems: state.verifiedItems,
    verifiedDetail: state.verifiedDetail,
  };

  const {
    data: fetchUserDiseaseTypeCertificationData,
    call: callDiseaseTypeCertification,
  } = useFetch(
    null,
    fetchUserDiseaseTypeCertification,
    userId,
    diseaseTypeCertificationId,
  );

  const { mutate: putDiseaseTypeCertification, done: isPosted } = useMutate(
    postUserDiseaseTypeCertification,
    userId,
    postPatchData,
  );

  const { mutate: changeDiseaseTypeCertification, done: isPatched } = useMutate(
    patchUserDiseaseTypeCertification,
    userId,
    diseaseTypeCertificationId,
    postPatchData,
  );

  const { mutate: eraseDiseaseTypeCertification, done: isDeleted } = useMutate(
    deleteUserDiseaseTypeCertification,
    userId,
    diseaseTypeCertificationId,
  );

  const message = isPosted
    ? ALERT_MESSAGES.CREATE
    : isPatched
    ? ALERT_MESSAGES.UPDATE
    : isDeleted
    ? ALERT_MESSAGES.DELETE
    : '';

  const isMutated = isPosted || isPatched || isDeleted;
  const windowCloseCondition = windowClose;

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    const ids = params?.id?.split('/');
    if (ids[1]) {
      setUserId(ids[0]);
      setDiseaseTypeCertificationId(ids[1]);
    } else {
      setUserId(ids[0]);
      setDiseaseTypeCertificationId('');
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (diseaseTypeCertificationId) callDiseaseTypeCertification();
  }, [diseaseTypeCertificationId, callDiseaseTypeCertification]);
  useEffect(() => {
    if (
      fetchUserDiseaseTypeCertificationData &&
      diseaseTypeInfo &&
      !didCancel.current
    ) {
      setState({
        diseaseType: fetchUserDiseaseTypeCertificationData.diseaseTypeKrName,
        dateAt: fetchUserDiseaseTypeCertificationData.diagnosisDate,
        hospitalName: fetchUserDiseaseTypeCertificationData.institution,
        diseaseCode: fetchUserDiseaseTypeCertificationData.diseaseTypeCode,
        certification: certificationOptions?.filter(
          (certification) =>
            certification.enName === fetchUserDiseaseTypeCertificationData.type,
        )?.[0]?.enName,
        documentType:
          fetchUserDiseaseTypeCertificationData?.documentType === null
            ? ''
            : fetchUserDiseaseTypeCertificationData?.documentType,
        verifiedItems: fetchUserDiseaseTypeCertificationData.verifiedItems,
        verifiedDetail: fetchUserDiseaseTypeCertificationData.verifiedDetail,
      });
      didCancel.current = true;
      setIsLoading(false);
    } else if (diseaseTypeCertificationId === '' && !didCancel.current) {
      didCancel.current = true;
      setIsLoading(false);
    }
  }, [
    fetchUserDiseaseTypeCertificationData,
    diseaseTypeInfo,
    diseaseTypeCertificationId,
  ]);

  const closeWindowAfterAlert = useCallback(() => {
    if (isMutated) {
      setWindowClose(false);
      if (isMutated) alert(message);
      window.close();
    }
  }, [isMutated, message]);

  useEffect(() => {
    if (!isDeleteImageExist.current || windowCloseCondition) {
      closeWindowAfterAlert();
    }
  }, [windowCloseCondition, closeWindowAfterAlert]);

  const changeState = useCallback((e) => {
    setState({ [e.target.name]: e.target.value });
  }, []);

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...state?.verifiedItems, tag]
      : state?.verifiedItems?.filter((t) => t !== tag);
    setState({ verifiedItems: nextSelectedTags });
  };

  const onFinish = (values) => {
    setState({
      certification: values.certification,
      hospitalName: values.hospitalName,
      diseaseCode: values.diseaseCode,
      diseaseType: diseaseTypeInfo.find(
        (diseaseType) => diseaseType.krName === values.diseaseType,
      ).id,
      documentType: values.documentType,
      dateAt: values.reportedAt
        ? moment(values.reportedAt).format(dateFormat)
        : null,
      verifiedDetail: values.verifiedDetail,
    });

    setShowAlert(false);
    if (diseaseTypeCertificationId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
        setBtnLoadings(true);
      } else {
        setBtnLoadings(false);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
      setBtnLoadings(true);
    } else {
      setBtnLoadings(false);
    }
    setBtnLoadings(false);
  };

  const onFinishFailed = () => {
    setBtnLoadings(false);
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      eraseDiseaseTypeCertification();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeDiseaseTypeCertification();
      setIsPatch(false);
    }
    if (isPost) {
      putDiseaseTypeCertification();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  if (isLoading) return <ElementLoading type="질환 인증" />;
  return (
    <>
      <WindowHeader title="질환 인증" />

      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              hospitalName: state.hospitalName,
              reportedAt: state.dateAt
                ? moment(state.dateAt).format('YYYYMMDD')
                : '',
              diseaseCode: state.diseaseCode,
              diseaseType: state.diseaseType,
              certification: state.certification,
              documentType: state.documentType,
              verifiedDetail: state.verifiedDetail,
            }}
          >
            <Form.Item
              label="일시"
              name="reportedAt"
              rules={[
                {
                  pattern: new RegExp(/^[0-9]{8}$/),
                  message: '8자리의 숫자만 입력해주세요.(ex: 20220701)',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="병원명" name="hospitalName">
              <Input />
            </Form.Item>

            <Form.Item
              name="diseaseType"
              label="질환"
              hasFeedback
              rules={[{ required: true, message: '질환을 선택해주세요' }]}
            >
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                placeholder="질환을 선택해주세요"
                name="diseaseType"
              >
                {diseaseTypeInfo?.map((diseaseType) => (
                  <Select.Option
                    key={diseaseType?.krName}
                    id={diseaseType?.id}
                    value={diseaseType?.krName}
                  >
                    {diseaseType?.krName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="질환코드" name="diseaseCode">
              <Input />
            </Form.Item>

            <Form.Item name="certification" label="인증" hasFeedback>
              <Select
                allowClear
                style={{ width: '100%' }}
                placeholder="인증을 선택해주세요"
                name="certification"
              >
                {certificationOptions?.map((certification) => (
                  <Select.Option
                    key={certification?.id}
                    id={certification?.id}
                    value={certification?.enName}
                  >
                    {certification?.krName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="인증 근거"
              name="verifiedItems"
              className="diseaseTypeCertificationTag"
            >
              {tagsData.map((tag) => (
                <CheckableTag
                  key={tag}
                  checked={state?.verifiedItems.indexOf(tag) > -1}
                  onChange={(checked) => handleChange(tag, checked)}
                >
                  {tag}
                </CheckableTag>
              ))}
            </Form.Item>
            <Form.Item label="인증 근거 상세" name="verifiedDetail">
              <Input />
            </Form.Item>
            <Form.Item label="서류 종류" name="documentType">
              <Row>
                <Col span={24}>
                  <div style={{ marginTop: 6 }}>진단서</div>
                  <Radio.Group
                    name="documentType"
                    onChange={changeState}
                    value={state.documentType}
                    optionType="button"
                    buttonStyle="solid"
                    style={{ marginBottom: 8 }}
                    options={listMap.diagnosisOptions}
                  />
                  <div style={{ marginTop: 6 }}>소견서</div>
                  <Radio.Group
                    name="documentType"
                    onChange={changeState}
                    value={state.documentType}
                    optionType="button"
                    buttonStyle="solid"
                    style={{ marginBottom: 8 }}
                    options={listMap.opinionOptions}
                  />
                  <div style={{ marginTop: 6 }}>입퇴원 관련 서류</div>
                  <Radio.Group
                    name="documentType"
                    onChange={changeState}
                    value={state.documentType}
                    optionType="button"
                    buttonStyle="solid"
                    style={{ marginBottom: 8 }}
                    options={listMap.hospitalizationOptions}
                  />
                  <div style={{ marginTop: 6 }}>진료 관련 서류</div>
                  <Radio.Group
                    name="documentType"
                    onChange={changeState}
                    value={state.documentType}
                    optionType="button"
                    buttonStyle="solid"
                    style={{ marginBottom: 8 }}
                    options={listMap.treatmentOptions}
                  />
                  <div style={{ marginTop: 6 }}>처방전</div>
                  <Radio.Group
                    name="documentType"
                    onChange={changeState}
                    value={state.documentType}
                    optionType="button"
                    buttonStyle="solid"
                    style={{ marginBottom: 8 }}
                    options={listMap.prescriptionOptions}
                  />
                  <div style={{ marginTop: 6 }}>기타 서류</div>
                  <Radio.Group
                    name="documentType"
                    onChange={changeState}
                    value={state.documentType}
                    optionType="button"
                    buttonStyle="solid"
                    style={{ marginBottom: 8 }}
                    options={listMap.etcOptions}
                  />
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: 100 }}
                loading={btnLoadings}
                onClick={() => setBtnLoadings(true)}
              >
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {diseaseTypeCertificationId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요. 내용 또는 이미지를 입력해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default UserdiseaseTypeCertificationWindow;
