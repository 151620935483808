import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout, Input, Button } from 'antd';
import 'antd/dist/antd.css';
import AntList from '../../components/antList';
import DeployButton from '../../components/deployButton';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { NEW } from '../../../window/util/utils';
import { useMutate } from '../../../hooks/useRequest';
import { patchDeployResearch } from '../../../services/geneResearchService';
import { geneResearchCreators } from '../../../store/reducers/geneResearch.reducer';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import useWindow from '../../../hooks/useWindow';
import * as listMap from '../../../util/listMap';
import DiseaseHealthProfileExcelDown from './diseaseHealthProfileExcelDown';

const Research = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { research, researchPagination } = useSelector((state) => {
    return {
      research: state?.geneResearchReducer?.research.data,
      researchPagination: state?.paginationReducer?.researchPagination,
    };
  }, shallowEqual);

  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const confirmRef = useRef(() => {});

  const getData = useCallback(() => {
    dispatch(geneResearchCreators.fetchAllResearchs.request());
  }, [dispatch]);
  useEffect(getData, [getData]);

  useEffect(() => {
    if (research) {
      if (researchPagination.total === null) setTotal(research?.length);
      else setTotal(researchPagination.total);
      setPageUpdate(researchPagination.page);
      confirmRef.current(researchPagination.text);
    }
  }, [research, researchPagination]);

  const { findWindowById, createWindow, destroyWindowById } = useWindow();
  const { Search } = Input;
  const { Content } = Layout;
  const [deployChangeInfo, setDeployChangeInfo] = useState([]);

  const {
    done: patchDeployDone,
    mutate: patchDeployMutate,
    initialize: patchDeployInitialize,
  } = useMutate(patchDeployResearch, {
    ids: deployChangeInfo.map((deployInfo) => deployInfo.id),
    changes: deployChangeInfo.map((deployInfo) => {
      return {
        isApp:
          deployInfo.isApp === undefined
            ? research.find(({ id }) => id === deployInfo.id).isApp
            : deployInfo.isApp,
      };
    }),
  });

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateEditWindow = (record) => {
    if (findWindowById(record.id)) {
      alert('이미 편집중인 글입니다.');
      return;
    }

    createWindow({
      id: record.id,
      dataType: 'research',
      subId: JSON.stringify(record),
    });
  };

  const handleCreateNewWindow = () => {
    createWindow({ id: `${NEW}${Date.now()}`, dataType: 'research' });
  };

  const deploy = () => {
    if (window.confirm('앱에 변경된 사항을 반영하여 배포하시겠습니까?')) {
      patchDeployInitialize();
      patchDeploy();
    }
  };

  const patchDeploy = () => {
    patchDeployMutate();
    setDeployChangeInfo([]);
  };

  const cancelDeploy = () => {
    setDeployChangeInfo([]);
  };

  const isDeployReady = deployChangeInfo?.length > 0;

  const onClickDeploy = () => {
    if (!isDeployReady) {
      alert('배포할 변경사항이 없습니다.');
      return;
    }
    deploy();
  };

  const onClickCancelDeploy = () => {
    if (!isDeployReady) {
      alert('변경사항이 없습니다.');
      return;
    }
    cancelDeploy();
  };

  useEffect(() => {
    if (patchDeployDone) {
      getData();
    }
  }, [getData, patchDeployDone]);

  const onChange = (pagination, filters, sorter, extra) => {
    dispatch(
      paginationCreators.setValue(
        (researchPagination.page = pagination?.current),
        (researchPagination.filter = filters),
        (researchPagination.total = extra?.currentDataSource?.length),
      ),
    );
    setTotal(extra?.currentDataSource?.length);
  };

  const columns = [
    {
      title: '연구명',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a?.title?.localeCompare(b?.title),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: researchPagination.filter?.title || null,
      onFilter: (search, { title, hostHospitalName, diseaseTypes }) =>
        [
          title,
          hostHospitalName,
          diseaseTypes?.map((item) => item?.krName)?.join(', '),
        ].some((value) => value?.includes(search)),

      render: (text, record) => (
        <a onClick={() => handleCreateEditWindow(record)}>{text}</a>
      ),
    },
    {
      title: '연구 주관 병원',
      width: 200,
      dataIndex: 'hostHospitalName',
      key: 'hostHospitalName',
      sorter: {
        compare: (a, b) =>
          a?.hostHospitalName?.localeCompare(b?.hostHospitalName),
      },
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypes',
      key: 'diseaseTypes',
      width: 330,
      render: (value) => value?.map((item) => item?.krName)?.join(', '),
      sorter: {
        compare: (a, b) => {
          const nameA = a.diseaseTypes[0]?.krName || '';
          const nameB = b.diseaseTypes[0]?.krName || '';
          return nameA.localeCompare(nameB);
        },
      },
    },
    {
      title: '앱 노출',
      dataIndex: 'isApp',
      key: 'isApp',
      width: '100px',
      filters: [
        {
          text: 'On',
          value: true,
        },
        {
          text: 'Off',
          value: false,
        },
      ],
      filteredValue: researchPagination.filter?.isApp || null,
      onFilter: (value, record) => record.isApp === value,
    },
    {
      title: '연구 상태',
      dataIndex: 'status',
      key: 'status',
      render: (value) => listMap.researchStatusMap[value],
      sorter: {
        compare: (a, b) => a?.status?.localeCompare(b?.status),
      },
      filteredValue: researchPagination.filter?.status || null,
      filters: [
        {
          text: '연구 준비 중',
          value: 'ready',
        },
        {
          text: '연구 모집 중',
          value: 'recruiting',
        },
        {
          text: '연구 완료',
          value: 'completed',
        },
      ],
      onFilter: (value, record) => record?.status?.includes(value),
    },
    {
      title: '연결된 설문',
      dataIndex: 'healthProfiles',
      key: 'healthProfiles',
      width: 200,
      render: (text) => {
        const isLinkRequired = text.some((item) => item.isLinkRequired);
        return isLinkRequired ? (
          <a
            style={{ color: '#40a9ff', textDecoration: 'underline' }}
            onClick={() => history.push('/geneResearch')}
          >
            {text.map((item) => item.type).join(', ')}
          </a>
        ) : (
          text.map((item) => item.type).join(', ')
        );
      },
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabData" link="research" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="데이터"
            subTitle="연구 신청"
            className="white-bg"
          />
          <Content className="excelDownContentStyle">
            <DiseaseHealthProfileExcelDown />
          </Content>
          <Content className="site-layout-background contentStyle">
            <DeployButton
              onClickDeploy={onClickDeploy}
              onClickCancelDeploy={onClickCancelDeploy}
              disabled={!isDeployReady}
            />
            <div className="divider" />
            <Button
              type="primary"
              style={{ width: 100 }}
              onClick={handleCreateNewWindow}
            >
              추가
            </Button>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
                dispatch(
                  paginationCreators.setValue(
                    (researchPagination.text = value),
                  ),
                );
              }}
              defaultValue={researchPagination.text}
            />
            <div className="searchResult">
              {research && `검색결과 ${total}개`}
            </div>

            <AntList
              deployChangeInfo={deployChangeInfo}
              setDeployChangeInfo={setDeployChangeInfo}
              pageUpdate={pageUpdate}
              setPageUpdate={setPageUpdate}
              columns={columns}
              dataSource={research}
              onChange={onChange}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Research;
