import { all, takeLatest } from 'redux-saga/effects';
import { popupCreators, popupActions } from '../reducers/popup.reducer';

import { createAsyncSaga } from '../reducer.util';
import { fetchAllPopup } from '../../services/popupService';

const handleFetchAllPopups = createAsyncSaga(
  popupCreators.fetchAllPopups,
  fetchAllPopup,
);

export function* popupSaga() {
  yield all([takeLatest(popupActions.FETCH_ALL_POPUPS, handleFetchAllPopups)]);
}
